export const editCarFormInitialState = {
  vin: '',
  make: null,
  model: null,
  referenceNumber: '',
  type: 0,
  details: '',
  mileage: 0,
  interiorColor: '',
  exteriorColor: '',
  isMetallic: false,
  isMatt: false,
  isWrapped: false,
  upholstery: 0,
  wheelLocation: '',
  rims: 0,
  additionalInfo: '',
  emissionLabel: '',
  bodyType: 0,
  engineType: 0,
  firstRegistrationDate: null,
  hp: 0,
  origin: 0,
  engineSize: 0,
  cylinders: 0,
  gearbox: 0,
  driveType: 0,
  gearsNumber: 0,
  seatsNumber: 0,
  doorsNumber: 0,
  carHiddenPriceShow: true,
  weight: 0,
  isArchived: true,
  archivedAt: null,
  createdAt: null,
  lastUpdatedAt: null,
  deletedAt: null,
  combinedStatus: '',
  engineNumber: '',
  fuelConsumption: '',
  electricityConsumption: '',
  CO2Emission: 0,
  energyEfficiencyCategory: '',
  euroNorm: 0,
  lastAppraisalDoneAt: null,
  manufacturerSpecs: [''],
  additionalSpecsDetails: '',
  creationSource: 0,
  isFinanceRelated: true,
  isInsuranceRelated: true,
  isWarrantyRelated: true,
  carAge: 0,
}

export const getEditCarFormInitialState = (car) => {
  return {
    ...editCarFormInitialState,
    ...car,
  }
}
