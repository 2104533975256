import { getCars } from '../cars.actions'
import { apiConstants } from '../../../../api/api'
import { API_ERROR_CODES } from '../../../../api/APIErrors'

export default (builder) => {
  builder
    .addCase(getCars.pending, (state) => {
      state.isLoading = true
    })
    .addCase(getCars.fulfilled, (state, action) => {
      state.isLoading = false
      state.error = null
      state.data = action.payload?.[apiConstants.hydraMember]
      state.total = action.payload?.[apiConstants.hydraTotal]
      if (state.initialTotal === 0) {
        state.initialTotal = action.payload?.[apiConstants.hydraTotal]
      }
      if (!state.initialized) state.initialized = true
    })
    .addCase(getCars.rejected, (state, action) => {
      if (action.payload?.code !== API_ERROR_CODES.ERR_CANCELED) {
        state.isLoading = false
        state.error = action.payload
      }
    })
}
