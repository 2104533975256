import React, { useEffect, useState } from 'react'
import { Box, Typography, useMediaQuery } from '@mui/material'
import { useParams } from 'react-router-dom'
import { privateRequest } from '../../utils/request'
import { API_ENDPOINTS } from '../../config/default'
import { colorsConfig } from '../../config/themeConfig'
import IconStatusGreen from '../../assests/img/icons/icon-status-green.svg'
import IconStatusOrange from '../../assests/img/icons/icon-status-orange.svg'
import TextH1 from '../../ui/Text/TextH1'

const CarLoggingNodes = () => {
  const xsOnly = useMediaQuery('(max-width: 600px)')
  const params = useParams()

  const [loggingNodes, setLoggingNodes] = useState([])

  const getCarLoggingNodes = () => {
    privateRequest.get(`/cars/${params.id}${API_ENDPOINTS.carLoggingNodes}`).then((response) => {
      setLoggingNodes(response.data['hydra:member'])
    })
  }

  useEffect(() => {
    getCarLoggingNodes()
  }, [])

  return (
    <Box>
      <TextH1
        className={xsOnly ? 'xs-only' : ''}
        sx={{
          mb: '60px',
          '&.xs-only': {
            mb: '30px',
            fontSize: '24px',
          },
        }}
      >
        Logging Tree
      </TextH1>
      <Box>
        {loggingNodes.map((log, index) => {
          const performer = log.translationContext.actionPerformer.includes('Fleatify')
            ? 'operator'
            : 'dealer'
          const time = new Date(log.createdAt).toLocaleTimeString()
          const weekDay = new Date(log.createdAt).toLocaleDateString('en-US', { weekday: 'long' })
          const date = new Date(log.createdAt).toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
          })
          return (
            <Box
              key={index}
              className={xsOnly ? 'xs-only' : ''}
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'flex-start',
                mb: 2,
                position: 'relative',
                '&.xs-only': {
                  flexDirection: 'column-reverse',
                  gap: '4px',
                  mb: '30px',
                },
              }}
            >
              <Box
                className={xsOnly ? 'xs-only' : ''}
                sx={{
                  position: 'absolute',
                  left: '45%',
                  top: '-2px',
                  width: '26px',
                  height: '26px',
                  '&.xs-only': {
                    top: 0,
                    left: 0,
                  },
                }}
              >
                <img src={performer === 'dealer' ? IconStatusGreen : IconStatusOrange} alt="" />
              </Box>
              {index === loggingNodes.length - 1 || xsOnly ? null : (
                <Box
                  sx={{
                    position: 'absolute',
                    left: '45%',
                    top: '32px',
                    width: '1px',
                    height: 'calc(100% - 23px)',
                    background: colorsConfig.mainBlack,
                    marginLeft: '12px',
                  }}
                />
              )}

              <Box
                className={xsOnly ? 'xs-only' : ''}
                sx={{
                  width: 'calc(50% - 25px)',
                  '&.xs-only': {
                    width: '100%',
                  },
                }}
              >
                <Typography
                  variant="body2"
                  sx={{
                    fontSize: '16px',
                    fontWeight: 400,
                    color: colorsConfig.iconGray,
                    textAlign: 'left',
                  }}
                >
                  {weekDay}, {date} at {time}
                </Typography>
              </Box>
              <Box
                className={xsOnly ? 'xs-only' : ''}
                sx={{
                  width: 'calc(50% - 25px)',
                  '&.xs-only': {
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column-reverse',
                  },
                }}
              >
                <Typography
                  sx={{
                    fontWeight: 700,
                    fontSize: '16px',
                    textAlign: 'left',
                    color: colorsConfig.mainBlack,
                  }}
                >
                  {log.translationContext.description}
                </Typography>
                <Typography
                  className={xsOnly ? 'xs-only' : ''}
                  sx={{
                    fontWeight: 400,
                    fontSize: '16px',
                    color: colorsConfig.iconGray,
                    textAlign: 'left',
                    '&.xs-only': {
                      pl: '35px',
                      mb: '10px',
                    },
                  }}
                >
                  {log.translationContext.actionPerformer}
                </Typography>
              </Box>
            </Box>
          )
        })}
      </Box>
    </Box>
  )
}

export default CarLoggingNodes
