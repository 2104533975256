import React, { useEffect, useState } from 'react'
import { Box, Grid, Stack, StepConnector } from '@mui/material'
import LayoutPublicMini from '../../../components/Layout/LayoutPublicMini'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import { colorsConfig } from '../../../config/themeConfig'
import AdditionalInfoStep from '../../AddNewVehicle/Steps/AdditionalInfoStep'
import { useTranslation } from 'react-i18next'
import UITextRegular from '../../../ui/UIText/UITextRegular'
import ButtonLG from '../../../ui/Buttons/ButtonLG'
import VehicleReducedDataStep from '../../AddNewVehicle/Steps/VehicleReducedDataStep'
import {
  getReducedMainValuesFormData,
  requiredFieldsInitialData,
} from '../../AddNewVehicle/addNewVehicleForm.helpers'
import { useDispatch, useSelector } from 'react-redux'
import { getEnumDetails } from '../../../store/features/references/references.actions'
import { useParams } from 'react-router-dom'
import {
  createCustomerCar,
  getCustomerAppraisal,
  submitCustomerAppraisal,
  updateCustomerCar,
} from '../../../store/features/customerAppraisal/customerAppraisal.actions'
import AppraisalRoot from './AppraisalRoot'
import ButtonHollow from '../../../ui/Buttons/ButtonHollow'
import UIPreloader from '../../../ui/UIPreloader/UIPreloader'
import { CheckCircleRounded, WarningRounded } from '@mui/icons-material'
import UITextHeadlineSmall from '../../../ui/UIText/UITextHeadlineSmall'
import useBreakpoints from '../../../hooks/useBreakpoints'
import UIStepCounter from '../../../ui/UIBoxes/UIStepCounter'
import UITextTitleSmall from '../../../ui/UIText/UITextTitleSmall'
import { isCarDataValid } from './appraisalByLink.helpers'
import AppraisalOverview from './AppraisalOverview/AppraisalOverview'
import UIPaper from '../../../ui/UIPaper/UIPaper'

const AppraisalByLink = () => {
  const { t, i18n } = useTranslation()
  const { smDown } = useBreakpoints()
  const dispatch = useDispatch()
  const params = useParams()

  const isCreatingCar = useSelector(
    (state) => state.customerAppraisal.customer.car.create.isLoading,
  )
  const isUpdatingCar = useSelector(
    (state) => state.customerAppraisal.customer.car.update.isLoading,
  )
  const carFirstData = useSelector((state) => state.addCar.carFirstData)
  const carMainData = useSelector((state) => state.addCar.carMainData)
  const car = useSelector((state) => state.customerAppraisal.customer?.car?.data)
  const isSubmittingAppraisal = useSelector(
    (state) => state.customerAppraisal.submitAppraisal.isLoading,
  )
  const customerAppraisal = useSelector((state) => state.customerAppraisal.customer.data)
  const carDataAppraisal = useSelector((state) => state.car.appraisal)
  const enumDetails = useSelector((state) => state.ref.enumDetails.data)
  const submitAppraisalSuccess = useSelector(
    (state) => state.customerAppraisal.submitAppraisal.success,
  )

  const [activeStep, setActiveStep] = useState(0)
  const [displaySuccessMessage, setDisplaySuccessMessage] = useState(false)
  const [showErrors, setShowErrors] = useState(false)
  const [isFirstStepValid, setIsFirstStepValid] = useState(false)

  const [requiredFieldsData, setRequiredFieldsData] = useState(requiredFieldsInitialData)

  const [appraisalLinkExpired, setAppraisalLinkExpired] = useState(false)

  const localToken = localStorage.getItem('X-Appraisal-Token')

  const steps = [
    {
      label: t('step_customer_appraisal_vehicle_data'),
      component: (
        <VehicleReducedDataStep
          showErrors={showErrors}
          handleNext={() => setActiveStep(activeStep + 1)}
          formData={requiredFieldsData}
          setFormData={setRequiredFieldsData}
        />
      ),
      nextButtonText: t('button_next_step'),
    },
    {
      label: t('step_customer_appraisal_appraisal'),
      component: (
        <AppraisalRoot carId={car?.id} activeStep={activeStep} setActiveStep={setActiveStep} />
      ),
      nextButtonText: t('button_next_step'),
    },
    {
      label: t('step_customer_appraisal_additional_information'),
      component: <AdditionalInfoStep />,
      nextButtonText: t('button_park_car_to_virtual_garage'),
      nextButtonTextMobile: t('button_add_car'),
    },
    {
      label: t('step_customer_appraisal_review_and_submit'),
      component: <AppraisalOverview />,
    },
  ]

  const handleNext = async () => {
    if (activeStep === 0) {
      const coreData = {
        vin: carFirstData.vin.value,
        make: carFirstData.make.id,
        model: carFirstData.model.id,
      }
      const mainData = getReducedMainValuesFormData(requiredFieldsData)
      if (!isCarDataValid(coreData, mainData)) {
        setIsFirstStepValid(false)
        setShowErrors(true)
        return
      }
      if (car && car?.id) {
        const res = await dispatch(
          updateCustomerCar({
            data: {
              ...coreData,
              ...mainData,
            },
            token: params?.token,
          }),
        )
        if (updateCustomerCar.fulfilled.match(res)) {
          setActiveStep(activeStep + 1)
        }
      } else {
        const res = await dispatch(
          createCustomerCar({
            coreData,
            mainData,
            token: params?.token,
          }),
        )
        if (createCustomerCar.fulfilled.match(res)) {
          setActiveStep(activeStep + 1)
        }
      }
    }
    if (activeStep === 2) {
      if (carMainData?.additionalInfo && carMainData?.additionalInfo !== '')
        dispatch(
          updateCustomerCar({
            data: {
              additionalInfo: carMainData.additionalInfo,
            },
            token: params?.token,
          }),
        )
      setActiveStep(activeStep + 1)
    }
    if (activeStep === 3) {
      const res = await dispatch(submitCustomerAppraisal({ token: params?.token }))
      if (submitCustomerAppraisal.fulfilled.match(res)) {
        setDisplaySuccessMessage(true)
        setActiveStep(0)
      }
    }
  }

  const handleSetToken = () => {
    localStorage.setItem('X-Appraisal-Token', params?.token)
  }

  const handleInit = async () => {
    const res = await dispatch(getCustomerAppraisal({ token: params?.token }))
    if (getCustomerAppraisal.rejected.match(res) && res.payload?.status === 401) {
      setAppraisalLinkExpired(true)
    }
  }

  const handleGetEnumDetails = async () => {
    await dispatch(getEnumDetails())
  }

  useEffect(() => {
    if (showErrors) {
      const coreData = {
        vin: carFirstData.vin.value,
        make: carFirstData.make.id,
        model: carFirstData.model.id,
      }
      const mainData = getReducedMainValuesFormData(requiredFieldsData)
      setIsFirstStepValid(isCarDataValid(coreData, mainData))
    }
  }, [showErrors, carFirstData, requiredFieldsData])

  useEffect(() => {
    if (!customerAppraisal?.car && enumDetails?.length > 0) {
      setRequiredFieldsData({ ...requiredFieldsData, origin: 15 })
    }
  }, [enumDetails, customerAppraisal])

  useEffect(() => {
    if (localToken) {
      handleGetEnumDetails()
    }
  }, [i18n.language, localToken])

  useEffect(() => {
    handleSetToken()
    handleInit()
    return () => {
      localStorage.removeItem('X-Appraisal-Token')
    }
  }, [])

  return (
    <LayoutPublicMini>
      {appraisalLinkExpired ? (
        <Box
          sx={{ height: '80vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        >
          <UIPaper>
            <Stack gap="15px">
              <Stack direction="row" alignItems="center" justifyContent="center" gap="10px">
                <WarningRounded sx={{ fill: colorsConfig.buttonYellow, fontSize: '30px' }} />
                <UITextHeadlineSmall text="title_customer_appraisal_link_expired" />
              </Stack>
              <UITextRegular
                color={colorsConfig.iconGray}
                text="p_customer_appraisal_link_expired"
                sx={{ textAlign: 'center' }}
              />
            </Stack>
          </UIPaper>
        </Box>
      ) : (
        <Stack gap={{ xs: '15px', sm: '30px' }} sx={{ position: 'relative' }}>
          {isSubmittingAppraisal && <UIPreloader text={t('preloader_submitting_appraisal')} />}
          {displaySuccessMessage && (
            <Box
              sx={{
                position: { xs: 'fixed', sm: 'absolute' },
                top: { sm: 0, xs: 0 },
                left: { sm: '-20px', xs: 0 },
                width: {
                  xs: '100vw',
                  sm: 'calc(100% + 40px)',
                },
                height: { xs: '100vh', sm: '100%' },
                background: 'white',
                zIndex: 11,
                p: {
                  xs: '16px',
                  sm: 0,
                },
              }}
            >
              {smDown && <LayoutPublicMini />}

              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100%',
                }}
              >
                <Stack gap="30px" alignItems="center">
                  <Stack
                    direction="row"
                    alignItems="center"
                    gap="10px"
                    sx={{ position: 'relative', ml: '-15px' }}
                  >
                    <CheckCircleRounded sx={{ fill: colorsConfig.mainGreen, fontSize: '32px' }} />
                    <UITextHeadlineSmall text={t('common_thank_you')} />
                  </Stack>
                  <UITextRegular
                    sx={{ textAlign: { xs: 'center', sm: 'left' } }}
                    text={t('p_customer_appraisal_submit_appraisal_success')}
                  />
                </Stack>
              </Box>
            </Box>
          )}
          {smDown ? (
            <Stack direction="row" gap="10px" alignItems="center">
              <UIStepCounter current number={activeStep + 1} />
              <UITextTitleSmall text={steps[activeStep]?.label} />
            </Stack>
          ) : (
            <Stepper sx={{ mt: '50px' }} activeStep={activeStep} connector={<StepConnector />}>
              {steps.map((stepItem, index) => {
                return (
                  <Step key={index}>
                    <Stack direction="row" alignItems="center" gap="10px">
                      <UIStepCounter
                        completed={activeStep > index}
                        current={activeStep === index}
                        number={index + 1}
                      />
                      <UITextRegular text={stepItem?.label} />
                    </Stack>
                  </Step>
                )
              })}
            </Stepper>
          )}

          <Grid
            container
            sx={{
              position: 'relative',
              minHeight: '200px',
              ...(smDown && {
                pb: '50px',
              }),
            }}
          >
            {isCreatingCar || isUpdatingCar ? (
              <UIPreloader text={t('preloader_saving_data')} />
            ) : null}
            {steps[activeStep].component}
          </Grid>
          {activeStep !== 1 && (
            <Stack
              direction="row"
              gap="10px"
              justifyContent="flex-end"
              sx={{
                ...(smDown && {
                  position: 'fixed',
                  bottom: 0,
                  right: 0,
                  width: '100vw',
                  p: '16px',
                  background: 'white',
                  zIndex: 10,
                  boxShadow: '0 0 24px 0 rgba(255,255,255,.5)',
                  '& button': {
                    flex: 1,
                    maxWidth: activeStep === 0 ? '100%' : 'calc(50% - 5px)',
                  },
                }),
              }}
            >
              {activeStep !== 0 && (
                <ButtonHollow onClick={() => setActiveStep(activeStep - 1)}>
                  {t('button_back')}
                </ButtonHollow>
              )}
              <ButtonLG disabled={!isFirstStepValid && showErrors} onClick={() => handleNext()}>
                {activeStep === 3
                  ? smDown
                    ? t('button_submit')
                    : t('button_submit_appraisal')
                  : t('button_next')}
              </ButtonLG>
            </Stack>
          )}
        </Stack>
      )}
    </LayoutPublicMini>
  )
}

export default AppraisalByLink
