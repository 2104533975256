import React from 'react'
import { Box, IconButton, Modal } from '@mui/material'
import { CloseRounded } from '@mui/icons-material'
import { colorsConfig } from '../../config/themeConfig'

const UIModal = ({ open, setOpen, wrapperStyles, width = '500px', children }) => {
  return (
    <Modal open={open} onClose={() => setOpen(false)}>
      <Box
        className="disable-scrollbar"
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: {
            md: width,
            xs: 'calc(100vw - 32px)',
          },
          background: colorsConfig.white,
          borderRadius: '10px',
          padding: {
            xs: '10px',
            md: '30px',
          },
          maxHeight: '80vh',
          overflow: 'auto',
          ...wrapperStyles,
        }}
      >
        <IconButton
          sx={{
            position: 'absolute',
            top: '5px',
            right: '5px',
            zIndex: '100',
            '& svg': {
              fill: colorsConfig.mainBlack,
              transition: '0.25s all ease-in-out',
            },
            '&:hover': {
              '& svg': {
                fill: colorsConfig.darkRed,
                transition: '0.25s all ease-in-out',
              },
            },
          }}
          onClick={() => {
            setOpen(false)
          }}
        >
          <CloseRounded />
        </IconButton>
        {children}
      </Box>
    </Modal>
  )
}

export default UIModal
