export const insuranceWorkflowsEnums = {
  BLANK: 'Blank',
  CAR_DETAILS_SAVED: 'Car details saved',
  DRIVER_DETAILS_SAVED: 'Driver details saved',
  AWAITING_QUOTES: 'Awaiting quotes',
  QUOTES_RECEIVED: 'Quotes received',
  AWAITING_CUSTOMER: 'Awaiting customer approval',
  INSURED: 'Insured',
}

export const insuranceApplicationWorkflowEnums = {
  new: 'new',
  applicationPreparation: 'application_preparation',
  approvalProcessStarted: 'approval_process_started',
  repliesReceived: 'replies_received',
  dealerSelected: 'dealer_selected',
  customerSelected: 'customer_selected',
}

export const insuranceApplicationWorkflowStateKeys = {
  [insuranceApplicationWorkflowEnums.new]: 'workflow_state_insurance_application_new',
  [insuranceApplicationWorkflowEnums.applicationPreparation]:
    'workflow_state_insurance_application_application_preparation',
  [insuranceApplicationWorkflowEnums.approvalProcessStarted]:
    'workflow_state_insurance_application_approval_process_started',
  [insuranceApplicationWorkflowEnums.repliesReceived]:
    'workflow_state_insurance_application_replies_received',
  [insuranceApplicationWorkflowEnums.dealerSelected]:
    'workflow_state_insurance_application_dealer_selected',
  [insuranceApplicationWorkflowEnums.customerSelected]:
    'workflow_state_insurance_application_customer_selected',
}

export const insuranceApplicationsInProgressStatusOptions = [
  {
    value: '',
    label: 'common_all',
  },
  {
    value: insuranceApplicationWorkflowEnums.new,
    label: insuranceApplicationWorkflowStateKeys[insuranceApplicationWorkflowEnums.new],
  },
  {
    value: insuranceApplicationWorkflowEnums.applicationPreparation,
    label:
      insuranceApplicationWorkflowStateKeys[
        insuranceApplicationWorkflowEnums.applicationPreparation
      ],
  },
  {
    value: insuranceApplicationWorkflowEnums.approvalProcessStarted,
    label:
      insuranceApplicationWorkflowStateKeys[
        insuranceApplicationWorkflowEnums.approvalProcessStarted
      ],
  },
  {
    value: insuranceApplicationWorkflowEnums.repliesReceived,
    label: insuranceApplicationWorkflowStateKeys[insuranceApplicationWorkflowEnums.repliesReceived],
  },
  {
    value: insuranceApplicationWorkflowEnums.dealerSelected,
    label: insuranceApplicationWorkflowStateKeys[insuranceApplicationWorkflowEnums.dealerSelected],
  },
]

export const insuranceWorkflows = [
  insuranceWorkflowsEnums.CAR_DETAILS_SAVED,
  insuranceWorkflowsEnums.DRIVER_DETAILS_SAVED,
  insuranceWorkflowsEnums.AWAITING_QUOTES,
  insuranceWorkflowsEnums.QUOTES_RECEIVED,
  insuranceWorkflowsEnums.AWAITING_CUSTOMER,
  insuranceWorkflowsEnums.INSURED,
]

export const insuranceApplicationsTableHead = [
  {
    title: 'table_col_number',
    sortable: false,
  },
  {
    title: 'table_col_brand_model',
    sortable: false,
  },
  {
    title: 'table_col_client',
    sortable: false,
  },
  {
    title: 'table_col_requests_sent',
    sortable: false,
  },
  {
    title: 'table_col_status',
    sortable: false,
  },

  {
    title: 'table_col_received_quotes',
    sortable: false,
  },
]

export const insuredInsuranceApplicationsTableHead = [
  {
    title: 'table_col_number',
    sortable: false,
  },
  {
    title: 'table_col_brand_model',
    sortable: false,
  },
  {
    title: 'table_col_client',
    sortable: false,
  },
  {
    title: 'table_col_insurance_date',
    sortable: false,
  },
  {
    title: 'table_col_status',
    sortable: false,
  },
]

export const insuranceApplicationsStatusTableHead = [
  {
    title: 'Number',
    sortable: false,
  },
  {
    title: 'Brand/Model',
    sortable: false,
  },
  {
    title: 'Client',
    sortable: false,
  },
  {
    title: 'Received quotes',
    sortable: false,
  },
]

export const drivingAccidentFreeOptions = [
  '< 1 year',
  '1 year',
  '2 years',
  '3 years',
  '4 years',
  '5 years',
  '> 5 years',
]

export const getInsuranceApplicationStatus = (application) => {
  return insuranceWorkflowsEnums.CAR_DETAILS_SAVED
}

export const initialCustomerData = {
  fullName: '',
  email: '',
  nationality: '',
  dateOfBirth: null,
  phoneNumber: '',
  title: '',
  countryOfFirstLicense: '',
  driverExperience: '',
  firstIssueDate: '',
  drivingAccidentFree: '',
  isForBusiness: null,
}

export const initialApplicationData = {
  id: null,
  data: {
    carPrice: '',
    startDate: null,
    annualMileage: 2,
    additionalRequest: '',
    ecoAmount: 2,
    consent: false,
  },
  packages: [
    {
      name: 'full',
      selected: false,
      currentPrice: null,
      loaded: false,
      selectedRisks: [
        'Third Party liability',
        'Comprehensive casco',
        'Personal Accident for driver',
        'Personal Accident for passengers',
        'Roadside Assistance',
      ],
    },
    {
      name: 'basic',
      selected: false,
      currentPrice: null,
      loaded: false,
      selectedRisks: ['Third Party liability'],
    },
    {
      name: 'custom',
      selected: false,
      currentPrice: null,
      loaded: false,
      selectedRisks: ['Third Party liability', 'Comprehensive casco'],
    },
  ],
  customer: initialCustomerData,
  currentWorkflowState: insuranceWorkflowsEnums.BLANK,
  collapseBlocksState: {
    carDetailsCollapsed: false,
    driversCollapsed: true,
    insuranceDetailsCollapsed: true,
    responsesCollapsed: true,
    carDetailsSaved: false,
    driversSaved: false,
    insuranceDetailsSaved: false,
    carDetailsDisabled: false,
    driversDetailsDisabled: true,
    insuranceDetailsDisabled: true,
    insurancesResponsesDisabled: true,
  },
  deals: [],
}

export const getInsuranceStatusInfo = (application) => {
  let name = insuranceWorkflowsEnums.CAR_DETAILS_SAVED
  let collapseBlocksState = {
    carDetailsCollapsed: true,
    driversCollapsed: false,
    insuranceDetailsCollapsed: true,
    responsesCollapsed: true,
    carDetailsSaved: true,
    driversSaved: false,
    insuranceDetailsSaved: false,
    carDetailsDisabled: false,
    driversDetailsDisabled: false,
    insuranceDetailsDisabled: true,
    insurancesResponsesDisabled: true,
  }

  if (application?.customer?.person) {
    name = insuranceWorkflowsEnums.DRIVER_DETAILS_SAVED
    collapseBlocksState = {
      carDetailsCollapsed: true,
      driversCollapsed: true,
      insuranceDetailsCollapsed: false,
      responsesCollapsed: true,
      carDetailsSaved: true,
      driversSaved: true,
      insuranceDetailsSaved: false,
      carDetailsDisabled: false,
      driversDetailsDisabled: false,
      insuranceDetailsDisabled: false,
      insurancesResponsesDisabled: true,
    }
  }

  if (application?.selectedRisks.length > 0) {
    name = insuranceWorkflowsEnums.AWAITING_QUOTES
    collapseBlocksState = {
      carDetailsCollapsed: true,
      driversCollapsed: true,
      insuranceDetailsCollapsed: true,
      responsesCollapsed: true,
      carDetailsSaved: true,
      driversSaved: true,
      insuranceDetailsSaved: true,
      carDetailsDisabled: false,
      driversDetailsDisabled: false,
      insuranceDetailsDisabled: false,
      insurancesResponsesDisabled: true,
    }
  }

  if (application?.insuranceDeals?.length > 0) {
    const deals = application?.insuranceDeals
    if (
      !!deals.find(
        (item) =>
          item.currentWorkflowState === 'approved' ||
          item.currentWorkflowState === 'insurance_company_request_info' ||
          item.currentWorkflowState === 'insurance_company_declined',
      )
    ) {
      name = insuranceWorkflowsEnums.QUOTES_RECEIVED
      collapseBlocksState = {
        carDetailsCollapsed: true,
        driversCollapsed: true,
        insuranceDetailsCollapsed: true,
        responsesCollapsed: true,
        carDetailsSaved: true,
        driversSaved: true,
        insuranceDetailsSaved: true,
        carDetailsDisabled: false,
        driversDetailsDisabled: false,
        insuranceDetailsDisabled: false,
        insurancesResponsesDisabled: false,
      }
    }
    if (!!deals.find((item) => item.isDealerSelected)) {
      name = insuranceWorkflowsEnums.AWAITING_CUSTOMER
    }
    if (!!deals.find((item) => item.isCustomerSelected)) {
      name = insuranceWorkflowsEnums.INSURED
    }
  }

  return {
    name,
    collapseBlocksState,
  }
}
