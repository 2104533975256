import React from 'react'
import { FONT, UIVariant } from '../../utils/constants/ui.constants'
import { Button } from '@mui/material'
import { colorsConfig } from '../../config/themeConfig'
import { useTranslation } from 'react-i18next'
import IconStatus from '../../assests/img/icons/icon-car-status.svg'

const UIStatusBlock = ({ text, variant = UIVariant.success, sx, ...props }) => {
  const { t } = useTranslation()
  return (
    <Button
      sx={{
        border: '1px solid',
        borderColor: colorsConfig.mainGreen,
        borderRadius: '6px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '10px',
        py: '9.5px',
        px: '40px',
        textTransform: 'none',
        fontSize: '16px',
        fontWeight: 400,
        fontFamily: FONT.PRIMARY,
        color: colorsConfig.mainBlack,
        background: 'transparent',
        transition: '0.25s all ease-in-out',
        '&:hover': {
          background: colorsConfig.bgLightBlue,
          transition: '0.25s all ease-in-out',
        },
        ...sx,
      }}
      {...props}
    >
      <img width="17px" src={IconStatus} alt="" />
      {t(text)}
    </Button>
  )
}

export default UIStatusBlock
