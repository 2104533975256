import React from 'react'
import { Grid } from '@mui/material'
import BoxShadowed from '../../../ui/Layout/BoxShadowed'
import { useSelector } from 'react-redux'
import ControlPointFileCollection from '../AppraisalBlock/ControlPointFileCollection'
import UITextRegular from '../../../ui/UIText/UITextRegular'
import { colorsConfig } from '../../../config/themeConfig'
import { formErrors } from '../../../components/Forms/FormTemplates/formErrors'

const AppraisalDocuments = ({ carId, showErrors, isValid }) => {
  const documents = useSelector((state) => state.car.appraisal.documents)

  const filteredDocs = documents?.filter(
    (item) => item?.sysName !== 'uae_custom_cardpossession_card',
  )

  return (
    <BoxShadowed sx={{ mt: '30px' }}>
      <UITextRegular text="p_appraisal_documents_hint" sx={{ mb: '20px' }} />
      <Grid container spacing="20px">
        {filteredDocs
          .sort((a, b) => a?.direct - b?.direct)
          .map((item) => {
            return (
              <Grid key={item?.id} item md={3} xs={12}>
                <ControlPointFileCollection data={item} carId={carId} />
                {showErrors && !isValid && item?.sysName === 'vehicle_registration_document' && (
                  <UITextRegular
                    sx={{ mt: '10px' }}
                    color={colorsConfig.darkRed}
                    text={formErrors.required}
                  />
                )}
              </Grid>
            )
          })}
      </Grid>
    </BoxShadowed>
  )
}

export default AppraisalDocuments
