import React from 'react'
import IconTG from '../../../assests/img/icons/icon-tg.png'
import { IconButton } from '@mui/material'
import Tooltip from '@material-ui/core/Tooltip'
import { useTranslation } from 'react-i18next'

const ButtonLinkTG = ({
  title = 'button_share_via_telegram',
  link,
  message,
  recipient,
  sx,
  ...props
}) => {
  const { t } = useTranslation()
  return (
    <Tooltip title={t(title)}>
      <IconButton
        component="a"
        href={
          recipient
            ? `https://t.me/${recipient}?text=${message}`
            : `https://telegram.me/share/url?url=${link}&text=${message}`
        }
        target="_blank"
        sx={{
          width: '50px',
          height: '50px',
          borderRadius: '6px',
          ...sx,
        }}
        {...props}
      >
        <img
          src={IconTG}
          alt=""
          style={{
            width: '80%',
          }}
        />
      </IconButton>
    </Tooltip>
  )
}

export default ButtonLinkTG
