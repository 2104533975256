import React from 'react'

export const appVersion = '1.0.7'

export const ROLES = {
  ROLE_COMPANY: 'ROLE_DEALER',
  ROLE_OPERATOR: 'ROLE_OPERATOR',
  ROLE_EXPERT: 'ROLE_EXPERT',
  ROLE_ADMIN: 'ROLE_ADMIN',
}

export const LOCAL_ROLE = {
  COMPANY: 'COMPANY',
  OPERATOR: 'OPERATOR',
  EXPERT: 'EXPERT',
  ADMIN: 'ADMIN',
}

export const REGION_VERSIONS = {
  CH: 'CH',
  AE: 'AE',
}

export const REGION_LOCALE = REGION_VERSIONS.CH

export const COMPANY_NAME = {
  [REGION_VERSIONS.AE]: 'Fleetify',
  [REGION_VERSIONS.CH]: 'Fleatify',
}

export const CURRENCY = {
  [REGION_VERSIONS.AE]: 'AED',
  [REGION_VERSIONS.CH]: 'CHF',
}

export const getCurrency = () => CURRENCY[REGION_LOCALE]

export const COMPANY_ADDRESS = {
  [REGION_VERSIONS.AE]: (
    <>
      Al Sila Tower, Level 12 <br />
      Abu Dhabi Global Market Square <br />
      Al Maryah Island, Abu Dhabi <br />
      United Arab Emirates
    </>
  ),
  [REGION_VERSIONS.CH]: (
    <>
      Insercle AG <br />
      Konkordiastrasse 12 <br />
      8032 Zurich - CH
    </>
  ),
}

export const MAIN_EMAIL = 'info@insercle.com'
export const COMPANY_PHONE = {
  number: 41445152334,
  text: '+41 44 515 23 34',
}

export const INITIATOR = {
  SYSTEM: 'SYSTEM',
  DEALER: 'DEALER',
  OPERATOR: 'OPERATOR',
  EXPERT: 'EXPERT',
}
