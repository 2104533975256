import React from 'react'
import ButtonLG from '../../../../ui/Buttons/ButtonLG'
import IconWarranty from '../../../../assests/img/icons/icon-warranty.svg'
import { ReceiptRounded } from '@mui/icons-material'
import { Stack } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import {
  getWarrantyCertificate,
  getWarrantyInvoice,
} from '../../../../store/features/warrantyApplicationSlice/warrantyApplication.actions'

const CarWarrantyDocuments = () => {
  const certificateSignedUrl = useSelector(
    (state) => state.warrantyApplication.warrantyCertificate.signedUrl,
  )
  const certificateLoading = useSelector(
    (state) => state.warrantyApplication.warrantyCertificate.isLoading,
  )
  const invoiceSignedUrl = useSelector((state) => state.warrantyApplication.invoice.signedUrl)
  const invoiceLoading = useSelector((state) => state.warrantyApplication.invoice.isLoading)

  const dispatch = useDispatch()
  const handleDownloadCertificate = async () => {
    // if (certificateSignedUrl) {
    //   downloadFileFromPrivateBucket(certificateSignedUrl, certificateName)
    //   return
    // }
    await dispatch(getWarrantyCertificate())
    // if (getWarrantyCertificate.fulfilled.match(res)) {
    //   console.log(certificateSignedUrl)
    //   downloadFileFromPrivateBucket(res?.signedUrl ?? certificateSignedUrl, certificateName)
    // }
  }

  const handleDownloadInvoice = async () => {
    await dispatch(getWarrantyInvoice())
  }

  return (
    <Stack gap="10px" direction={{ xs: 'column', md: 'row' }}>
      <ButtonLG
        sx={{
          display: 'flex',
          gap: '5px',
          whiteSpace: 'nowrap',
          px: '20px',
        }}
        disabled={certificateLoading}
        onClick={() => handleDownloadCertificate()}
      >
        <img src={IconWarranty} alt="" />
        {certificateLoading ? 'Downloading...' : 'Download certificate'}
      </ButtonLG>
      <ButtonLG
        sx={{
          display: 'flex',
          gap: '5px',
          whiteSpace: 'nowrap',
          px: '20px',
        }}
        onClick={() => handleDownloadInvoice()}
      >
        <ReceiptRounded sx={{ fill: 'white' }} />
        {invoiceLoading ? 'Downloading...' : 'Download invoice'}
      </ButtonLG>
    </Stack>
  )
}

export default CarWarrantyDocuments
