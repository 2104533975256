import React, { useEffect, useState } from 'react'
import { Box, Typography } from '@mui/material'
import TextH1 from '../../../../../ui/Text/TextH1'
import UIPreloaderCircular from '../../../../../ui/UIPreloader/UIPreloaderCircular'
import { PieChart } from '@mui/x-charts/PieChart'
import { pieChartConfig } from '../../../dashboard.constants'
import StatusList from '../StatusList'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { getDashboardPoliciesStatusCount } from '../../../../../store/features/dashboard/dashboard.actions'
import {
  getPoliciesStatusCakePieChartData,
  getPoliciesStatusCakeStatusListData,
  policiesStatusCakeColors,
} from './policiesStatusCake.helpers'
import {
  resetStatusCakePolicies,
  setStatusCakePoliciesParams,
} from '../../../../../store/features/dashboard/dashboard.reducer'
import RVIStatusCakeModal from './RVIStatusCakeModal'

const RVIStatusCake = () => {
  const { t } = useTranslation()
  const isLoading = useSelector(
    (state) => state.dashboard.statusCakes.policies.statusCount.isLoading,
  )
  const statusCounts = useSelector((state) => state.dashboard.statusCakes.policies.statusCount.data)
  const params = useSelector((state) => state.dashboard.statusCakes.policies.params)
  const dispatch = useDispatch()

  const total = statusCounts?.reduce((acc, cur) => acc + cur?.count, 0)

  const [statusCakeModal, setStatusCakeModal] = useState(false)

  const handleOpenSelectedPolicies = (data) => {
    dispatch(setStatusCakePoliciesParams({ ...params, currentWorkflowState: data?.workflowState }))
    setStatusCakeModal(true)
  }

  useEffect(() => {
    dispatch(getDashboardPoliciesStatusCount())
    return () => {
      dispatch(resetStatusCakePolicies())
    }
  }, [])

  return (
    <Box
      sx={{
        width: '100%',
        height: '369px',
        padding: '32px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <TextH1>{t('title_rvi_status_cake')}</TextH1>
      {isLoading ? (
        <UIPreloaderCircular sx={{ mt: '50px' }} />
      ) : (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            flex: 1,
            gap: '25px',
          }}
        >
          <Box sx={{ position: 'relative' }}>
            <Box
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Typography sx={{ fontSize: '32px', fontWeight: 700, color: '#11263C' }}>
                {total}
              </Typography>
              <Typography
                sx={{ fontSize: '12px', color: 'rgba(60, 60, 67, 0.60)', marginTop: '-5px' }}
              >
                {t('common_total')}
              </Typography>
            </Box>
            <PieChart
              colors={policiesStatusCakeColors}
              series={[
                {
                  data: getPoliciesStatusCakePieChartData(statusCounts, t),
                  ...pieChartConfig,
                },
              ]}
              width={210}
              height={210}
              sx={{
                '& .MuiChartsLegend-root': {
                  display: 'none',
                },
              }}
            />
          </Box>
          <StatusList
            data={getPoliciesStatusCakeStatusListData(statusCounts, t)}
            colors={policiesStatusCakeColors}
            handleOpenModal={handleOpenSelectedPolicies}
          />
          {statusCakeModal && (
            <RVIStatusCakeModal open={statusCakeModal} setOpen={setStatusCakeModal} />
          )}
        </Box>
      )}
    </Box>
  )
}

export default RVIStatusCake
