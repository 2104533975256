import React, { useEffect, useState } from 'react'
import { Box, Typography, useMediaQuery } from '@mui/material'
import { getCarParts, getForeshortenings, getPilotLamps } from '../Appraisal/getAppraisalReferences'
import { setReferences } from '../../store/features/appraisalReferencesSlices'
import {
  resetCar,
  setAppraisal,
  setAuctionData,
  setCarData,
  setDamages,
  setDocuments,
  setInteriorConditions,
  setMechanicalConditions,
  setPhotos,
  setPilotLamps,
} from '../../store/features/carSlice/carSlice'
import IconVirtualGarage from '../../assests/img/icons/icon-virtual-garage-home.svg'
import BreadcrumbsComponent from '../../components/BreadcrumbsComponent'
import { colorsConfig } from '../../config/themeConfig'
import VehicleDetailsSection from '../CarPage/CarPageSections/VehicleDetailsSection'
import DocumentsSection from '../CarPage/CarPageSections/DocumentsSection'
import PhotosSection from '../CarPage/CarPageSections/PhotosSection'
import DamagesSection from '../CarPage/CarPageSections/DamagesSection'
import WarningLights from '../Appraisal/WarningLights/WarningLights'
import { privateRequest } from '../../utils/request'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { API_ENDPOINTS, CDN_BASE_URL } from '../../config/default'
import CarDefaultPicture from '../../assests/img/car-not-found.png'
import IconAppleGreen from '../../assests/img/icons/icon-apple-green.svg'
import ImageVerified from '../../assests/img/fleatify-verified.svg'
import AuctionTimer from '../CarPage/AuctionCar/AuctionTimer'
import BidForm from '../CarPage/AuctionCar/BidForm'
import { setCurrentPageTitle } from '../../store/features/authSlice'
import TextNormal from '../../ui/Text/TextNormal'
import CarPageTabs from '../CarPage/CarPageTabs'
import {
  parseControlPointsDocuments,
  parseControlPointsInteriorConditions,
  parseMechanicalConditions,
} from '../Appraisal/appraisal.helpers'
import AppraisalInteriorConditions from '../Appraisal/AppraisalInteriorConditions/AppraisalInteriorConditions'
import AppraisalMechanicalConditions from '../Appraisal/AppraisalMechanicalConditions/AppraisalMechanicalConditions'

const AuctionCarPage = () => {
  const params = useParams()
  const xsOnly = useMediaQuery('(max-width: 600px)')
  const tabletOnly = useMediaQuery('(min-width: 600px) and (max-width: 1200px)')

  const appraisalReferences = useSelector((state) => state.references)
  const carData = useSelector((state) => state.car.carData)
  const dispatch = useDispatch()

  const [blocks, setBlocks] = useState([])
  const [loading, setLoading] = useState(true)

  const sections = [
    {
      id: 1,
      name: 'Vehicle Details',
      component: <VehicleDetailsSection />,
    },
    {
      id: 2,
      name: 'Documents',
      component: <DocumentsSection />,
    },
    {
      id: 3,
      name: 'Photos',
      component: <PhotosSection />,
    },
    {
      id: 4,
      name: 'Damages',
      component: <DamagesSection />,
    },
    {
      id: 5,
      name: 'Warning Lights',
      component: <WarningLights readonly={true} />,
    },
    {
      id: 6,
      name: 'Interior Conditions',
      component: <AppraisalInteriorConditions readonly />,
    },
    {
      id: 7,
      name: 'Mechanical Conditions',
      component: <AppraisalMechanicalConditions readonly />,
    },
  ]

  const [carTitle, setCarTitle] = useState('')
  const [tab, setTab] = useState(0)
  const [auction, setAuction] = useState(null)

  function compare(a, b) {
    if (a.direct < b.direct) {
      return -1
    }
    if (a.direct > b.direct) {
      return 1
    }
    return 0
  }

  const getReferences = () => {
    const referencePromises = [getForeshortenings(), getCarParts(), getPilotLamps()]
    Promise.all(referencePromises).then((result) => {
      dispatch(setReferences(result))
    })
  }

  const getAuction = () => {
    privateRequest.get(`/auctions/${params.id}`).then((response) => {
      setAuction(response.data)
      dispatch(setCarData(response.data.car))
      dispatch(setAuctionData(response.data))
      setCarTitle(`${response.data.car.make.name} ${response.data.car.model.name}`)
      dispatch(
        setCurrentPageTitle(`${response.data.car.make.name} ${response.data.car.model.name}`),
      )
    })
  }

  const getCarAppraisal = () => {
    privateRequest.get(API_ENDPOINTS.appraisalByAuction(auction.id)).then((response) => {
      dispatch(
        setAppraisal({
          foreshortenings: response.data.foreshortenings.sort(compare),
          damages: response.data.damages,
          pilotLamps: response.data.pilotLamps,
          controlPoints: response.data.controlPoints,
        }),
      )
    })
  }

  const handleSetCarControlPoints = () => {
    dispatch(
      setDocuments(
        parseControlPointsDocuments(appraisalReferences.controlPoints, carData.controlPoints),
      ),
    )
    dispatch(
      setInteriorConditions(
        parseControlPointsInteriorConditions(
          appraisalReferences.controlPoints,
          carData.controlPoints,
        ),
      ),
    )
    dispatch(
      setMechanicalConditions(
        parseMechanicalConditions(appraisalReferences.controlPoints, carData.controlPoints),
      ),
    )
  }

  useEffect(() => {
    if (!appraisalReferences.loaded) {
      getReferences()
    }
  }, [appraisalReferences.loaded])

  useEffect(() => {
    if (appraisalReferences.controlPoints.length > 0 && carData.loaded) {
      handleSetCarControlPoints()
    }
  }, [appraisalReferences.controlPoints, carData.loaded])

  useEffect(() => {
    if (appraisalReferences.loaded && carData.loaded) {
      // setControlPointsBlocks()
      dispatch(
        setDamages({
          carDamages: carData.damages,
          carParts: appraisalReferences.carParts,
        }),
      )
      dispatch(setPilotLamps(appraisalReferences.pilotLamps))
      // dispatch(setControlPoints(appraisalReferences.controlPoints))
      dispatch(setPhotos(appraisalReferences.foreshortenings))
      setTimeout(() => {
        setLoading(false)
      }, 700)
    }
  }, [appraisalReferences.loaded, carData])

  useEffect(() => {
    if (auction) {
      getCarAppraisal()
    }
  }, [auction])

  useEffect(() => {
    getAuction()
    return () => {
      dispatch(resetCar())
    }
  }, [])

  return (
    <Box
      className={[xsOnly ? 'xs-only' : '', tabletOnly ? 'tablet-only' : ''].join(' ')}
      sx={{
        '&.xs-only': {
          px: '16px',
          pt: '12px',
        },
        '&.tablet-only': {
          px: '16px',
          pb: '30px',
        },
      }}
    >
      <BreadcrumbsComponent
        currentPage={{ name: carTitle }}
        parentPages={[
          {
            name: 'Cars on Auction',
            icon: <img src={IconVirtualGarage} alt="" />,
            link: '/cars-on-auction',
          },
        ]}
      />
      <Box
        className={xsOnly ? 'xs-only' : ''}
        sx={{
          mt: '13px',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          '&.xs-only': {
            display: 'block',
            mt: '5px',
            mb: '13px',
          },
        }}
      >
        <Typography
          className={xsOnly ? 'xs-only' : ''}
          sx={{
            fontWeight: 700,
            color: colorsConfig.mainBlack,
            fontSize: '40px',
            marginRight: '15px',
            '&.xs-only': {
              fontSize: '24px',
              mr: 0,
            },
          }}
        >
          #{carData.referenceNumber} {carTitle}
        </Typography>
        <Box
          sx={{
            display: 'flex',
            gap: '10px',
          }}
        >
          {(carData.carMainSpecs.engineType === 'electric' ||
            carData.carMainSpecs.engineType === 'hybrid') &&
          !xsOnly ? (
            <Box
              sx={{
                width: '40px',
                height: '40px',
                borderRadius: '6px',
                boxShadow: '0 0 10px 0 rgba(0,0,0,0.1)',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <img src={IconAppleGreen} alt="" />
              <TextNormal
                sx={{
                  position: 'absolute',
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center',
                  fontSize: '9px',
                  fontWeight: 700,
                  pt: '2px',
                }}
              >
                ECO
              </TextNormal>
            </Box>
          ) : null}
          {carData?.isOnCompound ? <img src={ImageVerified} alt="" /> : null}
        </Box>
      </Box>
      <Box
        className={xsOnly ? 'xs-only' : ''}
        sx={{
          mt: '25px',
          mb: '60px',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
          '&.xs-only': {
            display: 'block',
            mt: 0,
          },
        }}
      >
        <Box
          className={xsOnly ? 'xs-only' : ''}
          sx={{
            height: '298px',
            borderRadius: '6px',
            width: '45%',
            mr: '37px',
            overflow: 'hidden',
            '&.xs-only': {
              width: '100%',
              position: 'relative',
              height: '230px',
            },
          }}
        >
          <img
            src={
              auction?.car.mainForeshortening
                ? `${CDN_BASE_URL}${auction.car.mainForeshortening.photo.path}`
                : CarDefaultPicture
            }
            alt=""
            style={
              auction?.car.mainForeshortening
                ? {
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                  }
                : {
                    width: '75%',
                  }
            }
          />
          {xsOnly &&
          (carData.carMainSpecs.engineType === 'electric' ||
            carData.carMainSpecs.engineType === 'hybrid') ? (
            <Box
              sx={{
                width: '40px',
                height: '40px',
                borderRadius: '6px',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                background: colorsConfig.mainBlack,
                position: 'absolute',
                top: 0,
                left: 0,
              }}
            >
              <img src={IconAppleGreen} alt="" />
              <TextNormal
                sx={{
                  position: 'absolute',
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center',
                  fontSize: '9px',
                  fontWeight: 700,
                  pt: '2px',
                }}
              >
                ECO
              </TextNormal>
            </Box>
          ) : null}
        </Box>
        <Box
          className={xsOnly ? 'xs-only' : ''}
          sx={{
            width: 'calc(55% - 38px)',
            '&.xs-only': {
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              pt: '13px',
            },
          }}
        >
          <AuctionTimer
            startTime={auction?.startTime}
            endTime={auction?.endTime}
            currentStatus={auction?.car.combinedStatus}
            mode={'large'}
          />
          <BidForm />
        </Box>
      </Box>
      <Box
        sx={{
          mb: 2,
        }}
      >
        <CarPageTabs tab={tab} setTab={setTab} sections={sections} />
      </Box>
      {sections[tab].component}
    </Box>
  )
}

export default AuctionCarPage
