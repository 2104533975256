import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { setCurrentPageTitle } from '../../store/features/authSlice'
import ComingSoon from '../../components/ComingSoon'

const StockFinancing = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setCurrentPageTitle('page_title_stock_financing'))
  }, [])

  return <ComingSoon />
}

export default StockFinancing
