import React from 'react'
import UIModal from '../../../ui/UIModal/UIModal'
import { Stack } from '@mui/material'
import UITextHeadlineSmall from '../../../ui/UIText/UITextHeadlineSmall'
import UIButton from '../../../ui/UIButtons/UIButton'
import { UIVariant } from '../../../utils/constants/ui.constants'

const CompleteStepConfirmationModal = ({ open, setOpen, onConfirm, title }) => {
  return (
    <UIModal width="480px" open={open} setOpen={setOpen}>
      <Stack gap="30px">
        <UITextHeadlineSmall sx={{ fontSize: { xs: '22px', md: '24px' } }} text={title} />
        <Stack direction="row" gap="10px">
          <UIButton
            fullWidth
            variant={UIVariant.hollow}
            text="button_cancel"
            onClick={() => setOpen(false)}
          />
          <UIButton
            fullWidth
            text="button_confirm"
            onClick={() => {
              onConfirm()
              setOpen(false)
            }}
          />
        </Stack>
      </Stack>
    </UIModal>
  )
}

export default CompleteStepConfirmationModal
