import React, { useEffect, useState } from 'react'
import { Box, Stack } from '@mui/material'
import { useParams } from 'react-router-dom'
import Logo from '../../../assests/img/logo-blue.svg'
import CollapseBlock from '../../../ui/Accordions/CollapseBlock'
import { publicRequest } from '../../../utils/request'
import { API_ENDPOINTS } from '../../../config/default'
import CustomerInsuranceResponsesItem from './CustomerInsuranceResponsesItem'
import { generateUID } from '../../../utils/generateUID'

const CustomerInsuranceResponses = () => {
  const params = useParams()
  const [application, setApplication] = useState(null)
  const [referenceCompanies, setReferenceCompanies] = useState([])
  const [hasCustomerSelectedOffer, setHasCustomerSelectedOffer] = useState(false)

  const getApplication = () => {
    publicRequest.get(API_ENDPOINTS.insurance.customerApplications(params.id)).then((response) => {
      const insuranceDeals = response.data.insuranceDeals
      if (insuranceDeals.find((item) => item?.isCustomerSelected)) {
        setHasCustomerSelectedOffer(true)
      }
      setApplication(response.data)
    })
  }

  const getReferenceCompanies = () => {
    publicRequest.get(API_ENDPOINTS.insurance.companies).then((response) => {
      setReferenceCompanies(response.data['hydra:member'])
    })
  }

  useEffect(() => {
    getReferenceCompanies()
    getApplication()
  }, [])

  return (
    <Box>
      <Box
        sx={{
          maxWidth: '1176px',
          mx: 'auto',
          display: 'flex',
          alignItems: 'center',
          height: '90px',
        }}
      >
        <Box>
          <img src={Logo} alt="" />
        </Box>
      </Box>
      <Box
        sx={{
          width: '1200px',
          maxWidth: '90vw',
          ml: 'auto',
          mr: 'auto',
          pb: '50px',
        }}
      >
        {application ? (
          <CollapseBlock
            collapsed={false}
            title="Insurance responses"
            description="Choose the best option for you"
          >
            <Stack gap="30px">
              {application?.insuranceDeals
                .sort((a, b) => b.isCustomerSelected - a.isCustomerSelected)
                .map((item) => {
                  return (
                    <CustomerInsuranceResponsesItem
                      data={item}
                      referenceCompanies={referenceCompanies}
                      key={generateUID()}
                      hasCustomerSelectedOffer={hasCustomerSelectedOffer}
                    />
                  )
                })}
            </Stack>
          </CollapseBlock>
        ) : null}
      </Box>
    </Box>
  )
}

export default CustomerInsuranceResponses
