import React from 'react'
import { Stack } from '@mui/material'
import UITextRegular from '../../../../ui/UIText/UITextRegular'
import { colorsConfig } from '../../../../config/themeConfig'
import LoggingNodesListItem from './LoggingNodesListItem'

const LoggingNodesList = ({ data }) => {
  const isEmpty = !data || data?.length === 0

  return (
    <Stack
      gap="30px"
      sx={{ minHeight: isEmpty ? '200px' : 'auto' }}
      alignItems="stretch"
      justifyContent={isEmpty ? 'flex-start' : 'center'}
    >
      {!isEmpty ? (
        <>
          {data?.map((item) => (
            <LoggingNodesListItem key={item.id} data={item} />
          ))}
        </>
      ) : (
        <UITextRegular
          sx={{ textAlign: 'center' }}
          color={colorsConfig.lightGray}
          text="logging_nodes_empty_message"
        />
      )}
    </Stack>
  )
}

export default LoggingNodesList
