import { adminRequest } from '../../utils/request'

class LoanProgramsApiService {
  #api

  #endPoints

  #abortControllers

  constructor({ api }) {
    this.#api = api
    this.#endPoints = {
      collection: '/reference/product-provider/loan-programs',
      instance: (id) => `/reference/product-provider/loan-programs/${id}`,
    }
    this.#abortControllers = {}
  }

  getLoanPrograms = async (params, prefix = 'getLoanPrograms') => {
    this.#abortControllers[prefix]?.abort()
    this.#abortControllers[prefix] = new AbortController()
    const response = await this.#api.get(this.#endPoints.collection, {
      params,
      signal: this.#abortControllers[prefix]?.signal,
    })
    return response
  }

  createLoanProgram = async (data) => {
    const response = await this.#api.post(this.#endPoints.collection, data)
    return response
  }

  updateLoanProgram = async (loanProgramId, data) => {
    const response = await this.#api.put(this.#endPoints.instance(loanProgramId), data)
    return response
  }

  deleteLoanProgram = async (loanProgramId) => {
    const response = await this.#api.delete(this.#endPoints.instance(loanProgramId))
    return response
  }
}

const loanProgramsApiService = new LoanProgramsApiService({ api: adminRequest })

export default loanProgramsApiService
