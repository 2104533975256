import React, { useEffect, useState } from 'react'
import { Box, Button, Grid, IconButton, styled, Typography, useMediaQuery } from '@mui/material'
import { API_ENDPOINTS, CDN_BASE_URL, FILE_TYPES } from '../../../config/default'
import { FileUploader } from 'react-drag-drop-files'
import { useDispatch, useSelector } from 'react-redux'
import CarPhotoItem from './CarPhotoItem'
import { privateRequest } from '../../../utils/request'
import { colorsConfig } from '../../../config/themeConfig'
import IconUpload from '../../../assests/img/icons/icon-upload.svg'
import IconUploaded from '../../../assests/img/icons/icon-uploaded.svg'
import CarPhotoSorted from './CarPhotoSorted'
import { addCarPhoto, setCarDataPhotos, setPhotos } from '../../../store/features/carSlice/carSlice'
import { uploadFile } from '../../../utils/uploadFile'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import TakePhotoButton from './TakePhotoButton'
import { AddAPhoto } from '@mui/icons-material'

const CarPhotos = ({ carId }) => {
  const { t } = useTranslation()
  const xsOnly = useMediaQuery('(max-width: 600px)')

  const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  })

  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated)
  const foreshortenings = useSelector((state) => state.references.foreshortenings)
  const carDataPhotos = useSelector((state) => state.car.carData.foreshortenings)
  const carPhotos = useSelector((state) => state.car.appraisal.foreshortenings)
  const dispatch = useDispatch()
  const params = useParams()

  const [showGuidelines, setShowGuidelines] = useState(!isAuthenticated)
  const [photosUpdated, setPhotosUpdated] = useState(false)
  const [disableSaveOrderButton, setDisableSaveOrderButton] = useState(false)

  const getCarPhotos = () => {
    setPhotosUpdated(true)
    privateRequest.get(`/cars/${carId ?? params.id}${API_ENDPOINTS.carPhotos}`).then((response) => {
      const data = response.data['hydra:member']
      data.sort(compare)
      dispatch(
        setCarDataPhotos(
          data.map((item) => {
            if (item.foreshortening) {
              const intId = parseFloat(item.foreshortening.match(/\d+/)[0])
              return {
                ...item,
                foreshortening: {
                  '@id': item.foreshortening,
                  id: intId,
                },
              }
            } else return item
          }),
        ),
      )
    })
  }

  const handleSetCarPhotos = (files) => {
    dispatch(
      addCarPhoto(
        carPhotos.map((item, index) => {
          if (files[index]) {
            return {
              ...item,
              status: 'loading',
              newCarPhoto: files[index],
            }
          } else return item
        }),
      ),
    )

    const resultFiles = Array.from(files).map((file) => {
      return uploadFile(file, FILE_TYPES.foreshortening)
    })

    Promise.all(resultFiles).then((resultFileIds) => {
      const newForeshortenings = resultFileIds.map((fileId) => {
        return {
          foreshortening: null,
          direct: 9999,
          photo: fileId,
        }
      })
      const formData = {
        foreshortenings: newForeshortenings,
      }
      privateRequest
        .post(`/cars/${carId ?? params.id}${API_ENDPOINTS.carPhotosBatch}`, formData)
        .then(() => {
          getCarPhotos()
        })
    })
  }

  const handleDeletePhoto = (carForeshortening) => {
    setPhotosUpdated(true)
    dispatch(setCarDataPhotos(carDataPhotos.filter((item) => item['@id'] !== carForeshortening)))
    privateRequest.delete(carForeshortening)
  }

  function compare(a, b) {
    if (a.direct < b.direct) {
      return -1
    }
    if (a.direct > b.direct) {
      return 1
    }
    return 0
  }

  const handleSetOrder = (currentIndex, direction) => {
    const sortedPhotos = carDataPhotos.map((item, index) => {
      if (index === currentIndex - 1 && direction === 'up') {
        return {
          ...item,
          direct: currentIndex,
        }
      }
      if (index === currentIndex && direction === 'up') {
        return {
          ...item,
          direct: currentIndex - 1,
        }
      }
      if (index === currentIndex + 1 && direction === 'down') {
        return {
          ...item,
          direct: currentIndex,
        }
      }
      if (index === currentIndex && direction === 'down') {
        return {
          ...item,
          direct: currentIndex + 1,
        }
      }
      return {
        ...item,
        direct: index,
      }
    })
    sortedPhotos.sort(compare)
    dispatch(setCarDataPhotos(sortedPhotos))
  }

  const handleSaveOrder = () => {
    if (carDataPhotos.length > 0) {
      const formData = {
        foreshortenings: carDataPhotos.map((item) => {
          return {
            direct: item.direct,
            carForeshortening: item['@id'],
          }
        }),
      }
      const formDataMainForeshortening = {
        carForeshortening: carDataPhotos[0]['@id'],
      }
      privateRequest.put(`/cars/${carId}${API_ENDPOINTS.carPhotosDirectBatch}`, formData)
      privateRequest.put(
        `/cars/${carId}${API_ENDPOINTS.mainForeshortening}`,
        formDataMainForeshortening,
      )
    }
  }

  useEffect(() => {
    if (photosUpdated) {
      setPhotosUpdated(false)
      dispatch(setPhotos(foreshortenings))
    }
  }, [carDataPhotos])

  // useEffect(() => {
  //   return () => {
  //     handleSaveOrder()
  //   }
  // }, [])

  return (
    <Box
      sx={{
        mt: '40px',
      }}
    >
      <Typography
        sx={{
          fontWeight: 700,
          fontSize: '18px',
          color: colorsConfig.mainBlack,
          mb: '12px',
        }}
      >
        {t('p_upload_11_photos')}
      </Typography>
      <Button
        sx={{
          textTransform: 'none',
          fontSize: '16px',
          fontWeight: 700,
          color: colorsConfig.mainBlue,
          mb: '40px',
          px: '0',
        }}
        onClick={() => {
          setShowGuidelines(!showGuidelines)
        }}
      >
        {showGuidelines ? t('button_hide_image_guidelines') : t('button_see_image_guidelines')}
      </Button>
      {showGuidelines ? (
        <Box
          sx={{
            mb: '40px',
            position: 'relative',
            mt: '-50px',
          }}
        >
          <Typography
            sx={{
              fontSize: '16px',
              fontWeight: 400,
              fontFamily: '"Helvetica", sans-serif',
              mb: '25px',
            }}
          >
            {t('p_upload_one_photo_for_each_angle')}
          </Typography>
          <Grid container spacing={xsOnly ? '8px' : 3}>
            {carPhotos.map((carPhotoItem, index) => {
              let image, noPhoto
              if (carPhotoItem.newCarPhoto) {
                image = `${URL.createObjectURL(carPhotoItem.newCarPhoto)}`
                noPhoto = false
              } else if (carPhotoItem.carPhoto) {
                image = `${CDN_BASE_URL}${carPhotoItem.carPhoto.path}`
                noPhoto = false
              } else {
                image = `${CDN_BASE_URL}${carPhotoItem.foreshorteningFile}`
                noPhoto = true
              }
              return (
                <Grid
                  item
                  md={3}
                  xs={6}
                  key={index}
                  sx={{ ...(xsOnly && { display: 'flex', alignItems: 'center' }) }}
                >
                  <CarPhotoItem
                    key={index}
                    index={index}
                    image={image}
                    noPhoto={noPhoto}
                    status={carPhotoItem.status}
                    handleDeletePhoto={handleDeletePhoto}
                    carView={carPhotoItem.foreshortening}
                    foreshorteningId={carPhotoItem.carForeshortening}
                    mainPhoto={carPhotoItem.mainPhoto}
                    photoId={carPhotoItem.carPhoto ? carPhotoItem.carPhoto['@id'] : null}
                    refresh={getCarPhotos}
                    carId={carId}
                  />
                </Grid>
              )
            })}
          </Grid>
        </Box>
      ) : null}
      <Typography
        sx={{
          fontSize: '16px',
          fontWeight: 400,
          color: colorsConfig.mainBlack,
          mb: '18px',
        }}
      >
        {t('p_upload_multiple_files_or_drag_n_drop')}
      </Typography>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            marginRight: '24px',
          }}
        >
          {xsOnly ? (
            <Box
              sx={{
                width: '239px',
                height: '160px',
                background: colorsConfig.darkGray,
                borderRadius: '6px',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                cursor: 'pointer',
              }}
            >
              <IconButton component="label" sx={{ zIndex: 999 }}>
                <AddAPhoto sx={{ fill: colorsConfig.white, fontSize: '40px' }} />
                <VisuallyHiddenInput
                  type="file"
                  onChange={(e) => handleSetCarPhotos([e.target.files[0]])}
                />
              </IconButton>
              <IconButton component="label" sx={{ zIndex: 999 }}>
                <img width="37px" src={IconUpload} alt="" />
                <VisuallyHiddenInput
                  type="file"
                  multiple
                  onChange={(e) => handleSetCarPhotos(e.target.files)}
                />
              </IconButton>
            </Box>
          ) : (
            <FileUploader
              handleChange={handleSetCarPhotos}
              name="file"
              types={['JPG', 'PNG', 'jpeg']}
              hoverTitle="Drop here"
              label="Upload or drop all the photos here"
              classes="upload-picture"
              multiple
              maxSize={10}
              children={
                <Box
                  sx={{
                    width: '239px',
                    height: '160px',
                    background: colorsConfig.darkGray,
                    borderRadius: '6px',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    cursor: 'pointer',
                  }}
                >
                  <Box
                    sx={{
                      mb: '5px',
                    }}
                  >
                    <img src={IconUpload} alt="" />
                  </Box>
                  <Typography
                    sx={{
                      color: 'white',
                      fontSize: '18px',
                      fontWeight: 700,
                    }}
                  >
                    {t('button_upload')}
                  </Typography>
                </Box>
              }
            />
          )}
        </Box>
      </Box>
      <Box
        sx={{
          mt: '40px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
            mb: '20px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-start',
              alignItems: 'center',
              ...(xsOnly && {
                mb: '16px',
              }),
            }}
          >
            <Box
              sx={{
                marginRight: '5px',
              }}
            >
              <img src={IconUploaded} alt="" />
            </Box>
            <Typography
              className={xsOnly ? 'xs-only' : ''}
              sx={{
                fontWeight: 700,
                color: colorsConfig.mainBlack,
                fontSize: '18px',
              }}
            >
              {t('common_uploaded')}
            </Typography>
          </Box>
          {/*<Box>*/}
          {/*    <ButtonLG*/}
          {/*        onClick={() => {*/}
          {/*            handleSaveOrder()*/}
          {/*        }}*/}
          {/*        disabled={disableSaveOrderButton}*/}
          {/*    >*/}
          {/*        Save order*/}
          {/*    </ButtonLG>*/}
          {/*</Box>*/}
        </Box>
        <Grid container spacing={xsOnly ? '8px' : 3}>
          {carDataPhotos.map((carPhotoItem, index) => {
            if (!carPhotoItem.photo) return null
            return (
              <CarPhotoSorted
                key={index}
                index={index}
                image={`${CDN_BASE_URL}${carPhotoItem.photo.path}`}
                handleDeletePhoto={handleDeletePhoto}
                carView={carPhotoItem.foreshortening}
                setOrder={handleSetOrder}
                foreshorteningId={carPhotoItem['@id']}
                mainPhoto={carPhotoItem.mainPhoto}
                lastPhoto={carDataPhotos.length === index + 1}
                photoId={carPhotoItem.photo ? carPhotoItem.photo['@id'] : null}
              />
            )
          })}
        </Grid>
      </Box>
    </Box>
  )
}

export default CarPhotos
