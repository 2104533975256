import * as Yup from 'yup'
import { formErrors } from '../formErrors'

export const addCompanyAndManagerFormFormValidationSchema = Yup.object().shape({
  name: Yup.string().required(formErrors.required),
  tradeLicenseNumber: Yup.string().required(formErrors.required),
  tradeLicense: Yup.string().required(formErrors.required),
  landlineNumber: Yup.string().required(formErrors.required),
  phoneNumber: Yup.string().required(formErrors.required),
  firstName: Yup.string().required(formErrors.required),
  surname: Yup.string().required(formErrors.required),
  login: Yup.string().required(formErrors.required),
  email: Yup.string().required(formErrors.required).email(formErrors.invalidEmail),
})
