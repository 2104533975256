import React, { useEffect, useState } from 'react'
import { Box, Button, IconButton, Modal, Typography, useMediaQuery } from '@mui/material'
import { colorsConfig } from '../../../config/themeConfig'
import IconCheckmarkGreen from '../../../assests/img/icons/icon-checkmark-green.svg'
import TextNormal from '../../../ui/Text/TextNormal'
import IconCrossRed from '../../../assests/img/icons/icon-cross-red.svg'
import TextAreaSmall from '../../../ui/TextAreaSmall'
import { useDispatch, useSelector } from 'react-redux'
import {
  setSectionApproved,
  setSectionHint,
  setSectionUnchecked,
} from '../../../store/features/qualityCheckSlice'
import ButtonLG from '../../../ui/Buttons/ButtonLG'
import { privateRequest } from '../../../utils/request'
import { API_ENDPOINTS } from '../../../config/default'
import { setWorkflowState } from '../../../store/features/carSlice/carSlice'
import { toast } from 'react-toastify'
import { useNavigate, useParams } from 'react-router-dom'
import { Close } from '@mui/icons-material'
import ButtonSuccess from '../../../ui/Buttons/ButtonSuccess'
import ButtonDanger from '../../../ui/Buttons/ButtonDanger'
// "hydra:description": "Wrong enum state\\. Available values: \"car_damage\", \"car_foreshortening\", " +
// "\"subblock\", \"photos\", \"pilot_lamps\", \"vehicle_details\""

const AddComment = (props) => {
  const xsOnly = useMediaQuery('(max-width: 600px)')

  const { tab, setTab } = props

  const currentSection = useSelector((state) => state.qualityCheck.sections[tab])
  const qualityCheckDamages = useSelector((state) => state.qualityCheck.damages)
  const qualityCheckPhotos = useSelector((state) => state.qualityCheck.photos)
  const sections = useSelector((state) => state.qualityCheck.sections)
  const qualityCheckSubBlocks = useSelector((state) => state.qualityCheck.subBlocks)

  const dispatch = useDispatch()
  const params = useParams()
  const navigate = useNavigate()

  const [showComment, setShowComment] = useState(false)
  const [isSectionRejected, setIsSectionRejected] = useState(false)
  const [buttonDisabled, setButtonDisabled] = useState(true)
  const [confirmationModal, setConfirmationModal] = useState(false)
  const [confirmButtonDisabled, setConfirmButtonDisabled] = useState(false)

  const handleReject = () => {
    if (currentSection.approved) {
      dispatch(
        setSectionApproved({
          index: tab,
          value: !currentSection.approved,
        }),
      )
      setShowComment(true)
    }
    if (!currentSection.approved) {
      dispatch(
        setSectionUnchecked({
          index: tab,
        }),
      )
    }
  }

  const handleApprove = () => {
    if (tab < 6) {
      setTab(tab + 1)
    }
    if (currentSection.approved) {
      setShowComment(false)
      dispatch(
        setSectionApproved({
          index: tab,
          value: true,
        }),
      )
    }
  }

  const handleSetIsSectionRejected = () => {
    if (tab === 2) {
      const findRejection = qualityCheckPhotos.filter((item) => !item.approved && item.hint)[0]
      if (findRejection) {
        setIsSectionRejected(true)
        return
      }
    }
    if (tab === 3) {
      const findRejection = qualityCheckDamages.filter((item) => !item.approved && item.hint)[0]
      if (findRejection) {
        setIsSectionRejected(true)
        return
      }
    }
    setIsSectionRejected(false)
  }

  const submitHints = () => {
    sections.forEach((item, index) => {
      let photosRejected = false
      let damagesRejected = false
      if (index === 2) {
        photosRejected = qualityCheckPhotos.filter(
          (itemPhoto) => !itemPhoto.approved && itemPhoto.hint,
        )[0]
      }
      if (index === 3) {
        damagesRejected = qualityCheckDamages.filter(
          (itemDamage) => !itemDamage.approved && itemDamage.hint,
        )[0]
      }
      if (
        (!item.approved && item.hint) ||
        (item.hint && index === 2 && photosRejected) ||
        (item.hint && index === 3 && damagesRejected)
      ) {
        const formData = {
          type: item.systemName,
          value: item.systemName === 'subblock' ? item.entityId : null,
          hint: item.hint,
        }
        privateRequest.post(`/cars/${params.id}${API_ENDPOINTS.qualityCheckHints}`, formData)
      }
    })
    qualityCheckSubBlocks.forEach((item) => {
      if (!item.approved && item.hint) {
        const formData = {
          type: 'subblock',
          value: item['@id'],
          hint: item.hint,
        }
        privateRequest.post(`/cars/${params.id}${API_ENDPOINTS.qualityCheckHints}`, formData)
      }
    })
    qualityCheckDamages.forEach((item) => {
      if (!item.approved && item.hint) {
        const formData = {
          type: 'car_damage',
          value: item['@id'],
          hint: item.hint,
        }
        privateRequest.post(`/cars/${params.id}${API_ENDPOINTS.qualityCheckHints}`, formData)
      }
    })
    qualityCheckPhotos.forEach((item) => {
      if (!item.approved && item.hint) {
        const formData = {
          type: 'car_foreshortening',
          value: item['@id'],
          hint: item.hint,
        }
        privateRequest.post(`/cars/${params.id}${API_ENDPOINTS.qualityCheckHints}`, formData)
      }
    })
  }

  const handleFinishQualityCheck = () => {
    setConfirmButtonDisabled(true)
    if (
      sections.filter((item) => !item.approved)[0] ||
      qualityCheckSubBlocks.filter((item) => !item.approved)[0] ||
      qualityCheckDamages.filter((item) => !item.approved)[0] ||
      qualityCheckPhotos.filter((item) => !item.approved)[0]
    ) {
      submitHints()
      privateRequest
        .post(`/cars/${params.id}${API_ENDPOINTS.onSiteAppraisalModerationFail}`, {})
        .then(() => {
          dispatch(setWorkflowState('car_workflow.quality_check_failed'))
          toast.success('Quality Check has been finished successfully')
          navigate('/operator-panel')
        })
    } else {
      privateRequest
        .post(`/cars/${params.id}${API_ENDPOINTS.onSiteAppraisalModerationSuccess}`, {})
        .then(() => {
          dispatch(setWorkflowState('car_workflow.ready_for_sale'))
          toast.success('Quality Check has been finished successfully')
          navigate('/operator-panel')
        })
    }
  }

  useEffect(() => {
    console.log(sections)
    setButtonDisabled(
      Boolean(sections.filter((item, index) => !item.checked && index !== sections.length - 1)[0]),
    )
  }, [sections])

  useEffect(() => {
    handleSetIsSectionRejected()
  }, [tab, qualityCheckPhotos, qualityCheckDamages, qualityCheckSubBlocks])

  return (
    <Box
      sx={{
        mt: '25px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end',
          alignItems: 'center',
        }}
      >
        {isSectionRejected ? (
          <Button
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              textTransform: 'none',
              '&:hover': {
                '& .button-icon-checkmark': {
                  transition: '0.25s all ease-in-out',
                  borderColor: colorsConfig.mainGreen,
                  '& img': {
                    filter: 'grayscale(0%)',
                    transition: '0.25s all ease-in-out',
                  },
                },
              },
            }}
            onClick={() => {
              setTab(tab + 1)
            }}
          >
            <Box
              className={`button-icon-checkmark`}
              sx={{
                border: '1px solid',
                borderColor: colorsConfig.iconGray,
                width: '20px',
                height: '20px',
                borderRadius: '4px',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                mr: '5px',
                transition: '0.25s all ease-in-out',
                '& img': {
                  filter: 'grayscale(100%)',
                  transition: '0.25s all ease-in-out',
                },
                cursor: 'pointer',
                '&.active': {
                  transition: '0.25s all ease-in-out',
                  borderColor: colorsConfig.mainGreen,
                  '& img': {
                    filter: 'grayscale(0%)',
                    transition: '0.25s all ease-in-out',
                  },
                },
              }}
            >
              <img src={IconCheckmarkGreen} alt="" />
            </Box>
            <TextNormal>Save and go further</TextNormal>
          </Button>
        ) : tab < 6 ? (
          <>
            <Button
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                textTransform: 'none',
                mr: '30px',
                '&:hover': {
                  '& .button-icon-checkmark': {
                    transition: '0.25s all ease-in-out',
                    borderColor: colorsConfig.mainGreen,
                    '& img': {
                      filter: 'grayscale(0%)',
                      transition: '0.25s all ease-in-out',
                    },
                  },
                },
              }}
              onClick={() => {
                handleApprove()
              }}
            >
              <Box
                className={`button-icon-checkmark ${currentSection.approved && currentSection.checked ? 'active' : ''}`}
                sx={{
                  border: '1px solid',
                  borderColor: colorsConfig.iconGray,
                  width: '20px',
                  height: '20px',
                  borderRadius: '4px',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center',
                  mr: '5px',
                  transition: '0.25s all ease-in-out',
                  '& img': {
                    filter: 'grayscale(100%)',
                    transition: '0.25s all ease-in-out',
                  },
                  cursor: 'pointer',
                  '&.active': {
                    transition: '0.25s all ease-in-out',
                    borderColor: colorsConfig.mainGreen,
                    '& img': {
                      filter: 'grayscale(0%)',
                      transition: '0.25s all ease-in-out',
                    },
                  },
                }}
              >
                <img src={IconCheckmarkGreen} alt="" />
              </Box>
              <TextNormal>Approve</TextNormal>
            </Button>
            {tab < 6 ? (
              <Button
                sx={{
                  textTransform: 'none',
                  '&:hover': {
                    '& .button-icon-cross': {
                      transition: '0.25s all ease-in-out',
                      borderColor: colorsConfig.mainRed,
                      '& img': {
                        filter: 'grayscale(0%)',
                        transition: '0.25s all ease-in-out',
                      },
                    },
                  },
                }}
                onClick={() => {
                  handleReject()
                }}
              >
                <Box
                  className={`button-icon-cross ${!currentSection.approved ? 'active' : ''}`}
                  sx={{
                    border: '1px solid',
                    borderColor: colorsConfig.iconGray,
                    width: '20px',
                    height: '20px',
                    borderRadius: '4px',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    mr: '5px',
                    alignItems: 'center',
                    '& img': {
                      transition: '0.25s all ease-in-out',
                      filter: 'grayscale(100%)',
                    },
                    cursor: 'pointer',
                    transition: '0.25s all ease-in-out',
                    '&.active': {
                      transition: '0.25s all ease-in-out',
                      borderColor: colorsConfig.mainRed,
                      '& img': {
                        filter: 'grayscale(0%)',
                        transition: '0.25s all ease-in-out',
                      },
                    },
                  }}
                >
                  <img src={IconCrossRed} alt="" />
                </Box>
                <TextNormal>Reject</TextNormal>
              </Button>
            ) : null}
          </>
        ) : (
          <ButtonLG
            onClick={() => {
              setConfirmationModal(true)
            }}
            disabled={buttonDisabled}
          >
            Finish quality check
          </ButtonLG>
        )}
      </Box>
      {(!currentSection.approved || isSectionRejected) && tab < 6 ? (
        <Box
          sx={{
            mt: '20px',
          }}
        >
          <Box
            sx={{
              position: 'relative',
            }}
          >
            <TextAreaSmall
              fullWidth
              multiline
              rows={7}
              value={currentSection.hint}
              onChange={(e) => {
                dispatch(
                  setSectionHint({
                    index: tab,
                    value: e.target.value,
                  }),
                )
              }}
              sx={{
                height: '80px',
              }}
              placeholder={'Write the reason of rejection'}
              label={'Comment'}
            />
            {!isSectionRejected ? (
              <Button
                sx={{
                  height: '38px',
                  fontFamily: '"Helvetica", sans-serif',
                  fontSize: '16px',
                  fontWeight: 700,
                  color: 'white',
                  textTransform: 'none',
                  background: colorsConfig.mainBlue,
                  px: '14px',
                  position: 'absolute',
                  right: '13px',
                  bottom: '15px',
                  borderRadius: '6px',
                  transition: '0.25s all ease-in-out',
                  '&:hover': {
                    background: colorsConfig.buttonHover,
                    color: 'white',
                    transition: '0.25s all ease-in-out',
                  },
                  '&.Mui-disabled': {
                    background: colorsConfig.buttonDisabled,
                    color: 'rgba(255,255,255,.7)',
                  },
                }}
                disabled={currentSection.hint === ''}
                onClick={() => {
                  if (tab < 6) {
                    setTab(tab + 1)
                  }
                }}
              >
                Save and go further
              </Button>
            ) : null}
          </Box>
        </Box>
      ) : null}
      <Modal
        open={confirmationModal}
        onClose={() => {
          setConfirmationModal(false)
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: xsOnly ? '90%' : 500,
            background: 'white',
            borderRadius: '10px',
            padding: '25px',
            paddingTop: '35px',
            maxHeight: '80vh',
            overflow: 'auto',
          }}
          className={'disable-scrollbar'}
        >
          <Typography
            variant={'body1'}
            sx={{
              fontSize: xsOnly ? '24px' : '30px',
              fontWeight: 700,
              mb: '20px',
              lineHeight: '35px',
              textAlign: 'center',
            }}
          >
            Are you sure you want to finish <br />
            quality check?
          </Typography>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <ButtonDanger
              sx={{
                mr: '10px',
              }}
              onClick={() => {
                setConfirmationModal(false)
              }}
            >
              No
            </ButtonDanger>
            <ButtonSuccess
              disabled={confirmButtonDisabled}
              onClick={() => {
                handleFinishQualityCheck()
              }}
            >
              Yes
            </ButtonSuccess>
          </Box>
          <IconButton
            sx={{
              position: 'absolute',
              top: '5px',
              right: '5px',
            }}
            onClick={() => {
              setConfirmationModal(false)
            }}
          >
            <Close
              style={{
                fill: colorsConfig.mainRed,
              }}
            />
          </IconButton>
        </Box>
      </Modal>
    </Box>
  )
}

export default AddComment
