import React, { useState } from 'react'
import { Stack } from '@mui/material'
import {
  AddRounded,
  FilterListOffRounded,
  FilterListRounded,
  RefreshRounded,
} from '@mui/icons-material'
import UIIconButton from '../UIButtons/UIIconButton'
import UITextLabelExtraLarge from '../UIText/UITextLabelExtraLarge'
import SearchInput from '../../components/Search/SearchInput'
import useBreakpoints from '../../hooks/useBreakpoints'
import UITableStatusFilter from './UITableStatusFilter'

const UITableControls = ({
  title,
  onAdd,
  onRefresh,
  searchValue,
  setSearchValue,
  viewMode,
  onViewModeChange,
  filters,
  addText = 'Add',
  refreshText = 'Refresh',
  showFiltersText = 'Show filters',
  hideFiltersText = 'Hide filters',
  statusFilterProps,
  sx,
}) => {
  const { smDown } = useBreakpoints()
  const [filtersOpen, setFiltersOpen] = useState(false)

  return (
    <Stack
      direction={{ xs: 'column', sm: 'row' }}
      justifyContent="space-between"
      alignItems="center"
      sx={{ ...sx }}
    >
      <Stack
        direction={{ xs: 'column', sm: 'row' }}
        alignItems="center"
        gap="16px"
        sx={{ width: { xs: '100%', sm: 'auto' }, px: { xs: '16px', md: 0 } }}
      >
        <UITextLabelExtraLarge
          className="ui-table-title"
          fontWeight="700"
          text={title}
          sx={{ whiteSpace: 'nowrap' }}
        />
        {setSearchValue && (
          <SearchInput
            fullWidth
            sx={{
              flex: {
                xs: 1,
                sm: 'auto',
              },
            }}
            label="Search"
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
          />
        )}
      </Stack>
      <Stack direction="row" gap="20px" alignItems="center">
        {filters && filtersOpen ? filters : null}
        <Stack direction="row" alignItems="center" gap="4px">
          {/*{onViewModeChange && viewMode && (*/}
          {/*  <UIViewModeButton value={viewMode} setValue={(value) => onViewModeChange(value)} />*/}
          {/*)}*/}
          {onAdd && (
            <UIIconButton text={addText} onClick={() => onAdd()}>
              <AddRounded />
            </UIIconButton>
          )}
          {onRefresh && (
            <UIIconButton text={refreshText} onClick={() => onRefresh()}>
              <RefreshRounded />
            </UIIconButton>
          )}
          {filters && (
            <UIIconButton
              text={filtersOpen ? hideFiltersText : showFiltersText}
              onClick={() => setFiltersOpen(!filtersOpen)}
            >
              {filtersOpen ? <FilterListOffRounded /> : <FilterListRounded />}
            </UIIconButton>
          )}
          {statusFilterProps && <UITableStatusFilter {...statusFilterProps} />}
        </Stack>
      </Stack>
    </Stack>
  )
}

export default UITableControls
