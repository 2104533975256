import rviApiService from '../../../api/rviService/rviApiService'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { adaptParams } from '../../../api/api'
import policyWorkflowLogApiService from '../../../api/logsApiService/policyWorkflowLogApiService'

export const getDashboardPoliciesStatusCount = createAsyncThunk(
  'dashboard/getDashboardPoliciesStatusCount',
  async (_, { rejectWithValue }) => {
    try {
      const response = await rviApiService.getPoliciesStateCounts()
      return response.data
    } catch (e) {
      return rejectWithValue(e)
    }
  },
)

export const getDashboardPolicies = createAsyncThunk(
  'dashboard/getDashboardPolicies',
  async (_, { rejectWithValue, getState }) => {
    try {
      const params = getState().dashboard.statusCakes.policies.params
      const response = await rviApiService.getPolicies(
        adaptParams(params),
        '',
        'getDashboardPolicies',
      )
      return response.data
    } catch (e) {
      console.log(e)
      return rejectWithValue(e)
    }
  },
)

export const getDashboardPoliciesWorkflowLogs = createAsyncThunk(
  'dashboard/getDashboardPoliciesWorkflowLogs',
  async (_, { rejectWithValue }) => {
    try {
      const response = await policyWorkflowLogApiService.getPoliciesWorkflowLogs({
        pagination: false,
      })
      return response.data
    } catch (e) {
      return rejectWithValue(e)
    }
  },
)
