import React, { useEffect } from 'react'
import { Form, Formik } from 'formik'
import { Box, Button, FormHelperText, Grid, Stack } from '@mui/material'
import FormInput from '../../FormComponents/FormInput'
import ButtonLG from '../../../../ui/Buttons/ButtonLG'
import FormUIDMaskedInput from '../../FormComponents/FormUIDMaskedInput/FormUIDMaskedInput'
import FormPhoneNumber from '../../FormComponents/FormPhoneNumber/FormPhoneNumber'
import TextFieldSmallEdible from '../../../../ui/TextFieldSmallEdible'
import FormUploadFile from '../../FormComponents/FormUploadFile/FormUploadFile'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { createCompany } from '../../../../store/features/companiesSlice/companies.actions'
import { toast } from 'react-toastify'
import FormInputAutocomplete from '../../FormComponents/FormInputAutocomplete/FormInputAutocomplete'
import FormSelect from '../../FormComponents/FormSelect'
import { localeOptions } from '../../../../utils/constants/options.constants'
import { colorsConfig } from '../../../../config/themeConfig'
import { useTranslation } from 'react-i18next'
import { Close } from '@mui/icons-material'
import { addCompanyAndManagerFormFormValidationSchema } from './addCompanyAndManagerForm.helpers'
import { createCompanyWithManager } from '../../../../store/features/company/company.actions'
import UIPreloader from '../../../../ui/UIPreloader/UIPreloader'

const AddCompanyAndManagerForm = ({ onClose, onSubmit }) => {
  const { t } = useTranslation()
  const isLoading = useSelector((state) => state.company.createWithManager.isLoading)
  const dispatch = useDispatch()

  const handleSubmit = async (values) => {
    const companyData = {
      ...values,
      invoiceEmail: values?.email,
      taxRegistrationNumber: `${values?.tradeLicenseNumber} MWST`,
    }
    const { phoneNumber, firstName, surname, login, email, locale } = values
    const managerData = {
      phoneNumber,
      firstName,
      surname,
      user: {
        login,
        email,
        locale,
      },
    }
    const res = await dispatch(createCompanyWithManager({ companyData, managerData }))
    if (createCompanyWithManager.fulfilled.match(res)) {
      toast.success('Dealer has been added successfully')
      if (onSubmit) onSubmit(res.payload)
      onClose()
    }
  }

  useEffect(() => {
    console.log({ isLoading })
  }, [isLoading])

  return (
    <Formik
      initialValues={{
        name: '',
        invoiceEmail: '',
        tradeLicenseNumber: '',
        tradeLicense: null,
        landlineNumber: '',
        phoneNumber: '',
        firstName: '',
        surname: '',
        login: '',
        locale: 'de',
      }}
      onSubmit={handleSubmit}
      validationSchema={addCompanyAndManagerFormFormValidationSchema}
    >
      {(props) => (
        <Form noValidate>
          <Grid container spacing="30px" sx={{ position: 'relative' }}>
            {isLoading && <UIPreloader />}
            <Grid item xs={12} md={6}>
              <Stack gap="24px">
                <FormInput required label="Username" name="login" id="login" />

                <FormInput required label="E-mail" name="email" id="email" />

                <FormPhoneNumber
                  required
                  label="form_input_label_mobile_number"
                  name="phoneNumber"
                />

                <FormInput required label="First name" name="firstName" id="firstName" />

                <FormInput required label="Last name" name="surname" id="surname" />

                <FormSelect label="Acount language" name="locale" options={localeOptions} />
              </Stack>
            </Grid>
            <Grid item xs={12} md={6}>
              <Stack gap="24px">
                <FormInput required label="Company name" name="name" id="name" />

                <FormPhoneNumber
                  required
                  label="form_input_label_landline_number"
                  name="landlineNumber"
                />
                <FormUIDMaskedInput
                  required
                  label="form_input_label_uid"
                  name="tradeLicenseNumber"
                  id="tradeLicenseNumber"
                />
                <TextFieldSmallEdible
                  fullWidth
                  required
                  label="form_input_label_vat_nr"
                  inputProps={{
                    inputMode: 'numeric',
                    readOnly: true,
                  }}
                  value={`${props.values?.tradeLicenseNumber} MWST`}
                />
                {props.values['tradeLicense'] ? (
                  <Box>
                    <Button
                      variant="outlined"
                      component="div"
                      sx={{
                        cursor: 'auto',
                        textAlign: 'left',
                        justifyContent: 'space-between',
                      }}
                      disableRipple
                      fullWidth
                      endIcon={
                        <Close
                          sx={{
                            cursor: 'pointer',
                          }}
                          onClick={() => {
                            props.setFieldValue('tradeLicense', null)
                          }}
                        />
                      }
                    >
                      {props.values['tradeLicense']?.name}
                    </Button>
                  </Box>
                ) : null}
                <Stack
                  gap="4px"
                  sx={{
                    '& .MuiButton-root': {
                      backgroundColor: 'transparent',
                      justifyContent: 'start',
                    },
                  }}
                >
                  <Button
                    fullWidth
                    component="label"
                    variant="contained"
                    sx={{
                      height: '38px',
                      border: `1px solid ${colorsConfig.mainBlue}`,
                      color: '#757575',
                      textTransform: 'none',
                      transition: '0.25s all ease-in-out',
                      '&:hover': {
                        color: 'white',
                        background: colorsConfig.mainBlue,
                        transition: '0.25s all ease-in-out',
                        '& > svg > path': {
                          fill: 'white',
                          transition: '0.25s all ease-in-out',
                        },
                      },
                    }}
                  >
                    <svg
                      width="14"
                      height="19"
                      viewBox="0 0 14 19"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      style={{
                        marginRight: '10px',
                      }}
                    >
                      <path
                        d="M1.58203 18.5H12.1289C13.0013 18.5 13.7109 17.7903 13.7109 16.918V5.77344H10.0195C9.14716 5.77344 8.4375 5.06377 8.4375 4.19141V0.5H1.58203C0.709664 0.5 0 1.20966 0 2.08203V16.918C0 17.7903 0.709664 18.5 1.58203 18.5ZM3.69141 7.91797H10.0195C10.311 7.91797 10.5469 8.15383 10.5469 8.44531C10.5469 8.73679 10.311 8.97266 10.0195 8.97266H3.69141C3.39993 8.97266 3.16406 8.73679 3.16406 8.44531C3.16406 8.15383 3.39993 7.91797 3.69141 7.91797ZM3.69141 10.0273H10.0195C10.311 10.0273 10.5469 10.2632 10.5469 10.5547C10.5469 10.8462 10.311 11.082 10.0195 11.082H3.69141C3.39993 11.082 3.16406 10.8462 3.16406 10.5547C3.16406 10.2632 3.39993 10.0273 3.69141 10.0273ZM3.69141 12.1367H10.0195C10.311 12.1367 10.5469 12.3726 10.5469 12.6641C10.5469 12.9555 10.311 13.1914 10.0195 13.1914H3.69141C3.39993 13.1914 3.16406 12.9555 3.16406 12.6641C3.16406 12.3726 3.39993 12.1367 3.69141 12.1367ZM3.69141 14.2461H7.91016C8.20164 14.2461 8.4375 14.482 8.4375 14.7734C8.4375 15.0649 8.20164 15.3008 7.91016 15.3008H3.69141C3.39993 15.3008 3.16406 15.0649 3.16406 14.7734C3.16406 14.482 3.39993 14.2461 3.69141 14.2461Z"
                        fill="#757575"
                      />
                      <path
                        d="M10.0195 4.71836H13.4019L9.49219 0.808594V4.19101C9.49219 4.48196 9.72858 4.71836 10.0195 4.71836Z"
                        fill="#757575"
                      />
                    </svg>
                    {t('form_input_label_upload_registry_copy')}
                    <input
                      type="file"
                      hidden
                      name="tradeLicense"
                      onChange={(e) => {
                        props.setFieldValue('tradeLicense', e.target.files[0])
                      }}
                    />
                  </Button>
                </Stack>
              </Stack>
            </Grid>
            <Grid xs={12} item display="flex" justifyContent="flex-end">
              <ButtonLG
                type="submit"
                disabled={isLoading || (props.touched && !props.isValid && props.submitCount > 0)}
              >
                Save dealer
              </ButtonLG>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  )
}

export default AddCompanyAndManagerForm
