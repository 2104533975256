import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  getPoliciesInRestitution,
  getPoliciesStatusCount,
} from '../../../../store/features/rviOperator/rviOperator.actions'
import UIPreloader from '../../../../ui/UIPreloader/UIPreloader'
import UITable from '../../../../ui/UITable/UITable'
import { awaitingRestitutionPoliciesHead } from '../../../../utils/constants/tables/table.constants'
import { getOperatorRviRestitutionInProgressTableData } from '../../../../utils/helpers/tables/table.helpers'
import RestitutionInProgressActionItems from '../../../RVI/Operator/RVIPoliciesActions/RestitutionInProgressActionItems'
import UITablePagination from '../../../../ui/UITable/UITablePagination'
import { setPoliciesInRestitutionParams } from '../../../../store/features/rviOperator/rviOperator.reducer'
import UITableWrapper from '../../../../ui/UITable/UITableWrapper'

const DashboardAwaitingRestitution = () => {
  const policiesInRestitution = useSelector((state) => state.rviOperator.policiesInRestitution.data)
  const isLoadingPoliciesInRestitution = useSelector(
    (state) => state.rviOperator.policiesInRestitution.isLoading,
  )
  const totalPoliciesInRestitution = useSelector(
    (state) => state.rviOperator.policiesInRestitution.total,
  )
  const policiesInRestitutionParams = useSelector(
    (state) => state.rviOperator.policiesInRestitution.params,
  )
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getPoliciesInRestitution())
  }, [policiesInRestitutionParams])

  useEffect(() => {
    dispatch(getPoliciesStatusCount())
  }, [])

  return (
    <UITableWrapper>
      {isLoadingPoliciesInRestitution && <UIPreloader />}
      <UITable
        head={awaitingRestitutionPoliciesHead}
        content={getOperatorRviRestitutionInProgressTableData(policiesInRestitution)}
        sort={policiesInRestitutionParams.sort}
        setSort={(value) =>
          dispatch(setPoliciesInRestitutionParams({ ...policiesInRestitutionParams, sort: value }))
        }
        emptyText="No policies found."
        renderActionItems={(data) => {
          return <RestitutionInProgressActionItems data={data} />
        }}
      />
      {totalPoliciesInRestitution !== 0 && (
        <UITablePagination
          count={totalPoliciesInRestitution}
          page={policiesInRestitutionParams.page}
          rowsPerPage={policiesInRestitutionParams.itemsPerPage}
          onPageChange={(e, newValue) => {
            dispatch(
              setPoliciesInRestitutionParams({
                ...policiesInRestitutionParams,
                page: newValue,
              }),
            )
          }}
          onRowsPerPageChange={(e) => {
            dispatch(
              setPoliciesInRestitutionParams({
                ...policiesInRestitutionParams,
                page: 1,
                itemsPerPage: e.target.value,
              }),
            )
          }}
        />
      )}
    </UITableWrapper>
  )
}

export default DashboardAwaitingRestitution
