import React, { useEffect, useState } from 'react'
import { Box, Button, Typography } from '@mui/material'
import { FileOpen } from '@mui/icons-material'
import { useDispatch, useSelector } from 'react-redux'
import {
  deleteControlPointFile,
  updateControlPoint,
} from '../../../store/features/carSlice/carSlice'
import { API_ENDPOINTS, FILE_TYPES } from '../../../config/default'
import { toast } from 'react-toastify'
import { FileUploader } from 'react-drag-drop-files'
import { colorsConfig } from '../../../config/themeConfig'
import IconUploadFile from '../../../assests/img/icons/icon-upload-file.svg'
import { privateRequest } from '../../../utils/request'
import { uploadFile } from '../../../utils/uploadFile'
import { useParams } from 'react-router-dom'

const ControlPointFile = (props) => {
  const { name, blockIndex, subBlockIndex, controlPointIndex } = props

  const value = useSelector(
    (state) =>
      state.car.appraisal.controlPoints[blockIndex].subblocks[subBlockIndex].controlPoints[
        controlPointIndex
      ].value,
  )
  const currentFile = useSelector(
    (state) =>
      state.car.appraisal.controlPoints[blockIndex].subblocks[subBlockIndex].controlPoints[
        controlPointIndex
      ].file,
  )
  const controlPoint = useSelector(
    (state) =>
      state.car.appraisal.controlPoints[blockIndex].subblocks[subBlockIndex].controlPoints[
        controlPointIndex
      ],
  )
  const dispatch = useDispatch()

  const params = useParams()

  const [displayFile, setDisplayFile] = useState(null)

  const handleSetFile = (file) => {
    if (file.size / (1024 * 1024) > 10) {
      toast.error('We are currently not accepting files over 10 MB size, sorry for inconvenience')
    } else {
      dispatch(
        updateControlPoint({
          newValue: file,
          block: blockIndex,
          subBlock: subBlockIndex,
          controlPoint: controlPointIndex,
        }),
      )
    }
  }

  const handleSetFiles = (files) => {
    const newFiles = Array.from(files).filter((item) => {
      if (item.size / (1024 * 1024) > 10) {
        toast.error(
          `The file named ${item.name} is too large (over 10 MB) and could not be uploaded. Sorry for the inconvenience`,
        )
        return false
      }
      return true
    })
    if (newFiles.length > 0) {
      dispatch(
        updateControlPoint({
          newValue: newFiles,
          block: blockIndex,
          subBlock: subBlockIndex,
          controlPoint: controlPointIndex,
        }),
      )
      const uploadedFiles = newFiles.map((item) =>
        uploadFile(item, FILE_TYPES.controlPointDocument),
      )
      Promise.all(uploadedFiles).then((result) => {
        privateRequest
          .post(API_ENDPOINTS.submitCarControlPoints(params.id), {
            carControlPoints: [
              {
                controlPoint: controlPoint['@id'],
                value: result,
              },
            ],
          })
          .then(() => {})
      })
    }
  }

  const handleDeleteFile = () => {
    dispatch(
      deleteControlPointFile({
        newValue: null,
        value: null,
        block: blockIndex,
        subBlock: subBlockIndex,
        controlPoint: controlPointIndex,
        deleted: true,
      }),
    )
  }

  useEffect(() => {
    // if (value) {
    //     if (value.name.includes('.jpg') ||
    //         value.name.includes('.gif') ||
    //         value.name.includes('.png') ||
    //         value.name.includes('.jpeg')
    //     ) {
    //         setDisplayFile({
    //             type: 'image',
    //             source: URL.createObjectURL(value)
    //         })
    //     } else {
    //         setDisplayFile({
    //             type: 'default',
    //             source: URL.createObjectURL(value)
    //         })
    //     }
    //     return
    // }
    // if (currentFile) {
    //     if (currentFile.path.includes('.jpg') ||
    //         currentFile.path.includes('.gif') ||
    //         currentFile.path.includes('.png') ||
    //         currentFile.path.includes('.jpeg') ) {
    //         setDisplayFile({
    //             type: 'image',
    //             source: `${CDN_BASE_URL}${currentFile.path}`
    //         })
    //     } else {
    //         setDisplayFile({
    //             type: 'default',
    //             source: `${CDN_BASE_URL}${currentFile.path}`
    //         })
    //     }
    //     return
    // }
    // if (!currentFile && !value) {
    //     setDisplayFile(null)
    // }
  }, [value, currentFile])

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: 'fit-content',
      }}
    >
      <Box sx={{ width: '100%' }}>
        <Typography
          variant="body1"
          sx={{
            display: 'flex',
            alignItems: 'center',
            height: '40px',
            color: colorsConfig.mainBlack,
            fontSize: '16px',
            fontWeight: 700,
            fontFamily: '"Helvetica", sans-serif',
            fontStyle: 'normal',
            lineHeight: 'normal',
            whiteSpace: 'normal',
            marginBottom: '20px',
          }}
        >
          {name}
        </Typography>
      </Box>
      <Box
        sx={{
          width: '100%',
          height: !value && !currentFile ? '150px' : '245px',
          background: colorsConfig.bgLightBlue,
          display: 'flex',
          flexDirection: 'column',
          gap: '12px',
          borderRadius: '6px',
        }}
      >
        <Box
          sx={{
            width: '100%',
            position: 'relative',
            border: 'none',
            backgroundColor: '#F7FAFB',
            backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='6' ry='6' stroke='%23007DFF' stroke-width='3' stroke-dasharray='10%2c 10' stroke-dashoffset='10' stroke-linecap='round'/%3e%3c/svg%3e")`,
            borderRadius: '6px',
            '& .MuiTypography-root': {
              width: '100px',
              marginTop: '-3px',
            },
          }}
        >
          <FileUploader
            name="file"
            types={['JPG', 'PNG', 'pdf', 'doc', 'jpeg']}
            hoverTitle="Drop here"
            multiple
            label="Drag & Drop files here"
            classes="upload-picture"
            maxSize={10}
            handleChange={handleSetFiles}
            onDrop={handleSetFiles}
            children={
              <>
                <Box
                  sx={{
                    borderRadius: '6px',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    minHeight: '92px',
                    background: 'none',
                    cursor: 'pointer',
                    marginLeft: '10px',
                  }}
                >
                  <Box
                    sx={{
                      marginRight: '10px',
                      width: '60px',
                      height: '40px',
                      backgroundImage: `url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="61" height="40" viewBox="0 0 61 40" fill="none"><path d="M57.3957 13.1934H52.1789V8.81382C52.1796 8.38231 52.0789 7.95492 51.8825 7.55615C51.6861 7.15737 51.398 6.79504 51.0345 6.48992C50.6711 6.18479 50.2395 5.94287 49.7645 5.778C49.2895 5.61314 48.7804 5.52857 48.2664 5.52915H28.3777C27.7954 5.52918 27.2204 5.42009 26.6947 5.20984C26.169 4.99959 25.7058 4.69347 25.3389 4.31382L22.7827 1.67513C22.2955 1.169 21.6795 0.760787 20.9799 0.480404C20.2804 0.20002 19.515 0.054564 18.7398 0.0546904H3.9243C3.41031 0.0541139 2.90123 0.138681 2.42623 0.303547C1.95123 0.468413 1.51964 0.710338 1.15619 1.01546C0.792741 1.32059 0.504571 1.68292 0.308191 2.08169C0.111811 2.48047 0.0110788 2.90785 0.0117655 3.33936V35.7153C0.0090536 36.2085 0.124236 36.6971 0.350493 37.1522C0.57675 37.6073 0.909481 38.0197 1.32899 38.3649C1.74021 38.7171 2.23142 38.9965 2.77354 39.1864C3.31566 39.3764 3.89767 39.473 4.4851 39.4708H48.3838C49.3528 39.4705 50.2956 39.2069 51.0714 38.7195C51.8472 38.232 52.4141 37.5468 52.6876 36.7664L59.8997 15.9963C60.0122 15.67 60.0329 15.3262 59.9601 14.992C59.8873 14.6578 59.7231 14.3424 59.4803 14.0706C59.2375 13.7988 58.9228 13.5781 58.561 13.4259C58.1993 13.2737 57.8003 13.1941 57.3957 13.1934Z" fill="%23376CFB"/><path d="M57.3949 13.1953H20.321C19.3532 13.1955 18.4116 13.4592 17.6375 13.9468C16.8634 14.4345 16.2985 15.1197 16.0277 15.8997L8.77643 36.7694C8.50558 37.5491 7.94085 38.2341 7.16698 38.7215C6.3931 39.2089 5.45182 39.4725 4.48438 39.4727H48.387C49.3552 39.473 50.2974 39.2095 51.072 38.7218C51.8467 38.2342 52.4119 37.5487 52.6829 36.7683L59.899 15.9982C60.0123 15.6719 60.0337 15.3278 59.9612 14.9934C59.8888 14.6589 59.7246 14.3432 59.4816 14.0712C59.2387 13.7993 58.9236 13.5785 58.5614 13.4265C58.1992 13.2745 57.7999 13.1953 57.3949 13.1953Z" fill="%234294FF"/></svg>')`,
                    }}
                  />
                  <Typography
                    sx={{
                      paddingTop: '12px',
                      fontSize: '16px',
                      fontStyle: 'normal',
                      lineHeight: 'normal',
                    }}
                  >
                    Drag & Drop files here
                  </Typography>
                </Box>
              </>
            }
            sx={{
              width: '100%',
              position: 'relative',
              'label.is-disabled': {
                border: 'none',
                backgroundColor: '#F7FAFB',
                backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='6' ry='6' stroke='%23007DFF' stroke-width='3' stroke-dasharray='10%2c 10' stroke-dashoffset='10' stroke-linecap='round'/%3e%3c/svg%3e")`,
                borderRadius: '6px',
              },
              '& .MuiTypography-root': {
                width: '100px',
                marginTop: '-3px',
              },
            }}
          />
        </Box>
        <Box
          sx={{
            width: '100%',
            position: 'relative',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            // flex: 1,
          }}
        >
          <Button
            component={value || currentFile ? '' : 'label'}
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
            }}
            onClick={() => {
              if (value || currentFile) {
                handleDeleteFile()
              }
            }}
          >
            <Box
              sx={{
                mr: '5px',
                position: 'relative',
                mt: '-2px',
                maxWidth: '17px',
              }}
            >
              {displayFile ? (
                displayFile.type === 'default' ? (
                  <a
                    target="_blank"
                    href={displayFile.source}
                    style={{
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                    className={'link-to-file'}
                    onClick={(e) => {
                      e.stopPropagation()
                    }}
                  >
                    <FileOpen
                      sx={{
                        fill: colorsConfig.buttonHover,
                      }}
                    />
                  </a>
                ) : (
                  <a
                    target="_blank"
                    href={displayFile.source}
                    onClick={(e) => {
                      e.stopPropagation()
                    }}
                  >
                    <img
                      src={displayFile.source}
                      style={{
                        width: '100%',
                        verticalAlign: 'middle',
                      }}
                      alt=""
                    />
                  </a>
                )
              ) : (
                <img
                  style={{ width: '100%', verticalAlign: 'middle' }}
                  src={IconUploadFile}
                  alt=""
                />
              )}
            </Box>
            <Typography
              sx={{
                fontWeight: 700,
                fontFamily: '"Helvetica", sans-serif',
                fonSize: '18px',
                textTransform: 'none',
                color: value || currentFile ? colorsConfig.mainRed : colorsConfig.mainBlue,
                transition: '0.25s all ease-in-out',
                '&:hover': {
                  color: value || currentFile ? colorsConfig.mainRed : colorsConfig.buttonHover,
                  transition: '0.25s all ease-in-out',
                },
              }}
            >
              {!value && !currentFile ? 'Upload' : 'Delete'}
            </Typography>
            {!value && !currentFile ? (
              <input
                hidden
                type="file"
                onChange={(e) => {
                  handleSetFile(e.target.files[0])
                }}
              />
            ) : null}
          </Button>
        </Box>
        <Box
          sx={{
            width: 'calc(100% - 12px)',
            height: '1px',
            background: '#E0E0E0',
            margin: '-5px 6px 0 6px',
            borderRadius: '50%',
          }}
        />
        {!value && !currentFile ? null : (
          <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '8px' }}></Box>
        )}
      </Box>
      {/*<Button*/}
      {/*    variant="contained"*/}
      {/*    component="label"*/}
      {/*>*/}
      {/*    Upload*/}

      {/*</Button>*/}
    </Box>
  )
}

export default ControlPointFile
