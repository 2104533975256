import { createSlice } from '@reduxjs/toolkit'
import getCarsBuilder from './builders/getCars.builder'
import { value } from 'lodash/seq'

const initialState = {
  isLoading: false,
  error: null,
  data: [],
  params: {
    page: 1,
    itemsPerPage: 10,
    isArchived: false,
    sort: {
      order: 'desc',
      key: 'lastUpdatedAt',
    },
    'creationSource[]': [0, 1, 3],
    searchWithOR: '',
    searchWithORFields: ['model.name', 'make.name', 'vin', 'referenceNumber', 'id'],
    combinedStatus: '',
  },
  total: 0,
  initialTotal: 0,
  initialized: false,
}

const carsSlice = createSlice({
  name: 'carsReducer',
  initialState,
  reducers: {
    setCarsParams: (state, action) => {
      state.params = action.payload
    },
  },
  extraReducers: (builder) => {
    getCarsBuilder(builder)
  },
})

export const { setCarsParams } = carsSlice.actions

export default carsSlice.reducer
