import { privateRequest } from '../../utils/request'
import { patchHeaders } from '../api'

class CompaniesApiService {
  #api

  #endPoints

  #abortControllers

  constructor({ api }) {
    this.#api = api
    this.#endPoints = {
      collection: '/admin/companies',
      instance: (id) => `/admin/companies/${id}`,
    }
    this.#abortControllers = {}
  }

  getCompanies = async (params, prefix = 'getCompanies') => {
    this.#abortControllers[prefix]?.abort()
    this.#abortControllers[prefix] = new AbortController()
    const response = await this.#api.get(this.#endPoints.collection, {
      params,
      signal: this.#abortControllers[prefix]?.signal,
    })
    return response
  }

  createCompany = async (data) => {
    const response = await this.#api.post(this.#endPoints.collection, data)
    return response
  }

  updateCompany = async (companyId, data) => {
    const response = await this.#api.put(this.#endPoints.instance(companyId), data)
    return response
  }

  patchCompany = async (companyId, data) => {
    const response = await this.#api.patch(this.#endPoints.instance(companyId), data, {
      headers: patchHeaders,
    })
    return response
  }

  getCompany = async (companyId) => {
    const response = await this.#api.get(this.#endPoints.instance(companyId))
    return response
  }

  deleteCompany = async (companyId) => {
    const response = await this.#api.delete(this.#endPoints.instance(companyId))
    return response
  }
}

const companiesApiService = new CompaniesApiService({ api: privateRequest })

export default companiesApiService
