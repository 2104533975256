import React, { useEffect } from 'react'
import { Box, Stack } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { setCurrentPageTitle } from '../../../../store/features/authSlice'
import UIPaper from '../../../../ui/UIPaper/UIPaper'
import {
  getLoanPrograms,
  getLoanProgramsBanks,
} from '../../../../store/features/financeAdmin/loanPrograms/loanPrograms.actions'
import UITableControls from '../../../../ui/UITable/UITableControls'
import UITable from '../../../../ui/UITable/UITable'
import UIPreloader from '../../../../ui/UIPreloader/UIPreloader'
import { loanProgramsTableHead } from '../../../../utils/constants/tables/financeAdmin.table.constants'
import { getLoanProgramsTableData } from '../../../../utils/helpers/tables/financeAdmin.table.helpers'
import UITablePagination from '../../../../ui/UITable/UITablePagination'
import { setLoanProgramsParams } from '../../../../store/features/financeAdmin/loanPrograms/loanPrograms.reducer'
import { apiConstants } from '../../../../api/api'

const LoanPrograms = () => {
  const isLoading = useSelector((state) => state.loanPrograms.isLoading)
  const loanPrograms = useSelector((state) => state.loanPrograms.data)
  const loanProgramsParams = useSelector((state) => state.loanPrograms.params)
  const total = useSelector((state) => state.loanPrograms.total)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  // const handleSetLoanPrograms = (data) => {
  //   setLoanPrograms(
  //     data.map((item) => {
  //       return {
  //         id: item.id,
  //         link: `/admin/financing/loan-programs/${item.id}/edit`,
  //         cells: [
  //           {
  //             type: 'text',
  //             value: item.title,
  //           },
  //           {
  //             type: 'text',
  //             value: item.marketingTitle,
  //           },
  //           {
  //             type: 'text',
  //             value: item.creditType,
  //           },
  //           {
  //             type: 'component',
  //             value: item.productProvider.title,
  //           },
  //         ],
  //       }
  //     }),
  //   )
  // }

  // const getLoanPrograms = () => {
  //   setLoading(true)
  //   adminRequest
  //     .get(`${API_FINANCE_ADMIN_ENDPOINTS.loanPrograms}?pagination=false`)
  //     .then((response) => {
  //       Promise.all(
  //         response.data['hydra:member'].map((item) => adminRequest.get(item.productProvider)),
  //       ).then((result) => {
  //         handleSetLoanPrograms(
  //           response.data['hydra:member'].map((item) => {
  //             return {
  //               ...item,
  //               productProvider: result.filter(
  //                 (resultItem) => resultItem.data['@id'] === item.productProvider,
  //               )[0].data,
  //             }
  //           }),
  //         )
  //         setCount(response.data['hydra:totalItems'])
  //         setLoading(false)
  //       })
  //     })
  // }

  const getData = async () => {
    const res = await dispatch(getLoanPrograms())
    if (getLoanPrograms.fulfilled.match(res)) {
      dispatch(getLoanProgramsBanks({ loanPrograms: res.payload?.[apiConstants.hydraMember] }))
    }
  }

  useEffect(() => {
    getData()
  }, [loanProgramsParams])

  useEffect(() => {
    dispatch(setCurrentPageTitle('Loan programs'))
  }, [])

  return (
    <UIPaper>
      <Stack gap="30px">
        <UITableControls
          onRefresh={() => getData()}
          onAdd={() => {}}
          addText="Add loan program"
          searchValue={loanProgramsParams.title}
          setSearchValue={(value) =>
            dispatch(
              setLoanProgramsParams({
                ...loanProgramsParams,
                page: 1,
                title: value,
              }),
            )
          }
        />
        <Box
          sx={{
            position: 'relative',
          }}
        >
          {isLoading && <UIPreloader text="Loading loan programs..." />}
          <UITable
            head={loanProgramsTableHead}
            content={getLoanProgramsTableData(loanPrograms)}
            emptyText="No loan programs found"
          />
          <UITablePagination
            count={total}
            page={loanProgramsParams.page}
            rowsPerPage={loanProgramsParams.itemsPerPage}
            onPageChange={(e, newValue) => {
              dispatch(
                setLoanProgramsParams({
                  ...loanProgramsParams,
                  page: newValue,
                }),
              )
            }}
            onRowsPerPageChange={(e) => {
              dispatch(
                setLoanProgramsParams({
                  ...loanProgramsParams,
                  page: 1,
                  itemsPerPage: e.target.value,
                }),
              )
            }}
          />
        </Box>
      </Stack>
    </UIPaper>
  )
}

export default LoanPrograms
