import React from 'react'
import { Box, Stack } from '@mui/material'
import { generateUID } from '../../../../utils/generateUID'
import TextNormal from '../../../../ui/Text/TextNormal'
import { colorsConfig } from '../../../../config/themeConfig'
import { useTranslation } from 'react-i18next'

const StatusList = ({ data, colors, handleOpenModal }) => {
  const { t } = useTranslation()
  return (
    <Stack
      gap="16px"
      className="disable-scrollbar"
      sx={{
        height: '220px',
        overflow: 'auto',
      }}
    >
      {data.map((item, index) => {
        return (
          <Box
            key={generateUID()}
            sx={{
              display: 'flex',
              gap: '15px',
            }}
          >
            <Box
              sx={{
                width: '30px',
                height: '4px',
                borderRadius: '100px',
                backgroundColor: colors && colors?.length >= data.length ? colors[index] : 'white',
                position: 'relative',
                bottom: '-6px',
              }}
            />
            <Stack gap="2px">
              <TextNormal
                sx={{ fontSize: '14px', color: colorsConfig.gray20, lineHeight: 'normal' }}
              >
                {item?.name}
              </TextNormal>
              <TextNormal
                component={'a'}
                onClick={(e) => {
                  e.preventDefault()
                }}
                sx={{
                  color: colorsConfig.darkBlue,
                  '& .link-pre': {
                    fontWeight: 700,
                  },
                  '& .link-main': {
                    textDecoration: 'underline',
                    cursor: 'pointer',
                    transition: '0.25s all ease-in-out',
                    '&:hover': {
                      color: colorsConfig.mainBlue,
                      transition: '0.25s all ease-in-out',
                    },
                  },
                }}
              >
                <span className="link-pre">{item?.count}</span>{' '}
                <span className="link-main" onClick={() => handleOpenModal(item)}>
                  {t('common_check')}
                </span>
              </TextNormal>
            </Stack>
          </Box>
        )
      })}
    </Stack>
  )
}

export default StatusList
