export const addWorkingDaysToCurrentDate = (days = 15) => {
  let result = new Date()
  let count = 0
  while (count < days) {
    result.setDate(result.getDate() + 1)
    if (result.getDay() !== 0 && result.getDay() !== 6) {
      count++
    }
  }
  return result
}

export const getDaysFromDate = (date) => {
  const date1 = new Date()
  const date2 = new Date(date)
  const diffTime = Math.abs(date2 - date1)
  const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24))
  return diffDays
}

export const getExtendedDate = (data, locale) => {
  console.log('locale', locale)
  const date = new Date(data)
  if (isNaN(date.getTime())) {
    return null
  }
  const options = {
    weekday: 'short',
    month: 'long',
    day: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    hour12: true,
    timeZone: 'UTC',
  }
  return new Intl.DateTimeFormat('en-US', options).format(date)
}
