import React from 'react'
import { getCarEnumName } from '../../../utils/helpers/enums.helpers'
import { CAR_ENUMS } from '../../../utils/constants/enums.constants'
import { capitalizeString } from '../../../utils/helpers/common.helpers'
import { useSelector } from 'react-redux'
import GrayTable from '../../../ui/GrayTable'
import { useTranslation } from 'react-i18next'
import { Box, Stack } from '@mui/material'
import { formatDate, getFormattedDate } from '../../../utils/date.helpers'
import UITextTitleSmall from '../../../ui/UIText/UITextTitleSmall'
import UITextRegular from '../../../ui/UIText/UITextRegular'
import { sumDelimiter } from '../../../utils/sumDelimiter'

const VehicleDetailsReduced = ({ car }) => {
  const { t } = useTranslation()

  const enumDetails = useSelector((state) => state.ref.enumDetails.data)

  const details = [
    {
      title: 'VIN',
      value: car?.vin ?? '-',
    },
    {
      title: t('form_input_label_make'),
      value: car?.make?.name ?? '-',
    },
    {
      title: t('form_input_label_model'),
      value: car?.model?.name ?? '-',
    },
    {
      title: t('form_input_label_first_registration_date'),
      value: getFormattedDate(car?.firstRegistrationDate)?.slice(3) ?? '-',
    },
    {
      title: `${t('form_input_label_mileage')} (km)`,
      value: car?.mileage ? sumDelimiter(car?.mileage) : '-',
    },
    {
      title: t('form_input_label_engine_type'),
      value: getCarEnumName(car?.engineType, CAR_ENUMS.CAR_ENGINE, enumDetails),
    },
    {
      title: `${t('form_input_label_engine_size')} (ccm)`,
      value: car.engineSize ? car.engineSize : '-',
    },
    {
      title: t('form_input_label_body_type'),
      value: getCarEnumName(car?.bodyType, CAR_ENUMS.CAR_BODY, enumDetails),
    },
    {
      title: t('form_input_label_steering_wheel_location'),
      value: car?.wheelLocation ? capitalizeString(car?.wheelLocation) : '-',
    },
    {
      title: t('form_input_label_origin'),
      value: getCarEnumName(car?.origin, CAR_ENUMS.CAR_ORIGIN, enumDetails),
    },
    {
      title: t('form_input_label_drive'),
      value: getCarEnumName(car?.driveType, CAR_ENUMS.CAR_DRIVE, enumDetails),
    },
    {
      title: t('form_input_label_gear'),
      value: getCarEnumName(car?.gearbox, CAR_ENUMS.CAR_GEARBOX, enumDetails),
    },
    {
      title: t('form_input_label_seats_number'),
      value: car?.seatsNumber ?? '-',
    },
    {
      title: t('form_input_label_doors_number'),
      value: car?.doorsNumber ? `${car?.doorsNumber} / ${parseInt(car?.doorsNumber) + 1}` : '-',
    },
    {
      title: t('form_input_label_last_service_date'),
      value: car?.lastServiceDate ? formatDate(car?.lastServiceDate) : '-',
    },
    {
      title: `${t('form_input_label_last_service_mileage')} (km)`,
      value: car?.lastServiceMileage ? sumDelimiter(car?.lastServiceMileage) : '-',
    },
  ]

  return (
    <Box
      sx={{
        borderRadius: '6px',
        overflow: 'hidden',
        px: '20px',
        boxShadow: '0 0 24px 0 rgba(0,0,0,.1)',
      }}
    >
      <Stack gap="30px">
        <GrayTable content={details} />
        {car?.additionalInfo && car?.additionalInfo !== '' ? (
          <Stack gap="10px" sx={{ pb: '30px' }}>
            <UITextTitleSmall text="form_input_label_additional_info" />
            <UITextRegular text={car?.additionalInfo ?? '-'} />
          </Stack>
        ) : null}
      </Stack>
    </Box>
  )
}

export default VehicleDetailsReduced
