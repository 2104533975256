import React, { useState } from 'react'
import { Box, IconButton } from '@mui/material'
import AppleIconHelpGif from '../../../assests/gif/icon-apple-help.gif'
import AskOperatorPopover from './AskOperatorPopover'

const AskOperatorButton = () => {
  const [askOperatorPopover, setAskOperatorPopover] = useState(false)

  return (
    <Box
      sx={{
        position: 'fixed',
        zIndex: 100,
        bottom: '30px',
        right: '30px',
      }}
    >
      <IconButton
        sx={{
          width: '79px',
          height: '79px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          cursor: 'pointer',
          boxShadow: '0 0 12px 0 rgba(0,0,0,.1)',
        }}
        onClick={() => setAskOperatorPopover(true)}
      >
        <img src={AppleIconHelpGif} alt="GIF" style={{ width: '115px', height: '115px' }} />
      </IconButton>
      {askOperatorPopover && (
        <AskOperatorPopover open={askOperatorPopover} setOpen={setAskOperatorPopover} />
      )}
    </Box>
  )
}

export default AskOperatorButton
