import React, { useState } from 'react'
import GrayTable from '../../../ui/GrayTable'
import { useMediaQuery } from '@mui/material'
import { useSelector } from 'react-redux'
import UINoDataIcon from '../../../ui/UIIcons/UINoDataIcon'
import { getFormattedDate } from '../../../utils/date.helpers'
import { sumDelimiter } from '../../../utils/sumDelimiter'
import { LOCAL_ROLE } from '../../../utils/constants/global.constants'
import CollapseBlock from '../../../ui/Accordions/CollapseBlock'

const getBooleanValue = (val) => (val === true ? 'Yes' : val === false ? 'No' : <UINoDataIcon />)
const getRawValue = (val) => val ?? <UINoDataIcon />
const getMoneyValue = (moneyString) => {
  return moneyString ? `${sumDelimiter(moneyString)} CHF` : <UINoDataIcon />
}

const RVIPolicyDetails = () => {
  const role = useSelector((state) => state.auth.role)
  const policy = useSelector((state) => state.rviPolicy.data)
  const xsOnly = useMediaQuery('(max-width: 600px)')

  const details = policy?.details || {}

  const [collapsed, setCollapsed] = useState(true)

  const {
    policyNumber,
    internalCustomerId,
    dealerId,
    dealerName,
    externalModelId,
    customerContractNumber,
    color,
    mileage,
    vehicleGrossPriceExcludingOptionsIncludingVAT,
    vehicleOptionsIncludingVAT,
    discountIncludingVAT,
    vehicleGrossPriceIncludingOptionsIncludingVAT,
    vehicleNetPriceIncludingVAT,
    mileagePa,
    contractDurationInMonths,
    offerId,
    firstRegistrationDate,
    salesPeriod,
    businessPartnerId,
    RVWarrant,
    customerReference,
    listPriceIncludingOptionsExcludingVAT,
    RVPremiumExcludingVAT,
    RVPremiumPercent,
    risk,
    handlingFee,
    inRisk,
    reinsurance,
    reinsurer,
    reinsuranceBrutto,
    insuranceTax,
    reinsuranceNetto,
    reinsuranceRisk,
    reinsuranceVK,
    reserves,
    status,
    realEndDate,
    initialRVPercent,
    initialResidualValueIncludingVAT,
  } = details

  const policyDetails = [
    {
      title: 'policy_details_policy_number',
      value: policyNumber ?? <UINoDataIcon />,
      visibleFor: [LOCAL_ROLE.OPERATOR, LOCAL_ROLE.COMPANY],
    },
    {
      title: 'policy_details_internal_customer_id',
      value: internalCustomerId ?? <UINoDataIcon />,
      visibleFor: [LOCAL_ROLE.OPERATOR],
    },
    {
      title: 'policy_details_dealer_id',
      value: dealerId ?? <UINoDataIcon />,
      visibleFor: [LOCAL_ROLE.OPERATOR, LOCAL_ROLE.COMPANY],
    },
    {
      title: 'policy_details_dealer_name',
      value: dealerName ?? <UINoDataIcon />,
      visibleFor: [LOCAL_ROLE.OPERATOR, LOCAL_ROLE.COMPANY],
    },
    {
      title: 'policy_details_model_id',
      value: externalModelId ?? <UINoDataIcon />,
      visibleFor: [LOCAL_ROLE.OPERATOR],
    },
    {
      title: 'policy_details_customer_contact_number',
      value: customerContractNumber ?? <UINoDataIcon />,
      visibleFor: [LOCAL_ROLE.OPERATOR, LOCAL_ROLE.COMPANY],
    },
    // make, model
    {
      title: 'policy_details_color',
      value: color ?? <UINoDataIcon />,
      visibleFor: [LOCAL_ROLE.OPERATOR, LOCAL_ROLE.COMPANY],
    },
    // vin
    {
      title: 'form_input_label_mileage',
      value: mileage ? `${sumDelimiter(mileage)} km` : <UINoDataIcon />,
      visibleFor: [LOCAL_ROLE.OPERATOR, LOCAL_ROLE.COMPANY],
    },
    {
      title: 'policy_details_gross_price_without_options',
      value: getMoneyValue(vehicleGrossPriceExcludingOptionsIncludingVAT),
      visibleFor: [LOCAL_ROLE.OPERATOR, LOCAL_ROLE.COMPANY],
    },
    {
      title: 'policy_details_options',
      value: getMoneyValue(vehicleOptionsIncludingVAT),
      visibleFor: [LOCAL_ROLE.OPERATOR, LOCAL_ROLE.COMPANY],
    },
    {
      title: 'policy_details_gross_price_with_options',
      value: getMoneyValue(vehicleGrossPriceIncludingOptionsIncludingVAT),
      visibleFor: [LOCAL_ROLE.OPERATOR, LOCAL_ROLE.COMPANY],
    },
    {
      title: 'policy_details_discount',
      value: getMoneyValue(discountIncludingVAT),
      visibleFor: [LOCAL_ROLE.OPERATOR, LOCAL_ROLE.COMPANY],
    },
    {
      title: 'policy_details_net_price',
      value: getMoneyValue(vehicleNetPriceIncludingVAT),
      visibleFor: [LOCAL_ROLE.OPERATOR, LOCAL_ROLE.COMPANY],
    },
    {
      title: 'policy_details_annual_mileage',
      value: mileagePa ? `${sumDelimiter(mileagePa)} km` : <UINoDataIcon />,
      visibleFor: [LOCAL_ROLE.OPERATOR, LOCAL_ROLE.COMPANY],
    },
    {
      title: 'policy_details_contract_duration',
      value: contractDurationInMonths ? `${contractDurationInMonths} months` : <UINoDataIcon />,
      visibleFor: [LOCAL_ROLE.OPERATOR, LOCAL_ROLE.COMPANY],
    },

    {
      title: 'Offer ID',
      value: getRawValue(offerId),
      visibleFor: [LOCAL_ROLE.OPERATOR],
    },
    {
      title: 'policy_details_first_registration_date',
      value: firstRegistrationDate ? getFormattedDate(firstRegistrationDate) : <UINoDataIcon />,
      visibleFor: [LOCAL_ROLE.OPERATOR, LOCAL_ROLE.COMPANY],
    },
    // RVI Start date
    // RVI End date
    {
      title: 'Sales period',
      value: salesPeriod ? getFormattedDate(salesPeriod).slice(3) : <UINoDataIcon />,
      visibleFor: [LOCAL_ROLE.OPERATOR],
    },

    {
      title: 'Business partner ID',
      value: getRawValue(businessPartnerId),
      visibleFor: [LOCAL_ROLE.OPERATOR],
    },
    {
      title: 'RV warrant',
      value: getRawValue(RVWarrant),
      visibleFor: [LOCAL_ROLE.OPERATOR],
    },
    {
      title: 'Customer reference',
      value: getRawValue(customerReference),
      visibleFor: [LOCAL_ROLE.OPERATOR],
    },
    {
      title: 'List price (including options, excluding VAT)',
      value: getMoneyValue(listPriceIncludingOptionsExcludingVAT),
      visibleFor: [LOCAL_ROLE.OPERATOR],
    },
    {
      title: 'RV premium (excluding VAT)',
      value: getMoneyValue(RVPremiumExcludingVAT),
      visibleFor: [LOCAL_ROLE.OPERATOR],
    },
    {
      title: 'RV premium, %',
      value: getRawValue(RVPremiumPercent),
      visibleFor: [LOCAL_ROLE.OPERATOR],
    },
    {
      title: 'Risk',
      value: getMoneyValue(risk),
      visibleFor: [LOCAL_ROLE.OPERATOR],
    },
    {
      title: 'Insercle handling fee',
      value: getMoneyValue(handlingFee),
      visibleFor: [LOCAL_ROLE.OPERATOR],
    },
    {
      title: 'In risk',
      value: getBooleanValue(inRisk),
      visibleFor: [LOCAL_ROLE.OPERATOR],
    },
    {
      title: 'Reinsurance',
      value: getBooleanValue(reinsurance),
      visibleFor: [LOCAL_ROLE.OPERATOR],
    },
    {
      title: 'Reinsurer',
      value: getRawValue(reinsurer),
      visibleFor: [LOCAL_ROLE.OPERATOR],
    },
    {
      title: 'Reinsurance Brutto',
      value: getMoneyValue(reinsuranceBrutto),
      visibleFor: [LOCAL_ROLE.OPERATOR],
    },
    {
      title: 'Insurance tax',
      value: getMoneyValue(insuranceTax),
      visibleFor: [LOCAL_ROLE.OPERATOR],
    },
    {
      title: 'Reinsurance Netto',
      value: getMoneyValue(reinsuranceNetto),
      visibleFor: [LOCAL_ROLE.OPERATOR],
    },
    {
      title: 'Reinsurance VK',
      value: getMoneyValue(reinsuranceVK),
      visibleFor: [LOCAL_ROLE.OPERATOR],
    },
    {
      title: 'Reinsurance risk',
      value: getMoneyValue(reinsuranceRisk),
      visibleFor: [LOCAL_ROLE.OPERATOR],
    },
    {
      title: 'Reserves',
      value: getMoneyValue(reserves),
      visibleFor: [LOCAL_ROLE.OPERATOR],
    },
    {
      title: 'Status',
      value: getRawValue(status),
      visibleFor: [LOCAL_ROLE.OPERATOR],
    },
    {
      title: 'Real end date',
      value: realEndDate ? getFormattedDate(realEndDate) : <UINoDataIcon />,
      visibleFor: [LOCAL_ROLE.OPERATOR],
    },
    {
      title: 'policy_details_initial_residual_value',
      value: getRawValue(initialRVPercent),
      visibleFor: [LOCAL_ROLE.OPERATOR, LOCAL_ROLE.COMPANY],
    },
    {
      title: 'policy_details_initial_residual_value_with_vat',
      value: getMoneyValue(initialResidualValueIncludingVAT),
      visibleFor: [LOCAL_ROLE.OPERATOR, LOCAL_ROLE.COMPANY],
    },
  ]
  const filteredPolicyDetails = policyDetails.filter((item) =>
    item?.visibleFor?.find((el) => el === role),
  )

  return (
    <CollapseBlock
      title="title_policy_details"
      collapsed={collapsed}
      onCollapseChange={(value) => setCollapsed(value)}
    >
      <GrayTable content={filteredPolicyDetails} />
    </CollapseBlock>
  )
}

export default RVIPolicyDetails
