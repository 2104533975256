import React from 'react'
import { Box, Button } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import {
  setQualityCheckPhotoApproved,
  setQualityCheckPhotoHint,
} from '../../../store/features/qualityCheckSlice'
import { colorsConfig } from '../../../config/themeConfig'
import IconCrossRed from '../../../assests/img/icons/icon-cross-red.svg'
import TextNormal from '../../../ui/Text/TextNormal'
import TextAreaSmall from '../../../ui/TextAreaSmall'

const QualityCheckPhoto = (props) => {
  const { photoIndex } = props

  const qualityCheckPhoto = useSelector((state) => state.qualityCheck.photos[photoIndex])
  const dispatch = useDispatch()

  const handleReject = () => {
    dispatch(
      setQualityCheckPhotoApproved({
        index: photoIndex,
        value: !qualityCheckPhoto.approved,
      }),
    )
  }

  const handleSetComment = (value) => {
    dispatch(
      setQualityCheckPhotoHint({
        index: photoIndex,
        value: value,
      }),
    )
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        px: '7px',
        pt: '5px',
        position: 'absolute',
        width: '100%',
        height: 'auto',
        zIndex: 3,
      }}
    >
      <Button
        sx={{
          mb: '10px',
          textTransform: 'none',
          '&:hover': {
            '& .button-icon-cross': {
              transition: '0.25s all ease-in-out',
              background: colorsConfig.mainRed,
              '& img': {
                filter: 'brightness(1000%)',
                transition: '0.25s all ease-in-out',
              },
            },
          },
        }}
        onClick={() => {
          handleReject()
        }}
      >
        <Box
          className={`button-icon-cross ${!qualityCheckPhoto.approved ? 'active' : ''}`}
          sx={{
            background: 'white',
            width: '20px',
            height: '20px',
            borderRadius: '4px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            mr: '5px',
            alignItems: 'center',
            '& img': {
              transition: '0.25s all ease-in-out',
              filter: 'grayscale(200%)',
            },
            cursor: 'pointer',
            transition: '0.25s all ease-in-out',
            '&.active': {
              transition: '0.25s all ease-in-out',
              background: colorsConfig.mainRed,
              '& img': {
                filter: 'brightness(1000%)',
                transition: '0.25s all ease-in-out',
              },
            },
          }}
        >
          <img src={IconCrossRed} alt="" />
        </Box>
        <TextNormal
          sx={{
            color: 'white',
          }}
        >
          Reject
        </TextNormal>
      </Button>
      {!qualityCheckPhoto.approved ? (
        <Box
          sx={{
            mb: '7px',
            width: '100%',
            background: 'white',
            borderRadius: '6px',
          }}
        >
          <TextAreaSmall
            fullWidth
            multiline
            rows={4}
            placeholder={'Write the reason of rejection'}
            sx={{
              background: 'white',
            }}
            value={qualityCheckPhoto.hint}
            onChange={(e) => {
              handleSetComment(e.target.value)
            }}
          />
        </Box>
      ) : null}
    </Box>
  )
}

export default QualityCheckPhoto
