import React, { useEffect, useState } from 'react'
import ModalWrap from '../../../../../components/Modals/ModalWrap'
import { Stack } from '@mui/material'
import UITextHeadlineLarge from '../../../../../ui/UIText/UITextHeadlineLarge'
import { useDispatch, useSelector } from 'react-redux'
import UITable from '../../../../../ui/UITable/UITable'
import Box from '@mui/material/Box'
import IconAttention from '../../../../../assests/img/icons/icon-attention-red.svg'
import UITextRegular from '../../../../../ui/UIText/UITextRegular'
import { importedPoliciesTableHead } from '../../../../../utils/constants/tables/table.constants'
import { getImportedPoliciesTableData } from '../../../../../utils/helpers/tables/table.helpers'
import ImportedPoliciesActionItems from './ImportedPoliciesActionItems'
import ButtonHollow from '../../../../../ui/Buttons/ButtonHollow'
import ButtonLG from '../../../../../ui/Buttons/ButtonLG'
import UIBadgeSelectable from '../../../../../ui/UIBadge/UIBadgeSelectable'
import { linkPoliciesToDealers } from '../../../../../store/features/rviOperator/rviOperator.actions'
import { toast } from 'react-toastify'
import UIPreloader from '../../../../../ui/UIPreloader/UIPreloader'
import { colorsConfig } from '../../../../../config/themeConfig'
import { WarningRounded } from '@mui/icons-material'
import {
  resetImportPolicies,
  setPolicyImportSelectedRows,
} from '../../../../../store/features/rviOperator/rviOperator.reducer'
import ImportedPoliciesSelectedRowsControls from './ImportedPoliciesSelectedRowsControls'

const getCompaniesPoliciesCount = (policies, companies) => {
  return companies
    .map((item) => {
      const matchedPolicies = policies.filter(
        (policy) => policy?.matchedCompany?.value?.id?.toString() === item?.id?.toString(),
      )
      return {
        company: item,
        policiesCount: matchedPolicies?.length ?? 0,
        policies: matchedPolicies,
      }
    })
    .filter((item) => item.policiesCount > 0)
}

const ImportedPoliciesModal = ({ open, setOpen, onClose }) => {
  const companies = useSelector((state) => state.companies.allCompanies.data)
  const isLoading = useSelector((state) => state.rviOperator.importPolicies.imported.isLoading)
  const importedPolicies = useSelector((state) => state.rviOperator.importPolicies.imported.data)
  const importedPoliciesParams = useSelector(
    (state) => state.rviOperator.importPolicies.imported.params,
  )
  const importedPoliciesTotal = useSelector(
    (state) => state.rviOperator.importPolicies.imported.total,
  )
  const isSaving = useSelector((state) => state.rviOperator.importPolicies.linkPolicies.isLoading)
  const selectedRows = useSelector(
    (state) => state.rviOperator.importPolicies.imported.selectedRows,
  )
  const dispatch = useDispatch()

  const [filterCompanies, setFilterCompanies] = useState([])

  // const companiesWithPolicies =
  //   policyImport?.policies && policyImport?.policies?.length > 0
  //     ? getCompaniesPoliciesCount(policyImport?.policies, companies)
  //     : []
  //
  // const filteredPolicies =
  //   filterCompanies.length > 0
  //     ? policyImport?.policies?.filter((policy) =>
  //         filterCompanies.find((company) => company?.id === policy?.matchedCompany?.value?.id),
  //       )
  //     : policyImport?.policies

  // const handleFilter = (item) => {
  //   if (filterCompanies.find((el) => el?.id === item?.company?.id)) {
  //     setFilterCompanies(filterCompanies.filter((el) => el?.id !== item?.company?.id))
  //   } else {
  //     setFilterCompanies([...filterCompanies, item?.company])
  //   }
  // }

  const handleSubmit = async () => {
    // const res = await dispatch(linkPoliciesToDealers({ data: companiesWithPolicies }))
    // if (linkPoliciesToDealers.fulfilled.match(res)) {
    //   toast.success('Policies have been sent to the dealers')
    //   onClose()
    //   setOpen(false)
    // }
  }

  useEffect(() => {
    return () => {
      dispatch(resetImportPolicies())
    }
  }, [])

  return (
    <ModalWrap
      wrapperStyles={{
        width: '1000px',
      }}
      open={open}
      setOpen={(value) => {
        setOpen(value)
        if (!value) onClose()
      }}
    >
      {/*{isSaving && <UIPreloader text="Saving data..." />}*/}
      {/*<Stack gap="10px">*/}
      {/*  <UITextHeadlineLarge text="Imported polices" />*/}
      {/*  <Stack sx={{ mt: '10px' }} direction="row" gap="10px" alignItems="center">*/}
      {/*    {companiesWithPolicies?.map((item) => {*/}
      {/*      return (*/}
      {/*        <UIBadgeSelectable*/}
      {/*          isActive={!!filterCompanies.find((el) => el?.id === item?.company?.id)}*/}
      {/*          key={item?.company?.id}*/}
      {/*          onClick={() => handleFilter(item)}*/}
      {/*        >*/}
      {/*          {item?.company?.name} ({item?.policiesCount})*/}
      {/*        </UIBadgeSelectable>*/}
      {/*      )*/}
      {/*    })}*/}
      {/*    {policyImport?.duplicatePolicies && policyImport?.duplicatePolicies !== 0 ? (*/}
      {/*      <Stack direction="row" gap="10px" alignItems="center">*/}
      {/*        <WarningRounded sx={{ fill: colorsConfig.buttonYellow }} />*/}
      {/*        <UITextRegular*/}
      {/*          sx={{ color: colorsConfig.iconGray }}*/}
      {/*          text={`Duplicated policies: ${policyImport?.duplicatePolicies}`}*/}
      {/*        />*/}
      {/*      </Stack>*/}
      {/*    ) : null}*/}
      {/*  </Stack>*/}
      {/*  {selectedRows.length > 0 && <ImportedPoliciesSelectedRowsControls />}*/}
      {/*  {policyImport?.errorMessage && policyImport?.errorMessage !== '' ? (*/}
      {/*    <Box*/}
      {/*      sx={{*/}
      {/*        display: 'flex',*/}
      {/*        justifyContent: 'center',*/}
      {/*        alignItems: 'center',*/}
      {/*        height: '150px',*/}
      {/*      }}*/}
      {/*    >*/}
      {/*      <img src={IconAttention} alt="" />*/}
      {/*      <UITextRegular text={policyImport?.errorMessage} />*/}
      {/*    </Box>*/}
      {/*  ) : policyImport?.policies?.length > 0 ? (*/}
      {/*    <>*/}
      {/*      <Box sx={{ maxHeight: '50vh', overflowY: 'auto' }}>*/}
      {/*        <UITable*/}
      {/*          head={importedPoliciesTableHead}*/}
      {/*          content={getImportedPoliciesTableData(filteredPolicies)}*/}
      {/*          emptyText="No policies uploaded"*/}
      {/*          renderActionItems={(data) => <ImportedPoliciesActionItems data={data} />}*/}
      {/*          selectable*/}
      {/*          selectedRows={selectedRows}*/}
      {/*          setSelectedRows={(val) => dispatch(setPolicyImportSelectedRows(val))}*/}
      {/*        />*/}
      {/*      </Box>*/}
      {/*      <Stack direction="row" justifyContent="flex-end" alignItems="center" gap="10px">*/}
      {/*        <ButtonHollow*/}
      {/*          onClick={() => {*/}
      {/*            onClose()*/}
      {/*            setOpen(false)*/}
      {/*          }}*/}
      {/*        >*/}
      {/*          Do it later*/}
      {/*        </ButtonHollow>*/}
      {/*        <ButtonLG onClick={() => handleSubmit()}>Link polices</ButtonLG>*/}
      {/*      </Stack>*/}
      {/*    </>*/}
      {/*  ) : (*/}
      {/*    <Stack direction="row" justifyContent="flex-end" alignItems="center" gap="10px">*/}
      {/*      <ButtonLG*/}
      {/*        onClick={() => {*/}
      {/*          onClose()*/}
      {/*          setOpen(false)*/}
      {/*        }}*/}
      {/*      >*/}
      {/*        OK*/}
      {/*      </ButtonLG>*/}
      {/*    </Stack>*/}
      {/*  )}*/}
      {/*</Stack>*/}
    </ModalWrap>
  )
}

export default ImportedPoliciesModal
