import React, { useEffect, useState } from 'react'
import { Box, Stack } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import WrapWithPreloader from '../../../components/WrapWithPreloader'
import CarInsuranceTabs from './CarInsuranceComponents/CarInsuranceTabs'
import CarInsuranceBlockRequiredCarDetails from './CarInsuranceBlocks/CarInsuranceBlockRequiredCarDetails'
import CarInsuranceBlockDrivers from './CarInsuranceBlocks/CarInsuranceBlockDrivers'
import CarInsuranceBlockInsuranceDetails from './CarInsuranceBlocks/CarInsuranceBlockInsuranceDetails'
import CarInsuranceBlockResponses from './CarInsuranceBlocks/CarInsuranceBlockResponses/CarInsuranceBlockResponses'
import {
  addApplication,
  resetInsuranceApplications,
  setApplications,
  setReferenceCompanies,
} from '../../../store/features/insuranceSlice'
import { privateRequest } from '../../../utils/request'
import { API_ENDPOINTS } from '../../../config/default'
import { getInsuranceStatusInfo, initialApplicationData } from '../insurance.constants'
import { parseInsuranceCustomer, parsePackagesFromRisks } from '../insurance.helpers'

const CarInsuranceApplications = () => {
  const applications = useSelector((state) => state.insurance.applications)
  const referenceCompanies = useSelector((state) => state.insurance.referenceCompanies)
  const dispatch = useDispatch()
  const params = useParams()

  const [loading, setLoading] = useState(true)

  const handleSetApplications = (data) => {
    dispatch(
      setApplications(
        data.map((item) => {
          return {
            ...initialApplicationData,
            id: item.id,
            data: {
              ...initialApplicationData.data,
              carPrice: parseInt(item?.carPrice),
              annualMileage: item?.annualMileage !== 0 ? item?.annualMileage / 5000 : 2,
              additionalRequest: item?.additionalRequestNote || '',
              startDate: item?.startDate || null,
              consent: item.selectedRisks.length > 0,
            },
            packages: parsePackagesFromRisks(item?.selectedRisks),
            customer: parseInsuranceCustomer(item),
            collapseBlocksState: getInsuranceStatusInfo(item).collapseBlocksState,
            currentWorkflowState: getInsuranceStatusInfo(item).name,
            secureHash: item?.secureHash,
          }
        }),
      ),
    )
  }
  const getApplications = () => {
    privateRequest
      .get(API_ENDPOINTS.insurance.applications, {
        params: {
          car: params.id,
          isArchived: false,
        },
      })
      .then((response) => {
        if (response.data['hydra:member'].length > 0) {
          handleSetApplications(response.data['hydra:member'])
        } else {
          dispatch(addApplication())
        }
        setLoading(false)
      })
  }

  const getReferenceCompanies = () => {
    privateRequest.get(API_ENDPOINTS.insurance.companies).then((response) => {
      dispatch(setReferenceCompanies(response.data['hydra:member']))
    })
  }

  useEffect(() => {
    if (referenceCompanies.length > 0) {
      getApplications()
    }
  }, [referenceCompanies])

  useEffect(() => {
    getReferenceCompanies()
    return () => {
      dispatch(resetInsuranceApplications())
    }
  }, [])

  return (
    <Box>
      <WrapWithPreloader
        loading={loading}
        loadingText={'Loading insurance applications...'}
        hideContent={true}
      >
        <CarInsuranceTabs />
        <Stack spacing="30px">
          <CarInsuranceBlockRequiredCarDetails />
          <CarInsuranceBlockDrivers />
          <CarInsuranceBlockInsuranceDetails />
          <CarInsuranceBlockResponses />
        </Stack>
      </WrapWithPreloader>
    </Box>
  )
}

export default CarInsuranceApplications
