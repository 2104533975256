import React from 'react'
import { Box, Stack } from '@mui/material'
import IconStatusFinance from '../../../assests/img/icons/icon-status-finance.svg'
import IconStatusInsurance from '../../../assests/img/icons/icon-status-insurance.svg'
import IconStatusWarranty from '../../../assests/img/icons/icon-status-warranty.svg'
import { useSelector } from 'react-redux'
import { getIconByCombinedStatus } from './carStatusIcons.helpers'
import Tooltip from '@material-ui/core/Tooltip'
import { getCarWorkflowStateName } from '../../../utils/helpers/cars.helpers'
import UITooltip from '../../../ui/UITooltip/UITooltip'

const CarStatusIcons = ({ car }) => {
  const { isInsuranceRelated, isFinanceRelated, isWarrantyRelated, combinedStatus } = car

  const carWorkflows = useSelector((state) => state.workflows.carWorkflowStates)
  const auctionWorkflows = useSelector((state) => state.workflows.auctionWorkflowStates)
  const dealWorkflows = useSelector((state) => state.workflows.dealWorkflowStates)

  return (
    <Stack
      direction="row"
      alignItems="center"
      gap="9px"
      sx={{
        position: 'relative',
        zIndex: 2,
      }}
      onClick={(e) => {
        e.stopPropagation()
      }}
    >
      <UITooltip
        title={getCarWorkflowStateName(car, carWorkflows, auctionWorkflows, dealWorkflows)}
      >
        <img
          src={getIconByCombinedStatus(car?.combinedStatus)}
          alt=""
          style={{
            width: '20px',
            verticalAlign: 'top',
          }}
        />
      </UITooltip>
      {isFinanceRelated ? (
        <UITooltip text="status_finance_in_progress">
          <img
            src={IconStatusFinance}
            alt=""
            style={{
              width: '20px',
              verticalAlign: 'top',
            }}
          />
        </UITooltip>
      ) : null}
      {isInsuranceRelated ? (
        <UITooltip text="status_insurance_in_progress">
          <img
            src={IconStatusInsurance}
            alt=""
            style={{
              width: '20px',
              verticalAlign: 'top',
            }}
          />
        </UITooltip>
      ) : null}
      {isWarrantyRelated ? (
        <UITooltip text="status_warranty_in_progress">
          <img
            src={IconStatusWarranty}
            alt=""
            style={{
              width: '20px',
              verticalAlign: 'top',
            }}
          />
        </UITooltip>
      ) : null}
    </Stack>
  )
}

export default CarStatusIcons
