import React, { useEffect, useState } from 'react'
import { Box, Grid, IconButton, Modal, Typography, useMediaQuery } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { API_ENDPOINTS, CDN_BASE_URL } from '../../../config/default'
import { colorsConfig } from '../../../config/themeConfig'
import TextNormal from '../../../ui/Text/TextNormal'
import ButtonLG from '../../../ui/Buttons/ButtonLG'
import { privateRequest } from '../../../utils/request'
import { useNavigate, useParams } from 'react-router-dom'
import { setWorkflowState } from '../../../store/features/carSlice/carSlice'
import { toast } from 'react-toastify'
import carDefaultPicture from '../../../assests/img/car-not-found.png'
import ButtonDanger from '../../../ui/Buttons/ButtonDanger'
import ButtonSuccess from '../../../ui/Buttons/ButtonSuccess'
import { Close } from '@mui/icons-material'

const QualityCheckCarSummary = () => {
  const xsOnly = useMediaQuery('(max-width: 600px)')

  const carData = useSelector((state) => state.car.carData)
  const sections = useSelector((state) => state.qualityCheck.sections)
  const qualityCheckSubBlocks = useSelector((state) => state.qualityCheck.subBlocks)
  const qualityCheckDamages = useSelector((state) => state.qualityCheck.damages)
  const qualityCheckPhotos = useSelector((state) => state.qualityCheck.photos)
  const dispatch = useDispatch()
  const params = useParams()
  const navigate = useNavigate()

  const [buttonDisabled, setButtonDisabled] = useState(true)
  const [confirmationModal, setConfirmationModal] = useState(false)

  const submitHints = () => {
    sections.forEach((item, index) => {
      let photosRejected = false
      let damagesRejected = false
      if (index === 2) {
        photosRejected = qualityCheckPhotos.filter(
          (itemPhoto) => !itemPhoto.approved && itemPhoto.hint,
        )[0]
      }
      if (index === 3) {
        damagesRejected = qualityCheckDamages.filter(
          (itemDamage) => !itemDamage.approved && itemDamage.hint,
        )[0]
      }
      if (
        (!item.approved && item.hint) ||
        (item.hint && index === 2 && photosRejected) ||
        (item.hint && index === 3 && damagesRejected)
      ) {
        const formData = {
          type: item.systemName,
          value: item.systemName === 'subblock' ? item.entityId : null,
          hint: item.hint,
        }
        privateRequest.post(`/cars/${params.id}${API_ENDPOINTS.qualityCheckHints}`, formData)
      }
    })
    qualityCheckSubBlocks.forEach((item) => {
      if (!item.approved && item.hint) {
        const formData = {
          type: 'subblock',
          value: item['@id'],
          hint: item.hint,
        }
        privateRequest.post(`/cars/${params.id}${API_ENDPOINTS.qualityCheckHints}`, formData)
      }
    })
    qualityCheckDamages.forEach((item) => {
      if (!item.approved && item.hint) {
        const formData = {
          type: 'car_damage',
          value: item['@id'],
          hint: item.hint,
        }
        privateRequest.post(`/cars/${params.id}${API_ENDPOINTS.qualityCheckHints}`, formData)
      }
    })
    qualityCheckPhotos.forEach((item) => {
      if (!item.approved && item.hint) {
        const formData = {
          type: 'car_foreshortening',
          value: item['@id'],
          hint: item.hint,
        }
        privateRequest.post(`/cars/${params.id}${API_ENDPOINTS.qualityCheckHints}`, formData)
      }
    })
  }

  const handleFinishQualityCheck = () => {
    if (
      sections.filter((item) => !item.approved)[0] ||
      qualityCheckSubBlocks.filter((item) => !item.approved)[0] ||
      qualityCheckDamages.filter((item) => !item.approved)[0] ||
      qualityCheckPhotos.filter((item) => !item.approved)[0]
    ) {
      submitHints()
      privateRequest
        .post(`/cars/${params.id}${API_ENDPOINTS.onSiteAppraisalModerationFail}`, {})
        .then(() => {
          dispatch(setWorkflowState('car_workflow.quality_check_failed'))
          toast.success('Quality Check has been finished successfully')
          navigate('/operator-panel')
        })
    } else {
      privateRequest
        .post(`/cars/${params.id}${API_ENDPOINTS.onSiteAppraisalModerationSuccess}`, {})
        .then(() => {
          dispatch(setWorkflowState('car_workflow.ready_for_sale'))
          toast.success('Quality Check has been finished successfully')
          navigate('/operator-panel')
        })
    }
  }

  useEffect(() => {
    setButtonDisabled(
      Boolean(sections.filter((item, index) => !item.checked && index !== sections.length - 1)[0]),
    )
  }, [sections])

  useEffect(() => {
    console.log(qualityCheckPhotos)
  }, [qualityCheckPhotos])

  return (
    <Box>
      <Grid container spacing={3}>
        <Grid item md={4}>
          <Box
            sx={{
              borderRadius: '6px',
              overflow: 'hidden',
              height: '298px',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {carData.foreshortenings.length > 0 && carData.foreshortenings[0].photo ? (
              <img
                src={`${CDN_BASE_URL}${carData.foreshortenings[0].photo.path}`}
                alt=""
                style={{
                  width: '100%',
                }}
              />
            ) : (
              <img src={carDefaultPicture} style={{ width: '100%' }} alt="" />
            )}
          </Box>
        </Grid>
        <Grid item md={6}>
          <Typography
            sx={{
              fontWeight: 700,
              color: colorsConfig.mainBlack,
              fontSize: '40px',
              mb: '17px',
            }}
          >
            #{carData.referenceNumber} {carData.make.name} {carData.model.name}
          </Typography>
          <TextNormal
            sx={{
              lineHeight: '18.4px',
              mb: '17px',
            }}
          >
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
            incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
            exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
            <br />
            <br />
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
            incididunt ut labore et dolore magna aliqua.
          </TextNormal>
          <ButtonLG
            onClick={() => {
              setConfirmationModal(true)
            }}
            disabled={buttonDisabled}
          >
            Finish quality check
          </ButtonLG>
        </Grid>
      </Grid>
      <Modal
        open={confirmationModal}
        onClose={() => {
          setConfirmationModal(false)
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: xsOnly ? '90%' : 500,
            background: 'white',
            borderRadius: '10px',
            padding: '25px',
            paddingTop: '35px',
            maxHeight: '80vh',
            overflow: 'auto',
          }}
          className={'disable-scrollbar'}
        >
          <Typography
            variant={'body1'}
            sx={{
              fontSize: xsOnly ? '24px' : '30px',
              fontWeight: 700,
              mb: '20px',
              lineHeight: '35px',
              textAlign: 'center',
            }}
          >
            Are you sure you want to finish <br />
            quality check?
          </Typography>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <ButtonDanger
              sx={{
                mr: '10px',
              }}
              onClick={() => {
                setConfirmationModal(false)
              }}
            >
              No
            </ButtonDanger>
            <ButtonSuccess
              onClick={() => {
                handleFinishQualityCheck()
              }}
            >
              Yes
            </ButtonSuccess>
          </Box>
          <IconButton
            sx={{
              position: 'absolute',
              top: '5px',
              right: '5px',
            }}
            onClick={() => {
              setConfirmationModal(false)
            }}
          >
            <Close
              style={{
                fill: colorsConfig.mainRed,
              }}
            />
          </IconButton>
        </Box>
      </Modal>
    </Box>
  )
}

export default QualityCheckCarSummary
