import React, { useEffect } from 'react'
import LoggingNodesModal from './LoggingNodesTemplates/LoggingNodesModal'
import { useDispatch, useSelector } from 'react-redux'
import { getPolicyWorkflowLogs } from '../../../store/features/rviPolicy/rviPolicy.actions'
import { getPolicyLoggingNodesData } from './LoggingNodesTemplates/loggingNodes.helpers'
import { useTranslation } from 'react-i18next'

const PolicyLoggingNodes = ({ policyId, open, setOpen }) => {
  const { i18n } = useTranslation()
  const isLoading = useSelector((state) => state.rviPolicy.workflowLogs.isLoading)
  const loggingNodes = useSelector((state) => state.rviPolicy.workflowLogs.data)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getPolicyWorkflowLogs({ policyId }))
  }, [])

  return (
    <LoggingNodesModal
      open={open}
      setOpen={setOpen}
      data={getPolicyLoggingNodesData(loggingNodes, i18n.language)}
      isLoading={isLoading}
    />
  )
}

export default PolicyLoggingNodes
