import { createDealerSupportTicket } from '../settings.actions'

export default (builder) => {
  builder
    .addCase(createDealerSupportTicket.pending, (state) => {
      state.createSupportTicket.isLoading = true
    })
    .addCase(createDealerSupportTicket.fulfilled, (state) => {
      state.createSupportTicket.isLoading = false
      state.createSupportTicket.error = null
    })
    .addCase(createDealerSupportTicket.rejected, (state, action) => {
      state.createSupportTicket.isLoading = false
      state.createSupportTicket.error = action.payload
    })
}
