import { getDashboardPolicies } from '../dashboard.actions'
import { API_ERROR_CODES } from '../../../../api/APIErrors'
import { apiConstants } from '../../../../api/api'

export default (builder) => {
  builder
    .addCase(getDashboardPolicies.pending, (state) => {
      state.statusCakes.policies.isLoading = true
    })
    .addCase(getDashboardPolicies.fulfilled, (state, action) => {
      state.statusCakes.policies.isLoading = false
      state.statusCakes.policies.error = null
      state.statusCakes.policies.data = action.payload?.[apiConstants.hydraMember]
      state.statusCakes.policies.total = action.payload?.[apiConstants.hydraTotal]
    })
    .addCase(getDashboardPolicies.rejected, (state, action) => {
      if (action.payload?.code !== API_ERROR_CODES.ERR_CANCELED) {
        state.statusCakes.policies.isLoading = false
        state.statusCakes.policies.error = action.payload
      }
    })
}
