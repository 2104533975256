import React, { useRef } from 'react'
import { Box, Button, Typography, useMediaQuery } from '@mui/material'
import { colorsConfig } from '../../config/themeConfig'
import bannerGreen from '../../assests/img/banners/banner-green.png'
import banner1 from '../../assests/img/banners/banner-1-minified.png'
import banner2 from '../../assests/img/banners/banner-2-minified.png'
import banner3 from '../../assests/img/banners/banner-3-minified.png'
import banner4 from '../../assests/img/banners/banner-4-minified.png'
import banner5 from '../../assests/img/banners/banner-5-minified.png'
import banner6 from '../../assests/img/banners/banner-6-minified.png'
import BannerMobile1 from '../../assests/img/banners/banner-1-mobile.png'
import BannerMobile2 from '../../assests/img/banners/banner-2-mobile.png'
import BannerMobile3 from '../../assests/img/banners/banner-3-mobile.png'
import BannerMobile4 from '../../assests/img/banners/banner-4-mobile.png'
import BannerMobile5 from '../../assests/img/banners/banner-5-mobile.png'
import BannerMobile6 from '../../assests/img/banners/banner-6-mobile.png'
import BannerMobileGreen from '../../assests/img/banners/banner-green-mobile.png'
import { Carousel } from 'react-responsive-carousel'
import { useNavigate } from 'react-router-dom'
import TextNormal from '../../ui/Text/TextNormal'
import TextBoldNormal from '../../ui/Text/TextBoldNormal'
import { useTranslation } from 'react-i18next'
import UIButton from '../../ui/UIButtons/UIButton'

const Banners = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const xsOnly = useMediaQuery('(max-width: 600px)')

  const carouselRef = useRef()

  const content = [
    {
      id: 1,
      title: t('banner_virtual_garage_green_title'),
      caption: t('banner_virtual_garage_green_desc'),
      theme: 'dark',
      image: bannerGreen,
      imageMobile: BannerMobileGreen,
      link: '/go-greener',
    },
    {
      id: 2,
      title: t('banner_virtual_garage_auction'),
      image: banner1,
      imageMobile: BannerMobile1,
      comingSoon: true,
    },
    {
      id: 3,
      title: t('banner_virtual_garage_appraisal'),
      image: banner2,
      imageMobile: BannerMobile2,
    },
    {
      id: 4,
      title: t('banner_virtual_garage_insurance'),
      image: banner3,
      imageMobile: BannerMobile3,
      comingSoon: true,
    },
    {
      id: 5,
      title: t('banner_virtual_garage_finance'),
      image: banner4,
      imageMobile: BannerMobile4,
      comingSoon: true,
    },
    {
      id: 6,
      title: t('banner_virtual_garage_car_warranty'),
      image: banner5,
      imageMobile: BannerMobile5,
      comingSoon: true,
    },
    {
      id: 7,
      title: t('banner_virtual_garage_car_subscription'),
      image: banner6,
      imageMobile: BannerMobile6,
      comingSoon: true,
    },
  ]

  return (
    <Box
      sx={{
        '& .control-dots': {
          display: 'flex',
          justifyContent: 'center',
        },
      }}
    >
      <Carousel
        ref={carouselRef}
        autoPlay
        interval={2500}
        infiniteLoop
        showArrows={false}
        showThumbs={false}
        showStatus={false}
        renderIndicator={(clickHandler, isSelected, index, label) => {
          return (
            <Box
              className={carouselRef?.current?.state?.selectedItem === 0 ? 'gray-theme' : ''}
              sx={{
                width: '19px',
                height: '19px',
                borderRadius: '50%',
                background: isSelected ? 'white' : 'rgba(255,255,255,.5)',
                marginRight: '10px',
                '&:last-child': {
                  marginRight: 0,
                },
                '&.gray-theme': {
                  background: isSelected ? '#57606B' : '#57606B80',
                },
              }}
              onClick={clickHandler}
            />
          )
        }}
      >
        {content.map((item) => {
          return (
            <Box
              key={item.id}
              sx={{
                position: 'relative',
                height: {
                  xs: '286px',
                  sm: '200px',
                },
                borderRadius: {
                  xs: '4px',
                  sm: '6px',
                },
                overflow: 'hidden',
              }}
            >
              {xsOnly ? (
                <Box
                  sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <TextBoldNormal
                    sx={{
                      color: item.theme === 'dark' ? colorsConfig.mainBlack : 'white',
                      fontSize: '32px',
                      lineHeight: 'normal',
                      mb: item.caption ? '14px' : '20px',
                      px: '20px',
                    }}
                  >
                    {item.title}
                  </TextBoldNormal>
                  {item.caption ? (
                    <TextNormal
                      sx={{
                        fontSize: '14px',
                        textAlign: 'center',
                        px: '20px',
                        mb: '17px',
                      }}
                    >
                      {item.caption}
                    </TextNormal>
                  ) : null}
                  {item?.comingSoon && (
                    <UIButton
                      disabled
                      variant="contained"
                      sx={{
                        px: '60px',
                        py: '10px',
                        color: `${colorsConfig.mainBlack} !important`,
                        background: 'white !important',
                        opacity: 1,
                      }}
                    >
                      Coming soon
                    </UIButton>
                  )}
                </Box>
              ) : (
                <Box
                  className={item.caption ? 'with-caption' : ''}
                  sx={{
                    position: 'absolute',
                    left: '33px',
                    top: '0px',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'flex-start',
                    '&.with-caption': {
                      top: '15px',
                    },
                  }}
                >
                  <Typography
                    className={[
                      item.theme === 'dark' ? 'dark' : '',
                      item.caption ? 'with-caption' : '',
                    ].join(' ')}
                    sx={{
                      fontSize: '40px',
                      fontWeight: 700,
                      color: 'white',
                      mb: '18px',
                      '&.dark': {
                        color: colorsConfig.mainBlack,
                      },
                      '&.with-caption': {
                        mb: '5px',
                      },
                      ...(!item?.comingSoon && {
                        position: 'relative',
                        mt: '-40px',
                      }),
                    }}
                  >
                    {item.title}
                  </Typography>
                  {item.caption ? (
                    <TextNormal
                      sx={{
                        mb: '15px',
                        maxWidth: '595px',
                        lineHeight: 'normal',
                        textAlign: 'left',
                      }}
                    >
                      {item.caption}
                    </TextNormal>
                  ) : null}
                  {item?.comingSoon && (
                    <UIButton
                      disabled
                      variant="contained"
                      sx={{
                        px: '60px',
                        py: '10px',
                        color: `${colorsConfig.mainBlack} !important`,
                        background: 'white !important',
                        opacity: 1,
                      }}
                    >
                      Coming soon
                    </UIButton>
                  )}
                </Box>
              )}

              {xsOnly ? (
                <img
                  src={item.imageMobile}
                  style={{
                    objectFit: 'cover',
                    height: '100%',
                  }}
                  alt=""
                />
              ) : (
                <img
                  src={item.image}
                  style={{
                    objectFit: 'cover',
                    height: '100%',
                  }}
                  alt=""
                />
              )}
            </Box>
          )
        })}
      </Carousel>
    </Box>
  )
}

export default Banners
