import React from 'react'
import Tooltip from '@material-ui/core/Tooltip'
import { IconButton } from '@mui/material'
import { colorsConfig } from '../../config/themeConfig'
import { UISize, UIVariant } from '../../utils/constants/ui.constants'

const UIIconButton = ({
  text = '',
  children,
  sx,
  variant = UIVariant.primary,
  size = UISize.md,
  className,
  ...props
}) => {
  return (
    <Tooltip title={text}>
      <IconButton
        className={[className, `ui-variant-${variant}`].join(' ')}
        sx={{
          '& svg': {
            color: colorsConfig.iconGray,
            transition: '0.25s all ease-in-out',
            ...(size === UISize.sm && {
              fontSize: '18px',
            }),
          },
          '&:hover': {
            '& svg': {
              color: colorsConfig.mainBlue,
              transition: '0.25s all ease-in-out',
            },
          },
          '&.ui-variant-danger': {
            '&:hover': {
              '& svg': {
                color: colorsConfig.darkRed,
                transition: '0.25s all ease-in-out',
              },
            },
          },
          ...(size === UISize.sm && {
            width: '30px',
            height: '30px',
          }),
          ...sx,
        }}
        {...props}
      >
        {children}
      </IconButton>
    </Tooltip>
  )
}

export default UIIconButton
