import { policyWorkflowStates } from '../../utils/constants/workflows.constants'
import { getFormattedDate } from '../../utils/date.helpers'

export const getPolicyCarMakeName = (policy) =>
  policy?.details?.make?.name ?? policy?.details?.makeName ?? ''

export const getPolicyCarModelName = (policy) =>
  policy?.details?.model?.name ?? policy?.details?.modelName ?? ''

export const getPolicyCarTitle = (policy) =>
  `${getPolicyCarMakeName(policy)} ${getPolicyCarModelName(policy)}`

export const getPolicyWorkflowStateCount = (workflowState, statusCounts) => {
  if (!statusCounts || !statusCounts.length) return 0
  return statusCounts?.find((e) => e?.workflowState === workflowState)?.count ?? 0
}

export const getPolicyRelevantEndDate = (policy) =>
  policy?.currentWorkflowState === policyWorkflowStates.closed
    ? getFormattedDate(policy?.details?.realEndDate)
    : getFormattedDate(policy?.details?.RVIEndDate)
