import React, { useState } from 'react'
import {
  Autocomplete,
  Box,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputAdornment,
  MenuItem,
  RadioGroup,
} from '@mui/material'
import CollapseBlock from '../../../../ui/Accordions/CollapseBlock'
import FormControlTFS from '../../../../ui/CustomSelect/FormControlTFS'
import InputLabelTFS from '../../../../ui/CustomSelect/InputLabelTFS'
import SelectTFS from '../../../../ui/CustomSelect/SelectTFS'
import TextFieldSmallEdible from '../../../../ui/TextFieldSmallEdible'
import { countries } from '../../../../default-data/coutries'
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import TextNormal from '../../../../ui/Text/TextNormal'
import CustomRadio from '../../../../ui/CustomRadio'
import RBox from '../../../../ui/RBox'
import ButtonLG from '../../../../ui/Buttons/ButtonLG'
import { colorsConfig } from '../../../../config/themeConfig'
import { useDispatch, useSelector } from 'react-redux'
import {
  setCollapseBlockState,
  setDriverSaved,
  updateCustomerData,
} from '../../../../store/features/insuranceSlice'
import { patchRequest } from '../../../../utils/request'
import { API_ENDPOINTS } from '../../../../config/default'
import { drivingAccidentFreeOptions, insuranceWorkflowsEnums } from '../../insurance.constants'
import useValidation from '../../../../hooks/useValidation'
import { getAccidentFreeDate } from '../../insurance.helpers'
import { addUAECodePrefix } from '../../../../utils/formatString.helpers'
import { toast } from 'react-toastify'
import insuranceApplicationService from '../../../../api/insuranceService/insuranceApplicationService'

const CarInsuranceBlockDrivers = () => {
  const selectedTab = useSelector((state) => state.insurance.selectedTab)
  const collapsed = useSelector(
    (state) => state.insurance.applications[selectedTab]?.collapseBlocksState?.driversCollapsed,
  )
  const saved = useSelector(
    (state) => state.insurance.applications[selectedTab]?.collapseBlocksState?.driversSaved,
  )
  const disabled = useSelector(
    (state) =>
      state.insurance.applications[selectedTab]?.collapseBlocksState?.driversDetailsDisabled,
  )
  const application = useSelector((state) => state.insurance.applications[selectedTab])
  const customer = useSelector((state) => state.insurance.applications[selectedTab]?.customer)
  const dispatch = useDispatch()
  const titleValidation = useValidation(customer?.title, { isEmpty: true })
  const firstNameValidation = useValidation(customer?.firstName, { isEmpty: true })
  const lastNameValidation = useValidation(customer?.lastName, { isEmpty: true })
  const nationalityValidation = useValidation(customer?.nationality, { isEmpty: true })
  const dateOfBirthValidation = useValidation(customer?.dateOfBirth, { isEmpty: true })
  const phoneNumberValidation = useValidation(customer?.phoneNumber, {
    isEmpty: true,
    phoneNumberUAEVarious: true,
  })
  const emailValidation = useValidation(customer?.email, { isEmpty: true, isEmail: true })
  const countryOfFirstLicenseValidation = useValidation(customer?.countryOfFirstLicense, {
    isEmpty: true,
  })
  const drivingExperienceValidation = useValidation(customer?.driverExperience, { isEmpty: true })
  const drivingAccidentFreeValidation = useValidation(customer?.drivingAccidentFree, {
    isEmpty: true,
  })
  const isForBusinessValidation = useValidation(customer?.isForBusiness, { isEmpty: true })
  const [showErrors, setShowErrors] = useState(false)
  const [buttonDisabled, setButtonDisabled] = useState(false)

  const hasErrors = () => {
    if (titleValidation.hasErrors) return true
    if (firstNameValidation.hasErrors) return true
    if (lastNameValidation.hasErrors) return true
    if (nationalityValidation.hasErrors) return true
    if (dateOfBirthValidation.hasErrors) return true
    if (phoneNumberValidation.hasErrors) return true
    if (emailValidation.hasErrors) return true
    if (countryOfFirstLicenseValidation.hasErrors) return true
    if (drivingExperienceValidation.hasErrors) return true
    if (drivingAccidentFreeValidation.hasErrors) return true
    if (isForBusinessValidation.hasErrors) return true
    return false
  }

  const handleSubmit = () => {
    if (hasErrors()) {
      setShowErrors(true)
      return
    }
    setButtonDisabled(true)
    const formData = {
      customer: {
        person: {
          title: customer?.title,
          firstName: customer?.firstName,
          lastName: customer?.lastName,
          email: customer?.email,
          nationality: customer?.nationality?.label,
          dateOfBirth: customer?.dateOfBirth,
          phoneNumber: addUAECodePrefix(customer?.phoneNumber),
        },
        driverLicense: {
          countryOfFirstLicense: customer?.countryOfFirstLicense?.label,
          firstIssueYears: parseInt(customer?.driverExperience),
          accidentFreeYears: getAccidentFreeDate(customer?.drivingAccidentFree),
        },
        isForBusiness: JSON.parse(customer?.isForBusiness),
      },
    }
    patchRequest
      .patch(`${API_ENDPOINTS.insurance.applications}/${application.id}`, formData)
      .then(() => {
        if (application.currentWorkflowState === insuranceWorkflowsEnums.CAR_DETAILS_SAVED) {
          insuranceApplicationService
            .transitionToApplicationPreparation(application.id)
            .then(() => {
              dispatch(setDriverSaved({ id: application.id }))
            })
            .finally(() => {
              setButtonDisabled(false)
            })
        } else {
          toast.success('Driver details have been updated')
          dispatch(
            setCollapseBlockState({
              id: application.id,
              block: 'driversCollapsed',
              value: true,
            }),
          )
        }
      })
      .finally(() => {
        setButtonDisabled(false)
      })
  }

  return (
    <CollapseBlock
      title={'Driver Details'}
      description={'Enter driver details'}
      collapsed={collapsed}
      saved={saved}
      disabled={disabled}
      onCollapseChange={(value) => {
        dispatch(
          setCollapseBlockState({
            id: application.id,
            block: 'driversCollapsed',
            value: value,
          }),
        )
      }}
    >
      <Grid container spacing={'24px'}>
        <Grid item xs={12} md={4}>
          <FormControlTFS fullWidth error={showErrors && titleValidation.hasErrors}>
            <InputLabelTFS id={'label-title'}>Title</InputLabelTFS>
            <SelectTFS
              value={customer?.title}
              onChange={(e) => {
                dispatch(
                  updateCustomerData({
                    ...customer,
                    title: e.target.value,
                  }),
                )
              }}
            >
              <MenuItem value={''}>Not chosen</MenuItem>
              <MenuItem value={'Mr'}>Mr</MenuItem>
              <MenuItem value={'Ms'}>Ms</MenuItem>
              <MenuItem value={'Mrs'}>Mrs</MenuItem>
            </SelectTFS>
            {showErrors ? <FormHelperText>{titleValidation.errorText}</FormHelperText> : null}
          </FormControlTFS>
        </Grid>
        <Grid item xs={12} md={4}>
          <TextFieldSmallEdible
            fullWidth
            label="First name"
            value={customer?.firstName}
            onChange={(e) => {
              dispatch(
                updateCustomerData({
                  ...customer,
                  firstName: e.target.value,
                }),
              )
            }}
            error={showErrors && firstNameValidation.hasErrors}
            helperText={showErrors ? firstNameValidation.errorText : ''}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextFieldSmallEdible
            fullWidth
            label="Last name"
            value={customer?.lastName}
            onChange={(e) => {
              dispatch(
                updateCustomerData({
                  ...customer,
                  lastName: e.target.value,
                }),
              )
            }}
            error={showErrors && lastNameValidation.hasErrors}
            helperText={showErrors ? lastNameValidation.errorText : ''}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Autocomplete
            id="nationality"
            options={[
              {
                label: null,
              },
              ...countries,
            ]}
            getOptionLabel={(option) => option.label || ''}
            fullWidth
            autoHighlight
            value={customer?.nationality}
            onChange={(e, newVal) => {
              dispatch(
                updateCustomerData({
                  ...customer,
                  nationality: newVal,
                }),
              )
            }}
            renderOption={(props, option) => {
              if (!option.label) return null
              return (
                <Box
                  component="li"
                  sx={{
                    '& > img': {
                      mr: 2,
                      flexShrink: 0,
                    },
                  }}
                  {...props}
                >
                  <img
                    loading="lazy"
                    width="20"
                    srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                    src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                    alt=""
                  />
                  {option.label}
                </Box>
              )
            }}
            renderInput={(params) => (
              <TextFieldSmallEdible
                {...params}
                isAutocomplete
                required
                label="Nationality"
                error={showErrors && nationalityValidation.hasErrors}
                helperText={showErrors ? nationalityValidation.errorText : ''}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <LocalizationProvider sx={{ width: '100%' }} fullWidth dateAdapter={AdapterDayjs}>
            <DesktopDatePicker
              label="Date of birth"
              inputFormat="DD/MM/YYYY"
              //mask={'__/__/____'}
              disableMaskedInput={false}
              renderInput={(params) => (
                <TextFieldSmallEdible
                  {...params}
                  fullWidth
                  error={showErrors && dateOfBirthValidation.hasErrors}
                  helperText={showErrors ? dateOfBirthValidation.errorText : ''}
                />
              )}
              onChange={(newVal) => {
                dispatch(
                  updateCustomerData({
                    ...customer,
                    dateOfBirth: newVal,
                  }),
                )
              }}
              value={customer?.dateOfBirth}
              views={['year', 'month', 'day']}
              disableFuture
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} md={4}>
          <TextFieldSmallEdible
            fullWidth
            type="text"
            required
            inputProps={{
              inputMode: 'numeric',
            }}
            label="Phone number"
            value={customer?.phoneNumber}
            onChange={(e) => {
              if (e.target.value.length <= 9) {
                dispatch(
                  updateCustomerData({
                    ...customer,
                    phoneNumber: e.target.value.replace(/[^0-9+]/g, ''),
                  }),
                )
              }
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="end">
                  <TextNormal
                    sx={{
                      fontSize: '14px',
                      color: '#757575',
                      mr: '5px',
                    }}
                  >
                    + 41
                  </TextNormal>
                </InputAdornment>
              ),
            }}
            error={showErrors && phoneNumberValidation.hasErrors}
            helperText={showErrors ? phoneNumberValidation.errorText : ''}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextFieldSmallEdible
            fullWidth
            label={'Email'}
            value={customer?.email}
            onChange={(e) => {
              dispatch(
                updateCustomerData({
                  ...customer,
                  email: e.target.value,
                }),
              )
            }}
            error={showErrors && emailValidation.hasErrors}
            helperText={showErrors ? emailValidation.errorText : ''}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Autocomplete
            id="driver-license-country"
            options={[
              {
                label: null,
              },
              ...countries,
            ]}
            getOptionLabel={(option) => option.label || ''}
            fullWidth
            autoHighlight
            value={customer?.countryOfFirstLicense}
            onChange={(e, newVal) => {
              dispatch(
                updateCustomerData({
                  ...customer,
                  countryOfFirstLicense: newVal,
                }),
              )
            }}
            renderOption={(props, option) => {
              if (!option.label) return null
              return (
                <Box
                  component="li"
                  sx={{
                    '& > img': {
                      mr: 2,
                      flexShrink: 0,
                    },
                  }}
                  {...props}
                >
                  <img
                    loading="lazy"
                    width="20"
                    srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                    src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                    alt=""
                  />
                  {option.label}
                </Box>
              )
            }}
            renderInput={(params) => (
              <TextFieldSmallEdible
                {...params}
                isAutocomplete
                required
                label="Which country issued your first driving license?"
                error={showErrors && countryOfFirstLicenseValidation.hasErrors}
                helperText={showErrors ? countryOfFirstLicenseValidation.errorText : ''}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextFieldSmallEdible
            fullWidth
            label={'Driving experience'}
            value={customer?.driverExperience}
            onChange={(e) => {
              if (e.target.value.length < 3) {
                dispatch(
                  updateCustomerData({
                    ...customer,
                    driverExperience: e.target.value.replace(/[^0-9+]/g, ''),
                  }),
                )
              }
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <TextNormal
                    sx={{
                      color: colorsConfig.lightGray,
                      fontSize: '14px',
                    }}
                  >
                    years
                  </TextNormal>
                </InputAdornment>
              ),
            }}
            error={showErrors && drivingExperienceValidation.hasErrors}
            helperText={showErrors ? drivingExperienceValidation.errorText : ''}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <FormControlTFS fullWidth error={showErrors && drivingAccidentFreeValidation.hasErrors}>
            <InputLabelTFS id={'driving-accident-free'}>Driving accident free</InputLabelTFS>
            <SelectTFS
              labelId={'driving-accident-free'}
              value={customer?.drivingAccidentFree}
              onChange={(e) => {
                dispatch(
                  updateCustomerData({
                    ...customer,
                    drivingAccidentFree: e.target.value,
                  }),
                )
              }}
            >
              {drivingAccidentFreeOptions.map((item, index) => {
                return (
                  <MenuItem key={index} value={item}>
                    {item}
                  </MenuItem>
                )
              })}
            </SelectTFS>
            {showErrors ? (
              <FormHelperText>{drivingAccidentFreeValidation.errorText}</FormHelperText>
            ) : null}
          </FormControlTFS>
        </Grid>
      </Grid>
      <RBox
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: '34px',
          mt: '20px',
          position: 'relative',
        }}
      >
        <TextNormal
          sx={{
            minWidth: '160px',
          }}
        >
          Is the car for personal or business use?
        </TextNormal>
        <FormControl>
          <RadioGroup
            row
            value={customer?.isForBusiness}
            onChange={(e) => {
              dispatch(
                updateCustomerData({
                  ...customer,
                  isForBusiness: e.target.value,
                }),
              )
            }}
          >
            <FormControlLabel
              control={<CustomRadio />}
              value={false}
              label={'Personal'}
              sx={{ mr: '30px' }}
            />
            <FormControlLabel control={<CustomRadio />} value={true} label={'Business'} />
          </RadioGroup>
        </FormControl>
        {isForBusinessValidation.hasErrors && showErrors ? (
          <TextNormal
            sx={{
              color: colorsConfig.mainRed,
              position: 'absolute',
              bottom: '-20px',
              left: 0,
              fontSize: '14px',
            }}
          >
            Selection is required
          </TextNormal>
        ) : null}
      </RBox>
      <RBox
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          mt: '30px',
        }}
      >
        <ButtonLG
          onClick={() => {
            handleSubmit()
          }}
          disabled={(showErrors && hasErrors()) || buttonDisabled}
        >
          Save and continue
        </ButtonLG>
      </RBox>
    </CollapseBlock>
  )
}

export default CarInsuranceBlockDrivers
