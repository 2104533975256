import { downloadAllCarFiles } from '../car.actions'

export default (builder) => {
  builder
    .addCase(downloadAllCarFiles.pending, (state) => {
      state.downloadAllFiles.isLoading = true
    })
    .addCase(downloadAllCarFiles.fulfilled, (state, action) => {
      state.downloadAllFiles.isLoading = false
      state.downloadAllFiles.error = null
      state.downloadAllFiles.data = action.payload
    })
    .addCase(downloadAllCarFiles.rejected, (state, action) => {
      state.downloadAllFiles.isLoading = false
      state.downloadAllFiles.error = action.payload
    })
}
