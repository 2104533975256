import React from 'react'
import { colorsConfig } from '../../config/themeConfig'
import { Box, Button, Tab, Tabs, useMediaQuery } from '@mui/material'
import { useSelector } from 'react-redux'

const AppraisalTabs = ({ currentTab, handleChangeTab, steps, renderTabTitle, stepsCompleted }) => {
  const xsOnly = useMediaQuery('(max-width: 600px)')
  const tabletOnly = useMediaQuery('(min-width: 600px) and (max-width: 1200px)')
  const carData = useSelector((state) => state.car.carData)
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated)
  const carAppraisalChecksResource = useSelector((state) => state.car.carAppraisalChecksResource)
  const role = useSelector((state) => state.auth.role)
  const filteredSteps = role !== 'EXPERT' ? steps : steps.filter((step, index) => index !== 0)

  const isStepDisabledV2 = (stepId) => {
    const prevStep = steps?.[steps?.findIndex((e) => e?.id === stepId) - 1]
    if (!prevStep) return false
    if (stepsCompleted?.find((e) => e?.id === prevStep?.id)) return false
    return true
  }

  const isTabDisabled = (title) => {
    if (
      (carData.combinedStatus &&
        carData.combinedStatus !== 'car_workflow.new' &&
        carData.combinedStatus !== 'car_workflow.appraisal_on_site') ||
      title === 'Intro' ||
      !isAuthenticated
    ) {
      return false
    }
    if (title === 'Documents') {
      return !carAppraisalChecksResource.documentsCheck
    }
    if (title === 'Photos') {
      return !carAppraisalChecksResource.photosCheck
    }
    if (title === 'Damages') {
      return !carAppraisalChecksResource.damagesCheck
    }
    if (title === 'Warning Lights') {
      return !carAppraisalChecksResource.warningLightsCheck
    }
    if (title === 'Interior Conditions') {
      return !carAppraisalChecksResource.interiorConditionsCheck
    }
    if (title === 'Mechanical Conditions') {
      return !carAppraisalChecksResource.mechanicalConditionsCheck
    }
    return false
  }

  return (
    <Box>
      {xsOnly ? (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
            flexWrap: 'wrap',
            position: 'relative',
            width: 'calc(100% + 4px)',
          }}
        >
          {filteredSteps.map((step, index) => {
            return (
              <Button
                key={index}
                className={index === currentTab ? 'active' : ''}
                sx={{
                  background: colorsConfig.bgLightBlue,
                  color: colorsConfig.mainBlack,
                  position: 'relative',
                  height: '48px',
                  textTransform: 'none',
                  fontFamily: '"Helvetica", sans-serif',
                  fontSize: '16px',
                  fontWeight: 500,
                  mb: '4px',
                  mr: '4px',
                  whiteSpace: 'nowrap',
                  flexGrow: 1,
                  '&::after': {
                    content: '" "',
                    display: 'block',
                    width: '100%',
                    height: '3px',
                    bottom: 0,
                    left: 0,
                    position: 'absolute',
                    background: colorsConfig.mainGreen,
                    transformOrigin: '50% 50%',
                    transform: 'scale(0)',
                    transition: '0.25s all ease-in-out',
                  },
                  '&.active': {
                    background: colorsConfig.bgLightBlue,
                    color: colorsConfig.mainBlack,
                    '&::after': {
                      transform: 'scale(1)',
                      transition: '0.25s all ease-in-out',
                    },
                  },
                  '&.Mui-disabled > div > p': {
                    color: 'rgba(0, 0, 0, 0.38) !important',
                  },
                }}
                onClick={(e) => {
                  handleChangeTab(e, index)
                }}
                disabled={isTabDisabled(step.title)}
              >
                {renderTabTitle(step.title).component}
              </Button>
            )
          })}
        </Box>
      ) : (
        <Tabs
          value={currentTab}
          onChange={handleChangeTab}
          variant="scrollable"
          allowScrollButtonsMobile
          scrollButtons={tabletOnly || 'auto'}
          className={tabletOnly ? 'tablet-only' : ''}
          sx={{
            width: '100%',
            '& .MuiTabs-indicator': {
              background: colorsConfig.mainGreen,
            },
            '& .MuiTab-textColorPrimary': {
              color: colorsConfig.mainBlack,
              textTransform: 'none',
              fontFamily: '"HelveticaNeue", sans-serif',
              fontSize: '16px',
              fontWeight: 500,
            },
            '& .MuiButtonBase-root': {
              background: colorsConfig.bgLightBlue,
            },
            '& .Mui-selected': {
              color: colorsConfig.mainBlack,
            },
            '&.tablet-only': {
              '& .Mui-disabled.MuiTabs-scrollButtons': {
                opacity: 0.4,
              },
            },
          }}
        >
          {steps.map((stepItem, index) => {
            if (carData.combinedStatus === 'car_workflow.appraisal_compound' && index === 0) {
              return null
            }
            return (
              <Tab
                disabled={isTabDisabled(stepItem.title) || isStepDisabledV2(stepItem?.id)}
                key={index}
                label={renderTabTitle(stepItem.title).component}
                sx={{
                  '&.Mui-disabled > div > p': {
                    color: 'rgba(0, 0, 0, 0.38) !important',
                  },
                }}
              />
            )
          })}
        </Tabs>
      )}
    </Box>
  )
}

export default AppraisalTabs
