import React, { useEffect, useState } from 'react'
import { Stack } from '@mui/material'
import CollapseBlock from '../../../../../ui/Accordions/CollapseBlock'
import { useDispatch, useSelector } from 'react-redux'
import { setCollapseBlockState, setDeals } from '../../../../../store/features/insuranceSlice'
import CarInsuranceResponsesItem from './CarInsuranceResponsesItem'
import { generateUID } from '../../../../../utils/generateUID'
import UiBadgeCollapsable from '../../../../../ui/UIBadge/UIBadgeCollapsable'
import CarInsuranceBlockResponsesControls from './CarInsuranceBlockResponsesControls'
import { privateRequest } from '../../../../../utils/request'
import { API_ENDPOINTS } from '../../../../../config/default'
import { getProcessedDeals, sortByCustomerSelected } from '../../../insurance.helpers'

const CarInsuranceBlockResponses = () => {
  const selectedTab = useSelector((state) => state.insurance.selectedTab)
  const application = useSelector((state) => state.insurance.applications[selectedTab])
  const collapsed = useSelector(
    (state) => state.insurance.applications[selectedTab]?.collapseBlocksState?.responsesCollapsed,
  )
  const disabled = useSelector(
    (state) =>
      state.insurance.applications[selectedTab]?.collapseBlocksState?.insurancesResponsesDisabled,
  )
  const deals = useSelector((state) => state.insurance.applications[selectedTab]?.deals)
  const dispatch = useDispatch()

  const [loading, setLoading] = useState(false)

  const getInsuranceDeals = () => {
    setLoading(true)
    privateRequest
      .get(API_ENDPOINTS.insurance.dealsByApplications(application?.id))
      .then((response) => {
        dispatch(
          setDeals({
            index: selectedTab,
            data: response.data['hydra:member'],
          }),
        )
        setLoading(false)
      })
  }

  useEffect(() => {
    if (
      application?.currentWorkflowState !== 'new' ||
      application?.currentWorkflowState !== 'application_preparation'
    ) {
      getInsuranceDeals()
    }
  }, [selectedTab, application?.currentWorkflowState])

  return (
    <CollapseBlock
      title={`Insurance responses`}
      counter={getProcessedDeals(deals)?.length}
      disabled={disabled}
      description={'Choose the best option for you and your customer'}
      collapsed={collapsed}
      onCollapseChange={(value) => {
        dispatch(
          setCollapseBlockState({
            id: application.id,
            block: 'responsesCollapsed',
            value: value,
          }),
        )
      }}
    >
      <Stack gap="60px">
        <Stack gap="30px">
          <Stack gap="10px">
            <UiBadgeCollapsable
              title={'Dealer commission is visible only for you'}
              description={
                "When you send a link to a client, the dealer's commission is not displayed to them"
              }
              variant="primary"
            />
            {!deals.find((item) => item.isCustomerSelected) ? (
              <UiBadgeCollapsable
                title={'Select the best programs for your customer and send it for approval'}
                description={
                  'Mark the most compelling offers and share them with the client via a link or email'
                }
                variant="secondary"
              />
            ) : null}
          </Stack>
          {!loading && !disabled && deals && deals?.length > 0 ? (
            <Stack gap="15px">
              {sortByCustomerSelected(getProcessedDeals(deals)).map((item) => {
                return <CarInsuranceResponsesItem key={generateUID()} data={item} />
              })}
            </Stack>
          ) : null}
        </Stack>
        <CarInsuranceBlockResponsesControls />
      </Stack>
    </CollapseBlock>
  )
}

export default CarInsuranceBlockResponses
