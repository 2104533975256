import React, { useEffect, useState } from 'react'
import { Box, TableBody, TableCell, TableRow, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'

const SimpleTableBody = (props) => {
  const { tableCellsContent } = props

  const navigate = useNavigate()

  const [link, setLink] = useState('')

  useEffect(() => {
    if (tableCellsContent[0].contentType && tableCellsContent[0].contentType === 'deals') {
      setLink(`/deals/`)
    } else {
      setLink(`/cars/`)
    }
  }, [tableCellsContent])

  return (
    <TableBody>
      {tableCellsContent.map((row) => {
        return (
          <TableRow
            key={row.id}
            onClick={() => {
              navigate(`${link}${row.id}`)
            }}
            sx={{
              cursor: 'pointer',
            }}
          >
            {row.body.map((item, indexCell) => {
              return (
                <React.Fragment key={indexCell}>
                  {item.type === 'text' ? (
                    <TableCell>
                      <Typography variant="body1">{item.content}</Typography>
                    </TableCell>
                  ) : null}
                  {item.type === 'text-with-caption' ? (
                    <TableCell>
                      <Typography
                        variant="body1"
                        sx={{
                          mb: 1,
                        }}
                      >
                        {item.content.main}
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{
                          px: '12px',
                          py: '4px',
                          background: '#eaeaea',
                          borderRadius: '12px',
                          fontSize: '12px',
                          display: 'inline-block',
                        }}
                      >
                        VIN: {item.content.caption}
                      </Typography>
                    </TableCell>
                  ) : null}
                  {item.type === 'status' ? (
                    <TableCell>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                        }}
                      >
                        {item.content === 'Moderation failed' ? (
                          <React.Fragment>
                            <div
                              className="status-indicator"
                              style={{
                                background: 'red',
                              }}
                            />
                            <Typography
                              variant="body2"
                              sx={{
                                color: 'red',
                              }}
                            >
                              {item.content} !
                            </Typography>
                          </React.Fragment>
                        ) : (
                          <React.Fragment>
                            <div
                              className="status-indicator"
                              style={{
                                background: item.color,
                              }}
                            />
                            <Typography variant="body2">{item.content}</Typography>
                          </React.Fragment>
                        )}
                      </Box>
                    </TableCell>
                  ) : null}
                  {item.type === 'image' ? (
                    <TableCell>
                      <Box
                        sx={{
                          borderRadius: '8px',
                          overflow: 'hidden',
                          verticalAlign: 'top',
                          width: '80px',
                        }}
                      >
                        <img
                          src={item.content}
                          style={{
                            width: '100%',
                            verticalAlign: 'top',
                          }}
                          alt=""
                        />
                      </Box>
                    </TableCell>
                  ) : null}
                </React.Fragment>
              )
            })}
          </TableRow>
        )
      })}
    </TableBody>
  )
}

export default SimpleTableBody
