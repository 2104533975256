import { privateRequest } from '../../utils/request'

class ReferencesApiService {
  #api

  #endPoints

  constructor({ api }) {
    this.#api = api
    this.#endPoints = {
      enumDetails: '/reference/enum-details',
    }
  }

  getEnumDetails = async () => {
    const response = await this.#api.get(this.#endPoints.enumDetails, {
      params: {
        pagination: false,
      },
    })
    return response
  }
}

const referencesApiService = new ReferencesApiService({ api: privateRequest })

export default referencesApiService
