import { formErrors } from '../components/Forms/FormTemplates/formErrors'

function VINValidator() {
  let response = {}
  let vinLength = (response.vinLength = 17)
  let checkDigitIdx = (response.checkDigitIndex = 8)
  let regEx = (response.regEx = '[A-HJ-NPR-Z0-9]{17}')
  let characterMap = (response.characterMap = {
    A: 1,
    B: 2,
    C: 3,
    D: 4,
    E: 5,
    F: 6,
    G: 7,
    H: 8,
    J: 1,
    K: 2,
    L: 3,
    M: 4,
    N: 5,
    P: 7,
    R: 9,
    S: 2,
    T: 3,
    U: 4,
    V: 5,
    W: 6,
    X: 7,
    Y: 8,
    Z: 9,
  })
  let weights = (response.weights = [8, 7, 6, 5, 4, 3, 2, 10, null, 9, 8, 7, 6, 5, 4, 3, 2])

  function validate(vin) {
    if (!vin) {
      return {
        valid: false,
        error: formErrors.notEmpty,
        errCode: 'emptyvin',
      }
    }

    if (vin.length !== vinLength) {
      return {
        valid: false,
        error: formErrors.vinLength,
        errCode: 'invalidlength',
      }
    }

    vin = vin.toUpperCase()

    if (!new RegExp(regEx, 'g').test(vin)) {
      return {
        valid: false,
        error: formErrors.vinInvalidChars,
        errCode: 'invalidchars',
      }
    }

    let vinList = vin.split('')
    let sum = 0
    let i

    for (i = 0; i < vinLength; ++i) {
      if (i === checkDigitIdx) {
        continue
      }

      if (isNaN(vinList[i])) {
        sum += characterMap[vinList[i]] * weights[i]
      } else {
        sum += vinList[i] * weights[i]
      }
    }

    let checkDigit = sum % 11

    if (checkDigit === 10) {
      checkDigit = 'X'
    }

    /*
        if (checkDigit !== vinList[checkDigitIdx]) {
            return {
                valid: false,
                error: 'Некорректный контрольный символ (может быть нормой для некоторых марок)',
                errCode: 'invalidcheckdigit'
            };
        }
        */

    return { valid: true }
  }

  response.validate = validate

  return response
}

export default VINValidator
