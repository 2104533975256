import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setCurrentPageTitle } from '../../store/features/authSlice'
import UIPreloader from '../../ui/UIPreloader/UIPreloader'
import UITable from '../../ui/UITable/UITable'
import { companiesTableHead } from '../../utils/constants/tables/table.constants'
import { getCompaniesTableData } from '../../utils/helpers/tables/table.helpers'
import UITablePagination from '../../ui/UITable/UITablePagination'
import UITableWrapper from '../../ui/UITable/UITableWrapper'
import { getCompanies } from '../../store/features/companiesSlice/companies.actions'
import { setCompaniesParams } from '../../store/features/companiesSlice/companies.reducer'
import UIPaper from '../../ui/UIPaper/UIPaper'
import { Stack } from '@mui/material'
import UITableControls from '../../ui/UITable/UITableControls'
import { useNavigate } from 'react-router-dom'
import { UISize } from '../../utils/constants/ui.constants'
import CompaniesActionItems from './CompaniesActionItems'

const Companies = () => {
  const isLoading = useSelector((state) => state.companies.companies.isLoading)
  const companies = useSelector((state) => state.companies.companies.data)
  const params = useSelector((state) => state.companies.companies.params)
  const total = useSelector((state) => state.companies.companies.total)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    dispatch(getCompanies())
  }, [params])

  useEffect(() => {
    dispatch(setCurrentPageTitle('Companies'))
  }, [])

  return (
    <UIPaper>
      <Stack gap="30px">
        <UITableControls
          onRefresh={() => dispatch(getCompanies())}
          onAdd={() => navigate('/admin/companies/add')}
          addText="Add a new company"
        />
        <UITableWrapper>
          {isLoading && <UIPreloader />}
          <UITable
            size={UISize.sm}
            head={companiesTableHead}
            content={getCompaniesTableData(companies)}
            emptyText="No companies found."
            renderActionItems={(data) => <CompaniesActionItems data={data} />}
          />
          <UITablePagination
            count={total}
            page={params.page}
            rowsPerPage={params.itemsPerPage}
            onPageChange={(e, newValue) => {
              dispatch(
                setCompaniesParams({
                  ...params,
                  page: newValue,
                }),
              )
            }}
            onRowsPerPageChange={(e) => {
              dispatch(
                setCompaniesParams({
                  ...params,
                  page: 1,
                  itemsPerPage: e.target.value,
                }),
              )
            }}
          />
        </UITableWrapper>
      </Stack>
    </UIPaper>
  )
}

export default Companies
