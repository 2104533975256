export const tableHeadCellsFinancing = [
  {
    title: 'table_col_number',
    sortable: false,
  },
  {
    title: 'table_col_brand_model',
    sortable: false,
  },
  {
    title: 'table_col_client',
    sortable: false,
  },
  {
    title: 'table_col_request_sent_date',
    sortable: false,
  },
  {
    title: 'table_col_status',
    sortable: false,
  },
  {
    title: 'table_col_bank_replies',
    sortable: false,
  },
]

export const tableHeadCellsFinanceCarModal = [
  {
    title: 'Number',
    sortable: false,
  },
  {
    title: 'Image',
    sortable: false,
  },
  {
    title: 'Brand/Model',
    sortable: false,
  },
  {
    title: 'Status',
    sortable: false,
  },
  {
    title: 'Action',
    sortable: false,
  },
]

export const tableHeadCellsBanks = [
  {
    title: 'Logo',
    sortable: false,
  },
  {
    title: 'Title',
    sortable: false,
  },
  {
    title: 'Code',
    sortable: false,
  },
  {
    title: 'Bank email',
    sortable: false,
  },
  {
    title: 'Is Active',
    sortable: false,
  },
]

export const tableHeadCellsLoanPrograms = [
  {
    title: 'Title',
    sortable: false,
  },
  {
    title: 'Marketing Title',
    sortable: false,
  },
  {
    title: 'Credit Type',
    sortable: false,
  },
  {
    title: 'Product Provider',
    sortable: false,
  },
]

export const tableHeadCellsProductTypes = [
  {
    title: 'Title',
    sortable: false,
  },
  {
    title: 'Code',
    sortable: false,
  },
]

export const tableHeadCellsProducts = [
  {
    title: 'Title',
    sortable: false,
  },
  {
    title: 'Type',
    sortable: false,
  },
]

export const tableHeadCellsUsers = [
  {
    title: 'ID',
    sortable: false,
  },
  {
    title: 'Full name',
    sortable: false,
  },
  {
    title: 'Company',
    sortable: false,
  },
  {
    title: 'Is Active',
    sortable: false,
  },
  {
    title: 'Registered at',
    sortable: true,
    sortableAsc: 'asc',
    sortableDesc: 'desc',
  },
  {
    title: 'Action',
    sortable: false,
  },
]

export const operatorDealsTableHeadCells = [
  {
    title: 'Seller',
    sortable: false,
  },
  {
    title: 'Buyer',
    sortable: false,
  },
  {
    title: 'Brand/Model',
    sortable: false,
  },
  {
    title: 'Amount',
    sortable: false,
  },
  {
    title: 'Status',
    sortable: false,
  },
  {
    title: 'Request date',
    sortable: true,
    sortableAsc: '&order[updatedAt]=asc',
    sortableDesc: '&order[updatedAt]=desc',
  },
]

export const tableHeadCellsRisks = [
  {
    title: 'ID',
    sortable: false,
  },
  {
    title: 'Name',
    sortable: false,
  },
  {
    title: 'Formula',
    sortable: false,
  },
  {
    title: 'Updated at',
    sortable: false,
  },
]

export const tableHeadCellsOperatorRvi = [
  {
    title: 'Policy Number',
    sortable: false,
  },
  {
    title: 'Brand/Model',
    sortable: false,
  },
  {
    title: 'Initial RV, %',
    sortable: false,
  },
  {
    title: 'Dealer',
    sortable: false,
  },
  {
    title: 'Customer reference',
    sortable: false,
  },
]
