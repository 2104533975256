import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import CollapseBlock from '../../../../ui/Accordions/CollapseBlock'
import WarrantyCarDetailsForm from '../../../../components/Forms/FormTemplates/CarServices/WarrantyCarDetails/WarrantyCarDetailsForm'
import { setCarDetailsOpen } from '../../../../store/features/warrantyApplicationSlice/warrantyApplication.reducer'

const CarDetails = () => {
  const { open, saved } = useSelector((state) => state.warrantyApplication.carDetails)
  const dispatch = useDispatch()

  return (
    <CollapseBlock
      collapsed={!open}
      onCollapseChange={(value) => dispatch(setCarDetailsOpen(!value))}
      title="Car details"
      description="Enter car details"
      saved={saved}
    >
      <WarrantyCarDetailsForm />
    </CollapseBlock>
  )
}

export default CarDetails
