import React from 'react'
import { Button } from '@mui/material'
import { colorsConfig } from '../../config/themeConfig'

const ButtonLG = ({ children, sx, ...props }) => {
  return (
    <Button
      {...props}
      sx={{
        height: '48px',
        borderRadius: '4px',
        paddingLeft: '50px',
        paddingRight: '50px',
        background: colorsConfig.mainBlue,
        color: 'white',
        textTransform: 'none',
        fontWeight: 700,
        fontSize: '16px',
        transition: '0.25s all ease-in-out',
        '&:hover': {
          background: colorsConfig.buttonHover,
          transition: '0.25s all ease-in-out',
        },
        '&.Mui-disabled': {
          background: colorsConfig.buttonDisabled,
        },
        ...sx,
      }}
    >
      {children}
    </Button>
  )
}

export default ButtonLG
