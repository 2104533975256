import { createSlice } from '@reduxjs/toolkit'
import getRviPolicyBuilder from './builders/getRviPolicy.builder'
import rviStartRestitutionBuilder from './builders/rviStartRestitution.builder'
import cancelPolicyBuilder from './builders/cancelPolicy.builder'
import closeDueToEndOfContractBuilder from './builders/closeDueToEndOfContract.builder'
import updatePolicyBuilder from './builders/updatePolicy.builder'
import closePolicyAsDealerBuilder from './builders/closePolicyAsDealer.builder'
import closeRunningContractBuilder from './builders/closeRunningContract.builder'
import getRestitutionSalesPhotosBuilder from './builders/getRestitutionSalesPhotos.builder'
import getPolicyWorkflowLogsBuilder from './builders/getPolicyWorkflowLogs.builder'
import downloadRestitutionFilesBuilder from './builders/downloadRestitutionFiles.builder'

const initialState = {
  isLoading: false,
  error: null,
  data: null,
  salesPhotos: {
    isLoading: false,
    error: null,
    data: [],
  },
  workflowLogs: {
    isLoading: false,
    error: null,
    data: [],
  },
  startRestitution: {
    isLoading: false,
    error: null,
  },
  cancelPolicy: {
    isLoading: false,
    error: null,
  },
  closeDueToEndOfContract: {
    isLoading: false,
    error: null,
  },
  updatePolicy: {
    isLoading: false,
    error: null,
  },
  closeAsDealer: {
    isLoading: false,
    error: null,
  },
  closeRunningContract: {
    isLoading: false,
    error: null,
  },
  downloadRestitutionFiles: {
    isLoading: false,
    error: null,
    data: null,
  },
}

const rviPolicySlice = createSlice({
  name: 'rviPolicyReducer',
  initialState,
  reducers: {
    resetRviPolicy: (state) => {
      state.data = initialState.data
      state.salesPhotos = initialState.salesPhotos
      state.isLoading = initialState.isLoading
      state.error = initialState.error
    },
  },
  extraReducers: (builder) => {
    getRviPolicyBuilder(builder)
    rviStartRestitutionBuilder(builder)
    cancelPolicyBuilder(builder)
    closeDueToEndOfContractBuilder(builder)
    updatePolicyBuilder(builder)
    closePolicyAsDealerBuilder(builder)
    closeRunningContractBuilder(builder)
    getRestitutionSalesPhotosBuilder(builder)
    getPolicyWorkflowLogsBuilder(builder)
    downloadRestitutionFilesBuilder(builder)
  },
})

export const { resetRviPolicy } = rviPolicySlice.actions

export default rviPolicySlice.reducer
