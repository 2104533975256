import React, { useState } from 'react'
import { Box, Stack } from '@mui/material'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import UITextHeadlineLarge from '../../../ui/UIText/UITextHeadlineLarge'
import UITextLabelExtraLarge from '../../../ui/UIText/UITextLabelExtraLarge'
import { colorsConfig } from '../../../config/themeConfig'
import UITextRegular from '../../../ui/UIText/UITextRegular'
import UITextLabelLarge from '../../../ui/UIText/UITextLabelLarge'
import UINoDataIcon from '../../../ui/UIIcons/UINoDataIcon'
import { getFormattedDate } from '../../../utils/date.helpers'
import { LOCAL_ROLE } from '../../../utils/constants/global.constants'
import ButtonHollow from '../../../ui/Buttons/ButtonHollow'
import UIIconEdit from '../../../ui/LocalIcons/UIIconEdit'
import { getPolicyCarTitle, getPolicyRelevantEndDate } from '../policies.helpers'
import { useTranslation } from 'react-i18next'
import UIStatusBlock from '../../../ui/UIBoxes/UIStatusBlock'
import { policyWorkflowStateNameKeys } from '../../../utils/constants/workflows.constants'
import PolicyLoggingNodes from '../../../components/Modals/LoggingNodesModal/PolicyLoggingNodes'

const RVIPolicySummary = () => {
  const { t } = useTranslation()
  const role = useSelector((state) => state.auth.role)
  const policy = useSelector((state) => state.rviPolicy.data)

  const navigate = useNavigate()
  const params = useParams()

  const [loggingNodesModal, setLoggingNodesModal] = useState(false)

  const carTitle = getPolicyCarTitle(policy)
  const vin = policy?.details ? `VIN: ${policy?.details?.vin}` : 'VIN: -'
  const isPaid =
    policy?.details?.RVPremiumPaid === true ? (
      'common_yes'
    ) : policy?.details?.RVPremiumPaid === false ? (
      'common_no'
    ) : (
      <UINoDataIcon isNormalized />
    )

  const isDealer = role === LOCAL_ROLE.COMPANY

  const fullStatus = t(policyWorkflowStateNameKeys[policy?.currentWorkflowState])

  return (
    <Box sx={{ position: 'relative' }}>
      <Stack gap="28px">
        <Stack gap="30px" direction="row" alignItems="center">
          <Stack>
            <UITextHeadlineLarge text={carTitle} />
            <UITextLabelExtraLarge text={vin} sx={{ color: colorsConfig.darkGray }} />
          </Stack>
          <UIStatusBlock text={fullStatus} onClick={() => setLoggingNodesModal(true)} />
        </Stack>
        <Stack direction="row" gap="40px" flexWrap="nowrap">
          {!isDealer && (
            <>
              <Stack gap="4px">
                <UITextRegular text="Linked dealer" />
                <UITextLabelLarge>
                  {policy?.company?.name ?? <UINoDataIcon isNormalized />}
                </UITextLabelLarge>
              </Stack>
            </>
          )}
          <Stack gap="4px">
            <UITextRegular text="rvi_start_date" />
            <UITextLabelLarge>
              {getFormattedDate(policy?.details?.RVIStartDate) ?? <UINoDataIcon isNormalized />}
            </UITextLabelLarge>
          </Stack>
          <Stack gap="4px">
            <UITextRegular text="rvi_end_date" />
            <UITextLabelLarge>
              {getPolicyRelevantEndDate(policy) ?? <UINoDataIcon isNormalized />}
            </UITextLabelLarge>
          </Stack>
          {!isDealer && (
            <Stack gap="4px">
              <UITextRegular text="policy_details_rvi_premium_paid" />
              <UITextLabelLarge text={isPaid} />
            </Stack>
          )}
        </Stack>
      </Stack>
      {loggingNodesModal && (
        <PolicyLoggingNodes
          open={loggingNodesModal}
          setOpen={setLoggingNodesModal}
          policyId={policy?.id}
        />
      )}
    </Box>
  )
}

export default RVIPolicySummary
