import { privateRequest } from '../../utils/request'

class DealerSupportTicketApiService {
  #api

  #endPoints

  constructor({ api }) {
    this.#api = api
    this.#endPoints = {
      collection: '/dealer-support-tickets',
      instance: (id) => `/dealer-support-tickets/${id}`,
    }
  }

  createTicket = async (data) => {
    const response = await this.#api.post(this.#endPoints.collection, data)
    return response
  }
}

const dealerSupportTicketApiService = new DealerSupportTicketApiService({ api: privateRequest })

export default dealerSupportTicketApiService
