import React from 'react'
import UIModal from '../../../../ui/UIModal/UIModal'
import { Box, Stack } from '@mui/material'
import UITextHeadlineLarge from '../../../../ui/UIText/UITextHeadlineLarge'
import LoggingNodesList from './LoggingNodesList'
import UIPreloader from '../../../../ui/UIPreloader/UIPreloader'

const LoggingNodesModal = ({
  title = 'modal_title_logging_tree',
  data,
  isLoading,
  open,
  setOpen,
}) => {
  return (
    <UIModal width="802px" open={open} setOpen={setOpen}>
      <Stack gap="30px">
        <UITextHeadlineLarge text={title} />
        <Box
          sx={{ position: 'relative', minHeight: !data || data?.length === 0 ? '200px' : 'auto' }}
        >
          {isLoading && <UIPreloader text="preloader_loading" />}
          {(!isLoading || data?.length > 0) && <LoggingNodesList data={data} />}
        </Box>
      </Stack>
    </UIModal>
  )
}

export default LoggingNodesModal
