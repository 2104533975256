import React, { useState } from 'react'
import {
  getMonthlyInstallment,
  getSavedResponseClassName,
  getTotalPremium,
} from '../../OperatorPanel/Insurance/operator-insurance.helpers'
import { Box, Stack, useMediaQuery } from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'
import RBox from '../../../ui/RBox'
import TextBoldNormal from '../../../ui/Text/TextBoldNormal'
import { colorsConfig } from '../../../config/themeConfig'
import UIMarker from '../../../ui/Markers/UIMarker'
import { insuranceDealWorkflowTypes } from '../../OperatorPanel/Insurance/operator-insurance.constants'
import TextWithCaption from '../../../ui/Text/TextWithCaption'
import UIMarkerChecked from '../../../ui/Markers/UIMarkerChecked'
import { generateUID } from '../../../utils/generateUID'
import BoxShadowedGradient from '../../../ui/Layout/BoxShadowedGradient'
import ButtonHollow from '../../../ui/Buttons/ButtonHollow'
import ModalWrap from '../../../components/Modals/ModalWrap'
import TextH1 from '../../../ui/Text/TextH1'
import TextNormal from '../../../ui/Text/TextNormal'
import ButtonLG from '../../../ui/Buttons/ButtonLG'
import { publicPatchRequest } from '../../../utils/request'
import { API_ENDPOINTS } from '../../../config/default'
import { toast } from 'react-toastify'

const CustomerInsuranceResponsesItem = ({ data, referenceCompanies, hasCustomerSelectedOffer }) => {
  const navigate = useNavigate()
  const params = useParams()
  const xsOnly = useMediaQuery('(max-width: 600px)')

  const [confirmationModal, setConfirmationModal] = useState(false)
  const [confirmButtonDisabled, setConfirmButtonDisabled] = useState(false)

  const handleSubmit = () => {
    setConfirmButtonDisabled(true)
    publicPatchRequest
      .patch(API_ENDPOINTS.insurance.approveCustomerDeal(params.id, data?.id), {
        isCustomerSelected: true,
      })
      .then(() => {
        toast.success(
          'You have approved the insurance offer successfully! Dealer will contact you shortly',
        )
        navigate('/')
      })
      .finally(() => setConfirmButtonDisabled(false))
  }

  return (
    <BoxShadowedGradient
      className={[
        getSavedResponseClassName(data?.currentWorkflowState),
        hasCustomerSelectedOffer && !data?.isCustomerSelected ? 'disabled' : '',
      ].join(' ')}
      sx={{
        pb: '15px',
        px: '15px',
        '&.disabled': {
          '&::before': {
            content: '" "',
            display: 'block',
            position: 'absolute',
            top: 0,
            left: 0,
            background: 'rgba(255,255,255,.5)',
            borderRadius: '6px',
            height: '100%',
            width: '100%',
            zIndex: 10,
          },
        },
      }}
    >
      {hasCustomerSelectedOffer && data?.isCustomerSelected ? (
        <TextBoldNormal
          sx={{
            position: 'absolute',
            top: '21px',
            right: '27px',
            color: colorsConfig.mainGreen,
          }}
        >
          Your choice
        </TextBoldNormal>
      ) : null}
      <Box
        sx={{
          display: 'flex',
          mb: '24px',
        }}
      >
        <RBox
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            '&.xs-only': {
              flexWrap: 'wrap',
            },
          }}
        >
          <TextBoldNormal>
            {referenceCompanies.find((item) => item['@id'] === data?.insuranceCompany)?.name}:
          </TextBoldNormal>
          <TextBoldNormal
            sx={{
              ml: '6px',
              color: colorsConfig.mainBlue,
            }}
          >
            {data?.dealName}
          </TextBoldNormal>
          {!xsOnly ? (
            <UIMarker
              text={
                insuranceDealWorkflowTypes.find(
                  (item) => item.systemName === data?.currentWorkflowState,
                )?.name
              }
              markerVariant={getSavedResponseClassName(data?.currentWorkflowState)}
              sx={{ ml: '10px' }}
            />
          ) : null}
        </RBox>
      </Box>
      <Box
        sx={{
          display: 'flex',
          gap: '30px',
        }}
      >
        <TextWithCaption
          text={getMonthlyInstallment(data?.totalPremium)}
          caption="Monthly instalment"
        />
        <TextWithCaption
          text={getTotalPremium(data?.totalPremium)}
          caption="Total annual instalment"
        />
      </Box>
      {data?.riskDetails?.length !== 0 ? (
        <Box sx={{ mt: '30px', mb: '30px' }}>
          <TextBoldNormal sx={{ lineHeight: 'normal', mb: '16px' }}>Coverage:</TextBoldNormal>
          <Box
            sx={{
              display: 'flex',
              gap: '16px',
              flexWrap: 'wrap',
            }}
          >
            {data?.riskDetails?.map((item) => (
              <UIMarkerChecked key={generateUID()} text={item?.risk?.name} />
            ))}
          </Box>
        </Box>
      ) : null}
      {!hasCustomerSelectedOffer ? (
        <Stack direction="row" justifyContent="flex-end">
          <ButtonHollow
            sx={{
              width: '108px',
            }}
            onClick={() => {
              setConfirmationModal(true)
            }}
          >
            Approve
          </ButtonHollow>
        </Stack>
      ) : null}
      <ModalWrap open={confirmationModal} setOpen={setConfirmationModal}>
        <TextH1 sx={{ mb: '10px' }}>Approval confirmation</TextH1>
        <TextNormal sx={{ mb: '30px' }}>Are you sure you want to approve this offer?</TextNormal>
        <Stack direction="row" gap="10px">
          <ButtonHollow fullWidth onClick={() => setConfirmationModal(false)}>
            Cancel
          </ButtonHollow>
          <ButtonLG fullWidth onClick={() => handleSubmit()} disabled={confirmButtonDisabled}>
            Approve
          </ButtonLG>
        </Stack>
      </ModalWrap>
    </BoxShadowedGradient>
  )
}

export default CustomerInsuranceResponsesItem
