import React, { useState } from 'react'
import { Box, Button, IconButton, useMediaQuery } from '@mui/material'
import { colorsConfig } from '../../config/themeConfig'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import LogoBlue from '../../assests/img/logo-blue.svg'
import IconMobileBurger from '../../assests/img/icons/icon-mobile-burger.svg'
import HelpHeaderMobileMenu from './HelpHeaderMobileMenu'
import LanguageSelect from '../../components/LanguageSelect'
import { useTranslation } from 'react-i18next'
import HeaderProfile from '../../components/Layout/Header/HeaderProfile/HeaderProfile'

const HelpHeader = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const login = useSelector((state) => state.auth.login)
  const avatar = useSelector((state) => state.auth.avatar)
  const role = useSelector((state) => state.auth.role)
  const mobileOnly = useMediaQuery('(max-width: 767px)')
  const tabletOnly = useMediaQuery('(min-width: 600px) and (max-width: 1200px)')

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

  return (
    <React.Fragment>
      {mobileOnly || tabletOnly ? (
        <Box
          sx={{
            position: 'absolute',
            zIndex: 100,
            width: '100%',
            top: 0,
            left: 0,
          }}
        >
          <Box
            sx={{
              height: '65px',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              px: '15px',
            }}
          >
            <Box
              sx={{
                cursor: 'pointer',
              }}
              onClick={() => {
                navigate('/virtual-garage')
              }}
            >
              <img src={LogoBlue} alt="" />
            </Box>
            <Box>
              <IconButton
                onClick={() => {
                  setMobileMenuOpen(!mobileMenuOpen)
                }}
              >
                <img src={IconMobileBurger} alt="" />
              </IconButton>
            </Box>
          </Box>
          <HelpHeaderMobileMenu open={mobileMenuOpen} setOpen={setMobileMenuOpen} />
        </Box>
      ) : (
        <Box
          sx={{
            height: '72px',
            backgroundSize: '100% auto',
            paddingLeft: '20px',
            paddingTop: '15px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            alignItems: 'flex-start',
            paddingRight: '100px',
            position: 'absolute',
            top: 0,
            right: 0,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-start',
              alignItems: 'center',
              cursor: 'pointer',
            }}
          >
            <HeaderProfile isDarkened />
            <LanguageSelect variant="square" isDarkened />
            <Button
              variant="outlined"
              sx={{
                textTransform: 'none',
                fontStyle: 'normal',
                lineHeight: 'normal',
                fontSize: '16px',
                fontWeight: 700,
                color: colorsConfig.mainBlack,
                height: '36px',
                border: `1px solid ${colorsConfig.mainBlack}`,
                padding: '5px',
                ml: '10px',
              }}
              onClick={() => navigate('/virtual-garage')}
            >
              {t('button_go_to_fleatify')}
            </Button>
          </Box>
        </Box>
      )}
    </React.Fragment>
  )
}

export default HelpHeader
