import React, { useEffect, useState } from 'react'
import {
  Autocomplete,
  Box,
  CircularProgress,
  createTheme,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material'
import { API_ENDPOINTS, HINT_TYPES } from '../../config/default'
import { privateRequest } from '../../utils/request'
import { toast } from 'react-toastify'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import VINValidator from '../../utils/VinValidator'
import {
  resetCarData,
  setCarMainData,
  setFirstDataValid,
  setMake,
  setModel,
  setVin,
} from '../../store/features/addNewCarSlice/addNewCar.reducer'
import TextFieldSmall from '../../ui/TextFieldSmall'
import { colorsConfig } from '../../config/themeConfig'
import IconVirtualGarage from '../../assests/img/icons/icon-virtual-garage-home.svg'
import BreadcrumbsComponent from '../../components/BreadcrumbsComponent'
import ButtonLG from '../../ui/Buttons/ButtonLG'
import TextAreaSmall from '../../ui/TextAreaSmall'
import { setAppraisalTab } from '../../store/features/carSlice/carSlice'
import { setHints } from '../../store/features/qualityCheckSlice'
import { setCurrentPageTitle } from '../../store/features/authSlice'
import { gearboxTypes } from '../../config/formsConfig'
import CarRequiredFields from '../AddNewVehicle/FormTemplates/CarRequiredFields'
import CarOptionalFields from '../AddNewVehicle/FormTemplates/CarOptionalFields'
import UITextTitleSmall from '../../ui/UIText/UITextTitleSmall'
import CarPriceFields from '../AddNewVehicle/FormTemplates/CarPriceFields'
import IconApple from '../../assests/img/icons/icon-apple.svg'
import CarEcoFields from '../AddNewVehicle/FormTemplates/CarEcoFields'
import CarColorField from '../AddNewVehicle/FormTemplates/CarColorField'
import SimpleCheckbox from '../../ui/SimpleCheckbox'
import UITextRegular from '../../ui/UIText/UITextRegular'
import {
  getCoreValuesFormData,
  getMainValuesFormData,
  getUpholsteryCheckboxOptions,
} from '../AddNewVehicle/addNewVehicleForm.helpers'
import { useTranslation } from 'react-i18next'
import { editCarFormInitialState, getEditCarFormInitialState } from './editCar.helpers'
import { updateCar } from '../../store/features/carSlice/car.actions'

const EditVehicle = ({ mode }) => {
  const { t } = useTranslation()
  const qualityCheckHints = useSelector((state) => state.qualityCheck.hints)
  const enumsDetails = useSelector((state) => state.ref.enumDetails.data)

  const params = useParams()
  const navigate = useNavigate()

  const [makes, setMakes] = useState([])
  const [models, setModels] = useState([])
  const [makesLoading, setMakesLoading] = useState(false)
  const [firstBlur, setFirstBlur] = useState(false)
  const [vehicleDetailsHint, setVehicleDetailsHint] = useState(null)
  const [date, setDate] = useState(null)
  const [loading, setLoading] = useState(true)
  const [saveButtonDisabled, setSaveButtonDisabled] = useState(false)
  const [showErrors, setShowErrors] = useState(false)

  const [showSpecs, setShowSpecs] = useState(false)
  const [carSpecs, setCarSpecs] = useState([])
  const [carSpecsLoaded, setCarSpecsLoaded] = useState(false)
  const [carSpecsReference, setCarSpecsReference] = useState([])
  const [carSpecsBlocks, setCarSpecsBlocks] = useState([])

  const isMobileSmall = useMediaQuery('(max-width: 420px)')
  const isMobileMedium = useMediaQuery('(max-width: 500px)')

  const carFirstDataValid = useSelector((state) => state.addCar.carFirstData.valid)
  const carFirstData = useSelector((state) => state.addCar.carFirstData)
  const vin = useSelector((state) => state.addCar.carFirstData.vin)
  const make = useSelector((state) => state.addCar.carFirstData.make)
  const model = useSelector((state) => state.addCar.carFirstData.model)
  const carData = useSelector((state) => state.addCar.carMainData)
  const dispatch = useDispatch()

  const [isLoadingCar, setIsLoadingCar] = useState(false)
  const [formData, setFormData] = useState(editCarFormInitialState)

  const handleSetVin = (value) => {
    const validateVin = VINValidator().validate(value)
    const newVin = {
      valid: validateVin.valid,
      errorText: validateVin.error ? validateVin.error : '',
      value: value.toUpperCase(),
    }
    dispatch(setVin(newVin))
  }

  const handleSetMake = (value) => {
    dispatch(
      setMake({
        ...make,
        name: value,
      }),
    )
  }

  const handleSetModel = (value) => {
    let matchModel = null
    if (value) {
      matchModel = models.filter((item) => value === item.name)[0]
    }
    dispatch(
      setModel({
        name: value,
        id: matchModel ? matchModel['@id'] : '',
      }),
    )
  }

  const getMakes = (searchLine) => {
    privateRequest.get(`${API_ENDPOINTS.makes}?name=${searchLine}`).then((response) => {
      setMakes(response.data['hydra:member'])
      setMakesLoading(false)
      const matchMake = response.data['hydra:member'].filter((item) => item.name === make.name)[0]
      if (matchMake) {
        dispatch(
          setMake({
            ...make,
            id: matchMake['@id'],
          }),
        )
        getModels(matchMake['@id'])
      }
    })
  }

  const getModels = (makePath) => {
    privateRequest
      .get(`${API_ENDPOINTS.models}?make=${makePath}&pagination=false`)
      .then((response) => {
        setModels(response.data['hydra:member'])
        if (response.data['hydra:member'].length === 0) {
          toast.error('Can not add this make: no models found')
        }
      })
  }

  const getCar = () => {
    setIsLoadingCar(true)
    privateRequest
      .get(API_ENDPOINTS.carId(params.id))
      .then((response) => {
        setFormData(getEditCarFormInitialState(response.data))
        dispatch(
          setVin({
            valid: true,
            errorText: '',
            value: response.data.vin,
          }),
        )
        dispatch(
          setMake({
            name: response.data.make.name,
            id: response.data.make['@id'],
          }),
        )
        getModels(response.data.make['@id'])
        dispatch(
          setModel({
            name: response.data.model.name,
            id: response.data.model['@id'],
          }),
        )
        setTimeout(() => {
          dispatch(setFirstDataValid())
        }, 2000)
        setLoading(false)
      })
      .finally(() => setIsLoadingCar(false))
  }

  const handleUpdateCar = async () => {
    const data = {
      ...getMainValuesFormData(formData),
      ...getCoreValuesFormData(carFirstData),
    }
    delete data?.independentCarInfo

    const res = await dispatch(
      updateCar({
        carIdUrl: `/cars/${params.id}`,
        data,
      }),
    )
    if (updateCar.fulfilled.match(res)) {
      toast.success('Car has been updated successfully')
      navigate(-1)
    }
  }

  const getCarSpecsReference = () => {
    privateRequest.get(API_ENDPOINTS.carSpecsOptionsBlocks).then((response) => {
      setCarSpecsReference(response.data['hydra:member'])
    })
  }

  const getCarSpecs = () => {
    privateRequest
      .get(`${API_ENDPOINTS.carId(params.id)}${API_ENDPOINTS.carSpecs.default}`)
      .then((response) => {
        setCarSpecs(response.data['hydra:member'])
        setCarSpecsLoaded(true)
      })
  }

  const handleSetCarSpecsBlocks = () => {
    setCarSpecsBlocks(
      carSpecsReference.map((item) => {
        const newSpecs = item.specs.map((spec) => {
          const matchCarSpec = carSpecs.filter((carSpec) => carSpec.spec === spec['@id'])[0]
          if (matchCarSpec) {
            return {
              ...spec,
              carSpecId: matchCarSpec['@id'],
              value: matchCarSpec.value,
            }
          }
          return {
            ...spec,
            value: false,
          }
        })
        return {
          ...item,
          specs: newSpecs,
        }
      }),
    )
  }

  const changeCarSpec = (id, value) => {
    setCarSpecsBlocks(
      carSpecsBlocks.map((item) => {
        const newSpecs = item.specs.map((spec) => {
          if (spec['@id'] === id) {
            return {
              ...spec,
              value: value,
            }
          }
          return spec
        })
        return {
          ...item,
          specs: newSpecs,
        }
      }),
    )
  }

  const handleSetMainSpecsSubmitDisabled = () => {
    console.log(formData)
    if (!formData.firstRegistrationDate) return true
    if (!formData.mileage) return true
    if (!formData.engineType) return true
    if (!formData.bodyType) return true
    if (!formData.seatsNumber) return true
    if (!formData.doorsNumber) return true
    if (!formData.driveType) return true
    if (!formData.gearbox) return true
    if (!formData.origin) return true
    if (!formData.engineSize && formData.engineType !== 4) return true
    if (!vin.value) return true
    if (!make.id) return true
    if (!model.id) return true
    return false
  }

  const handleSetVehicleDetailsHint = () => {
    let hintIndex = -1
    const matchHint = qualityCheckHints.filter((item, index) => {
      if (item['@type'] === HINT_TYPES.vehicleDetails) {
        hintIndex = index
      }
      return item['@type'] === HINT_TYPES.vehicleDetails
    })[0]
    if (matchHint && hintIndex !== -1) {
      setVehicleDetailsHint({
        ...matchHint,
        index: hintIndex,
      })
    }
  }

  useEffect(() => {
    if (firstBlur && !loading) {
      if (make.name) {
        const matchMake = makes.filter((item) => {
          return item.name === make.name
        })[0]
        if (matchMake) {
          setMakesLoading(false)
          dispatch(
            setMake({
              ...make,
              id: matchMake['@id'],
            }),
          )
          getModels(matchMake['@id'])
        } else if (make.name.length >= 2) {
          dispatch(
            setMake({
              ...make,
              id: null,
            }),
          )
          setModels([])
          setMakesLoading(true)
          getMakes(make.name)
        }
      } else {
        dispatch(
          setMake({
            ...make,
            id: null,
          }),
        )
        setModels([])
      }
    }
    setFirstBlur(true)
  }, [make.name])

  useEffect(() => {
    if (carSpecsLoaded && carSpecsReference.length > 0) {
      handleSetCarSpecsBlocks()
    }
  }, [carSpecs, carSpecsReference])

  useEffect(() => {
    dispatch(setCurrentPageTitle('Edit information'))
    getCar()
    getCarSpecsReference()
    getCarSpecs()
    return () => {
      dispatch(resetCarData())
    }
  }, [])

  useEffect(() => {
    setSaveButtonDisabled(handleSetMainSpecsSubmitDisabled())
  }, [formData, vin, make, model])

  useEffect(() => {
    if (qualityCheckHints.length > 0 && mode && mode === 'fromAppraisal') {
      handleSetVehicleDetailsHint()
    }
  }, [qualityCheckHints])

  useEffect(() => {
    if (carData.engineType?.name === 'Electric') {
      dispatch(
        setCarMainData({
          ...carData,
          gearbox: gearboxTypes.find((item) => item.systemName === 'automatic'),
          gearsNumber: 1,
          engineSize: '',
          cylinder: '',
          fuelConsumption: '0',
        }),
      )
    }
  }, [carData.engineType])

  return (
    <Stack
      gap="24px"
      sx={{
        px: {
          xs: '16px',
          sm: 0,
        },
        pb: {
          xs: '95px',
          sm: 0,
        },
      }}
    >
      {mode && mode === 'fromAppraisal' ? null : (
        <Box>
          <BreadcrumbsComponent
            currentPage={{ name: `Edit information` }}
            parentPages={[
              {
                name: 'Virtual Garage',
                icon: <img src={IconVirtualGarage} alt="" />,
                link: '/virtual-garage',
              },
              {
                name: `${make.name} ${model.name}`,
                link: `/cars/${params.id}`,
              },
            ]}
          />
        </Box>
      )}
      <UITextTitleSmall text="title_required_fields" />
      <Grid container spacing="24px">
        <Grid item sm={4} xs={12}>
          <TextFieldSmall
            required
            fullWidth
            size={'small'}
            id="car-vin"
            label="VIN Number"
            value={vin.value}
            onChange={(e) => {
              if (e.target.value.length > 17) {
                e.preventDefault()
              } else {
                handleSetVin(e.target.value.replace(/[ioqIOQ]/g, ''))
              }
            }}
            helperText={vin.errorText}
            error={vin.value ? !vin.valid : false}
            InputProps={{
              endAdornment: (
                <Typography
                  variant="body2"
                  sx={{
                    color:
                      vin.value === null || vin.value.length === 17 || vin.value.length === 0
                        ? 'lightgray'
                        : '#d32f2f',
                  }}
                >
                  {vin.value ? vin.value.length : 0}/17
                </Typography>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Autocomplete
            required
            fullWidth
            id="car-make"
            options={makes.map((item) => {
              return item.name
            })}
            value={make.name}
            loading={!make.name ? true : makesLoading}
            loadingText={!make.name ? 'Start typing...' : 'Loading makes...'}
            onChange={(e, value) => {
              handleSetMake(value)
            }}
            freeSolo
            renderInput={(params) => (
              <TextFieldSmall
                {...params}
                label="Make"
                size={'small'}
                value={make.name}
                fullWidth
                onChange={(e) => {
                  handleSetMake(e.target.value)
                }}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {makesLoading ? <CircularProgress color="inherit" size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Autocomplete
            required
            disablePortal
            id="car-model"
            options={models.map((item) => {
              return item.name
            })}
            fullWidth
            value={model.name}
            loading={models.length === 0}
            freeSolo
            loadingText={'Please select the make first'}
            onChange={(e, newVal) => {
              handleSetModel(newVal)
            }}
            renderInput={(params) => (
              <TextFieldSmall
                {...params}
                label="Model"
                size={'small'}
                value={model.name}
                onChange={(e) => {
                  handleSetModel(e.target.value)
                }}
              />
            )}
          />
        </Grid>
      </Grid>
      {!isLoadingCar && formData && (
        <>
          <CarRequiredFields formData={formData} setFormData={setFormData} />
          <UITextTitleSmall text="title_optional_fields" />
          <CarOptionalFields formData={formData} setFormData={setFormData} />
          <CarPriceFields formData={formData} setFormData={setFormData} />
          <Stack gap="14px">
            <UITextTitleSmall
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {t('title_eco_data')} <img src={IconApple} alt="" style={{ marginLeft: '4px' }} />
            </UITextTitleSmall>
            <CarEcoFields formData={formData} setFormData={setFormData} />
          </Stack>
          <Grid container spacing="24px">
            <Grid item sm={6} xs={12}>
              <CarColorField
                title="form_input_label_exterior_color"
                value={formData.exteriorColor}
                setValue={(value) =>
                  setFormData({
                    ...formData,
                    exteriorColor: value,
                  })
                }
                sx={{ mb: '20px' }}
              />
              <Typography
                sx={{
                  fontWeight: 700,
                  fontSize: '24px',
                  color: colorsConfig.mainBlack,
                  mb: '20px',
                }}
              >
                {t('form_input_label_paintwork')}
              </Typography>
              <Grid container spacing={3}>
                <Grid item xs={3}>
                  <Stack direction="row" gap="4px" alignItems="center">
                    <SimpleCheckbox
                      value={formData.isMetallic}
                      setValue={(value) =>
                        setFormData({
                          ...formData,
                          isMetallic: value,
                        })
                      }
                    />
                    <UITextRegular text="paintwork_type_metallic" />
                  </Stack>
                </Grid>
                <Grid item xs={3}>
                  <Stack direction="row" gap="4px" alignItems="center">
                    <SimpleCheckbox
                      value={formData.isMatt}
                      setValue={(value) =>
                        setFormData({
                          ...formData,
                          isMatt: value,
                        })
                      }
                    />
                    <UITextRegular text="paintwork_type_matt" />
                  </Stack>
                </Grid>
                <Grid item xs={3}>
                  <Stack direction="row" gap="4px" alignItems="center">
                    <SimpleCheckbox
                      value={formData.isWrapped}
                      setValue={(value) =>
                        setFormData({
                          ...formData,
                          isWrapped: value,
                        })
                      }
                    />
                    <UITextRegular text="paintwork_type_wrapped" />
                  </Stack>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6}>
              <CarColorField
                title="form_input_label_interior_color"
                value={formData.interiorColor}
                setValue={(value) =>
                  setFormData({
                    ...formData,
                    interiorColor: value,
                  })
                }
                sx={{ mb: '20px' }}
              />
              <Typography
                sx={{
                  fontWeight: 700,
                  fontSize: '24px',
                  color: colorsConfig.mainBlack,
                  mb: '20px',
                }}
              >
                {t('form_input_label_upholstery')}
              </Typography>
              <Grid container spacing={3}>
                {getUpholsteryCheckboxOptions(enumsDetails).map((item) => {
                  return (
                    <Grid key={item?.name} item xs={3}>
                      <Stack direction="row" gap="4px" alignItems="center">
                        <SimpleCheckbox
                          value={formData.upholstery === item?.value}
                          setValue={(value) => {
                            setFormData({
                              ...formData,
                              upholstery: value ? item?.value : null,
                            })
                          }}
                        />
                        <UITextRegular
                          text={item?.readableValue}
                          sx={{ maxWidth: '100%', overflow: 'hidden', textOverflow: 'ellipsis' }}
                        />
                      </Stack>
                    </Grid>
                  )
                })}
              </Grid>
            </Grid>
          </Grid>
        </>
      )}

      <Grid container spacing="24px">
        <Grid xs={12} item>
          <TextAreaSmall
            fullWidth
            label="Additional Info"
            type="textarea"
            multiline
            rows={4}
            maxRows={10}
            value={formData.additionalSpecsDetails}
            onChange={(e) => {
              setFormData({ ...formData, additionalSpecsDetails: e.target.value })
            }}
          />
        </Grid>
        <Grid
          xs={12}
          item
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            alignItems: 'center',
          }}
        >
          {mode && mode === 'fromAppraisal' ? (
            <ButtonLG
              onClick={() => {
                handleUpdateCar()
              }}
              disabled={saveButtonDisabled}
            >
              {vehicleDetailsHint ? 'Apply & proceed' : 'Next'}
            </ButtonLG>
          ) : (
            <ButtonLG
              sx={{
                background: colorsConfig.mainGreen,
                '&:hover': {
                  background: colorsConfig.mainGreen,
                  opacity: 0.7,
                  transition: '0.25s all ease-in-out',
                },
              }}
              onClick={() => {
                handleUpdateCar()
              }}
              disabled={saveButtonDisabled}
            >
              Save changes
            </ButtonLG>
          )}
        </Grid>
      </Grid>
    </Stack>
  )
}

export default EditVehicle
