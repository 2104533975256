import {
  initialApplicationData,
  initialCustomerData,
  insuranceApplicationWorkflowStateKeys,
} from './insurance.constants'
import { countries } from '../../default-data/coutries'
import { removeUAECodePrefix } from '../../utils/formatString.helpers'
import InsuranceTableQuotes from './InsuranceService/InsuranceTableQuotes'
import React from 'react'
import { getFormattedDate } from '../../utils/date.helpers'

export const getAccidentFreeDate = (initValue) => {
  let finalDate
  switch (initValue) {
    case '< 1 year':
      finalDate = 0
      break
    case '1 year':
      finalDate = 1
      break
    case '2 years':
      finalDate = 2
      break
    case '3 years':
      finalDate = 3
      break
    case '4 years':
      finalDate = 4
      break
    case '5 years':
      finalDate = 5
      break
    case '> 5 years':
      finalDate = 6
      break
  }
  return finalDate
}

export const getAccidentFreeString = (yearsAmount) => {
  if (yearsAmount < 6 && yearsAmount > 1) {
    return `${yearsAmount} years`
  }
  if (yearsAmount === 1) {
    return `${yearsAmount} year`
  }
  if (yearsAmount === 0) {
    return '< 1 year'
  }
  if (yearsAmount > 5) {
    return '> 5 years'
  }
  return ''
}

export const sortByCustomerSelected = (data) =>
  data.sort((x, y) =>
    x.isCustomerSelected === y.isCustomerSelected ? 0 : x.isCustomerSelected ? -1 : 1,
  )

export const getPackageRisks = (referenceRisks, packageRisks) => {
  return referenceRisks
    .filter((item) => packageRisks.filter((risk) => risk === item.name)[0])
    .map((item) => item['@id'])
}

export const getTotalPremium = (calculatedRisks) => {
  if (!Array.isArray(calculatedRisks) || calculatedRisks.length === 0) {
    return 0
  }
  const totalPremium = calculatedRisks.reduce((sum, risk) => {
    const premium = parseFloat(risk.premium) || 0
    return sum + premium
  }, 0)
  return totalPremium
}

export const getProcessedDeals = (deals) => {
  if (!deals) return []
  return deals.filter(
    (item) =>
      item?.currentWorkflowState === 'approved' ||
      item?.currentWorkflowState === 'insurance_company_request_info' ||
      item?.currentWorkflowState === 'insurance_company_declined',
  )
}

export const parsePackagesFromRisks = (risks) => {
  const initialPackages = initialApplicationData.packages
  if (!risks || risks.length === 0) {
    return initialPackages
  }
  const matchedPackages = initialPackages.map((item) => {
    const selected =
      risks.every((risk) => item.selectedRisks.includes(risk.name)) &&
      risks.length === item.selectedRisks.length
    return {
      ...item,
      selected: selected,
    }
  })
  if (matchedPackages.find((item) => item.selected)) {
    return matchedPackages
  } else {
    return [
      initialPackages[0],
      initialPackages[1],
      {
        ...initialPackages[2],
        selected: true,
        selectedRisks: risks.map((item) => item.name),
      },
    ]
  }
}

export const parseInsuranceCustomer = (application) => {
  if (application?.customer?.person) {
    const person = application?.customer?.person
    const driverLicense = application?.customer?.driverLicense
    return {
      firstName: person?.firstName,
      lastName: person?.lastName,
      email: person?.email,
      nationality: countries.find((item) => item.label === person?.nationality),
      dateOfBirth: person?.dateOfBirth,
      phoneNumber: removeUAECodePrefix(person?.phoneNumber),
      title: person?.title,
      countryOfFirstLicense: countries.find(
        (item) => item.label === driverLicense?.countryOfFirstLicense,
      ),
      driverExperience: driverLicense?.firstIssueYears,
      drivingAccidentFree: getAccidentFreeString(driverLicense?.accidentFreeYears),
      isForBusiness: application?.customer?.isForBusiness.toString(),
    }
  } else {
    return initialCustomerData
  }
}

export const getInsuredInsuranceApplicationsTableData = (data) => {
  if (!data || data?.length === 0) return []
  return data
    .sort(function (a, b) {
      return new Date(b.updatedAt) - new Date(a.updatedAt)
    })
    .map((item) => {
      const driver =
        item?.customer && (item?.customer?.person?.firstName || item?.customer?.person?.lastName)
          ? `${item?.customer?.person?.firstName || ''} ${item?.customer?.person?.lastName || ''}`
          : `Customer #${item.id}`
      return {
        id: item.id,
        link: `${item.car['@id']}?service=Insurance`,
        greenCar: item?.car?.engineType === 'electric' || item?.car?.engineType === 'hybrid',
        cells: [
          {
            type: 'text',
            value: `#${item.id}`,
          },
          {
            type: 'text-with-caption',
            value: `${item?.car?.make?.name} ${item?.car?.model?.name}`,
            caption: `VIN: ${item?.car?.vin}`,
          },
          {
            type: 'text',
            value: driver,
          },
          {
            type: 'text',
            value: getFormattedDate(item?.startDate),
          },
        ],
      }
    })
}

export const getInsuranceApplicationsTableData = (data) => {
  if (!data || data?.length === 0) return []
  return data
    .sort(function (a, b) {
      return new Date(b.updatedAt) - new Date(a.updatedAt)
    })
    .map((item) => {
      const driver =
        item?.customer && (item?.customer?.person?.firstName || item?.customer?.person?.lastName)
          ? `${item?.customer?.person?.firstName || ''} ${item?.customer?.person?.lastName || ''}`
          : `Customer #${item.id}`
      const requestsSent = item?.insuranceDeals?.[0]?.createdAt
      return {
        id: item.id,
        link: `${item.car['@id']}?service=Insurance`,
        greenCar: item?.car?.engineType === 'electric' || item?.car?.engineType === 'hybrid',
        cells: [
          {
            type: 'text',
            value: `#${item.id}`,
          },
          {
            type: 'text-with-caption',
            value: `${item?.car?.make?.name} ${item?.car?.model?.name}`,
            caption: `VIN: ${item?.car?.vin}`,
          },
          {
            type: 'text',
            value: driver,
          },
          {
            type: 'text',
            value: getFormattedDate(requestsSent) ?? '-',
          },
          {
            type: 'text',
            value: insuranceApplicationWorkflowStateKeys[item?.currentWorkflowState] ?? '-',
          },
          {
            type: 'component',
            value: (
              <InsuranceTableQuotes
                success={
                  item?.insuranceDeals?.filter((item) => item?.currentWorkflowState === 'approved')
                    ?.length ?? 0
                }
                attention={
                  item?.insuranceDeals?.filter(
                    (item) => item?.currentWorkflowState === 'insurance_company_request_info',
                  )?.length ?? 0
                }
                danger={
                  item?.insuranceDeals?.filter(
                    (item) => item?.currentWorkflowState === 'insurance_company_declined',
                  )?.length ?? 0
                }
              />
            ),
          },
        ],
      }
    })
}
