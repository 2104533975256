import React from 'react'
import { Box, Stack, Typography, useMediaQuery } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import WarningLightItem from './WarningLightItem'
import TextBoldAccent from '../../../ui/Text/TextBoldAccent'
import { colorsConfig } from '../../../config/themeConfig'
import UICheckbox from '../../../ui/UICheckbox/UICheckbox'
import { setAppraisalNoWarningLights } from '../../../store/features/carSlice/carSlice'
import UITextRegular from '../../../ui/UIText/UITextRegular'

const WarningLights = ({ readonly, hideGaps }) => {
  const pilotLamps = useSelector((state) => state.car.appraisal.pilotLamps)
  const noWarningLights = useSelector((state) => state.car.appraisal.noWarningLights)
  const xsOnly = useMediaQuery('(max-width: 600px)')
  const dispatch = useDispatch()

  return (
    <Box
      className={[xsOnly ? 'xs-only' : '', hideGaps ? 'hide-gaps' : ''].join(' ')}
      sx={{
        mt: '40px',
        px: '60px',
        py: '30px',
        boxShadow: '0 0 24px 0 rgba(0,0,0,.1)',
        borderRadius: '6px',
        '&.hide-gaps': {
          borderRadius: 0,
          boxShadow: 'none',
          mt: 0,
        },
        '&.xs-only': {
          px: '21px',
        },
      }}
    >
      <Stack direction="row" gap="20px" alignItems="center" sx={{ mb: '24px' }}>
        <Box
          className={xsOnly ? 'xs-only' : ''}
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            width: '104px',
            height: '20px',
            borderRadius: '6px',
            overflow: 'hidden',
            '&.xs-only': {
              mb: '39px',
            },
          }}
        >
          <TextBoldAccent
            variant="body1"
            sx={{
              height: '100%',
              width: '50%',
              background: colorsConfig.mainGreen,
              color: 'white',
              textAlign: 'center',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {
              pilotLamps.filter(
                (item) => (item.value === 0 || item.value === null) && item.newValue !== 1,
              ).length
            }
          </TextBoldAccent>
          <Typography
            variant="body1"
            sx={{
              height: '100%',
              width: '50%',
              background: colorsConfig.mainRed,
              color: 'white',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {pilotLamps.filter((item) => item.value === 1 || item.newValue === 1).length}
          </Typography>
        </Box>
        {!readonly && (
          <Stack direction="row" gap="5px" alignItems="center">
            <UICheckbox
              checked={noWarningLights}
              onChange={(e) => dispatch(setAppraisalNoWarningLights(e.target.checked))}
            />
            <UITextRegular
              sx={{ position: 'relative', mt: '-1px' }}
              text="form_input_label_no_warning_lights"
            />
          </Stack>
        )}
      </Stack>
      <Box
        className={xsOnly ? 'xs-only' : ''}
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: {
            xs: 'flex-start',
            md: 'space-between',
          },
          alignItems: 'center',
          flexWrap: 'wrap',
          '&.xs-only': {
            position: 'relative',
            width: 'calc(100% + 15px)',
            height: 'calc(100% + 19px)',
            mt: '-19px',
          },
          ...(noWarningLights && {
            opacity: '0.5',
            pointerEvents: 'none',
          }),
        }}
      >
        {pilotLamps.map((pilotLamp, index) => {
          return (
            <WarningLightItem
              key={index}
              warningLight={pilotLamp}
              readonly={!!readonly || noWarningLights}
            />
          )
        })}
      </Box>
    </Box>
  )
}

export default WarningLights
