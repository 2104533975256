import React from 'react'
import { Table } from '@mui/material'
import TextH1 from '../../../../../ui/Text/TextH1'
import ModalWrap from '../../../../../components/Modals/ModalWrap'
import CarTableHead from '../../../../VirtualGarage/CarTableHead'
import CarTableBody from '../../../../VirtualGarage/CarTableBody'
import { getExpiryDate, getPackageName } from '../../../../Warranty/warranty.helpers'
import { warrantyApplicationsActivatedTableCells } from '../../../../Warranty/warranty.constants'

const WarrantyStatusCakeModal = ({ data, open, setOpen }) => {
  const handleGetTableContent = (rows) => {
    return rows.map((item) => {
      return {
        id: item?.id,
        link: `${item?.car['@id']}?service=Warranty`,
        greenCar: item?.car?.engineType === 'electric' || item?.car?.engineType === 'hybrid',
        cells: [
          {
            type: 'text',
            value: `#${item?.id}`,
          },
          {
            type: 'text-with-caption',
            value: `${item?.car?.make?.name} ${item?.car?.model?.name}`,
            caption: `VIN: ${item?.car?.vin}`,
          },
          {
            type: 'text',
            value: getPackageName(item?.selectedProduct),
          },
          {
            type: 'text',
            value: getExpiryDate(item?.startDate),
          },
        ],
      }
    })
  }

  return (
    <ModalWrap
      open={open}
      setOpen={setOpen}
      wrapperStyles={{
        width: '1100px',
        '&.xs-only': {
          width: 'calc(100vw - 32px)',
        },
      }}
    >
      {data ? (
        <>
          <TextH1>{data?.name}</TextH1>
          <Table>
            <CarTableHead tableCells={warrantyApplicationsActivatedTableCells} />
            <CarTableBody content={handleGetTableContent(data?.items)} />
          </Table>
        </>
      ) : null}
    </ModalWrap>
  )
}

export default WarrantyStatusCakeModal
