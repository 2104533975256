import { getDashboardPoliciesWorkflowLogs } from '../dashboard.actions'
import { apiConstants } from '../../../../api/api'

export default (builder) => {
  builder
    .addCase(getDashboardPoliciesWorkflowLogs.pending, (state) => {
      state.policiesWorkflowLogs.isLoading = true
    })
    .addCase(getDashboardPoliciesWorkflowLogs.fulfilled, (state, action) => {
      state.policiesWorkflowLogs.isLoading = false
      state.policiesWorkflowLogs.error = null
      state.policiesWorkflowLogs.data = action.payload?.[apiConstants.hydraMember]
    })
    .addCase(getDashboardPoliciesWorkflowLogs.rejected, (state, action) => {
      state.policiesWorkflowLogs.isLoading = false
      state.policiesWorkflowLogs.error = action.payload
    })
}
