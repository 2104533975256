import React from 'react'
import { Box, Button, Stack, Typography } from '@mui/material'
import { colorsConfig } from '../../config/themeConfig'
import { useLocation, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import LogoBlack from '../../assests/img/logo-black.svg'
import TextHelp from '../../assests/img/text-help.svg'

const sections = [
  {
    title: 'sidebar_faq',
    link: 'faq',
  },
  {
    title: 'sidebar_profile_management',
    link: 'profile-management',
  },
  {
    title: `sidebar_fleatify_coins`,
    link: `coins`,
  },
  {
    title: 'sidebar_dashboard',
    link: 'dashboard',
  },
  {
    title: 'sidebar_virtual_garage',
    link: 'virtual-garage',
  },
  {
    title: 'sidebar_appraisal',
    link: 'appraisal',
  },
  {
    title: 'sidebar_auction',
    link: 'auction',
  },
  {
    title: 'sidebar_loan_finance',
    link: 'finance',
  },
  {
    title: 'sidebar_insurance',
    link: 'insurance',
  },
  {
    title: 'sidebar_warranty',
    link: 'warranty',
  },
  {
    title: 'sidebar_transportation',
    link: 'transportation',
  },
  {
    title: 'sidebar_subscription',
    link: 'subscription',
  },
  {
    title: 'sidebar_stock_financing',
    link: 'stock-finance',
  },
]

const HelpSidebar = () => {
  const { t } = useTranslation()
  const role = useSelector((state) => state.auth.role)

  const navigate = useNavigate()
  const location = useLocation()

  return (
    <Box
      sx={{
        width: '290px',
        minWidth: '290px',
        background: colorsConfig.bgLightBlue,
        paddingLeft: '60px',
        paddingTop: '18px',
        paddingBottom: '100px',
        display: {
          xs: 'none',
          md: 'block',
        },
      }}
    >
      <Stack
        direction="row"
        gap="9px"
        sx={{
          cursor: 'pointer',
        }}
        onClick={() => {
          navigate('/virtual-garage')
        }}
      >
        <img width="131px" src={LogoBlack} alt="" />
        <img
          width="52.05px"
          src={TextHelp}
          alt=""
          style={{ position: 'relative', marginBottom: '-10px' }}
        />
      </Stack>
      <Box
        sx={{
          mt: '30px',
          position: 'relative',
          ml: '-8px',
        }}
      >
        {role === 'COMPANY' ? (
          <>
            {sections.map((item, index) => {
              const { title, link } = item
              return (
                <Box
                  key={index}
                  sx={{
                    mb: '8px',
                    '&:last-child': {
                      mb: 0,
                    },
                  }}
                >
                  <Button
                    className={location.pathname === link ? 'active' : ''}
                    sx={{
                      textTransform: 'none',
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'flex-start',
                      alignItems: 'center',
                      position: 'relative',
                      '&::after': {
                        content: '" "',
                        position: 'absolute',
                        display: 'block',
                        height: '2px',
                        width: 'calc(100% - 12px)',
                        bottom: '4px',
                        left: '6px',
                        background: colorsConfig.mainGreen,
                        opacity: 0,
                        transformOrigin: '0% 50%',
                        transition: '0.3s all ease-in-out',
                        transform: 'scaleX(0)',
                      },
                      '&:hover': {
                        '&::after': {
                          opacity: 1,
                          transition: '0.3s all ease-in-out',
                          transform: 'scaleX(1)',
                        },
                      },
                      '&.active': {
                        '&::after': {
                          opacity: 1,
                          transform: 'scaleX(1)',
                        },
                      },
                    }}
                    onClick={() => {
                      document.getElementById(link).scrollIntoView({ behavior: 'smooth' })
                    }}
                  >
                    <Typography
                      variant="body1"
                      sx={{
                        color: colorsConfig.mainBlack,
                        fontSize: '16px',
                        fontFamily: '"Helvetica", sans-serif',
                      }}
                    >
                      {t(title)}
                    </Typography>
                  </Button>
                </Box>
              )
            })}
          </>
        ) : null}
      </Box>
    </Box>
  )
}

export default HelpSidebar
