import React from 'react'
import Tooltip from '@material-ui/core/Tooltip'
import { styled, tooltipClasses } from '@mui/material'
import { colorsConfig } from '../../config/themeConfig'
import { useTranslation } from 'react-i18next'

const UITooltip = ({ text, children, ...props }) => {
  const { t } = useTranslation()

  const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(() => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: colorsConfig.white,
      color: colorsConfig.mainBlack,
      boxShadow: '0 0 12px 0 rgba(0,0,0,.15)',
      fontSize: '14px',
    },
    [`& .${tooltipClasses.arrow}`]: {
      color: colorsConfig.white,
    },
  }))

  return (
    <LightTooltip arrow placement="top" title={t(text)} {...props}>
      {children}
    </LightTooltip>
  )
}

export default UITooltip
