import React from 'react'
import { Checkbox } from '@mui/material'
import { colorsConfig } from '../../config/themeConfig'

const UICheckbox = ({ sx, ...props }) => {
  return (
    <Checkbox
      sx={{
        '&.Mui-checked': {
          '& svg': {
            fill: colorsConfig.mainBlue,
          },
        },
        ...sx,
      }}
      {...props}
    />
  )
}

export default UICheckbox
