import React from 'react'
import UIModal from '../../../ui/UIModal/UIModal'
import { Stack } from '@mui/material'
import UITextHeadlineLarge from '../../../ui/UIText/UITextHeadlineLarge'
import ManageLocationForm from '../../Forms/FormTemplates/ManageLocationForm/ManageLocationForm'

const AddLocationModal = ({ open, setOpen, onSubmit, initialAddressType }) => {
  return (
    <UIModal width="800px" open={open} setOpen={setOpen}>
      <Stack gap="30px">
        <UITextHeadlineLarge text="modal_title_add_address" />
        <ManageLocationForm
          onClose={() => setOpen(false)}
          onSubmit={onSubmit}
          initialAddressType={initialAddressType}
        />
      </Stack>
    </UIModal>
  )
}

export default AddLocationModal
