import React, { useEffect } from 'react'
import { Stack } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { setCurrentPageTitle } from '../../store/features/authSlice'
import Banners from '../VirtualGarage/Banners'
import UIPaper from '../../ui/UIPaper/UIPaper'
import UITableControls from '../../ui/UITable/UITableControls'
import UITable from '../../ui/UITable/UITable'
import { carsTableHead } from '../../utils/constants/tables/table.constants'
import UIPreloader from '../../ui/UIPreloader/UIPreloader'
import { getCars } from '../../store/features/cars/cars.actions'
import { getCarsTableData } from '../../utils/helpers/tables/table.helpers'
import UITablePagination from '../../ui/UITable/UITablePagination'
import { setCarsParams } from '../../store/features/cars/cars.reducer'
import UIButton from '../../ui/UIButtons/UIButton'
import { UIVariant } from '../../utils/constants/ui.constants'
import { AddRounded } from '@mui/icons-material'
import { colorsConfig } from '../../config/themeConfig'
import { useNavigate } from 'react-router-dom'

const VirtualGarage = () => {
  const isLoading = useSelector((state) => state.cars.isLoading)
  const cars = useSelector((state) => state.cars.data)
  const params = useSelector((state) => state.cars.params)
  const total = useSelector((state) => state.cars.total)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    dispatch(getCars())
  }, [params])

  useEffect(() => {
    dispatch(setCurrentPageTitle('page_title_virtual_garage'))
  }, [])

  return (
    <Stack gap="30px">
      <Banners />
      <Stack direction="row" justifyContent="flex-end">
        <UIButton
          variant={UIVariant.accent}
          text="button_add_vehicle"
          prefixIcon={<AddRounded sx={{ fill: colorsConfig.mainBlack }} />}
          onClick={() => navigate('/add-new-vehicle')}
        />
      </Stack>
      <UIPaper>
        <Stack gap="30px">
          <UITableControls
            title="virtual_garage_p_my_fleet"
            searchValue={params.searchWithOR}
            setSearchValue={(val) =>
              dispatch(setCarsParams({ ...params, page: 1, searchWithOR: val }))
            }
            statusFilterProps={{
              options: [],
              value: params.combinedStatus,
              onChange: (val) =>
                dispatch(setCarsParams({ ...params, page: 1, combinedStatus: val })),
            }}
          />
          <Stack gap="16px" sx={{ position: 'relative' }}>
            {isLoading && <UIPreloader text="preloader_loading_cars" />}
            <UITable
              head={carsTableHead}
              content={getCarsTableData(cars)}
              emptyText="placeholder_cars_empty"
              sort={params.sort}
              setSort={(val) => dispatch(setCarsParams({ ...params, page: 1, sort: val }))}
            />
            {total !== 0 && (
              <UITablePagination
                count={total}
                page={params?.page}
                onPageChange={(e, value) =>
                  dispatch(
                    setCarsParams({
                      ...params,
                      page: value,
                    }),
                  )
                }
                rowsPerPage={params?.itemsPerPage}
                onRowsPerPageChange={(e) =>
                  dispatch(
                    setCarsParams({
                      ...params,
                      page: 1,
                      itemsPerPage: e.target.value,
                    }),
                  )
                }
              />
            )}
          </Stack>
        </Stack>
      </UIPaper>
    </Stack>
  )
}

export default VirtualGarage
