import React, { useRef, useState } from 'react'
import { Box, Button, IconButton, Popover, Stack } from '@mui/material'
import iconFilter from '../../assests/img/icons/icon-filter.svg'
import { Close } from '@mui/icons-material'
import { colorsConfig } from '../../config/themeConfig'
import UITextRegular from '../UIText/UITextRegular'

const UITableStatusFilter = ({
  options,
  onChange,
  value,
  filterText = 'form_input_label_filter_statuses',
}) => {
  const ref = useRef(null)
  const [showFilters, setShowFilters] = useState(false)

  return (
    <Box
      ref={ref}
      sx={{
        position: 'relative',
      }}
    >
      <Button
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          px: '17px',
          py: '11.2px',
          border: '1px solid',
          borderColor: colorsConfig.mainBlack,
          textTransform: 'none',
        }}
        onClick={() => {
          setShowFilters(!showFilters)
        }}
      >
        <Box
          sx={{
            width: '18px',
            height: '18px',
          }}
        >
          <img src={iconFilter} alt="" />
        </Box>
        <UITextRegular
          fontWeight="700"
          sx={{
            marginLeft: '10px',
            whiteSpace: 'nowrap',
          }}
          text={filterText}
        />
      </Button>
      {showFilters && (
        <Popover
          anchorEl={ref?.current}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={showFilters}
          onClose={() => setShowFilters(false)}
        >
          <Box
            className="active"
            // className={showFilters ? 'active' : ''}
            sx={{
              // position: 'absolute',
              width: 'auto',
              minWidth: '200px',
              maxHeight: '400px',
              overflow: 'auto',
              background: '#F7FAFB',
              zIndex: 20,
              opacity: 0,
              transform: 'scale(0.5)',
              transformOrigin: '100% 0%',
              pointerEvents: 'none',
              borderRadius: '4px',
              transition: '0.25s all ease-in-out',
              p: '4px',
              paddingTop: '28px',
              pb: '12px',
              '&.active': {
                opacity: 1,
                transform: 'scale(1)',
                pointerEvents: 'auto',
                transition: '0.3s all ease-in-out',
                boxShadow: '0 0 10px 0 rgba(0,0,0,.1)',
              },
            }}
          >
            <IconButton
              sx={{
                position: 'absolute',
                top: '2px',
                right: '2px',
                width: '35px',
                height: '35px',
                zIndex: 10,
              }}
              onClick={() => {
                setShowFilters(false)
              }}
            >
              <Close
                sx={{
                  color: colorsConfig.mainRed,
                  fontSize: '20px',
                }}
              />
            </IconButton>
            <Stack gap="2px">
              {options.map((item) => {
                return (
                  <Button
                    key={item?.value}
                    className={value === item?.value ? 'active' : ''}
                    sx={{
                      background: '#F7FAFB',
                      color: colorsConfig.mainBlack,
                      textTransform: 'none',
                      textAlign: 'left',
                      justifyContent: 'flex-start',
                      fontWeight: 400,
                      paddingLeft: '30px',
                      paddingRight: '30px',
                      whiteSpace: 'nowrap',
                      '&:hover': {
                        background: 'white',
                      },
                      '&.active': {
                        background: 'white',
                      },
                    }}
                    onClick={() => {
                      setShowFilters(false)
                      onChange(item?.value)
                    }}
                  >
                    <Stack direction="row" gap="4px" alignItems="center">
                      <UITextRegular
                        sx={{
                          textTransform: 'none',
                        }}
                        text={item?.label}
                      />
                      {item?.count !== undefined && item?.count !== null && (
                        <UITextRegular
                          fontWeight="700"
                          sx={{ position: 'relative', mt: '-0.5px' }}
                          text={`(${item?.count})`}
                        />
                      )}
                    </Stack>
                  </Button>
                )
              })}
            </Stack>
          </Box>
        </Popover>
      )}
    </Box>
  )
}

export default UITableStatusFilter
