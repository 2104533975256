import React, { useState } from 'react'
import { Box, Stack } from '@mui/material'
import { colorsConfig } from '../../../config/themeConfig'
import IconAccountCircle from '../../../assests/img/icons/icon-account-circle.svg'
import UITextHeadlineSmall from '../../../ui/UIText/UITextHeadlineSmall'
import UICloseButton from '../../../ui/UIIconButtons/UICloseButton'
import UITextRegular from '../../../ui/UIText/UITextRegular'
import TextFieldSmallEdible from '../../../ui/TextFieldSmallEdible'
import ButtonLinkWA from '../../Buttons/Social/ButtonLinkWA'
import ButtonLinkTG from '../../Buttons/Social/ButtonLinkTG'
import UIButton from '../../../ui/UIButtons/UIButton'
import { UISize } from '../../../utils/constants/ui.constants'
import { EmailRounded } from '@mui/icons-material'
import { useDispatch, useSelector } from 'react-redux'
import UIPreloader from '../../../ui/UIPreloader/UIPreloader'
import { formErrors } from '../../Forms/FormTemplates/formErrors'
import { createDealerSupportTicket } from '../../../store/features/settings/settings.actions'
import { toast } from 'react-toastify'

const AskOperatorPopover = ({ open, setOpen }) => {
  const isSaving = useSelector((state) => state.settings.createSupportTicket.isLoading)
  const dispatch = useDispatch()

  const [message, setMessage] = useState('')
  const [showErrors, setShowErrors] = useState(false)

  const handleSubmit = async () => {
    if (message === '') {
      setShowErrors(true)
      return
    }
    const res = await dispatch(createDealerSupportTicket({ data: { message } }))
    if (createDealerSupportTicket.fulfilled.match(res)) {
      toast.success('We got your message! We will reply as soon as possible')
      setOpen(false)
    }
  }

  return (
    <Box
      sx={{
        position: 'absolute',
        bottom: '0',
        right: '0',
        borderRadius: '6px',
        background: colorsConfig.bgLightBlue,
        px: '25px',
        py: '18px',
        width: '307px',
        boxShadow: '0 0 24px 0 rgba(0,0,0,.1)',
      }}
    >
      {isSaving && <UIPreloader text="Sending..." />}
      <Stack direction="row" gap="8px" alignItems="center" sx={{ mb: '10px' }}>
        <img src={IconAccountCircle} alt="" />
        <UITextHeadlineSmall text="title_ask_operator" />
      </Stack>
      <UITextRegular text="p_request_help" sx={{ mb: '16px' }} />
      <TextFieldSmallEdible
        fullWidth
        type="textarea"
        multiline
        rows={10}
        sx={{ minHeight: '227px' }}
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        error={showErrors && message === ''}
        helperText={showErrors && message === '' ? formErrors.required : ''}
      />
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Stack direction="row" alignItems="center" gap="4px">
          <ButtonLinkWA title="Send via WhatsApp" message={message} recipient="+971509601872" />
          <ButtonLinkTG title="Send via Telegram" message={message} recipient="Ilyasq1" />
        </Stack>
        <UIButton
          disabled={showErrors && message === ''}
          prefixIcon={<EmailRounded />}
          size={UISize.sm}
          text="button_send"
          onClick={() => handleSubmit()}
        />
      </Stack>
      <UICloseButton
        sx={{ position: 'absolute', top: '5px', right: '5px' }}
        onClick={() => setOpen(false)}
      />
    </Box>
  )
}

export default AskOperatorPopover
