import { privateRequest, publicRequest } from '../../utils/request'
import { customerAppraisalHeaders } from '../api'

class CustomerAppraisalsApiService {
  #api

  #publicApi

  #endPoints

  #abortControllers

  constructor({ api, publicApi }) {
    this.#api = api
    this.#publicApi = publicApi
    this.#endPoints = {
      collection: '/customer-appraisals',
      instance: (id) => `/customer-appraisals/${id}`,
      sendEmail: (customerAppraisalIdUrl) =>
        `/customer-appraisals/${customerAppraisalIdUrl}/send-email`,
      cars: '/cars',
      car: (id) => `/cars/${id}`,
      workflow: {
        startCustomerAppraisal: (id) => `/cars/${id}/workflow/start-customer-appraisal`,
        completeCustomerAppraisal: (id) => `/cars/${id}/workflow/complete-customer-appraisal`,
      },
    }
    this.#abortControllers = {}
  }

  getCustomerAppraisals = async (params, prefix = 'getCustomerAppraisals') => {
    this.#abortControllers[prefix]?.abort()
    this.#abortControllers[prefix] = new AbortController()
    const response = await this.#api.get(this.#endPoints.collection, {
      params,
      signal: this.#abortControllers[prefix]?.signal,
    })
    return response
  }

  getCustomerAppraisalAsCustomer = async (token) => {
    const response = await this.#api.get(this.#endPoints.collection, {
      params: { pagination: false },
      headers: customerAppraisalHeaders(token),
    })
    return response
  }

  createCustomerAppraisal = async (data) => {
    const response = await this.#api.post(this.#endPoints.collection, data)
    return response
  }

  sendAppraisalLinkByEmail = async (customerAppraisalIdUrl) => {
    const response = await this.#api.post(this.#endPoints.sendEmail(customerAppraisalIdUrl), {})
    return response
  }

  getCustomerAppraisal = async () => {
    //const response = await this.#api.get(this.#endPoints.instance())
  }

  createCustomerCar = async (data, token) => {
    const response = await this.#publicApi.post(this.#endPoints.cars, data, {
      headers: customerAppraisalHeaders(token),
    })
    return response
  }

  updateCustomerCar = async (carId, data, token) => {
    const response = await this.#api.put(this.#endPoints.car(carId), data, {
      headers: customerAppraisalHeaders(token),
    })
    return response
  }

  startAppraisal = async (carId) => {
    const response = await this.#api.post(
      this.#endPoints.workflow.startCustomerAppraisal(carId),
      {},
    )
    return response
  }

  completeAppraisal = async (carId) => {
    const response = await this.#api.post(
      this.#endPoints.workflow.completeCustomerAppraisal(carId),
      {},
    )
    return response
  }

  deleteAppraisal = async (customerAppraisalId) => {
    const response = await this.#api.delete(this.#endPoints.instance(customerAppraisalId))
    return response
  }
}

const customerAppraisalsApiService = new CustomerAppraisalsApiService({
  api: privateRequest,
  publicApi: publicRequest,
})

export default customerAppraisalsApiService
