import React, { useEffect } from 'react'
import ComingSoon from '../../components/ComingSoon'
import { useDispatch } from 'react-redux'
import { setCurrentPageTitle } from '../../store/features/authSlice'

const Marketplace = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setCurrentPageTitle('page_title_marketplace'))
  }, [])

  return <ComingSoon />
}

export default Marketplace
