import React from 'react'
import ColorRadioButton from './ColorRadioButton'
import './ColorRadioGroup.scss'
import Box from '@mui/material/Box'

const ColorRadioGroup = ({ colors, itemsPerRow, allowMultiple, value, setValue, ...props }) => {
  return (
    <Box className="color-radio-group" {...props}>
      {colors.map((item, index) => {
        return (
          <ColorRadioButton
            key={index}
            color={item.color}
            label={item.label}
            image={
              item.specialType
                ? {
                    image: item.image,
                    imageFull: item.imageFull,
                  }
                : null
            }
            itemsPerRow={itemsPerRow}
            allowMultiple={!!allowMultiple}
            value={value}
            setValue={setValue}
            translation={item.translation || ''}
          />
        )
      })}
    </Box>
  )
}

export default ColorRadioGroup
