import React from 'react'
import { Grid } from '@mui/material'
import ControlPointBooleanDepricated from './ControlPointBooleanDepricated'
import ControlPointFile from './ControlPointFile'
import useBreakpoints from '../../../hooks/useBreakpoints'
import ControlPointFileCollection from './ControlPointFileCollection'

const Option = (props) => {
  const { isMinWidth1440 } = useBreakpoints()

  const { fromSingleSubBlock, type, name, blockIndex, subBlockIndex, controlPointIndex, readonly } =
    props

  return (
    <React.Fragment>
      {blockIndex === 0 ? (
        <Grid item xs={10} sm={type === 'boolean' ? 12 : 5} md={type === 'boolean' ? 10 : 2}>
          {type === 'file' ? (
            <ControlPointFile
              name={name}
              blockIndex={blockIndex}
              subBlockIndex={subBlockIndex}
              controlPointIndex={controlPointIndex}
            />
          ) : null}
          {type === 'file_collection' ? (
            <ControlPointFileCollection
              name={name}
              blockIndex={blockIndex}
              subBlockIndex={subBlockIndex}
              controlPointIndex={controlPointIndex}
            />
          ) : null}
          {type === 'boolean' ? (
            <ControlPointBooleanDepricated
              name={name}
              blockIndex={blockIndex}
              subBlockIndex={subBlockIndex}
              controlPointIndex={controlPointIndex}
              readonly={readonly ? readonly : false}
              fromSingleSubBlock={fromSingleSubBlock}
            />
          ) : null}
        </Grid>
      ) : null}
      {blockIndex === 1 ? (
        <Grid item xs={10} md={5}>
          {type === 'file' ? (
            <ControlPointFile
              name={name}
              blockIndex={blockIndex}
              subBlockIndex={subBlockIndex}
              controlPointIndex={controlPointIndex}
            />
          ) : null}
          {type === 'file_collection' ? (
            <ControlPointFileCollection
              name={name}
              blockIndex={blockIndex}
              subBlockIndex={subBlockIndex}
              controlPointIndex={controlPointIndex}
            />
          ) : null}
          {type === 'boolean' ? (
            <ControlPointBooleanDepricated
              name={name}
              blockIndex={blockIndex}
              subBlockIndex={subBlockIndex}
              controlPointIndex={controlPointIndex}
              readonly={readonly ? readonly : false}
              fromSingleSubBlock={fromSingleSubBlock}
              sx={{
                marginLeft: '50px',
                position: 'absolute',
                top: 0,
                right: 0,
              }}
            />
          ) : null}
        </Grid>
      ) : null}
      {blockIndex === 2 ? (
        <React.Fragment>
          {type === 'boolean' ? (
            <ControlPointBooleanDepricated
              name={name}
              blockIndex={blockIndex}
              subBlockIndex={subBlockIndex}
              controlPointIndex={controlPointIndex}
              readonly={readonly ? readonly : false}
            />
          ) : null}
        </React.Fragment>
      ) : null}
    </React.Fragment>
  )
}

export default Option
