import { createCompanyWithManager } from '../company.actions'

export default (builder) => {
  builder
    .addCase(createCompanyWithManager.pending, (state) => {
      state.createWithManager.isLoading = true
    })
    .addCase(createCompanyWithManager.fulfilled, (state, action) => {
      state.createWithManager.isLoading = false
      state.createWithManager.error = null
    })
    .addCase(createCompanyWithManager.rejected, (state, action) => {
      state.createWithManager.isLoading = false
      state.createWithManager.error = action.payload
    })
}
