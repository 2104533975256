import React, { useRef, useState } from 'react'
import { Box, Button, IconButton, styled, Typography } from '@mui/material'
import { privateRequest } from '../../../utils/request'
import { useParams } from 'react-router-dom'
import { API_ENDPOINTS, FILE_TYPES } from '../../../config/default'
import { useDispatch, useSelector } from 'react-redux'
import IconUpload from '../../../assests/img/icons/icon-upload.svg'
import { addCarPhoto } from '../../../store/features/carSlice/carSlice'
import { uploadFile } from '../../../utils/uploadFile'
import { FileUploader } from 'react-drag-drop-files'
import { useTranslation } from 'react-i18next'
import useBreakpoints from '../../../hooks/useBreakpoints'
import TakePhotoButton from './TakePhotoButton'
import { AddAPhoto } from '@mui/icons-material'
import { colorsConfig } from '../../../config/themeConfig'

const CarPhotoItem = ({ carId, image, carView, index, status, refresh }) => {
  const { t } = useTranslation()
  const { smDown } = useBreakpoints()
  const params = useParams()
  const carPhotos = useSelector((state) => state.car.appraisal.foreshortenings)
  const dispatch = useDispatch()

  const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  })

  const handleAddPhoto = async (file) => {
    dispatch(
      addCarPhoto(
        carPhotos.map((item) => {
          if (item.foreshortening === carView) {
            return {
              ...item,
              direct: index,
              status: 'loading',
              newCarPhoto: file,
            }
          }
          return item
        }),
      ),
    )
    const matchForeshortening = carPhotos.find(
      (item) => item.foreshortening === carView && item.carPhoto,
    )
    if (matchForeshortening) {
      await privateRequest.delete(matchForeshortening.carForeshortening)
    }
    const fileId = await uploadFile(file, FILE_TYPES.foreshortening)
    const formData = {
      foreshortenings: [
        {
          foreshortening: carView,
          photo: fileId,
          direct: index + 1,
        },
      ],
    }
    await privateRequest
      .post(`/cars/${carId ?? params.id}${API_ENDPOINTS.carPhotosBatch}`, formData)
      .then((response) => {
        refresh()
        // dispatch(addCarPhoto(carPhotos.map(item => {
        //     if (item.foreshortening === carView) {
        //         return {
        //             ...item,
        //             status: 'idle',
        //             newCarPhoto: null,
        //             carForeshortening: response.data['@id'],
        //             carPhotos: response.data.photos
        //         }
        //     }
        //     return item
        // })))
      })
  }

  return smDown ? (
    <Box
      sx={{
        flex: 1,
        width: '100%',
        height: 'auto',
        minHeight: '104px',
        borderRadius: '6px',
        overflow: 'hidden',
        position: 'relative',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '15px',
      }}
    >
      <IconButton component="label" sx={{ zIndex: 999 }}>
        <AddAPhoto sx={{ fill: colorsConfig.white, fontSize: '40px' }} />
        <VisuallyHiddenInput type="file" onChange={(e) => handleAddPhoto(e.target.files[0])} />
      </IconButton>
      <IconButton component="label" sx={{ zIndex: 999 }}>
        <img width="37px" src={IconUpload} alt="" />
        <VisuallyHiddenInput type="file" onChange={(e) => handleAddPhoto(e.target.files[0])} />
      </IconButton>
      <Box
        className="overlay-box"
        sx={{
          overflow: 'hidden',
          maxHeight: '100%',
          borderRadius: '6px',
          position: 'absolute',
          top: 0,
          left: 0,
        }}
      >
        <Box className="overlay-box-inner" sx={{ borderRadius: '6px' }}>
          <img
            src={image}
            alt=""
            style={{
              width: '100%',
              borderRadius: '6px',
            }}
          />
        </Box>
      </Box>
    </Box>
  ) : (
    <FileUploader
      handleChange={handleAddPhoto}
      name="file"
      types={['JPG', 'PNG', 'jpeg']}
      hoverTitle="Drop here"
      label="Upload or drop all the photos here"
      maxSize={10}
      children={
        <Box
          sx={{
            height: smDown ? 'auto' : '160px',
            borderRadius: '6px',
            overflow: 'hidden',
            position: 'relative',
          }}
        >
          <Button
            component="label"
            sx={{
              width: '100%',
              height: '75px',
              position: 'absolute',
              top: '50%',
              left: '0',
              mt: '-37.5px',
              zIndex: 2,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '15px',
              cursor: 'pointer',
              textTransform: 'none',
            }}
          >
            {status === 'loading' ? (
              <div className="lds-dual-ring"></div>
            ) : (
              <React.Fragment>
                <img width="37px" src={IconUpload} alt="" />
                <Typography
                  sx={{
                    fontSize: '18px',
                    fontWeight: 700,
                    fontFamily: '"Helvetica", sans-serif',
                    color: 'white',
                  }}
                >
                  {t('button_upload')}
                </Typography>
              </React.Fragment>
            )}
          </Button>
          <div
            className="overlay-box"
            style={{ overflow: 'hidden', maxHeight: '100%', borderRadius: '6px' }}
          >
            <div className="overlay-box-inner" style={{ borderRadius: '6px' }}>
              <img
                src={image}
                alt=""
                style={{
                  width: '100%',
                  borderRadius: '6px',
                }}
              />
            </div>
          </div>
        </Box>
      }
    />
  )
}

export default CarPhotoItem
