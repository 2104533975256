import React, { useEffect } from 'react'
import { Form, Formik } from 'formik'
import { Grid } from '@mui/material'
import FormInput from '../../FormComponents/FormInput'
import FormInputAutocomplete from '../../FormComponents/FormInputAutocomplete/FormInputAutocomplete'
import FormSelect from '../../FormComponents/FormSelect'
import FormSelectCountry from '../../FormComponents/FormSelectCountry'
import UIButton from '../../../../ui/UIButtons/UIButton'
import { UIVariant } from '../../../../utils/constants/ui.constants'
import { useDispatch, useSelector } from 'react-redux'
import { getAddressTypes } from '../../../../store/features/references/references.actions'
import {
  getAddressTypesOptions,
  getStatesOptionList,
} from '../../../../utils/helpers/optionsList.helpers'
import { getRegionNaming } from '../../../../utils/helpers/common.helpers'
import { getCityByPostcode } from '../../../../utils/helpers/geo.helpers'
import {
  getManageLocationFormInitialState,
  manageLocationFormValidationSchema,
} from './manageLocationForm.helpers'
import {
  createCompanyAddress,
  updateCompanyAddress,
} from '../../../../store/features/settings/settings.actions'
import UIPreloader from '../../../../ui/UIPreloader/UIPreloader'
import { useTranslation } from 'react-i18next'

const ManageLocationForm = ({ onClose, onSubmit, initialData, initialAddressType = 'None' }) => {
  const { t } = useTranslation()
  const isLoadingAddressTypes = useSelector((state) => state.ref.addressTypes.isLoading)
  const addressTypes = useSelector((state) => state.ref.addressTypes.data)
  const isSaving = useSelector((state) => state.settings.companyAddresses.create.isLoading)
  const isUpdating = useSelector((state) => state.settings.companyAddresses.update.isLoading)
  const dispatch = useDispatch()

  const onChangePostcode = (value, props) =>
    getCityByPostcode(props.values['country']?.code, value, (val) =>
      props.setFieldValue('city', val),
    )

  const onChangeCountry = (value, props) =>
    getCityByPostcode(value?.code, props.values['postcode'], (val) =>
      props.setFieldValue('city', val),
    )

  const handleSubmit = async (values) => {
    const data = {
      ...values,
      country: values.country?.label,
      type: values.type === 'all' ? null : values.type,
    }
    if (initialData) {
      const res = await dispatch(
        updateCompanyAddress({ addressId: initialData?.address?.id, data }),
      )
      if (updateCompanyAddress.fulfilled.match(res)) {
        onSubmit()
        onClose()
      }
    } else {
      const res = await dispatch(createCompanyAddress({ data }))
      if (createCompanyAddress.fulfilled.match(res)) {
        onSubmit(res.payload)
        onClose()
      }
    }
  }

  useEffect(() => {
    if (addressTypes?.length === 0) {
      dispatch(getAddressTypes())
    }
  }, [addressTypes])

  return (
    <Formik
      initialValues={getManageLocationFormInitialState(initialData, initialAddressType)}
      onSubmit={handleSubmit}
      validationSchema={manageLocationFormValidationSchema}
      enableReinitialize
    >
      {(props) => (
        <Form noValidate>
          {(isSaving || isUpdating) && <UIPreloader text="preloader_saving_data" />}
          <Grid container spacing="24px">
            <Grid item xs={4}>
              <FormSelect
                required
                label="form_input_label_location_type"
                name="type"
                id="type"
                options={getAddressTypesOptions(addressTypes)}
                disabled={isLoadingAddressTypes}
              />
            </Grid>
            <Grid item xs={4}>
              <FormSelectCountry
                required
                name="country"
                label="form_input_label_country"
                onChangeCallback={(val) => onChangeCountry(val, props)}
              />
            </Grid>
            <Grid item xs={4}>
              <FormInputAutocomplete
                required
                name="state"
                label={getRegionNaming(props.values['country']?.code)}
                freeSolo
                options={getStatesOptionList(props.values['country']?.code, t)}
              />
            </Grid>
            <Grid item xs={4}>
              <FormInput
                required
                label="form_input_label_postcode"
                name="postcode"
                id="postcode"
                onChangeCallback={(val) => onChangePostcode(val, props)}
              />
            </Grid>
            <Grid item xs={4}>
              <FormInput required label="form_input_label_city" name="city" id="city" />
            </Grid>
            <Grid item xs={6}>
              <FormInput
                required
                label="form_input_label_address_line1"
                name="addressLine1"
                id="addressLine1"
              />
            </Grid>
            <Grid item xs={6}>
              <FormInput
                label="form_input_label_address_line2"
                name="addressLine2"
                id="addressLine2"
              />
            </Grid>
            <Grid item xs={12}>
              <FormInput label="form_input_label_comment" multiline rows={4} name="comment" />
            </Grid>
            <Grid item xs={12} display="flex" justifyContent="flex-end" gap="10px">
              <UIButton text="button_cancel" variant={UIVariant.hollow} onClick={() => onClose()} />
              <UIButton
                type="submit"
                disabled={(props.submitCount > 0 && !props.isValid) || isSaving}
                text={initialData ? 'button_change' : 'button_add'}
              />
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  )
}

export default ManageLocationForm
