import React from 'react'
import IconAppraisalDocument from '../../assests/img/icons/icon-appraisal-document.svg'
import { Box, IconButton } from '@mui/material'
import { CancelRounded } from '@mui/icons-material'
import { colorsConfig } from '../../config/themeConfig'

const UIUploadedFile = ({ sx, handleRemove }) => {
  return (
    <Box
      //  className={xsOnly ? 'xs-only' : ''}
      // key={index}
      sx={{
        mt: '27px',
        width: '120px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        cursor: 'pointer',
        '&.xs-only': {
          width: 'calc(25% - 5px)',
        },
        ...sx,
      }}
      onClick={() => {
        //handleDownloadFile(item, index)
      }}
    >
      <img
        src={IconAppraisalDocument}
        alt=""
        style={{
          width: '75%',
        }}
      />
      <IconButton
        sx={{
          position: 'absolute',
          top: '-4px',
          right: '0px',
          width: '20px',
          height: '20px',
          opacity: '1',
          transition: '0.25s all ease-in-out',
          '&:hover': {
            transition: '0.25s all ease-in-out',
            opacity: '1',
          },
        }}
        color="error"
        onClick={(e) => {
          e.stopPropagation()
          handleRemove()
        }}
      >
        <CancelRounded
          sx={{
            fontSize: '18px',
            fill: colorsConfig.mainRed,
          }}
        />
      </IconButton>
    </Box>
  )
}

export default UIUploadedFile
