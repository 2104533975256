export const warrantyApplicationsTableCells = [
  {
    title: 'table_col_number',
    sortable: false,
  },
  {
    title: 'table_col_brand_model',
    sortable: false,
  },
  {
    title: 'table_col_created_at',
    sortable: false,
  },
]

export const warrantyApplicationsActivatedTableCells = [
  {
    title: 'table_col_number',
    sortable: false,
  },
  {
    title: 'table_col_brand_model',
    sortable: false,
  },
  {
    title: 'table_col_package',
    sortable: false,
  },
  {
    title: 'table_col_expires_on',
    sortable: false,
  },
]

export const warrantyProductsTableHeadCells = [
  {
    title: 'ID',
    sortable: false,
  },
  {
    title: 'Name',
    sortable: false,
  },
  {
    title: 'Price',
    sortable: false,
  },
  {
    title: 'Car Age (max)',
    sortable: false,
  },
  {
    title: 'Mileage (max)',
    sortable: false,
  },
]

export const coveredComponentsTableHeadCells = [
  {
    title: 'ID',
    sortable: false,
  },
  {
    title: 'Name',
    sortable: false,
  },
  {
    title: 'Created at',
    sortable: false,
  },
]
