import React, { useState } from 'react'
import UITableActionsList from '../../../../ui/UITable/UITableActionsList'
import { useDispatch } from 'react-redux'
import {
  getLinkedPolicies,
  getPoliciesStatusCount,
} from '../../../../store/features/rviOperator/rviOperator.actions'
import CancelPolicyModal from '../Modals/CancelPolicyModal'
import CloseRunningContractModal from '../Modals/CloseRunningContractModal'

const LinkedPoliciesActionItems = ({ data }) => {
  const dispatch = useDispatch()

  const [cancelPolicyModal, setCancelPolicyModal] = useState(false)
  const [closeRunningContractModal, setCloseRunningContractModal] = useState(false)

  const onCancelPolicy = () => {
    dispatch(getLinkedPolicies())
    dispatch(getPoliciesStatusCount())
    setCancelPolicyModal(false)
  }

  const onClosePolicy = () => {
    dispatch(getLinkedPolicies())
    dispatch(getPoliciesStatusCount())
    setCloseRunningContractModal(false)
  }

  return (
    <>
      <UITableActionsList
        actions={[
          {
            name: 'Close running contract',
            handleAction: () => setCloseRunningContractModal(true),
          },
          {
            name: 'Cancel policy',
            handleAction: () => {
              setCancelPolicyModal(true)
            },
          },
        ]}
      />
      {cancelPolicyModal && (
        <CancelPolicyModal
          open={cancelPolicyModal}
          setOpen={setCancelPolicyModal}
          data={data?.initialData}
          onClose={onCancelPolicy}
        />
      )}
      {closeRunningContractModal && (
        <CloseRunningContractModal
          open={closeRunningContractModal}
          setOpen={setCloseRunningContractModal}
          data={data?.initialData}
          onClose={onClosePolicy}
        />
      )}
    </>
  )
}

export default LinkedPoliciesActionItems
