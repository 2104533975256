import IconVirtualGarage from '../../../assests/img/icons/icon-status-virtual-garage.svg'
import IconAppraisal from '../../../assests/img/icons/icon-status-appraisal.svg'
import IconAuction from '../../../assests/img/icons/icon-status-auction.svg'
import IconAwaitingPayment from '../../../assests/img/icons/icon-status-awaiting-payment.svg'
import IconTransportation from '../../../assests/img/icons/icon-status-transportation-in-progress.svg'
import IconCarSold from '../../../assests/img/icons/icon-status-car-sold.svg'
import IconAppraisalFailed from '../../../assests/img/icons/icon-status-appraisal-fail.svg'
import IconAwaitingPaymentFailed from '../../../assests/img/icons/icon-status-awaiting-payment-failed.svg'

export const carStatusIcons = [
  {
    icon: IconVirtualGarage,
    statuses: ['car_workflow.new', 'car_workflow.ready_for_sale'],
  },
  {
    icon: IconAppraisal,
    statuses: [
      'car_workflow.appraisal_on_site',
      'car_workflow.quality_check',
      'car_workflow.requested_appraisal_compound',
      'car_workflow.awaiting_appraisal_compound',
      'car_workflow.appraisal_compound',
    ],
  },
  {
    icon: IconAuction,
    statuses: ['auction_workflow.pending', 'auction_workflow.active', 'auction_workflow.completed'],
  },
  {
    icon: IconAwaitingPayment,
    statuses: [
      'deal_workflow.pending',
      'deal_workflow.invoice_sent',
      'deal_workflow.money_received',
      'deal_workflow.money_payed',
      'deal_workflow.',
    ],
  },
  {
    icon: IconTransportation,
    statuses: ['deal_workflow.transportation_in_progress'],
  },
  {
    icon: IconCarSold,
    statuses: ['car_workflow.sale_succeed'],
  },
  {
    icon: IconAppraisalFailed,
    statuses: [
      'car_workflow.moderation_failed',
      'car_workflow.requested_appraisal_compound_failed',
    ],
  },
  {
    icon: IconAwaitingPaymentFailed,
    statuses: ['deal_workflow.cancel'],
  },
]

export const getIconByCombinedStatus = (combinedStatus) => {
  return (
    carStatusIcons.find((e) => !!e.statuses.find((status) => status === combinedStatus))?.icon ??
    null
  )
}
