import React from 'react'
import { Button, CircularProgress } from '@mui/material'
import { colorsConfig } from '../../config/themeConfig'
import { useTranslation } from 'react-i18next'
import { FONT, UISize, UIVariant } from '../../utils/constants/ui.constants'

const UITextButton = ({
  text,
  children,
  sx,
  prefixIcon,
  variant = UIVariant.primary,
  size = UISize.md,
  isLoading,
  preloaderText = '',
  ...props
}) => {
  const { t } = useTranslation()

  return (
    <Button
      sx={{
        color: colorsConfig.mainBlue,
        fontFamily: FONT.PRIMARY,
        fontSize: '16px',
        fontWeight: 400,
        textTransform: 'none',
        display: 'flex',
        alignItems: 'center',
        whiteSpace: 'nowrap',
        px: '20px',
        borderRadius: '4px',
        gap: '8px',
        '&.Mui-disabled': {
          color: colorsConfig.fadedBlue,
        },
        ...(variant === UIVariant.danger && {
          color: colorsConfig.darkRed,
          '&:hover': {
            background: colorsConfig.redTransparent,
          },
        }),
        ...(size === UISize.sm && {
          fontSize: '14px',
          px: '8px',
          py: '2px',
          minHeight: 'auto',
          minWidth: 'auto',
          height: '28px',
          lineHeight: 'normal',
        }),
        ...(isLoading && {
          opacity: '0.7',
          pointerEvents: 'none',
        }),
        ...sx,
      }}
      {...props}
    >
      {isLoading ? (
        <CircularProgress
          size={20}
          thickness={4}
          sx={{ color: colorsConfig.mainBlue, strokeWidth: '4px' }}
        />
      ) : prefixIcon ? (
        prefixIcon
      ) : null}
      {isLoading ? t(preloaderText) : text ? t(text) : children}
    </Button>
  )
}

export default UITextButton
