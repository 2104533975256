import { createAsyncThunk } from '@reduxjs/toolkit'
import carService from '../../../api/carService/carApiService'
import { adaptParams } from '../../../api/api'

export const getCars = createAsyncThunk(
  'cars/getCars',
  async (_, { rejectWithValue, getState }) => {
    try {
      const params = getState().cars.params
      const response = await carService.getCars(adaptParams(params))
      return response.data
    } catch (e) {
      return rejectWithValue(e)
    }
  },
)
