import { privateRequest } from '../../utils/request'

class CarService {
  #api

  #endPoints

  #abortControllers

  constructor({ api }) {
    this.#api = api
    this.#endPoints = {
      cars: '/cars',
      independentCarInfo: (id) => `/independent-car-infos/${id}`,
      makes: '/reference/catalog/makes',
      models: '/reference/catalog/models',
      attachAppraisalDocument: (id) => `/cars/${id}/attach-appraisal-document`,
      workflows: {
        confirmToDraft: (id) => `/cars/${id}/workflow/confirm-to-draft`,
        sendToDraftCompany: (id) => `/cars/${id}/workflow/send-to-draft-company`,
      },
      enums: '/reference/enum-details',
      vinProvider: '/vin-provider/car-details',
      pdfAppraisal: (id) => `/cars/${id}/pdf-appraisal-result`,
      carFiles: (id) => `/cars/${id}/files/download`,
    }
    this.#abortControllers = {}
  }

  getCars = async (params, prefix = 'getCars') => {
    this.#abortControllers[prefix]?.abort()
    this.#abortControllers[prefix] = new AbortController()
    const response = await this.#api.get(this.#endPoints.cars, {
      params,
      signal: this.#abortControllers[prefix]?.signal,
    })
    return response
  }

  updateIndependentCarInfo = async (independentCarInfoId, data) => {
    const response = await this.#api.put(
      this.#endPoints.independentCarInfo(independentCarInfoId),
      data,
    )
    return response
  }

  createCar = async (data) => {
    const response = await this.#api.post(this.#endPoints.cars, data)
    return response
  }

  updateCar = async (car, data) => {
    const response = await this.#api.put(car, data)
    return response
  }

  getMakes = async (params) => {
    const response = await this.#api.get(this.#endPoints.makes, {
      params,
    })
    return response
  }

  getModels = async (params) => {
    const response = await this.#api.get(this.#endPoints.models, {
      params,
    })
    return response
  }

  attachAppraisalDocument = async (carId, data) => {
    const response = await this.#api.put(this.#endPoints.attachAppraisalDocument(carId), data)
    return response
  }

  changeWorkflowConfirmToDraft = async (carId, data) => {
    const response = await this.#api.post(this.#endPoints.workflows.confirmToDraft(carId), data)
    return response
  }

  changeWorkflowSendToDraftCompany = async (carId) => {
    const response = await this.#api.post(this.#endPoints.workflows.sendToDraftCompany(carId), {})
    return response
  }

  getCarDetailsFromVIN = async (data) => {
    const response = await this.#api.post(this.#endPoints.vinProvider, data)
    return response
  }

  getPdfAppraisalResult = async (carId) => {
    const response = await this.#api.post(this.#endPoints.pdfAppraisal(carId), {})
    return response
  }

  getCarFiles = async (carId) => {
    const response = await this.#api.get(this.#endPoints.carFiles(carId))
    return response
  }
}

const carService = new CarService({ api: privateRequest })

export default carService
