export const TABLE_CELL_TYPE = {
  TEXT: 'text',
  COMPONENT: 'component',
  IMAGE: 'image',
  TEXT_WITH_VIN: 'text-with-vin',
  ACTION: 'action',
}

export const carsTableHead = [
  {
    title: 'table_col_number',
    sortable: true,
    key: 'referenceNumber',
  },
  {
    title: 'table_col_image',
    sortable: false,
    key: 'image',
  },
  {
    title: 'table_col_brand_model',
    sortable: false,
    key: 'brandModel',
  },
  {
    title: 'table_col_location',
    sortable: false,
    key: 'location',
  },
  {
    title: 'table_col_days_in_stock',
    sortable: true,
    key: 'createdAt',
    invertSorting: true,
  },
  {
    title: 'table_col_status',
    sortable: false,
    key: 'status',
  },
  {
    title: 'table_col_action',
    sortable: false,
    key: 'action',
  },
]

export const policiesOperatorTableHead = [
  {
    title: 'Upload date',
    sortable: true,
    key: 'createdAt',
  },
  {
    title: 'Policy number',
  },
  {
    title: 'Brand/Model',
  },
  {
    title: 'Dealer',
  },
  {
    title: 'Start date',
  },
  {
    title: 'End date',
  },
  {
    title: 'Status',
  },
  {
    title: 'Actions',
  },
]

export const closedPoliciesTableHead = [
  {
    title: 'Upload date',
    sortable: true,
    key: 'createdAt',
  },
  {
    title: 'Policy number',
  },
  {
    title: 'Brand/Model',
  },
  {
    title: 'Dealer',
  },
  {
    title: 'Start date',
  },
  {
    title: 'End date',
  },
  {
    title: 'Closing reason',
  },
  {
    title: 'Actions',
  },
]

export const closedPoliciesDealerTableHead = [
  {
    title: 'Policy number',
  },
  {
    title: 'Brand/Model',
  },
  {
    title: 'Start date',
  },
  {
    title: 'End date',
  },
  {
    title: 'Closing reason',
  },
]

export const awaitingRestitutionPoliciesHead = [
  {
    title: 'Policy number',
  },
  {
    title: 'Brand/Model',
  },
  {
    title: 'Dealer',
  },

  // {
  //   title: 'Location',
  // },
  // {
  //   title: 'Mileage',
  // },
  {
    title: 'Vehicle expertise',
  },
  {
    key: 'restitutionStartDate',
    title: 'Restitution date',
    sortable: true,
  },
  {
    title: 'Actions',
  },
]

export const policiesTableHead = [
  {
    title: 'Policy number',
  },
  {
    title: 'table_col_brand_model',
  },
  {
    title: 'table_col_start_date',
  },
  {
    title: 'table_col_end_date',
  },
  {
    title: 'table_col_status',
  },
  {
    title: '',
  },
]

export const policyImportsTableHead = [
  {
    title: 'File name',
  },
  {
    title: 'Uploaded at',
  },
  {
    title: 'Status',
  },
  {
    title: 'Error message',
  },
  {
    title: 'Total',
  },
  {
    title: 'Uploaded',
  },
  {
    title: 'Duplicated',
  },
  {
    title: '',
  },
]

export const importedPoliciesTableHead = [
  {
    title: 'Policy number',
  },
  {
    title: 'Brand/Model',
  },
  {
    title: 'Dealer name',
  },
  {
    title: 'Select dealer',
  },
]

export const companiesTableHead = [
  {
    title: 'ID',
  },
  {
    title: 'Name',
  },
  {
    title: 'E-mail',
  },
  {
    title: 'Landline number',
  },
  {
    title: 'Created at',
  },
  {
    title: 'Actions',
  },
]

export const customerAppraisalsTableHead = [
  {
    title: 'Image',
  },
  {
    title: 'Brand/Model',
  },
  {
    title: 'Customer',
  },
  {
    title: 'Status',
  },
  {
    key: 'createdAt',
    title: 'Created at',
    sortable: true,
  },
  {
    title: 'Action',
  },
]
