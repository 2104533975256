import React from 'react'
import { Box, IconButton, Modal, Typography } from '@mui/material'
import { colorsConfig } from '../../../config/themeConfig'
import useBreakpoints from '../../../hooks/useBreakpoints'
import { CloseRounded } from '@mui/icons-material'

const VINAutofillSucceededModal = ({
  isVINAutofillSucceededModalOpen,
  closeVINAutofillSucceededModal,
}) => {
  const { isMaxWidth600, isMaxWidth960 } = useBreakpoints()
  return (
    <Modal open={isVINAutofillSucceededModalOpen} onClose={closeVINAutofillSucceededModal}>
      <Box
        sx={{
          position: 'absolute',
          width: isMaxWidth600 ? '340px' : isMaxWidth960 ? '540px' : '688px',
          height: isMaxWidth600 ? '232px' : '305px',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          backgroundColor: colorsConfig.bgLightBlue,
          padding: '30px',
          borderRadius: '10px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <IconButton
          sx={{
            position: 'absolute',
            top: '5px',
            right: '5px',
            zIndex: '100',
            '& svg': {
              fill: colorsConfig.mainBlack,
              transition: '0.25s all ease-in-out',
            },
            '&:hover': {
              '& svg': {
                fill: colorsConfig.darkRed,
                transition: '0.25s all ease-in-out',
              },
            },
          }}
          onClick={() => {
            closeVINAutofillSucceededModal()
          }}
        >
          <CloseRounded />
        </IconButton>
        <Typography
          sx={{
            fontWeight: 700,
            fontFamily: '"Helvetica", sans-serif',
            fontSize: isMaxWidth600 ? '24px' : '40px',
            color: colorsConfig.mainBlack,
            fontStyle: 'normal',
            lineHeight: 'normal',
            textAlign: 'center',
          }}
        >
          Car information autofilled successfully
        </Typography>
        <Typography
          sx={{
            fontWeight: 700,
            fontFamily: 'Helvetica',
            fontSize: isMaxWidth600 ? '84px' : '115px',
            color: colorsConfig.mainBlack,
            fontStyle: 'normal',
            lineHeight: 'normal',
            textAlign: 'center',
          }}
        >
          👍
        </Typography>
      </Box>
    </Modal>
  )
}

export default VINAutofillSucceededModal
