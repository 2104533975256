import React from 'react'
import { Box, Container, Grid } from '@mui/material'
import { colorsConfig } from '../../config/themeConfig'
import TextH1 from '../../ui/Text/TextH1'
import TextNormal from '../../ui/Text/TextNormal'
import DubaiGreenFund from '../../assests/img/image-dubai-green-fund.png'

const GreenPagePartners = () => {
  return (
    <Box
      sx={{
        background: colorsConfig.bgGreen,
        py: '60px',
      }}
    >
      <Container maxWidth={false} sx={{ maxWidth: '1240px' }}>
        <Box
          sx={{
            mb: '50px',
          }}
        >
          <Grid container spacing={'20px'} columns={12}>
            <Grid item md={3} xs={12}>
              <Box
                sx={{
                  height: '100px',
                  borderRadius: '10px',
                  background: 'white',
                  boxShadow: '0px 0px 24px 0px rgba(112, 226, 0, 0.30)',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: '10px',
                }}
              >
                <TextH1
                  sx={{
                    whiteSpace: 'nowrap',
                    display: 'inline-flex',
                    fontSize: '38px',
                  }}
                >
                  600 257
                </TextH1>
                <TextNormal
                  sx={{
                    lineHeight: 'normal',
                    display: 'inline-flex',
                  }}
                >
                  Active eco <br />
                  donators
                </TextNormal>
              </Box>
            </Grid>
            <Grid item md={6} xs={12}>
              <Box
                sx={{
                  height: '100px',
                  borderRadius: '10px',
                  background: 'white',
                  boxShadow: '0px 0px 24px 0px rgba(112, 226, 0, 0.30)',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: '13px',
                }}
              >
                <TextH1
                  sx={{
                    whiteSpace: 'nowrap',
                    display: 'inline-flex',
                    fontSize: '38px',
                  }}
                >
                  XXXX million USD
                </TextH1>
                <TextNormal
                  sx={{
                    lineHeight: 'normal',
                    display: 'inline-flex',
                  }}
                >
                  Cumulative investments <br />
                  made to partner institutions
                </TextNormal>
              </Box>
            </Grid>
            <Grid item md={3} xs={12}>
              <Box
                sx={{
                  height: '100px',
                  borderRadius: '10px',
                  background: 'white',
                  boxShadow: '0px 0px 24px 0px rgba(112, 226, 0, 0.30)',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: '10px',
                }}
              >
                <TextH1
                  sx={{
                    whiteSpace: 'nowrap',
                    display: 'inline-flex',
                    fontSize: '38px',
                  }}
                >
                  450 925 t
                </TextH1>
                <TextNormal
                  sx={{
                    lineHeight: 'normal',
                    display: 'inline-flex',
                  }}
                >
                  CO2 <br />
                  compensated
                </TextNormal>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box
          sx={{
            display: 'flex',
            gap: '46px',
          }}
        >
          <img src={DubaiGreenFund} alt="" />
          <Box>
            <TextH1
              sx={{
                mb: '10px',
              }}
            >
              Dubai Green Fund
            </TextH1>
            <TextNormal
              sx={{
                maxWidth: '591px',
              }}
            >
              It is an impact investor launched by the Government of Dubai and sponsored by the
              Dubai Electricity & Water Authority – DEWA to encourage investment in green and
              sustainable projects. For more detailed information visit{' '}
              <a className={'simple-link-blue'} href="https://dgf.ae/" target="_blank">
                their website
              </a>
            </TextNormal>
          </Box>
        </Box>
      </Container>
    </Box>
  )
}

export default GreenPagePartners
