import React from 'react'
import { colorsConfig } from '../config/themeConfig'
import { IconButton, Tab, Tabs, useMediaQuery } from '@mui/material'
import IconAddRound from '../assests/img/icons/icon-add-round-blue.svg'
import RBox from './RBox'

const EnhancedTabs = (props) => {
  const { tab, setTab, tabs, handleAddTab, renderTab } = props

  const tabletOnly = useMediaQuery('(min-width: 600px) and (max-width: 1200px)')

  return (
    <RBox
      sx={{
        background: colorsConfig.bgLightBlue,
        py: '7px',
        pr: '17px',
        borderRadius: '6px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        mb: '30px',
        '&.xs-only': {
          pr: 0,
        },
        '&.tablet-only': {
          pr: '10px',
        },
      }}
    >
      <RBox
        className={['disable-scroll-bar'].join(' ')}
        sx={{
          maxWidth: 'calc(100vw - 128px - 250px - 46px)',
          minWidth: 'calc(100vw - 128px - 250px - 46px)',
          overflow: 'hidden',
          '&.xs-only': {
            maxWidth: '100%',
          },
          '&.tablet-only': {
            maxWidth: '100%',
          },
        }}
      >
        <Tabs
          className={[tabletOnly ? 'tablet-only' : ''].join(' ')}
          value={tab}
          onChange={(e, newVal) => {
            setTab(newVal)
          }}
          variant="scrollable"
          scrollButtons={tabletOnly ? true : 'auto'}
          sx={{
            width: '100%',
            minWidth: '100%',
            '& .MuiTabs-indicator': {
              background: colorsConfig.mainGreen,
            },
            '& .MuiTab-textColorPrimary': {
              color: colorsConfig.mainBlack,
              textTransform: 'none',
              fontFamily: '"HelveticaNeue", sans-serif',
              fontSize: '16px',
              fontWeight: 500,
            },
            '& .MuiButtonBase-root': {
              background: colorsConfig.bgLightBlue,
            },
            '& .Mui-selected': {
              color: colorsConfig.mainBlack,
            },
            '&.tablet-only': {
              '& .Mui-disabled.MuiTabs-scrollButtons.MuiTabs-scrollButtonsHideMobile': {
                opacity: 0.3,
              },
            },
          }}
        >
          {tabs.map((item, index) => {
            return (
              <Tab
                key={index}
                label={renderTab(index)}
                sx={{
                  fontSize: '13px',
                  pr: '10px',
                  pb: '6px',
                  pt: '6px',
                  minWidth: 0,
                }}
              />
            )
          })}
        </Tabs>
      </RBox>
      <IconButton
        sx={{
          width: '38px',
          height: '38px',
          padding: '0',
          '&:hover': {
            background: 'none',
          },
          '&.Mui-disabled': {
            opacity: '0.5',
          },
        }}
        onClick={() => {
          handleAddTab()
        }}
      >
        <img style={{ width: '100%' }} src={IconAddRound} alt="" />
      </IconButton>
    </RBox>
  )
}

export default EnhancedTabs
