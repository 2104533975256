import { createSlice } from '@reduxjs/toolkit'
import getCompanyBuilder from './builders/getCompany.builder'
import updateCompanyBuilder from './builders/updateCompany.builder'
import createCompanyWithManagerBuilder from './builders/createCompanyWithManager.builder'

const initialState = {
  isLoading: false,
  error: false,
  data: null,
  create: {
    isLoading: false,
    error: null,
  },
  createWithManager: {
    isLoading: false,
    error: null,
  },
  update: {
    isLoading: false,
    error: null,
  },
  delete: {
    isLoading: false,
    error: null,
  },
}

const companySlice = createSlice({
  name: 'companyReducer',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    getCompanyBuilder(builder)
    updateCompanyBuilder(builder)
    createCompanyWithManagerBuilder(builder)
  },
})

export default companySlice.reducer
