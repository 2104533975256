import { downloadRestitutionFiles } from '../rviPolicy.actions'

export default (builder) => {
  builder
    .addCase(downloadRestitutionFiles.pending, (state) => {
      state.downloadRestitutionFiles.isLoading = true
    })
    .addCase(downloadRestitutionFiles.fulfilled, (state, action) => {
      state.downloadRestitutionFiles.isLoading = false
      state.downloadRestitutionFiles.error = null
      state.downloadRestitutionFiles.data = action.payload
    })
    .addCase(downloadRestitutionFiles.rejected, (state, action) => {
      state.downloadRestitutionFiles.isLoading = false
      state.downloadRestitutionFiles.error = action.payload
    })
}
