import React from 'react'
import { Box, Stack } from '@mui/material'
import UITextRegular from '../../../../ui/UIText/UITextRegular'
import { colorsConfig } from '../../../../config/themeConfig'
import IconStatus from '../../../../assests/img/icons/icon-car-status.svg'
import {
  COMPANY_NAME,
  INITIATOR,
  REGION_LOCALE,
} from '../../../../utils/constants/global.constants'

const LoggingNodesListItem = ({ data }) => {
  const { timestamp, message, initiator } = data ?? null

  const initiatorName =
    initiator === INITIATOR.DEALER ? 'common_dealer' : COMPANY_NAME[REGION_LOCALE]

  return (
    <Stack direction="row" spacing="24px" alignItems="flex-start">
      <UITextRegular
        color={colorsConfig.iconGray}
        text={timestamp ?? '-'}
        sx={{ minWidth: '40%', pt: '5px' }}
      />
      <Box
        sx={{
          minWidth: '26px',
          verticalAlign: 'top',
          '& img': {
            width: '100%',
            verticalAlign: 'top',
          },
        }}
      >
        <img width="26px" src={IconStatus} alt="" />
      </Box>
      <Stack gap="4px" sx={{ pt: '4px' }}>
        <UITextRegular sx={{ flex: 1 }} fontWeight="700" text={message ?? '-'} />
        <UITextRegular color={colorsConfig.iconGray} text={initiatorName} />
      </Stack>
    </Stack>
  )
}

export default LoggingNodesListItem
