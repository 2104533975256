import React, { useEffect, useState } from 'react'
import './App.scss'
import './assests/css/preloaders.scss'
import { BrowserRouter } from 'react-router-dom'
import AppRouter from './router/AppRouter'
import './assests/fonts/stylesheet.css'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { useDispatch, useSelector } from 'react-redux'
import {
  checkAuth,
  setAvatar,
  setAuctionCarsAmount,
  setBalance,
  setCompany,
  setUnreadNotificationsCount,
  setCoinPricings,
  setUser,
} from './store/features/authSlice'
import {
  setCarWorkflows,
  setAuctionWorkflows,
  setDealWorkflows,
} from './store/features/workflowsSlice/workflows.reducer'
import { API_ENDPOINTS, CDN_BASE_URL, excludedPricings } from './config/default'
import { patchRequest, privateMultipartRequest, privateRequest } from './utils/request'
import ScrollToTop from './utils/ScrollUpTo'
import Avatar1 from './assests/img/avatars/avatar-1.png'
import DevModal from './utils/dev/devModal'
import { getWorkflowsStates } from './store/features/workflowsSlice/workflows.actions'
import { getEnumDetails } from './store/features/references/references.actions'
import { useTranslation } from 'react-i18next'
import { ErrorBoundary } from 'react-error-boundary'
import ErrorPlaceholderFullScreen from './components/ErrorPlaceholders/ErrorPlaceholderFullScreen'
import CookiesAndPrivacyPolicy from './components/LegalDocuments/CookiesAndPrivacyPolicy'
import CookiesBottomModal from './components/LegalDocuments/CookiesBottomModal'
import { getNavigatorLanguage } from './i18n/i18n.helpers'
import { getEnumSystemName } from './utils/helpers/enums.helpers'
import { CAR_ENUMS, USER_CREATION_SOURCE } from './utils/constants/enums.constants'
import WelcomeModal from './components/Modals/WelcomeModal/WelcomeModal'

function App() {
  const dispatch = useDispatch()
  const { i18n } = useTranslation()
  const auth = useSelector((state) => state.auth.isAuthenticated)
  const role = useSelector((state) => state.auth.role)
  const user = useSelector((state) => state.auth.user)
  const signedIn = useSelector((state) => state.auth.signedIn)
  const enumDetails = useSelector((state) => state.ref.enumDetails.data)
  const [authChecked, setAuthChecked] = useState(false)
  const [userInfoReceived, setUserInfoReceived] = useState(false)
  const [devModal, setDevModal] = useState(false)
  const [cookieModal, setCookieModal] = useState(false)
  const [welcomeModal, setWelcomeModal] = useState(false)

  const handleInit = async () => {
    const savedLocale = localStorage.getItem('locale')
    const navigatorLang = getNavigatorLanguage()
    if (savedLocale && i18n.language !== savedLocale) {
      await i18n.changeLanguage(savedLocale)
    } else if (i18n.language !== navigatorLang && !savedLocale) {
      await i18n.changeLanguage(navigatorLang)
    }
    dispatch(checkAuth())
    setAuthChecked(true)
  }

  useEffect(() => {
    handleInit()
  }, [])

  const getCarWorkflows = () => {
    privateRequest.get(API_ENDPOINTS.carWorkflows).then((response) => {
      dispatch(setCarWorkflows(response.data['hydra:member']))
    })
  }

  const getAuctionWorkflows = () => {
    privateRequest.get(API_ENDPOINTS.auctionWorkflows).then((response) => {
      dispatch(setAuctionWorkflows(response.data['hydra:member']))
    })
  }

  const getDealWorkflows = () => {
    privateRequest.get(API_ENDPOINTS.dealWorkflows).then((response) => {
      dispatch(setDealWorkflows(response.data['hydra:member']))
    })
  }

  const getInsuranceWorkflows = () => {
    dispatch(getWorkflowsStates())
  }

  const getCoinPricings = () => {
    privateRequest.get(API_ENDPOINTS.coinPricings).then((response) => {
      dispatch(
        setCoinPricings(
          response.data['hydra:member'].filter(
            (item) => !excludedPricings.filter((pricing) => pricing === item.identifier)[0],
          ),
        ),
      )
    })
  }

  const getUser = () => {
    privateRequest.get(API_ENDPOINTS.userInfo).then((response) => {
      if (response.data.avatar) {
        dispatch(setAvatar(`${CDN_BASE_URL}${response.data.avatar.avatar.path}`))
      } else {
        dispatch(setAvatar(Avatar1))
      }
      if (role !== 'OPERATOR' && role !== 'EXPERT') {
        dispatch(setCompany(response.data.manager.company))
      }
      if (role === 'COMPANY') {
        dispatch(setBalance(response.data.manager.company.coinBalance.balance))
        dispatch(setUser(response.data))
      }
      if (response.data?.locale) {
        privateRequest.defaults.headers['Accept-Language'] = response.data?.locale
        privateMultipartRequest.defaults.headers['Accept-Language'] = response.data?.locale
        patchRequest.defaults.headers['Accept-Language'] = response.data?.locale
      }
      setUserInfoReceived(true)
    })
  }

  const getUnreadNotificationsCount = () => {
    privateRequest.get(API_ENDPOINTS.notificationLogs).then((response) => {
      dispatch(
        setUnreadNotificationsCount(
          response.data['hydra:member'].filter((item) => !item.readAt).length,
        ),
      )
    })
  }

  const getAuctionsAmount = () => {
    privateRequest.get(API_ENDPOINTS.auctions).then((response) => {
      dispatch(
        setAuctionCarsAmount({
          auctionCarsAmount: response.data['hydra:totalItems'],
        }),
      )
    })
  }

  const handleDisplayWelcomeModal = () => {
    if (
      getEnumSystemName(user?.creationSource, CAR_ENUMS.USER_CREATION_SOURCE, enumDetails) ===
      USER_CREATION_SOURCE.IMPORT
    ) {
      const seenWelcomeModal = localStorage.getItem('seenWelcomeModal')
      if (seenWelcomeModal) return
      setWelcomeModal(true)
      localStorage.setItem('seenWelcomeModal', JSON.stringify(true))
    }
  }

  useEffect(() => {
    if (authChecked && auth) {
      if (role && role === 'COMPANY' && auth) {
        getUser()
      }
      if (role && role === 'OPERATOR' && auth) {
        setUserInfoReceived(true)
        dispatch(setBalance(0))
        i18n.changeLanguage('en')
      }
      if (role && role === 'EXPERT' && auth) {
        setUserInfoReceived(true)
        dispatch(setBalance(0))
      }

      if (role && role === 'ADMIN' && auth) {
        setUserInfoReceived(true)
        dispatch(setBalance(0))
      }
    }
  }, [authChecked, auth, role])

  useEffect(() => {
    if (userInfoReceived && auth) {
      getCarWorkflows()
      getAuctionWorkflows()
      getDealWorkflows()
    }
  }, [userInfoReceived, auth, i18n.language])

  useEffect(() => {
    if (userInfoReceived && auth) {
      getAuctionsAmount()
      getUnreadNotificationsCount()
      getCoinPricings()
      getInsuranceWorkflows()
    }
  }, [userInfoReceived, auth])

  useEffect(() => {
    if (userInfoReceived && auth) {
      dispatch(getEnumDetails())
    }
  }, [userInfoReceived, auth])

  useEffect(() => {
    if (signedIn && user && auth) {
      console.log('conditions met')
      handleDisplayWelcomeModal()
    }
  }, [signedIn, user, auth])

  useEffect(() => {
    const cookiePolicySeen = localStorage.getItem('cookiePolicySeen')
    if (!cookiePolicySeen) {
      setCookieModal(true)
      localStorage.setItem('cookiePolicySeen', JSON.stringify(true))
    }
  }, [])

  return (
    <BrowserRouter>
      <ErrorBoundary
        fallbackRender={({ error, resetErrorBoundary }) => (
          <ErrorPlaceholderFullScreen error={error} resetErrorBoundary={resetErrorBoundary} />
        )}
      >
        <ScrollToTop />
        {(authChecked && auth && userInfoReceived) || (authChecked && !auth) ? (
          <>
            <AppRouter />
            <CookiesAndPrivacyPolicy />
            <CookiesBottomModal open={cookieModal} setOpen={setCookieModal} />
            {welcomeModal && <WelcomeModal open={welcomeModal} setOpen={setWelcomeModal} />}
          </>
        ) : null}
        <DevModal open={devModal} setOpen={setDevModal} />
        <ToastContainer />
      </ErrorBoundary>
    </BrowserRouter>
  )
}

export default App
