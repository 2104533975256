import React from 'react'
import { colorsConfig } from '../../config/themeConfig'
import { Button, styled } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { FONT, UISize, UIVariant } from '../../utils/constants/ui.constants'

const UIButton = ({
  text,
  children,
  sx,
  size = UISize.md,
  variant = UIVariant.primary,
  prefixIcon,
  width,
  isFile,
  fileInputProps,
  onChange,
  ...props
}) => {
  const { t } = useTranslation()

  const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  })

  return (
    <Button
      component={isFile ? 'label' : 'button'}
      sx={{
        borderRadius: '4px',
        py: '10px',
        px: '50px',
        gap: '8px',
        background: colorsConfig.mainBlue,
        color: 'white',
        textTransform: 'none',
        fontFamily: FONT.PRIMARY,
        fontWeight: 700,
        fontSize: '16px',
        transition: '0.25s all ease-in-out',
        '&:hover': {
          background: colorsConfig.buttonHover,
          transition: '0.25s all ease-in-out',
        },
        '&.Mui-disabled': {
          background: colorsConfig.buttonDisabled,
        },
        ...(variant === UIVariant.hollow && {
          background: 'none',
          py: '9px',
          border: '1px solid',
          borderColor: colorsConfig.mainBlue,
          color: colorsConfig.mainBlue,
          '&:hover': {
            color: colorsConfig.white,
            background: colorsConfig.mainBlue,
            transition: '0.25s all ease-in-out',
          },
        }),
        ...(variant === UIVariant.danger && {
          border: '1px solid',
          borderColor: colorsConfig.mainRed,
          color: colorsConfig.mainRed,
          background: 'none',
          '&:hover': {
            color: colorsConfig.white,
            background: colorsConfig.mainRed,
            transition: '0.25s all ease-in-out',
          },
        }),
        ...(variant === UIVariant.attention && {
          borderRadius: '4px',
          px: {
            xl: '60px',
            md: '30px',
          },
          color: colorsConfig.mainBlack,
          background: colorsConfig.buttonYellow,
          whiteSpace: 'nowrap',
          transition: '0.25s all ease-in-out',
          '&:hover': {
            background: colorsConfig.buttonYellow,
            opacity: '0.7',
            transition: '0.25s all ease-in-out',
          },
          '&.Mui-disabled': {
            background: colorsConfig.buttonDisabled,
            color: colorsConfig.white,
            transition: '0.25s all ease-in-out',
          },
        }),
        ...(variant === UIVariant.attention_hollow && {
          background: 'transparent',
          border: '1px solid',
          borderColor: colorsConfig.buttonYellow,
          color: colorsConfig.mainBlack,
          transition: '0.25s all ease-in-out',
          '&:hover': {
            background: colorsConfig.bgLightBlue,
            transition: '0.25s all ease-in-out',
          },
        }),
        ...(variant === UIVariant.accent && {
          background: colorsConfig.mainGreen,
          borderRadius: '4px',
          textTransform: 'none',
          gap: '11px',
          px: '60px',
          position: 'relative',
          color: colorsConfig.mainBlack,
          transition: '0.25s all ease-in-out',
          '&:hover': {
            background: colorsConfig.mainGreen,
            opacity: '0.8',
            transition: '0.25s all ease-in-out',
          },
        }),
        ...(size === UISize.sm && {
          py: '1px',
          px: '30px',
          '& svg': {
            fontSize: '18px',
          },
        }),
        ...(width && {
          width,
        }),
        ...sx,
      }}
      {...props}
    >
      {prefixIcon ?? null}
      {text ? t(text) : children}
      {isFile && <VisuallyHiddenInput type="file" {...fileInputProps} />}
    </Button>
  )
}

export default UIButton
