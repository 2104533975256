import React, { useEffect, useState } from 'react'
import { Box, InputAdornment, Table, TextField, Typography, useMediaQuery } from '@mui/material'
import { colorsConfig } from '../../config/themeConfig'
import BannerAuction from '../../assests/img/banners/banner-auctions.png'
import BannerAuctionMobile from '../../assests/img/banners/banner-auctions-mobile.png'
import TextH1 from '../../ui/Text/TextH1'
import ButtonLG from '../../ui/Buttons/ButtonLG'
import { useDispatch, useSelector } from 'react-redux'
import { setAuctionCarsAmount, setCurrentPageTitle } from '../../store/features/authSlice'
import { useNavigate } from 'react-router-dom'
import { API_ENDPOINTS, CDN_BASE_URL, isDevEnv } from '../../config/default'
import carDefaultPicture from '../../assests/img/car-not-found.png'
import { privateRequest } from '../../utils/request'
import iconSearch from '../../assests/img/icons/icon-search.svg'
import CarTableHead from '../VirtualGarage/CarTableHead'
import CarTableBody from '../VirtualGarage/CarTableBody'
import { sumDelimiter } from '../../utils/sumDelimiter'
import TabTitleWithRedCounter from '../../components/TabTitleWithRedCounter'
import AuctionsTabs from './AuctionsTabs'
import TextBoldNormal from '../../ui/Text/TextBoldNormal'
import ComingSoon from '../../components/ComingSoon'
import { CURRENCY, REGION_LOCALE } from '../../utils/constants/global.constants'

const Auctions = () => {
  const auctionCarsAmount = useSelector((state) => state.auth.auctionCarsAmount)

  const xsOnly = useMediaQuery('(max-width: 600px)')
  const tabletOnly = useMediaQuery('(min-width: 600px) and (max-width: 1200px)')

  const actions = [
    {
      title: 'Start auction',
      conditions: [
        {
          role: 'COMPANY',
          status: 'car_workflow.ready_for_sale',
        },
      ],
      actions: [
        {
          type: 'modal',
          modalName: 'start_auction',
        },
      ],
    },
    {
      title: 'Reschedule auction',
      conditions: [
        {
          role: 'COMPANY',
          status: 'auction_workflow.pending',
        },
      ],
      actions: [
        {
          type: 'modal',
          modalName: 'start_auction',
          status: 'auction_workflow.pending',
        },
      ],
    },
    {
      title: 'Sell',
      specialConditions: 'has-bids',
      conditions: [
        {
          role: 'COMPANY',
          status: 'auction_workflow.completed',
        },
      ],
      actions: [
        {
          type: 'navigate-auction-car',
          link: '/cars/:id',
        },
      ],
    },
    {
      title: "Don't sell",
      specialConditions: 'has-bids',
      conditions: [
        {
          role: 'COMPANY',
          status: 'auction_workflow.completed',
        },
      ],
      actions: [
        {
          type: 'navigate-auction-car',
          link: '/cars/:id',
        },
      ],
    },
    {
      title: 'Return to virtual garage',
      specialConditions: 'no-bids',
      conditions: [
        {
          role: 'COMPANY',
          status: 'auction_workflow.completed',
        },
      ],
      actions: [
        {
          type: 'navigate-auction-car',
          link: '/cars/:id',
        },
      ],
    },
    {
      title: 'Restart auction',
      specialConditions: 'no-bids',
      conditions: [
        {
          role: 'COMPANY',
          status: 'auction_workflow.completed',
        },
      ],
      actions: [
        {
          type: 'modal',
          modalName: 'start_auction',
          status: 'auction_workflow.completed',
        },
      ],
    },
  ]

  const sections = [
    {
      id: 1,
      title: 'My auctions',
    },
    {
      id: 2,
      title: 'My bids',
    },
    {
      id: 3,
      title: 'Purchase deals',
    },
    {
      id: 4,
      title: 'Selling deals',
    },
  ]

  const tableHeadCellsAuctions = [
    {
      title: 'Number',
      sortable: false,
    },
    {
      title: 'Image',
      sortable: false,
    },
    {
      title: 'Brand/Model',
      sortable: false,
    },
    {
      title: 'Auction duration',
      sortable: false,
    },
    {
      title: 'Maximum bid',
      sortable: false,
    },
    {
      title: 'Status',
      sortable: false,
    },
    {
      title: 'Action',
      sortable: false,
    },
  ]

  const tableHeadCellsBids = [
    {
      title: 'Number',
      sortable: false,
    },
    {
      title: 'Image',
      sortable: false,
    },
    {
      title: 'Brand/Model',
      sortable: false,
    },
    {
      title: 'End time',
      sortable: false,
    },
    {
      title: 'Status',
      sortable: false,
    },
    {
      title: 'Bid Amount',
      sortable: false,
    },
  ]

  const tableHeadCellsPurchaseDeals = [
    {
      title: 'Car ID',
      sortable: false,
    },
    {
      title: 'Image',
      sortable: false,
    },
    {
      title: 'Brand/Model',
      sortable: false,
    },
    {
      title: 'Deal start time',
      sortable: false,
    },
    {
      title: 'My bid',
      sortable: false,
    },
    {
      title: 'Status',
      sortable: false,
    },
  ]

  const tableHeadCellsSellingDeals = [
    {
      title: 'Number',
      sortable: false,
    },
    {
      title: 'Image',
      sortable: false,
    },
    {
      title: 'Brand/Model',
      sortable: false,
    },
    {
      title: 'Deal start time',
      sortable: false,
    },
    {
      title: 'Maximum Bid',
      sortable: false,
    },
    {
      title: 'Status',
      sortable: false,
    },
  ]

  const carWorkflows = useSelector((state) => state.workflows.carWorkflowStates)
  const auctionWorkflows = useSelector((state) => state.workflows.auctionWorkflowStates)
  const dealWorkflows = useSelector((state) => state.workflows.dealWorkflowStates)
  const workflowsLoaded = useSelector((state) => state.workflows.loaded)
  const role = useSelector((state) => state.auth.role)
  const companyId = useSelector((state) => state.auth.companyId)

  const defaultMainStatusesCount = {
    newCar: 0,
    readyForAction: 0,
    dealPending: 0,
    awaitingPayment: 0,
  }

  const dispatch = useDispatch()

  const navigate = useNavigate()

  const [tab, setTab] = useState(0)
  const [cars, setCars] = useState([])
  const [auctions, setAuctions] = useState([])
  const [bids, setBids] = useState([])
  const [purchaseDeals, setPurchaseDeals] = useState([])
  const [auctionsCounter, setAuctionsCounter] = useState(0)
  const [bidsCounter, setBidsCounter] = useState(0)
  const [purchaseDealsCounter, setPurchaseDealsCounter] = useState(0)
  const [sellingDealsCounter, setSellingDealsCounter] = useState(0)
  const [sellingDeals, setSellingDeals] = useState([])
  const [mainStatuses, setMainStatuses] = useState(defaultMainStatusesCount)
  const [statuses, setStatuses] = useState([])
  const [filterStatus, setFilterStatus] = useState(null)
  const [sort, setSort] = useState('&order[createdAt]=desc')
  const [searchLine, setSearchLine] = useState('')

  const handleSetCarPhoto = (photo) => {
    if (photo) {
      return `${CDN_BASE_URL}${photo.photo.path}`
    } else {
      return carDefaultPicture
    }
  }

  const getCarStatus = (combinedStatus, isArchived) => {
    if (combinedStatus === 'car_workflow.ready_for_sale') {
      return 'Appraised'
    }
    if (!combinedStatus) {
      return 'Outdated car'
    }
    if (isArchived) {
      return 'Archived'
    }
    if (combinedStatus.includes('car_workflow')) {
      return carWorkflows.filter(
        (workflow) => workflow.sysName === combinedStatus.replace('car_workflow.', ''),
      )[0].value
    }
    if (combinedStatus.includes('auction_workflow')) {
      return auctionWorkflows.filter(
        (workflow) => workflow.sysName === combinedStatus.replace('auction_workflow.', ''),
      )[0].value
    }
    if (combinedStatus.includes('deal_workflow')) {
      return dealWorkflows.filter(
        (workflow) => workflow.sysName === combinedStatus.replace('deal_workflow.', ''),
      )[0].value
    }
  }

  const getActionList = (carStatus, isArchived, maxBid) => {
    if (isArchived) {
      return []
    }
    const availableActions = actions.filter((item) => {
      if (item.specialConditions === 'has-bids' && !maxBid) {
        return false
      }
      if (item.specialConditions === 'no-bids' && maxBid) {
        return false
      }
      return item.conditions.filter(
        (condition) => role === condition.role && carStatus === condition.status,
      )[0]
    })
    return availableActions
  }

  const getAuctionDuration = (startTime, endTime) => {
    const startTimeDate = new Date(startTime)
    const startYear = startTimeDate.getFullYear()
    let startMonth = startTimeDate.getMonth() + 1
    let startDay = startTimeDate.getDate()
    if (startDay < 10) startDay = '0' + startDay
    if (startMonth < 10) startMonth = '0' + startMonth
    const formattedStartDate = startDay + '.' + startMonth + '.' + startYear
    const endTimeDate = new Date(endTime)
    const endYear = endTimeDate.getFullYear()
    let endMonth = endTimeDate.getMonth() + 1
    let endDay = endTimeDate.getDate()
    if (endDay < 10) endDay = '0' + endDay
    if (endMonth < 10) endMonth = '0' + endMonth
    const formattedEndDate = endDay + '.' + endMonth + '.' + endYear
    return `${formattedStartDate} - ${formattedEndDate}`
  }

  const getFormattedDate = (date) => {
    const endTimeDate = new Date(date)
    const endYear = endTimeDate.getFullYear()
    let endMonth = endTimeDate.getMonth() + 1
    let endDay = endTimeDate.getDate()
    if (endDay < 10) endDay = '0' + endDay
    if (endMonth < 10) endMonth = '0' + endMonth
    const formattedEndDate = endDay + '.' + endMonth + '.' + endYear
    return formattedEndDate
  }

  const handleSetFilters = (data, mode) => {
    if (mode === 'cars') {
      let filteredData
      if (role === 'OPERATOR') {
        filteredData = data.filter(
          (item) => item.name === 'car_workflow.quality_check' && item.count !== 0,
        )
      } else if (role === 'COMPANY') {
        filteredData = data.filter((item) => item.count !== 0)
      }
      setStatuses(
        filteredData.map((item, index) => {
          return {
            id: index + 1,
            systemName: item.name,
            label: getCarStatus(item.name),
            count: item.count,
          }
        }),
      )
    }
    if (mode === 'auctions') {
      const filteredData = data.filter((item) => {
        if (item.count === 0) return false
        if (
          item.name === 'car_workflow.ready_for_sale' ||
          item.name === 'auction_workflow.pending' ||
          item.name === 'auction_workflow.active' ||
          item.name === 'auction_workflow.completed'
        )
          return true
        return false
      })
      setStatuses(
        filteredData.map((item, index) => {
          return {
            id: index + 1,
            systemName: item.name,
            label: getCarStatus(item.name),
            count: item.count,
          }
        }),
      )
    }
  }

  const getAuctionsAmount = () => {
    privateRequest.get(API_ENDPOINTS.auctions).then((response) => {
      dispatch(
        setAuctionCarsAmount({
          auctionCarsAmount: response.data['hydra:totalItems'],
        }),
      )
    })
  }

  const getAuctions = () => {
    let link = `/auctions?pagination=false&currentWorkflowState[]=completed&currentWorkflowState[]=active&currentWorkflowState[]=pending&car.company=${companyId}&order[createdAt]=desc`
    if (searchLine) {
      link = `${link}&or[car.make.name]=${searchLine}&or[car.model.name]=${searchLine}`
    }
    privateRequest.get(link).then((response) => {
      setAuctions(
        response.data['hydra:member'].map((item) => {
          return {
            id: item.id,
            link: `/cars/${item.car.id}`,
            auctionCarId: item.car.id,
            maxBid: item.maxBid ? item.maxBid.value : false,
            greenCar: item.car.engineType === 'electric' || item.car.engineType === 'hybrid',
            cells: [
              {
                type: 'text',
                value: item.car.referenceNumber ? item.car.referenceNumber : item.car.id,
              },
              {
                type: 'image',
                value: handleSetCarPhoto(item.car.mainForeshortening),
              },
              {
                type: 'text-with-caption',
                value: `${item.car.make.name} ${item.car.model.name}`,
                caption: `VIN ${item.car.vin}`,
              },
              {
                type: 'text',
                value: getAuctionDuration(item.startTime, item.endTime),
              },
              {
                type: 'text',
                value: item.maxBid
                  ? `${sumDelimiter(item.maxBid.value)} ${CURRENCY[REGION_LOCALE]}`
                  : '-',
              },
              {
                type: 'text',
                value: getCarStatus(item.car.combinedStatus, false),
              },
              {
                type: 'actions',
                value: getActionList(item.car.combinedStatus, false, item.maxBid),
              },
            ],
          }
        }),
      )
    })
  }

  const getBids = () => {
    let link = `/auctions?pagination=false&currentWorkflowState[]=completed&currentWorkflowState[]=active&allocatedWithBids=true`
    if (searchLine) {
      link = `${link}&or[car.make.name]=${searchLine}&or[car.model.name]=${searchLine}`
    }
    privateRequest.get(link).then((response) => {
      setBids(
        response.data['hydra:member'].map((item) => {
          return {
            id: item.id,
            link: `/cars/${item.car.id}`,
            greenCar: item.car.engineType === 'electric' || item.car.engineType === 'hybrid',
            cells: [
              {
                type: 'text',
                value: item.car.id,
              },
              {
                type: 'image',
                value: handleSetCarPhoto(item.car.mainForeshortening),
              },
              {
                type: 'text-with-caption',
                value: `${item.car.make.name} ${item.car.model.name}`,
                caption: `VIN ${item.car.vin}`,
              },
              {
                type: 'text',
                value: getFormattedDate(item.endTime),
              },
              {
                type: 'text',
                value: getCarStatus(item.car.combinedStatus, false),
              },
              {
                type: 'bids',
                value: {
                  actionAvailable: item.car.combinedStatus === 'auction_workflow.active',
                  amount: `${sumDelimiter(item.companyBid.value)} ${CURRENCY[REGION_LOCALE]}`,
                },
              },
            ],
          }
        }),
      )
    })
  }

  const getPurchaseDeals = () => {
    privateRequest
      .get(`${API_ENDPOINTS.purchasedCars(companyId)}&order[createdAt]=desc`)
      .then((response) => {
        const notObtainedCars = response.data['hydra:member'].filter(
          (item) => item.car.isCarObtained === false,
        )
        setPurchaseDeals(
          notObtainedCars.map((item) => {
            return {
              id: item.id,
              link: `/purchased-cars/${item.id}`,
              greenCar: item.car.engineType === 'electric' || item.car.engineType === 'hybrid',
              cells: [
                {
                  type: 'text',
                  value: item.car.id,
                },
                {
                  type: 'image',
                  value: handleSetCarPhoto(item.car.mainForeshortening),
                },
                {
                  type: 'text-with-caption',
                  value: `${item.car.make.name} ${item.car.model.name}`,
                  caption: `VIN ${item.car.vin}`,
                },
                {
                  type: 'text',
                  value: getFormattedDate(item.createdAt),
                },
                {
                  type: 'text',
                  value: item.value
                    ? `${sumDelimiter(item.value)} ${CURRENCY[REGION_LOCALE]}`
                    : 'Outdated car',
                },
                {
                  type: 'text',
                  value:
                    item.car.combinedStatus === 'car_workflow.sale_succeed'
                      ? 'Purchased vehicle'
                      : getCarStatus(item.car.combinedStatus, false),
                },
              ],
            }
          }),
        )
      })
  }

  const getSellingDeals = () => {
    privateRequest
      .get(`${API_ENDPOINTS.sellingDeals(companyId)}&order[createdAt]=desc`)
      .then((response) => {
        setSellingDeals(
          response.data['hydra:member'].map((item) => {
            return {
              id: item.id,
              link: `/cars/${item.car.id}`,
              greenCar: item.car.engineType === 'electric' || item.car.engineType === 'hybrid',
              cells: [
                {
                  type: 'text',
                  value: item.car.referenceNumber ? item.car.referenceNumber : item.car.id,
                },
                {
                  type: 'image',
                  value: handleSetCarPhoto(item.car.mainForeshortening),
                },
                {
                  type: 'text-with-caption',
                  value: `${item.car.make.name} ${item.car.model.name}`,
                  caption: `VIN ${item.car.vin}`,
                },
                {
                  type: 'text',
                  value: getFormattedDate(item.createdAt),
                },
                {
                  type: 'text',
                  value: item.value
                    ? `${sumDelimiter(item.value)} ${CURRENCY[REGION_LOCALE]}`
                    : 'Outdated car',
                },
                {
                  type: 'text',
                  value: getCarStatus(item.car.combinedStatus, false),
                },
              ],
            }
          }),
        )
      })
  }

  const getCarStatusCount = () => {
    privateRequest.get(API_ENDPOINTS.cars.statusCount).then((response) => {
      const data = response.data['hydra:member']
      setMainStatuses({
        newCar: data.filter((item) => item.name === 'car_workflow.new')[0].count,
        readyForAction: data.filter((item) => item.name === 'car_workflow.ready_for_sale')[0].count,
        dealPending: data.filter((item) => item.name === 'deal_workflow.pending')[0].count,
        awaitingPayment: 0,
      })
      handleSetFilters(data, 'auctions')
    })
  }

  const renderTabTitle = (title) => {
    switch (title) {
      case 'My auctions':
        return <TabTitleWithRedCounter title={title} counter={auctionsCounter} />
        break
      case 'My bids':
        return <TabTitleWithRedCounter title={title} counter={bidsCounter} />
        break
      case 'Purchase deals':
        return <TabTitleWithRedCounter title={title} counter={purchaseDealsCounter} />
        break
      case 'Selling deals':
        return <TabTitleWithRedCounter title={title} counter={sellingDealsCounter} />
        break
      default:
        return title
    }
  }

  useEffect(() => {
    if (auctionsCounter === 0) {
      setAuctionsCounter(auctions.length)
    }
    if (bidsCounter === 0) {
      setBidsCounter(bids.length)
    }
    if (purchaseDealsCounter === 0) {
      setPurchaseDealsCounter(purchaseDeals.length)
    }
    if (sellingDealsCounter === 0) {
      setSellingDealsCounter(sellingDeals.length)
    }
  }, [auctions, bids, purchaseDeals, sellingDeals])

  useEffect(() => {
    if (workflowsLoaded) {
      getAuctions()
      getBids()
      getPurchaseDeals()
      getSellingDeals()
      getCarStatusCount()
      getAuctionsAmount()
    }
  }, [workflowsLoaded, filterStatus, searchLine, sort])

  useEffect(() => {
    dispatch(setCurrentPageTitle('page_title_auctions'))
  }, [])

  return !isDevEnv ? (
    <ComingSoon />
  ) : (
    <Box>
      <Box
        className={[xsOnly ? 'xs-only' : '', tabletOnly ? 'tablet-only' : ''].join(' ')}
        sx={{
          '&.xs-only': {
            px: '16px',
            py: '16px',
          },
          '&.tablet-only': {
            px: '16px',
            pb: '30px',
          },
        }}
      >
        <Box
          className={xsOnly ? 'xs-only' : ''}
          sx={{
            height: '200px',
            backgroundImage: `url(${BannerAuction})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'cover',
            backgroundSize: 'cover',
            py: '40px',
            px: '30px',
            borderRadius: '4px',
            overflow: 'hidden',
            '&.xs-only': {
              p: 0,
              height: '268px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-start',
              alignItems: 'center',
              backgroundImage: `url(${BannerAuctionMobile})`,
            },
          }}
        >
          <Box
            className={xsOnly ? 'xs-only' : ''}
            sx={{
              mb: '20px',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-start',
              alignItems: 'flex-end',
              '&.xs-only': {
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
              },
            }}
          >
            <TextH1
              className={xsOnly ? 'xs-only' : ''}
              sx={{
                color: 'white',
                marginRight: '10px',
                lineHeight: 'normal',
                '&.xs-only': {
                  mr: 0,
                  px: '20px',
                  mt: '30px',
                  textAlign: 'center',
                  mb: '21px',
                },
              }}
            >
              New cars on the Auction
            </TextH1>
            <Typography
              sx={{
                height: '25px',
                background: colorsConfig.mainGreen,
                px: '5.5px',
                borderRadius: '227px',
                minWidth: '60px',
                textAlign: 'center',
                position: 'relative',
                top: '-7px',
                fontSize: '22px',
                fontWeight: 400,
                color: colorsConfig.mainBlack,
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              +{auctionCarsAmount !== null ? auctionCarsAmount : 0}
            </Typography>
          </Box>
          <ButtonLG
            onClick={() => {
              navigate('/cars-on-auction')
            }}
          >
            Go to auctions
          </ButtonLG>
        </Box>
      </Box>
      <Box
        id={'car-table'}
        className={[xsOnly ? 'xs-only' : '', tabletOnly ? 'tablet-only' : ''].join(' ')}
        sx={{
          background: 'white',
          boxShadow: '0 0 24px 0 rgba(0,0,0,.1)',
          borderRadius: '6px',
          py: '37px',
          px: '40px',
          mt: '40px',
          '&.xs-only': {
            boxShadow: 'none',
            p: 0,
            borderRadius: 0,
            px: '16px',
            mt: '20px',
            pb: '20px',
          },
          '&.tablet-only': {
            boxShadow: 'none',
            p: 0,
            borderRadius: 0,
            px: '16px',
            mt: '20px',
            pb: '20px',
          },
        }}
      >
        {xsOnly ? (
          <TextBoldNormal
            sx={{
              fontSize: '24px',
              mb: '20px',
            }}
          >
            Auction
          </TextBoldNormal>
        ) : null}
        <Box
          className={[xsOnly ? 'xs-only' : '', tabletOnly ? 'tablet-only' : ''].join(' ')}
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            '&.xs-only': {
              flexDirection: 'column-reverse',
              justifyContent: 'flex-start',
            },
            '&.tablet-only': {
              flexDirection: 'column-reverse',
              justifyContent: 'flex-start',
              alignItems: 'flex-end',
              gap: '10px',
            },
          }}
        >
          <AuctionsTabs
            tab={tab}
            setTab={setTab}
            sections={sections}
            renderTabTitle={renderTabTitle}
          />
          <Box
            className={xsOnly ? 'xs-only' : ''}
            sx={{
              '&.xs-only': {
                width: '100%',
                mb: '19px',
              },
            }}
          >
            <TextField
              className={xsOnly ? 'xs-only' : ''}
              id="search"
              label="Search"
              size="small"
              value={searchLine}
              onChange={(e) => {
                setSearchLine(e.target.value)
              }}
              sx={{
                '&.xs-only': {
                  width: '100% !important',
                  '& .MuiInputBase-input': {
                    width: '100% !important',
                  },
                },
                '& .MuiInputBase-input': {
                  width: '205px',
                  height: '38px',
                  boxSizing: 'border-box',
                  borderRadius: '6px',
                  borderColor: colorsConfig.mainBlack,
                  color: colorsConfig.mainBlack,
                },
                '& .MuiOutlinedInput-notchedOutline': {
                  borderRadius: '6px',
                  borderColor: colorsConfig.mainBlack,
                },
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <img src={iconSearch} alt="" />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        </Box>
        <Box
          className={xsOnly ? 'xs-only' : ''}
          sx={{
            mt: '20px',
            '&.xs-only': {
              maxWidth: 'calc(100vw - 32px)',
              overflowX: 'auto',
            },
          }}
        >
          <Box
            className={xsOnly ? 'xs-only' : ''}
            sx={{
              '&.xs-only': {
                minWidth: '1000px',
              },
            }}
          >
            {sections[tab].title === 'My auctions' ? (
              <Table>
                <CarTableHead
                  tableCells={tableHeadCellsAuctions}
                  sort={sort}
                  setSort={setSort}
                  small={tabletOnly}
                  disableColumns={tabletOnly ? [1] : []}
                />
                <CarTableBody
                  content={auctions}
                  setCars={setCars}
                  cars={cars}
                  getActionList={getActionList}
                  small={tabletOnly}
                  disableColumns={tabletOnly ? [1] : []}
                />
              </Table>
            ) : null}
            {sections[tab].title === 'My bids' ? (
              <Table>
                <CarTableHead
                  tableCells={tableHeadCellsBids}
                  sort={sort}
                  setSort={setSort}
                  small={tabletOnly}
                  disableColumns={tabletOnly ? [1] : []}
                />
                <CarTableBody
                  content={bids}
                  setCars={setCars}
                  cars={cars}
                  getActionList={getActionList}
                  small={tabletOnly}
                  disableColumns={tabletOnly ? [1] : []}
                />
              </Table>
            ) : null}
            {sections[tab].title === 'Purchase deals' ? (
              <Table>
                <CarTableHead
                  tableCells={tableHeadCellsPurchaseDeals}
                  sort={sort}
                  setSort={setSort}
                  small={tabletOnly}
                  disableColumns={tabletOnly ? [1] : []}
                />
                <CarTableBody
                  content={purchaseDeals}
                  setCars={setBids}
                  cars={bids}
                  getActionList={getActionList}
                  small={tabletOnly}
                  disableColumns={tabletOnly ? [1] : []}
                />
              </Table>
            ) : null}
            {sections[tab].title === 'Selling deals' ? (
              <Table>
                <CarTableHead
                  tableCells={tableHeadCellsSellingDeals}
                  sort={sort}
                  setSort={setSort}
                  small={tabletOnly}
                  disableColumns={tabletOnly ? [1] : []}
                />
                <CarTableBody
                  content={sellingDeals}
                  setCars={setCars}
                  cars={cars}
                  getActionList={getActionList}
                  small={tabletOnly}
                  disableColumns={tabletOnly ? [1] : []}
                />
              </Table>
            ) : null}
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default Auctions
