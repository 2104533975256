import React, { useEffect } from 'react'
import { Autocomplete, Grid } from '@mui/material'
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { useTranslation } from 'react-i18next'
import UIFormInputSelect from '../../../ui/UIFormComponents/UIFormInputSelect/UIFormInputSelect'
import UIInputAdornmentText from '../../../ui/UIFormComponents/UIInputAdornmentText'
import TextFieldSmallEdible from '../../../ui/TextFieldSmallEdible'
import { formErrors } from '../../../components/Forms/FormTemplates/formErrors'
import { sumDelimiter } from '../../../utils/sumDelimiter'
import { useSelector } from 'react-redux'
import {
  getCarEnumOptionsList,
  getCarEnumOptionsListWithIcons,
} from '../../../utils/helpers/optionsList.helpers'
import { CAR_ENUMS } from '../../../utils/constants/enums.constants'
import {
  doorsNumberOptions,
  seatsNumberOptions,
  wheelLocationOptions,
} from '../addNewVehicleForm.helpers'

const CarRequiredFields = ({
  isCarDataAutofilled,
  formData,
  setFormData,
  showErrors,
  notRequired,
  isFirstRegistrationDateFull,
}) => {
  const enumDetails = useSelector((state) => state.ref.enumDetails.data)
  const { t } = useTranslation()

  const isFieldNotReq = (fieldName) => {
    if (!notRequired) return false
    return !!notRequired?.find((e) => e === fieldName)
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={6} md={4}>
        <LocalizationProvider sx={{ width: '100%' }} fullWidth dateAdapter={AdapterDayjs}>
          <DesktopDatePicker
            label={
              isFirstRegistrationDateFull
                ? t('form_input_label_first_registration_date_full')
                : t('form_input_label_first_registration_date')
            }
            inputFormat={isFirstRegistrationDateFull ? 'DD/MM/YYYY' : 'MM/YYYY'}
            mask={isFirstRegistrationDateFull ? '__/__/____' : '__/____'}
            disableMaskedInput={false}
            renderInput={(params) => (
              <TextFieldSmallEdible
                {...params}
                fullWidth
                required
                className={
                  isCarDataAutofilled && !formData.firstRegistrationDate ? 'attention' : ''
                }
                value={formData?.firstRegistrationDate ?? ''}
                error={!formData.firstRegistrationDate && showErrors}
                helperText={
                  !formData.firstRegistrationDate && showErrors ? formErrors.enterValidDate : ''
                }
              />
            )}
            onChange={(newVal) => {
              setFormData({
                ...formData,
                firstRegistrationDate: newVal,
              })
            }}
            value={formData?.firstRegistrationDate}
            views={isFirstRegistrationDateFull ? ['year', 'month', 'day'] : ['year', 'month']}
            disableFuture
          />
        </LocalizationProvider>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <TextFieldSmallEdible
          required
          fullWidth
          label="form_input_label_mileage"
          type="text"
          value={sumDelimiter(formData.mileage)}
          inputProps={{
            inputMode: 'numeric',
          }}
          onChange={(e) => {
            setFormData({
              ...formData,
              mileage: e.target.value.replace(/[^0-9]/g, ''),
            })
          }}
          InputProps={{
            endAdornment: <UIInputAdornmentText text="km" />,
          }}
          error={showErrors && !formData.mileage}
          helperText={showErrors && !formData.mileage ? formErrors.notEmpty : ''}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <UIFormInputSelect
          required
          label="form_input_label_engine_type"
          value={formData.engineType ?? ''}
          onChange={(e) => {
            setFormData({
              ...formData,
              engineType: e.target.value,
            })
          }}
          className={
            isCarDataAutofilled && (!formData.engineType || formData.engineType?.name === 'Other')
              ? 'attention'
              : ''
          }
          options={getCarEnumOptionsList(CAR_ENUMS.CAR_ENGINE, enumDetails)}
          error={showErrors && !formData.engineType}
          showHelperText={showErrors && !formData.engineType}
          helperText={formErrors.notEmpty}
        />
      </Grid>
      {formData.engineType?.name !== 'Electric' ? (
        <Grid item xs={12} sm={6} md={4}>
          <TextFieldSmallEdible
            fullWidth
            required={!isFieldNotReq('engineSize')}
            id="car-engine-size"
            label="form_input_label_engine_size"
            type="text"
            value={formData.engineSize}
            inputProps={{
              inputMode: 'numeric',
            }}
            className={isCarDataAutofilled && !formData.engineSize ? 'attention' : ''}
            onChange={(e) => {
              if (e.target.value.length <= 4) {
                setFormData({
                  ...formData,
                  engineSize: e.target.value.replace(/[^0-9]/g, ''),
                })
              }
            }}
            error={
              !isFieldNotReq('engineSize') &&
              showErrors &&
              !formData.engineSize &&
              formData.engineType?.name !== 'Electric'
            }
            helperText={
              !isFieldNotReq('engineSize') &&
              showErrors &&
              !formData.engineSize &&
              formData.engineType?.name !== 'Electric'
                ? formErrors.notEmpty
                : ''
            }
            InputProps={{
              endAdornment: <UIInputAdornmentText text="ccm" />,
            }}
          />
        </Grid>
      ) : null}
      <Grid item xs={12} sm={6} md={4}>
        <UIFormInputSelect
          error={!isFieldNotReq('bodyType') && showErrors && !formData.bodyType}
          label="form_input_label_body_type"
          value={formData.bodyType ?? ''}
          onChange={(e) => {
            setFormData({
              ...formData,
              bodyType: e.target.value,
            })
          }}
          className={
            isCarDataAutofilled && (!formData.bodyType || formData.bodyType?.name === 'Other')
              ? 'attention'
              : ''
          }
          options={getCarEnumOptionsListWithIcons(CAR_ENUMS.CAR_BODY, enumDetails)}
          showHelperText={!isFieldNotReq('bodyType') && showErrors && !formData.bodyType}
          helperText={formErrors.notEmpty}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <UIFormInputSelect
          required
          label="form_input_label_steering_wheel_location"
          value={formData.wheelLocation}
          onChange={(e) => {
            setFormData({
              ...formData,
              wheelLocation: e.target.value,
            })
          }}
          options={wheelLocationOptions}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        {/*<Autocomplete*/}
        {/*  fullWidth*/}
        {/*  id="car-origin"*/}
        {/*  options={getCarEnumOptionsList(CAR_ENUMS.CAR_ORIGIN, enumDetails)}*/}
        {/*  getOptionLabel={(item) => t(item?.label)}*/}
        {/*  value={formData.origin}*/}
        {/*  onChange={(e, newVal) => {*/}
        {/*    setFormData({*/}
        {/*      ...formData,*/}
        {/*      origin: newVal,*/}
        {/*    })*/}
        {/*  }}*/}
        {/*  renderInput={(params) => (*/}
        {/*    <TextFieldSmallEdible*/}
        {/*      {...params}*/}
        {/*      isAutocomplete*/}
        {/*      label={t('form_input_label_origin')}*/}
        {/*      required*/}
        {/*      error={showErrors && !formData.origin}*/}
        {/*      helperText={showErrors && !formData.origin ? formErrors.notEmpty : ''}*/}
        {/*      className={*/}
        {/*        isCarDataAutofilled && (!formData.origin || formData.origin?.name === 'Other')*/}
        {/*          ? 'attention'*/}
        {/*          : ''*/}
        {/*      }*/}
        {/*    />*/}
        {/*  )}*/}
        {/*/>*/}
        <UIFormInputSelect
          required
          label="form_input_label_origin"
          value={formData.origin ?? ''}
          onChange={(e) => {
            setFormData({
              ...formData,
              origin: e.target.value,
            })
          }}
          className={
            isCarDataAutofilled && (!formData.origin || formData.origin?.name === 'Other')
              ? 'attention'
              : ''
          }
          options={getCarEnumOptionsList(CAR_ENUMS.CAR_ORIGIN, enumDetails)}
          showHelperText={showErrors && !formData.origin}
          helperText={formErrors.notEmpty}
          error={showErrors && !formData.origin}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <UIFormInputSelect
          required
          label="form_input_label_drive"
          value={formData.driveType ?? ''}
          onChange={(e) => {
            setFormData({
              ...formData,
              driveType: e.target.value,
            })
          }}
          className={
            isCarDataAutofilled && (!formData.driveType || formData.driveType?.name === 'Other')
              ? 'attention'
              : ''
          }
          options={getCarEnumOptionsList(CAR_ENUMS.CAR_DRIVE, enumDetails)}
          showHelperText={showErrors && !formData.driveType}
          helperText={formErrors.notEmpty}
          error={showErrors && !formData.driveType}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <UIFormInputSelect
          label="form_input_label_gear"
          value={formData.gearbox ?? ''}
          onChange={(e) => {
            setFormData({
              ...formData,
              gearbox: e.target.value,
            })
          }}
          className={
            isCarDataAutofilled && (!formData.gearbox || formData.gearbox?.name === 'Other')
              ? 'attention'
              : ''
          }
          options={getCarEnumOptionsList(CAR_ENUMS.CAR_GEARBOX, enumDetails)}
          showHelperText={showErrors && !formData.gearbox}
          helperText={formErrors.notEmpty}
          error={showErrors && !formData.gearbox}
          disabled={formData.engineType?.name === 'Electric'}
          required
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <UIFormInputSelect
          required={!isFieldNotReq('seatsNumber')}
          label="form_input_label_seats_number"
          value={formData.seatsNumber ?? ''}
          onChange={(e) => {
            setFormData({
              ...formData,
              seatsNumber: e.target.value,
            })
          }}
          className={isCarDataAutofilled && !formData.seatsNumber ? 'attention' : ''}
          options={seatsNumberOptions}
          showHelperText={!isFieldNotReq('seatsNumber') && showErrors && !formData.seatsNumber}
          helperText={formErrors.notEmpty}
          error={!isFieldNotReq('seatsNumber') && showErrors && !formData.seatsNumber}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <UIFormInputSelect
          required={!isFieldNotReq('doorsNumber')}
          label="form_input_label_doors_number"
          value={formData.doorsNumber ?? ''}
          onChange={(e) => {
            setFormData({
              ...formData,
              doorsNumber: e.target.value,
            })
          }}
          className={isCarDataAutofilled && !formData.doorsNumber ? 'attention' : ''}
          options={doorsNumberOptions}
          showHelperText={!isFieldNotReq('doorsNumber') && showErrors && !formData.doorsNumber}
          helperText={formErrors.notEmpty}
          error={!isFieldNotReq('doorsNumber') && showErrors && !formData.doorsNumber}
        />
      </Grid>
    </Grid>
  )
}

export default CarRequiredFields
