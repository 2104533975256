import { getImportedPolicies } from '../rviOperator.actions'
import {apiConstants} from "../../../../api/api";

export default (builder) => {
  builder
    .addCase(getImportedPolicies.pending, (state) => {
      state.importPolicies.imported.isLoading = true
    })
    .addCase(getImportedPolicies.fulfilled, (state, action) => {
      state.importPolicies.imported.isLoading = false
      state.importPolicies.imported.error = null
      state.importPolicies.imported.data = action.payload?.policiesWithCompanies ?? []
      state.importPolicies.imported.total = action.payload?.[apiConstants.hydraTotal]
    })
    .addCase(getImportedPolicies.rejected, (state, action) => {
      state.importPolicies.imported.isLoading = false
      state.importPolicies.imported.error = action.payload
    })
}
