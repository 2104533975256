import React, { useEffect, useState } from 'react'
import {
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material'
import { useDispatch } from 'react-redux'
import { signIn } from '../../store/features/authSlice'
import useInput from '../../hooks/useInput'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import { toast } from 'react-toastify'
import { API_ENDPOINTS, SHOW_DEV_COMPONENTS } from '../../config/default'
import { publicRequest } from '../../utils/request'
import { colorsConfig } from '../../config/themeConfig'
import IconCarSuccess from '../../assests/img/icons/icon-car-animated-blue.svg'
import IconCarError from '../../assests/img/icons/icon-car-animated-red.svg'
import { useTranslation } from 'react-i18next'
import { localStorageKeys, setToken } from '../../utils/localStorage.helpers'

const keyframes = `
    @keyframes moveCarSucceeded {
        0% {
            transform: translateX(0);
        }
        100% {
            transform: translateX(510px);
        }
    }
    
    @keyframes moveCarSucceededMobile {
        0% {
            transform: translateX(0);
        }
        100% {
            transform: translateX(340px);
        }
    }

    @keyframes moveCarDenied {
        0%, 100% {
            transform: translateX(0);
        }
        25% {
            transform: translateX(320px);
        }
        50% {
            transform: translateX(320px) scale(-1, 1);
        }
        75% {
            transform: translateX(0) scale(-1, 1);
        }
    }
    
    @keyframes moveCarDeniedMobile {
        0%, 100% {
            transform: translateX(0);
        }
        25% {
            transform: translateX(150px);
        }
        50% {
            transform: translateX(150px) scale(-1, 1);
        }
        75% {
            transform: translateX(0) scale(-1, 1);
        }
    }
`

const SignIn = ({ handleForgotPassword, handleSignUp }) => {
  const { t } = useTranslation()

  const xsOnly = useMediaQuery('(max-width: 600px)')
  const tabletOnly = useMediaQuery('(min-width: 600px) and (max-width: 1200px)')

  const test_accounts = [
    {
      userName: 'admin',
      password: 'password',
    },
    {
      userName: 'operator',
      password: 'password',
    },
    {
      userName: 'expert',
      password: 'password',
    },
    {
      userName: 'Andrey_dev',
      password: 'password',
    },
  ]

  const dispatch = useDispatch()

  const userName = useInput('', { isEmpty: true })
  const password = useInput('', { isEmpty: true, minLength: 4, maxLength: 25 })
  const [passwordVisible, setPasswordVisible] = useState(false)
  const [isAnimationStarted, setIsAnimationStarted] = useState(false)
  const [signInSucceeded, setSignInSucceeded] = useState(false)

  const handleSubmit = () => {
    setIsAnimationStarted(false)
    const formData = {
      login: userName.value,
      password: password.value,
    }

    publicRequest
      .post(API_ENDPOINTS.login, formData)
      .then(async (response) => {
        setToken(localStorageKeys.token, response.data.token)
        setToken(localStorageKeys.refreshToken, response.data.refresh_token)
        setToken(localStorageKeys.mercureToken, response.data.mercure_token)
        setTimeout(() => {
          dispatch(
            signIn({
              token: response.data.token,
              refreshToken: response.data.refresh_token,
              mercureToken: response.data.mercure_token,
            }),
          )
        }, 1500)
        setSignInSucceeded(true)
      })
      .catch(() => {
        toast.error(t('error_invalid_email_or_password'))
      })
      .finally(() => {
        setIsAnimationStarted(true)
      })
  }

  const handleSwitchAccount = (account) => {
    userName.onChangeValue(account.userName)
    password.onChangeValue(account.password)
  }

  useEffect(() => {
    const style = document.createElement('style')
    style.type = 'text/css'
    style.appendChild(document.createTextNode(keyframes))
    document.head.appendChild(style)
    return () => {
      document.head.removeChild(style)
    }
  }, [])

  return (
    <Container
      component="main"
      maxWidth="xs"
      className={xsOnly ? 'xs-only' : ''}
      sx={{
        '&.xs-only': {
          px: 0,
        },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {SHOW_DEV_COMPONENTS === 'true' ? (
          <Box
            className={[xsOnly ? 'xs-only' : '', tabletOnly ? 'tablet-only' : ''].join(' ')}
            sx={{
              position: {
                xs: 'relative',
                lg: 'absolute',
              },
              top: {
                xs: '0',
                lg: '20px',
              },
              right: {
                xs: 0,
                lg: '20px',
              },
              width: {
                xs: '100%',
                lg: '150px',
              },
              zIndex: 2,
              background: 'white',
              '&.xs-only': {
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                flexWrap: 'wrap',
              },
              '&.tablet-only': {
                position: 'absolute',
                width: '140px',
                right: '10px',
                top: '10px',
              },
            }}
          >
            {test_accounts.map((account, index) => {
              return (
                <Button
                  className={xsOnly ? 'xs-only' : ''}
                  fullWidth
                  key={index}
                  variant="contained"
                  color={account.userName === userName.value ? 'success' : 'primary'}
                  sx={{
                    mb: 1,
                    textTransform: 'none',
                    fontWeight: account.userName === userName.value ? 500 : 300,
                    '&.xs-only': {
                      maxWidth: '19%',
                      minWidth: '0',
                      fontSize: '10px',
                    },
                  }}
                  onClick={() => {
                    handleSwitchAccount(account)
                  }}
                >
                  {account.userName}
                </Button>
              )
            })}
          </Box>
        ) : null}
        <Box
          sx={{
            mb: '8px',
            display: 'flex',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              position: 'relative',
            }}
          >
            <Typography
              component="h1"
              variant="h5"
              sx={{
                fontFamily: 'Helvetica',
                fontSize: '40px',
                lineHeight: 'normal',
                fontWeight: '700',
                fontStyle: 'normal',
                zIndex: 2,
                background: 'white',
                position: 'relative',
                '&::after': {
                  content: '" "',
                  display: 'block',
                  position: 'absolute',
                  width: 'calc(100% - 3px)',
                  height: '100%',
                  top: 0,
                  left: 0,
                  background: 'transparent',
                  zIndex: -1,
                },
              }}
            >
              {t('modal_title_sign_in')}
            </Typography>
            {xsOnly ? (
              <Box
                sx={{
                  position: 'absolute',
                  bottom: '9px',
                  width: '45px',
                  right: '4px',
                  animation: isAnimationStarted
                    ? signInSucceeded
                      ? 'moveCarSucceededMobile 2s forwards'
                      : 'moveCarDeniedMobile 4s forwards'
                    : 'none',
                }}
              >
                {signInSucceeded ? (
                  <img
                    style={{
                      width: '100%',
                      verticalAlign: 'top',
                    }}
                    src={IconCarSuccess}
                    alt=""
                  />
                ) : (
                  <img
                    style={{
                      width: '100%',
                      verticalAlign: 'top',
                    }}
                    src={IconCarError}
                    alt=""
                  />
                )}
              </Box>
            ) : null}
          </Box>
        </Box>
        <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                className={xsOnly ? 'xs-only' : ''}
                required
                fullWidth
                id="username"
                label={t('form_input_label_username')}
                name="username"
                autoComplete="username"
                type="text"
                onChange={(e) => {
                  userName.onChange(e)
                }}
                onBlur={(e) => {
                  userName.onBlur(e)
                }}
                value={userName.value}
                helperText={userName.firstBlur && userName.hasErrors ? t(userName.errorText) : ''}
                error={userName.firstBlur && userName.hasErrors}
                sx={{
                  '&  .MuiInputLabel-root': {
                    fontSize: '14px',
                  },
                  "& .MuiInputLabel-root[data-shrink='false']": {
                    marginTop: '-7px',
                  },
                }}
                InputProps={{
                  sx: {
                    width: xsOnly ? '100%' : '305px',
                    height: '38px',
                    fontSize: '14px',
                    lineHeight: 'normal',
                    fontStyle: 'normal',
                  },
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                name="password"
                label={t('form_input_label_password')}
                type={passwordVisible ? 'text' : 'password'}
                id="password"
                autoComplete="new-password"
                sx={{
                  '&  .MuiInputLabel-root': {
                    fontSize: '14px',
                  },
                  "& .MuiInputLabel-root[data-shrink='false']": {
                    marginTop: '-7px',
                  },
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => {
                          setPasswordVisible(!passwordVisible)
                        }}
                      >
                        {passwordVisible ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                  sx: {
                    width: xsOnly ? '100%' : '305px',
                    height: '38px',
                    fontSize: '14px',
                    lineHeight: 'normal',
                    fontStyle: 'normal',
                  },
                }}
                onChange={(e) => {
                  password.onChange(e)
                }}
                onBlur={(e) => {
                  password.onBlur(e)
                }}
                value={password.value}
                helperText={password.firstBlur && password.hasErrors ? t(password.errorText) : ''}
                error={password.firstBlur && password.hasErrors}
              />
            </Grid>
          </Grid>
          <Grid
            sx={{
              position: 'relative',
              '& .MuiButtonBase-root': {
                zIndex: '2',
              },
            }}
          >
            <Button
              className={xsOnly ? 'xs-only' : ''}
              variant="contained"
              sx={{
                position: 'relative',
                width: '175px',
                height: '48px',
                backgroundColor: colorsConfig.mainBlue,
                fontFamily: 'Helvetica',
                fontSize: '16px',
                lineHeight: 'normal',
                fontWeight: '700',
                fontStyle: 'normal',
                textTransform: 'none',
                margin: '25px 0 25px',
                '&.xs-only': {
                  width: '100%',
                },
              }}
              onClick={() => {
                handleSubmit()
              }}
              disabled={userName.hasErrors || password.hasErrors}
            >
              {t('button_submit')}
            </Button>
            {!xsOnly ? (
              <Box
                sx={{
                  position: 'absolute',
                  top: 'calc(50% - 10px)',
                  left: '96px',
                  display: isAnimationStarted ? null : 'none',
                  transform: 'translateY(calc(-50% + 17px))',
                  animation: isAnimationStarted
                    ? signInSucceeded
                      ? 'moveCarSucceeded 1.5s forwards'
                      : 'moveCarDenied 6s forwards'
                    : 'none', // Apply the animation or 'none' to reset it
                }}
              >
                {signInSucceeded ? (
                  <img src={IconCarSuccess} alt="" />
                ) : (
                  <img src={IconCarError} alt="" />
                )}
              </Box>
            ) : null}
          </Grid>
          <Grid
            container
            sx={{
              gap: '11px',
            }}
          >
            <Grid item>
              <Link
                variant="body2"
                sx={{
                  cursor: 'pointer',
                  fontSize: '12px',
                  color: colorsConfig.mainBlue,
                  textDecoration: 'none',
                  fontStyle: 'normal',
                  lineHeight: 'normal',
                }}
                onClick={handleForgotPassword}
              >
                {t('p_forgot_your_password')}
              </Link>
            </Grid>
            <Grid item>
              <Link
                variant="body2"
                onClick={handleSignUp}
                sx={{
                  cursor: 'pointer',
                  fontSize: '12px',
                  color: colorsConfig.mainBlue,
                  textDecoration: 'none',
                  fontStyle: 'normal',
                  lineHeight: 'normal',
                }}
              >
                {t('p_no_account_yet')}
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
  )
}

export default SignIn
