import React, { useEffect } from 'react'
import UIModal from '../../../../../ui/UIModal/UIModal'
import { useDispatch, useSelector } from 'react-redux'
import { Stack } from '@mui/material'
import UITextHeadlineLarge from '../../../../../ui/UIText/UITextHeadlineLarge'
import { policyWorkflowStateNameKeys } from '../../../../../utils/constants/workflows.constants'
import UITableWrapper from '../../../../../ui/UITable/UITableWrapper'
import UIPreloader from '../../../../../ui/UIPreloader/UIPreloader'
import UITable from '../../../../../ui/UITable/UITable'
import { policiesTableHead } from '../../../../../utils/constants/tables/table.constants'
import { getRviTableData } from '../../../../../utils/helpers/tables/table.helpers'
import RVIPoliciesActionItems from '../../../../RVI/Dealer/RVIPoliciesActionItems'
import UITablePagination from '../../../../../ui/UITable/UITablePagination'
import { getDashboardPolicies } from '../../../../../store/features/dashboard/dashboard.actions'
import { setStatusCakePoliciesParams } from '../../../../../store/features/dashboard/dashboard.reducer'
import { useTranslation } from 'react-i18next'
import UITableControls from '../../../../../ui/UITable/UITableControls'

const RVIStatusCakeModal = ({ open, setOpen }) => {
  const { t } = useTranslation()
  const isLoading = useSelector((state) => state.dashboard.statusCakes.policies.isLoading)
  const policies = useSelector((state) => state.dashboard.statusCakes.policies.data)
  const params = useSelector((state) => state.dashboard.statusCakes.policies.params)
  const total = useSelector((state) => state.dashboard.statusCakes.policies.total)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getDashboardPolicies())
  }, [params])

  return (
    <UIModal width="1100px" open={open} setOpen={setOpen}>
      <Stack gap="30px">
        <UITableControls
          title={policyWorkflowStateNameKeys[params?.currentWorkflowState] ?? ''}
          searchValue={params?.policyExtendedSearch}
          setSearchValue={(val) =>
            dispatch(setStatusCakePoliciesParams({ ...params, policyExtendedSearch: val }))
          }
          sx={{
            '& .ui-table-title': { fontSize: '40px' },
          }}
        />
        <UITableWrapper>
          {isLoading ? <UIPreloader /> : null}
          <UITable
            head={policiesTableHead}
            content={getRviTableData(policies, t)}
            emptyText="placeholder_policies_empty"
            renderActionItems={(data) => {
              return <RVIPoliciesActionItems data={data} />
            }}
          />
        </UITableWrapper>
        <UITablePagination
          count={total}
          page={params.page}
          rowsPerPage={params.itemsPerPage}
          onPageChange={(e, newValue) => {
            dispatch(
              setStatusCakePoliciesParams({
                ...params,
                page: newValue,
              }),
            )
          }}
          onRowsPerPageChange={(e) => {
            dispatch(
              setStatusCakePoliciesParams({
                ...params,
                page: 1,
                itemsPerPage: e.target.value,
              }),
            )
          }}
        />
      </Stack>
    </UIModal>
  )
}

export default RVIStatusCakeModal
