import React, { useEffect, useState } from 'react'
import { Box, Paper, Table, TableContainer, TablePagination } from '@mui/material'
import SimpleTableHead from './SimpleTableHead'
import SimpleTableBody from './SimpleTableBody'
import DeleteModal from './DeleteModal'

const SimpleTable = (props) => {
  const { tableHeadCells, tableCellsContent, filters } = props

  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [filter, setFilter] = useState(null)
  const [filteredItems, setFilteredItems] = useState(tableCellsContent)
  const [searchText, setSearchText] = useState('')
  const [searchResult, setSearchResult] = useState(tableCellsContent)
  const [matchedItems, setMatchedItems] = useState(tableCellsContent)
  const [deleteModal, setDeleteModal] = useState(false)
  const [itemToDelete, setItemToDelete] = useState(null)

  const handleChangePage = (e, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const handleSetFilter = (value) => {
    const filteredRows = tableCellsContent.filter((row) => {
      const currentCellToCheck = row.body.filter((cell) => cell.type === 'status')[0]
      return value === 'all' || currentCellToCheck.content === value
    })
    setFilteredItems(filteredRows)
    setFilter(value)
  }

  const handleDeleteItem = (rowIndex) => {
    setItemToDelete(filteredItems[rowIndex])
    setDeleteModal(true)
  }

  const handleSearch = (value) => {
    setSearchText(value)
    if (value !== '') {
      const newSearchResult = tableCellsContent.filter((row) => {
        return !!row.body.filter((cell) => {
          return cell.content.indexOf(value) !== -1
        })[0]
      })
      setSearchResult(newSearchResult)
    } else {
      setSearchResult(tableCellsContent)
    }
  }

  useEffect(() => {
    const newMatchedItems = filteredItems.filter((filteredRow) => {
      return !!searchResult.filter((searchRow) => searchRow.id === filteredRow.id)[0]
    })
    setMatchedItems(newMatchedItems)
  }, [filteredItems, searchResult])

  return (
    <Box>
      {/*<Box sx={{*/}
      {/*    mt: '20px',*/}
      {/*    display: 'flex',*/}
      {/*    flexDirection: 'row',*/}
      {/*    justifyContent: 'flex-end',*/}
      {/*    flexWrap: {*/}
      {/*        xs: 'wrap',*/}
      {/*        sm: 'nowrap'*/}
      {/*    }*/}
      {/*}}>*/}
      {/*    <SimpleTableFilter*/}
      {/*        filters={filters}*/}
      {/*        filter={filter}*/}
      {/*        setFilter={handleSetFilter}*/}
      {/*    />*/}
      {/*    <SimpleTableSearch*/}
      {/*        searchText={searchText}*/}
      {/*        handleSearch={handleSearch}*/}
      {/*    />*/}
      {/*</Box>*/}
      <Paper
        sx={{
          marginTop: '20px',
        }}
      >
        <TableContainer>
          <Table
            sx={{
              minWidth: '950px',
            }}
          >
            <SimpleTableHead tableHeadCells={tableHeadCells} />
            <SimpleTableBody
              tableCellsContent={tableCellsContent.slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage,
              )}
              handleDeleteItem={handleDeleteItem}
            />
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={filteredItems.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <DeleteModal
        deleteModal={deleteModal}
        setDeleteModal={setDeleteModal}
        itemToDelete={itemToDelete}
      />
    </Box>
  )
}

export default SimpleTable
