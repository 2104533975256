import React from 'react'
import { TableCell } from '@mui/material'
import { colorsConfig } from '../../config/themeConfig'

const UITableBodyCell = ({ sx, children, highlightGreen, ...props }) => {
  return (
    <TableCell
      sx={{
        border: 'none',
        paddingTop: '12px',
        paddingBottom: '12px',
        '&:first-of-type': {
          paddingLeft: '4px',
        },
        '&:last-of-type': {
          paddingRight: '4px',
        },
        ...(highlightGreen && {
          background: colorsConfig.lightGreen,
          '&:last-of-type': {
            paddingRight: '4px',
            borderTopRightRadius: '8px',
            borderBottomRightRadius: '8px',
          },
          '&:first-of-type': {
            paddingLeft: '4px',
            borderTopLeftRadius: '8px',
            borderBottomLeftRadius: '8px',
          },
        }),
        ...sx,
      }}
      {...props}
    >
      {children}
    </TableCell>
  )
}

export default UITableBodyCell
