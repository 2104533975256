import React from 'react'
import { Box, Modal, useMediaQuery } from '@mui/material'
import TextBoldNormal from '../../ui/Text/TextBoldNormal'
import ButtonDanger from '../../ui/Buttons/ButtonDanger'
import ButtonSuccess from '../../ui/Buttons/ButtonSuccess'

const SubmitToQualityCheckConfirmModal = ({ open, setOpen, handleSubmit }) => {
  const xsOnly = useMediaQuery('(max-width: 600px)')

  return (
    <Modal
      open={open}
      onClose={() => {
        setOpen(false)
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 550,
          background: 'white',
          borderRadius: '10px',
          padding: '25px',
          maxHeight: '80vh',
          overflow: 'auto',
          '&.xs-only': {
            width: 'calc(100% - 30px)',
            px: '10px',
            py: '20px',
          },
        }}
        className={['disable-scrollbar', xsOnly ? 'xs-only' : ''].join(' ')}
      >
        <TextBoldNormal
          className={xsOnly ? 'xs-only' : ''}
          sx={{
            textAlign: 'center',
            mb: '20px',
            fontSize: '30px',
            '&.xs-only': {
              lineHeight: 'normal',
              fontSize: '24px',
            },
          }}
        >
          Are you sure you want to submit this car to Quality Check?
        </TextBoldNormal>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <ButtonDanger
            sx={{
              width: 'calc(50% - 5px)',
            }}
            onClick={() => {
              setOpen(false)
            }}
          >
            No
          </ButtonDanger>
          <ButtonSuccess
            sx={{
              width: 'calc(50% - 5px)',
            }}
            onClick={() => {
              handleSubmit()
            }}
          >
            Yes
          </ButtonSuccess>
        </Box>
      </Box>
    </Modal>
  )
}

export default SubmitToQualityCheckConfirmModal
