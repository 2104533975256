import { createSlice } from '@reduxjs/toolkit'
import getDashboardPoliciesStatusCountBuilder from './builders/getDashboardPoliciesStatusCount.builder'
import getDashboardPoliciesBuilder from './builders/getDashboardPolicies.builder'
import getDashboardPoliciesWorkflowLogsBuilder from './builders/getDashboardPoliciesWorkflowLogs.builder'

const initialState = {
  statusCakes: {
    policies: {
      isLoading: false,
      error: null,
      data: [],
      params: {
        page: 1,
        itemsPerPage: 10,
        currentWorkflowState: '',
        policyExtendedSearch: '',
      },
      total: 0,
      statusCount: {
        isLoading: false,
        error: null,
        data: null,
      },
    },
  },
  policiesWorkflowLogs: {
    isLoading: false,
    error: null,
    data: [],
  },
}

const dashboardSlice = createSlice({
  name: 'dashboardReducer',
  initialState,
  reducers: {
    setStatusCakePoliciesParams: (state, action) => {
      state.statusCakes.policies.params = action.payload
    },
    resetStatusCakePolicies: (state, action) => {
      state.statusCakes.policies = initialState.statusCakes.policies
    },
  },
  extraReducers: (builder) => {
    getDashboardPoliciesStatusCountBuilder(builder)
    getDashboardPoliciesBuilder(builder)
    getDashboardPoliciesWorkflowLogsBuilder(builder)
  },
})

export const { setStatusCakePoliciesParams, resetStatusCakePolicies } = dashboardSlice.actions

export default dashboardSlice.reducer
