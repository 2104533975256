import { CDN_BASE_URL } from '../../config/default'
import { WORKFLOW_NAMESPACE } from '../constants/workflows.constants'

export const getCarMainForeshortening = (car) => {
  if (
    car?.mainForeshortening &&
    car?.mainForeshortening?.photo &&
    car?.mainForeshortening?.photo?.path
  ) {
    return `${CDN_BASE_URL}${car?.mainForeshortening?.photo?.path}`
  } else return null
}

export const getCarWorkflowStateName = (
  car,
  carWorkflowStates,
  auctionWorkflowStates,
  dealWorkflowStates,
) => {
  if (
    !carWorkflowStates ||
    carWorkflowStates?.length === 0 ||
    !auctionWorkflowStates ||
    auctionWorkflowStates?.length === 0 ||
    !dealWorkflowStates ||
    dealWorkflowStates?.length === 0 ||
    !car ||
    !car?.combinedStatus
  )
    return null
  const combinedStatus = car?.combinedStatus
  const [namespace, status] = combinedStatus.split('.')
  switch (namespace) {
    case WORKFLOW_NAMESPACE.CAR_WORKFLOW:
      return carWorkflowStates.find((workflow) => workflow.sysName === status)?.value
    case WORKFLOW_NAMESPACE.AUCTION_WORKFLOW:
      return auctionWorkflowStates.find((workflow) => workflow.sysName === status)?.value
    case WORKFLOW_NAMESPACE.DEAL_WORKFLOW:
      return dealWorkflowStates.find((workflow) => workflow.sysName === status)?.value
    default:
      return null
  }
}

export const getCarTitle = (car) => {
  if (!car || !car?.make?.name) return null
  return `${car?.make?.name} ${car?.model?.name ?? ''}`
}

export const isCarGreen = (car) => car?.engineType === 3 || car?.engineType === 5
