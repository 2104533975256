import { useEffect, useState } from 'react'
import { privateRequest } from '../utils/request'
import { API_ENDPOINTS } from '../config/default'

const useAllCars = () => {
  const [loading, setLoading] = useState(true)
  const [notificationsLoading, setNotificationsLoading] = useState(true)
  const [cars, setCars] = useState([])
  const [loggingNodes, setLoggingNodes] = useState([])
  const [coinBalance, setCoinBalance] = useState(null)
  const [coinTransactions, setCoinTransactions] = useState(null)
  const [carWorkflows, setCarWorkflows] = useState([])
  const [auctionWorkflows, setAuctionWorkflows] = useState([])
  const [notificationLogs, setNotificationLogs] = useState([])

  const getCars = async () => {
    const response = await privateRequest.get(API_ENDPOINTS.cars.companyCars)
    setCars(response.data['hydra:member'])
    setTimeout(() => {
      setLoading(false)
    }, 1500)
  }

  const getCarLoggingNodes = async () => {
    setLoading(true)
    privateRequest.get(`${API_ENDPOINTS.allCarLoggingNodes}?pagination=false`).then((response) => {
      setLoggingNodes(
        response.data['hydra:member'].sort(function (a, b) {
          return new Date(b.createdAt) - new Date(a.createdAt)
        }),
      )
      setLoading(false)
    })
  }

  const getCoinBalance = () => {
    privateRequest
      .get(
        API_ENDPOINTS.userInfo,
        {},
        {
          headers: {
            'Content-Type': 'application/json',
            accept: 'application/json',
          },
        },
      )
      .then((response) => {
        setCoinBalance(response.data?.manager?.company?.coinBalance?.balance)
      })
  }

  const getCoinTransactions = () => {
    privateRequest
      .get(
        API_ENDPOINTS.coins.transactions,
        {},
        {
          headers: {
            accept: 'application/json',
          },
        },
      )
      .then((response) => {
        setCoinTransactions(response.data['hydra:member'])
      })
  }

  const getCarWorkflows = async () => {
    const response = await privateRequest.get(API_ENDPOINTS.carWorkflows)
    setCarWorkflows(response.data['hydra:member'])
  }

  const getAuctionWorkflows = async () => {
    const response = await privateRequest.get(API_ENDPOINTS.auctionWorkflows)
    setAuctionWorkflows(response.data['hydra:member'])
  }

  const getNotificationLogs = () => {
    privateRequest
      .get(API_ENDPOINTS.notificationLogs)
      .then((response) => {
        const notifications = response.data['hydra:member']
        notifications.sort(function (a, b) {
          return new Date(b.createdAt) - new Date(a.createdAt)
        })
        setNotificationLogs(notifications)
      })
      .finally(() => {
        setNotificationsLoading(false)
      })
  }

  const markNotificationAsRead = (id, string) => {
    privateRequest
      .put(API_ENDPOINTS.markNotificationAsRead(id), string, {
        headers: {
          'Content-Type': 'application/json',
          accept: 'application/json',
        },
      })
      .then(() => {
        getNotificationLogs()
      })
  }

  const markAllNotificationAsRead = () => {
    setNotificationLogs(
      notificationLogs.map((item) => {
        return {
          ...item,
          readAt: item.readAt ? item.readAt : new Date().getUTCDate(),
        }
      }),
    )
    privateRequest.post(API_ENDPOINTS.markAllNotificationsAsRead, {}).then(() => {
      getNotificationLogs()
    })
  }

  useEffect(() => {
    getCars()
    getCarWorkflows()
    getAuctionWorkflows()
    getCoinBalance()
    getCoinTransactions()
  }, [])

  useEffect(() => {
    if (cars.length > 0) {
      getCarLoggingNodes()
    }
    getNotificationLogs()
  }, [cars])

  const filterByFuelType = (carsArray, fuelType) =>
    carsArray.filter((car) => car.fuelType === fuelType)

  const filterByCombinedStatus = (carsArray, combinedStatus) =>
    carsArray.filter((car) => car.combinedStatus === combinedStatus)

  const getCarVIN = (carId) => cars.filter((car) => car.id === +carId).map((car) => car.vin)

  const getCarWorkflowColour = (carWorkflowsArray, sysName) => {
    const colors = carWorkflowsArray
      .filter((carWorkflow) => carWorkflow.sysName === sysName)
      .map((carWorkflow) => carWorkflow.color)

    return colors.length > 0 ? `#${colors[0]}` : `#FFFFFF`
  }

  const carWorkflowColours = {
    getAppraisalOnSiteColour: () => getCarWorkflowColour(carWorkflows, 'appraisal_on_site'),
    getAppraisalOnCompoundColour: () => getCarWorkflowColour(carWorkflows, 'appraisal_compound'),
    getQualityCheckColour: () => getCarWorkflowColour(carWorkflows, 'quality_check'),
    getReadyForSaleColour: () => getCarWorkflowColour(carWorkflows, 'ready_for_sale'),
    getQualityCheckFailedColour: () => getCarWorkflowColour(carWorkflows, 'moderation_failed'),
  }

  const carWorkflowFilters = {
    filterByAppraisalOnSite: () => filterByCombinedStatus(cars, 'car_workflow.appraisal_on_site'),
    filterByAppraisalOnCompound: () => [
      ...filterByCombinedStatus(cars, 'car_workflow.appraisal_compound'),
      ...filterByCombinedStatus(cars, 'car_workflow.requested_appraisal_compound'),
      ...filterByCombinedStatus(cars, 'car_workflow.awaiting_appraisal_compound'),
    ],
    filterByQualityCheck: () => filterByCombinedStatus(cars, 'car_workflow.quality_check'),
    filterByReadyForSale: () => filterByCombinedStatus(cars, 'car_workflow.ready_for_sale'),
    filterByQualityCheckFailed: () =>
      filterByCombinedStatus(cars, 'car_workflow.moderation_failed'),
  }

  const auctionWorkflowColours = {
    getAuctionPendingColour: () => getCarWorkflowColour(auctionWorkflows, 'pending'),
    getAuctionActiveColour: () => getCarWorkflowColour(auctionWorkflows, 'active'),
    getAuctionCompletedColour: () => getCarWorkflowColour(auctionWorkflows, 'completed'),
  }

  const auctionWorkflowFilters = {
    filterByAuctionPending: () => filterByCombinedStatus(cars, 'auction_workflow.pending'),
    filterByAuctionActive: () => filterByCombinedStatus(cars, 'auction_workflow.active'),
    filterByAuctionCompleted: () => filterByCombinedStatus(cars, 'auction_workflow.completed'),
  }

  const carEngineTypes = {
    filterByElectric: () => filterByFuelType(cars, 'Electric'),
    filterByPlugInHybridElectric: () => filterByFuelType(cars, 'Plug-in Hybrid Electric'),
    filterByHybrid: () => filterByFuelType(cars, 'Hybrid'),
    filterByPetrol: () => filterByFuelType(cars, 'Petrol'),
    filterByDiesel: () => filterByFuelType(cars, 'Diesel'),
  }

  const carEngineTypesArray = [
    { type: 'Electric', fill: '#4DED6D' },
    { type: 'Plug-in Hybrid Electric', fill: '#007DFF' },
    { type: 'Hybrid', fill: '#F5BD4F' },
    { type: 'Petrol', fill: '#AC0000' },
    { type: 'Diesel', fill: '#A5680D' },
  ]

  // const filterByFuelType = (carsArray, fuelType) =>
  //     carsArray.filter(car => car.fuelType === fuelType);

  const handleCarEngineTypes = () => {
    return carEngineTypesArray.map((fuelType) => {
      const carQuantity = filterByFuelType(cars, fuelType.type).length

      return {
        name: fuelType.type,
        value: carQuantity,
        fill: fuelType.fill,
      }
    })
  }

  useEffect(() => {
    console.log(filterByCombinedStatus(cars, 'car_workflow.appraisal_on_site'))
  }, [cars])

  return {
    loading,
    notificationsLoading,
    cars,
    loggingNodes,
    coinBalance,
    coinTransactions,
    getCarVIN,
    carWorkflowFilters,
    carWorkflowColours,
    auctionWorkflowFilters,
    auctionWorkflowColours,
    carEngineTypes,
    handleCarEngineTypes,
    notificationLogs,
    markNotificationAsRead,
    markAllNotificationAsRead,
  }
}

export default useAllCars
