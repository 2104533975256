import React, { useEffect, useState } from 'react'
import { Box, Stack } from '@mui/material'

import { useDispatch, useSelector } from 'react-redux'
import {
  createLoanApplication,
  patchCurrentLoanApplication,
  setLoanApplications,
} from '../../../store/features/financeCar'

import { useParams } from 'react-router-dom'
import { privateRequest } from '../../../utils/request'
import { API_ENDPOINTS, API_FINANCE_ENDPOINTS } from '../../../config/default'
import Preloader from '../../../components/Preloader'
import FinancingTabs from './FinancingTabs'
import CustomerDataForm from './CustomerDataForm'
import ButtonLG from '../../../ui/Buttons/ButtonLG'
import { colorsConfig } from '../../../config/themeConfig'
import IconCheckmark from '../../../assests/img/icons/icon-checkmark-black.svg'
import BankResponses from './BankResponses'
import SendToBanksModal from '../../../components/Modals/SendToBanksModal'
import { setBalance } from '../../../store/features/authSlice'
import LoanCalculator from './LoanCalculator/LoanCalculator'
import {
  getCarLoanApplications,
  submitLoanApplicationToBanks,
} from '../../../store/features/financing/financing.actions'
import { loanApplicationWorkflowStates } from '../../../utils/constants/workflows.constants'
import { resetFinancingData } from '../../../store/features/financing/financing.reducer'

export const LoanApplicationWorkflowTypes = {
  new: 'new',
  applicationPreparation: 'application_preparation',
  approvalProcessStarted: 'approval_process_started',
}

const LoanApplications = () => {
  const params = useParams()
  const dispatch = useDispatch()

  const loading = useSelector((state) => state.carFinance.loanApplicationsLoading)
  const selectedTab = useSelector((state) => state.carFinance.selectedTab)
  const loanApplication = useSelector((state) => state.financing?.currentLoanApplication.data)
  const currentWorkflowState = loanApplication?.currentWorkflowState ?? 'blank'

  const [sendToBanksModal, setSendToBanksModal] = useState(false)

  const isLoanApplicationSubmitted =
    currentWorkflowState !== loanApplicationWorkflowStates.new &&
    currentWorkflowState !== loanApplicationWorkflowStates.application_preparation &&
    currentWorkflowState !== 'blank'
  const isSendToBanksButtonDisabled =
    currentWorkflowState === loanApplicationWorkflowStates.new || currentWorkflowState === 'blank'
  const displayBankResponses =
    currentWorkflowState !== loanApplicationWorkflowStates.new &&
    currentWorkflowState !== loanApplicationWorkflowStates.application_preparation &&
    currentWorkflowState !== 'blank'

  const getLoanApplications = () => {
    privateRequest
      .get(`${API_FINANCE_ENDPOINTS.loanApplications}&car=${params.id}`)
      .then((response) => {
        if (response.data['hydra:member'].length > 0) {
          Promise.all(
            response.data['hydra:member'].map((item) => privateRequest.get(item['@id'])),
          ).then((result) => {
            dispatch(setLoanApplications(result.map((item) => item.data)))
          })
        } else {
          dispatch(createLoanApplication())
        }
      })
  }

  const handleSubmitToBanks = async () => {
    const res = await dispatch(submitLoanApplicationToBanks())
    if (submitLoanApplicationToBanks.fulfilled.match(res)) {
      setSendToBanksModal(false)
      privateRequest.get(API_ENDPOINTS.userInfo).then((response) => {
        dispatch(setBalance(response.data.manager.company.coinBalance.balance))
      })
    }
  }

  const handleScrollBehaviors = () => {
    if (
      currentWorkflowState === loanApplicationWorkflowStates.new ||
      currentWorkflowState === loanApplicationWorkflowStates.application_preparation
    ) {
      window.scrollTo(0, 840)
    }
    if (currentWorkflowState === 'blank') {
      window.scrollTo(0, 740)
    }
  }

  useEffect(() => {
    if (currentWorkflowState) {
      dispatch(
        patchCurrentLoanApplication({
          currentWorkflowState,
        }),
      )
    }
  }, [currentWorkflowState])

  useEffect(() => {
    if (!loading) {
      handleScrollBehaviors()
    }
  }, [currentWorkflowState, loading])

  useEffect(() => {
    getLoanApplications()
    dispatch(getCarLoanApplications({ carIdUrl: `/cars/${params?.id}` }))
    return () => {
      dispatch(resetFinancingData())
    }
  }, [])

  return (
    <Box>
      {loading ? (
        <Box
          sx={{
            minHeight: '400px',
            position: 'relative',
          }}
        >
          <Preloader type={'gooey'} text={'Loading finance applications...'} />
        </Box>
      ) : (
        <Stack gap="30px">
          <FinancingTabs />
          <LoanCalculator />
          <CustomerDataForm setSendToBanksModal={setSendToBanksModal} />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
            }}
          >
            <ButtonLG
              className={isLoanApplicationSubmitted ? 'success' : ''}
              sx={{
                minWidth: '266px',
                mt: '30px',
                '&.success': {
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: '8px',
                  background: colorsConfig.mainGreen,
                  color: colorsConfig.mainBlack,
                  pointerEvents: 'none',
                },
              }}
              disabled={isSendToBanksButtonDisabled}
              onClick={() => {
                setSendToBanksModal(true)
              }}
            >
              {isLoanApplicationSubmitted ? (
                <img style={{ width: '22px' }} src={IconCheckmark} alt="" />
              ) : null}
              {isLoanApplicationSubmitted ? 'Sent to banks' : 'Send to banks'}
            </ButtonLG>
          </Box>
          {displayBankResponses ? <BankResponses /> : null}
        </Stack>
      )}
      <SendToBanksModal
        open={sendToBanksModal}
        setOpen={setSendToBanksModal}
        handleAction={handleSubmitToBanks}
      />
    </Box>
  )
}

export default LoanApplications
