import React from 'react'
import { Box, useMediaQuery } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { updateControlPoint } from '../../../store/features/carSlice/carSlice'
import { colorsConfig } from '../../../config/themeConfig'
import SimpleCheckbox from '../../../ui/SimpleCheckbox'
import IconCheckmark from '../../../assests/img/icons/icon-checkmark.svg'
import IconCross from '../../../assests/img/icons/icon-cross-white.svg'
import TextNormal from '../../../ui/Text/TextNormal'
import { privateRequest } from '../../../utils/request'
import { useParams } from 'react-router-dom'
import { API_ENDPOINTS } from '../../../config/default'
import { toast } from 'react-toastify'

const ControlPointBooleanDepricated = (props) => {
  const { name, blockIndex, subBlockIndex, controlPointIndex, fromSingleSubBlock, readonly } = props

  const xsOnly = useMediaQuery('(max-width: 600px)')
  const params = useParams()

  const value = useSelector(
    (state) =>
      state.car.appraisal.controlPoints[blockIndex].subblocks[subBlockIndex].controlPoints[
        controlPointIndex
      ].value,
  )
  const controlPoint = useSelector(
    (state) =>
      state.car.appraisal.controlPoints[blockIndex].subblocks[subBlockIndex].controlPoints[
        controlPointIndex
      ],
  )
  const dispatch = useDispatch()

  const setValue = (newValue) => {
    if (!readonly) {
      dispatch(
        updateControlPoint({
          newValue: newValue,
          block: blockIndex,
          subBlock: subBlockIndex,
          controlPoint: controlPointIndex,
        }),
      )
      const formData = {
        carControlPoints: [
          {
            value: newValue,
            controlPoint: controlPoint['@id'],
          },
        ],
      }
      privateRequest
        .post(API_ENDPOINTS.submitCarControlPoints(params.id), formData)
        .then(() => {})
        .catch((error) => {
          toast.error('An error occurred while trying to save data')
        })
    }
  }

  return (
    <Box
      className={[xsOnly ? 'xs-only' : '', !fromSingleSubBlock ? 'mechanical-conditions' : ''].join(
        ' ',
      )}
      sx={{
        display: blockIndex !== 0 ? 'flex' : 'none',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        py: {
          xs: '0px',
          md: !fromSingleSubBlock ? '6px' : 0,
        },
        '&.xs-only': {
          '&.mechanical-conditions': {
            alignItems: 'flex-start',
            py: '5px',
          },
        },
      }}
    >
      <TextNormal
        className={[
          xsOnly ? 'xs-only' : '',
          !fromSingleSubBlock ? 'mechanical-conditions' : '',
        ].join(' ')}
        sx={{
          paddingRight: {
            xs: '10px',
            md: 0,
          },
          '&.xs-only': {
            '&.mechanical-conditions': {
              width: 'calc(72% - 5px)',
              fontSize: '14px',
            },
          },
        }}
      >
        {name}
      </TextNormal>
      {fromSingleSubBlock ? (
        <Box
          sx={{
            position: 'relative',
            mt: '-1px',
          }}
        >
          <SimpleCheckbox value={value} setValue={setValue} />
        </Box>
      ) : (
        <Box
          className={xsOnly ? 'xs-only' : ''}
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
            pr: '61px',
            '&.xs-only': {
              pr: 0,
              flex: 1,
            },
          }}
        >
          <Box
            className={value === true && typeof value === 'boolean' ? 'active' : ''}
            sx={{
              border: '1px solid',
              borderColor: colorsConfig.iconGray,
              width: '20px',
              height: '20px',
              borderRadius: '4px',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              mr: '3px',
              transition: '0.25s all ease-in-out',
              '& img': {
                filter: 'brightness(60%)',
                transition: '0.25s all ease-in-out',
              },
              cursor: 'pointer',
              '&.active': {
                transition: '0.25s all ease-in-out',
                borderColor: colorsConfig.mainGreen,
                background: colorsConfig.mainGreen,
                '& img': {
                  filter: 'brightness(100%)',
                  transition: '0.25s all ease-in-out',
                },
              },
            }}
            onClick={() => {
              if (typeof value === 'boolean' && value === true) {
                setValue(null)
              } else {
                setValue(true)
              }
            }}
          >
            <img
              src={IconCheckmark}
              alt=""
              style={{
                width: '11px',
              }}
            />
          </Box>
          <Box
            className={value === false && value !== null ? 'active' : ''}
            sx={{
              border: '1px solid',
              borderColor: colorsConfig.iconGray,
              width: '20px',
              height: '20px',
              borderRadius: '4px',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              '& img': {
                transition: '0.25s all ease-in-out',
                filter: 'brightness(60%)',
              },
              cursor: 'pointer',
              transition: '0.25s all ease-in-out',
              '&.active': {
                transition: '0.25s all ease-in-out',
                borderColor: colorsConfig.mainRed,
                background: colorsConfig.mainRed,
                '& img': {
                  filter: 'brightness(100%)',
                  transition: '0.25s all ease-in-out',
                },
              },
            }}
            onClick={() => {
              if (typeof value === 'boolean' && value === false) {
                setValue(null)
              } else {
                setValue(false)
              }
            }}
          >
            <img src={IconCross} alt="" />
          </Box>
        </Box>
      )}
    </Box>
  )
}

export default ControlPointBooleanDepricated
