import React from 'react'
import { Box, styled } from '@mui/material'
import IconArrowDown from '../../assests/img/icons/icon-arrow-down.svg'
import IconArrowDownBlue from '../../assests/img/icons/icon-arrow-down-blue.svg'

const UITriangleButton = ({ direction, active, ...props }) => {
  const TriangleButton = styled(Box)(() => ({
    position: 'absolute',
    bottom: '1px',
    left: 'calc(100% + 5px)',
    width: '14px',
    height: '11px',
    cursor: 'pointer',
    '&::after': {
      position: 'absolute',
      content: '" "',
      display: 'block',
      width: '13px',
      height: '8px',
      top: '50%',
      marginTop: '-3px',
      left: '50%',
      marginLeft: '-5.5px',
      background: `url(${IconArrowDown}) center center no-repeat`,
      backgroundSize: '13px auto',
    },
    '&::before': {
      position: 'absolute',
      content: '" "',
      display: 'block',
      width: '13px',
      height: '8px',
      top: '50%',
      marginTop: '-3px',
      left: '50%',
      marginLeft: '-5.5px',
      background: `url(${IconArrowDownBlue}) center center no-repeat`,
      backgroundSize: '13px auto',
      opacity: 0,
    },
    '&:hover': {
      '&::after': {
        opacity: 0,
        transition: '0.25s all ease-in-out',
      },
      '&::before': {
        opacity: 1,
        transition: '0.25s all ease-in-out',
      },
    },
    '&.active': {
      '&::after': {
        opacity: 0,
        transition: '0.25s all ease-in-out',
      },
      '&::before': {
        opacity: 1,
        transition: '0.25s all ease-in-out',
      },
    },
    '&.up': {
      bottom: 'auto',
      top: '-1px',
      '&::after': {
        transform: 'rotate(180deg)',
        transformOrigin: '50% 50%',
      },
      '&::before': {
        transform: 'rotate(180deg)',
        transformOrigin: '50% 50%',
      },
    },
  }))

  return <TriangleButton className={[direction, active ? 'active' : ''].join(' ')} {...props} />
}

export default UITriangleButton
