import React from 'react'
import { Box, useMediaQuery } from '@mui/material'
import BoxShadowedGradient from '../../../../../ui/Layout/BoxShadowedGradient'
import SimpleCheckbox from '../../../../../ui/SimpleCheckbox'
import TextNormal from '../../../../../ui/Text/TextNormal'
import RBox from '../../../../../ui/RBox'
import TextBoldNormal from '../../../../../ui/Text/TextBoldNormal'
import { colorsConfig } from '../../../../../config/themeConfig'
import UIMarker from '../../../../../ui/Markers/UIMarker'
import TextWithCaption from '../../../../../ui/Text/TextWithCaption'
import UIMarkerChecked from '../../../../../ui/Markers/UIMarkerChecked'
import { generateUID } from '../../../../../utils/generateUID'
import { useDispatch, useSelector } from 'react-redux'
import { updateDeal } from '../../../../../store/features/insuranceSlice'
import {
  getDealerCommission,
  getMonthlyInstallment,
  getSavedResponseClassName,
  getTotalPremium,
} from '../../../../OperatorPanel/Insurance/operator-insurance.helpers'
import { insuranceDealWorkflowTypes } from '../../../../OperatorPanel/Insurance/operator-insurance.constants'
import RequestInfoForm from './RequestInfoForm'

const CarInsuranceResponsesItem = ({ data }) => {
  const selectedTab = useSelector((state) => state.insurance.selectedTab)
  const deals = useSelector((state) => state.insurance.applications[selectedTab].deals)
  const referenceCompanies = useSelector((state) => state.insurance.referenceCompanies)
  const referenceRisks = useSelector((state) => state.insurance.referenceRisks)
  const dispatch = useDispatch()

  const xsOnly = useMediaQuery('(max-width: 600px)')

  const hasCustomerSelectedOffer = !!deals.find((item) => item.isCustomerSelected)

  return (
    <BoxShadowedGradient
      className={getSavedResponseClassName(data?.currentWorkflowState)}
      sx={{
        pb: '30px',
        px: '15px',
        opacity: hasCustomerSelectedOffer && !data.isCustomerSelected ? 0.3 : 1,
      }}
    >
      {data?.isCustomerSelected ? (
        <Box
          sx={{
            position: 'absolute',
            top: '15px',
            right: '15px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
          }}
        >
          {data.isCustomerSelected ? (
            <TextBoldNormal
              sx={{
                color: colorsConfig.mainGreen,
              }}
            >
              Customer choice
            </TextBoldNormal>
          ) : null}
        </Box>
      ) : null}
      {data?.currentWorkflowState === 'approved' && !hasCustomerSelectedOffer ? (
        <Box
          sx={{
            position: 'absolute',
            top: '8px',
            right: '15px',
            display: 'flex',
            alignItems: 'center',
            gap: '2px',
          }}
        >
          <SimpleCheckbox
            value={data?.isDealerSelected}
            setValue={(newVal) => {
              dispatch(
                updateDeal({
                  id: data?.id,
                  data: {
                    ...data,
                    isDealerSelected: newVal,
                  },
                }),
              )
            }}
          />
          <TextNormal>Add</TextNormal>
        </Box>
      ) : null}
      <Box
        sx={{
          display: 'flex',
          mb: '24px',
        }}
      >
        <RBox
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            '&.xs-only': {
              flexWrap: 'wrap',
            },
          }}
        >
          <TextBoldNormal>
            {referenceCompanies.find((item) => item['@id'] === data?.insuranceCompany)?.name}:
          </TextBoldNormal>
          <TextBoldNormal
            sx={{
              ml: '6px',
              color: colorsConfig.mainBlue,
            }}
          >
            {data?.dealName}
          </TextBoldNormal>
          {!xsOnly ? (
            <UIMarker
              text={
                insuranceDealWorkflowTypes.find(
                  (item) => item.systemName === data?.currentWorkflowState,
                )?.name
              }
              markerVariant={getSavedResponseClassName(data?.currentWorkflowState)}
              sx={{ ml: '10px' }}
            />
          ) : null}
        </RBox>
      </Box>
      <Box
        sx={{
          display: 'flex',
          gap: '30px',
        }}
      >
        <TextWithCaption
          text={getMonthlyInstallment(data?.totalPremium)}
          caption="Monthly instalment"
        />
        <TextWithCaption
          text={getTotalPremium(data?.totalPremium)}
          caption="Total annual instalment"
        />
        <TextWithCaption
          text={getDealerCommission(data?.totalDealerCommission, data?.totalPremium)}
          caption="Total dealer commission"
        />
      </Box>
      {data?.riskDetails?.length !== 0 ? (
        <Box sx={{ mt: '30px' }}>
          <TextBoldNormal sx={{ lineHeight: 'normal', mb: '16px' }}>Coverage:</TextBoldNormal>
          <Box
            sx={{
              display: 'flex',
              gap: '16px',
              flexWrap: 'wrap',
            }}
          >
            {data?.riskDetails?.map((item) => (
              <UIMarkerChecked
                key={generateUID()}
                text={referenceRisks.find((risk) => risk['@id'] === item?.risk)?.name}
              />
            ))}
          </Box>
        </Box>
      ) : null}
      {data?.comments && data?.comments.length > 0 ? (
        <Box
          sx={{
            mt: '25px',
          }}
        >
          <TextBoldNormal>Note from the company:</TextBoldNormal>
          <TextNormal>{data?.comments[0].comment}</TextNormal>
        </Box>
      ) : null}
      {data?.currentWorkflowState === 'insurance_company_request_info' ? (
        <RequestInfoForm deal={data} />
      ) : null}
    </BoxShadowedGradient>
  )
}

export default CarInsuranceResponsesItem
