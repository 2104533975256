import companiesApiService from '../../../api/companiesApiService/companiesApiService'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'
import { getApiError } from '../../../api/api'
import filesApiService from '../../../api/filesService/filesApiService'
import { FILE_TYPES } from '../../../config/default'
import managersApiService from '../../../api/managersApiService/managersApiService'

export const getCompany = createAsyncThunk(
  'company/getCompany',
  async ({ companyId }, { rejectWithValue }) => {
    try {
      const response = await companiesApiService.getCompany(companyId)
      return response.data
    } catch (e) {
      toast.error(getApiError(e))
      return rejectWithValue(e)
    }
  },
)

export const updateCompany = createAsyncThunk(
  'company/updateCompany',
  async ({ companyId, data }, { rejectWithValue }) => {
    try {
      const response = await companiesApiService.updateCompany(companyId, data)
      return response.data
    } catch (e) {
      toast.error(getApiError(e))
      return rejectWithValue(e)
    }
  },
)

export const createCompanyWithManager = createAsyncThunk(
  'company/createCompanyWithManager',
  async ({ companyData, managerData }, { rejectWithValue }) => {
    try {
      const fileRes = await filesApiService.createFile(
        companyData?.tradeLicense,
        FILE_TYPES.tradeLicense,
      )
      const companyFinalData = {
        ...companyData,
        tradeLicense: fileRes.data?.['@id'],
      }
      const companyRes = await companiesApiService.createCompany(companyFinalData)
      const managerFinalData = {
        ...managerData,
        company: companyRes.data?.['@id'],
      }
      const managerRes = await managersApiService.createManager(managerFinalData)
      return {
        company: companyRes.data,
        manager: managerRes.data,
      }
    } catch (e) {
      toast.error(getApiError(e))
      return rejectWithValue(e)
    }
  },
)
