import { getExtendedDate } from '../../../../utils/helpers/date.helpers'
import {
  policyWorkflowTransitionInitiator,
  policyWorkflowTransitionMessage,
} from '../../../../pages/RVI/policies.constants'

export const getPolicyLoggingNodesData = (nodes, locale) => {
  if (!nodes) return []
  return nodes.map((item) => {
    return {
      initialData: item,
      id: item?.id,
      timestamp: getExtendedDate(item?.createdAt, locale),
      message: policyWorkflowTransitionMessage[item?.transition],
      initiator: policyWorkflowTransitionInitiator[item?.transition],
    }
  })
}
