import React, { useEffect, useState } from 'react'
import TextBoldNormal from '../../../../../ui/Text/TextBoldNormal'
import { Box, Button, IconButton, useMediaQuery } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { updateDeal } from '../../../../../store/features/insuranceSlice'
import { colorsConfig } from '../../../../../config/themeConfig'
import { FileUploader } from 'react-drag-drop-files'
import IconFolder from '../../../../../assests/img/icons/icon-folder.svg'
import TextNormal from '../../../../../ui/Text/TextNormal'
import IconUploadFile from '../../../../../assests/img/icons/icon-upload-file.svg'
import TextFieldSmallEdible from '../../../../../ui/TextFieldSmallEdible'
import IconDocument from '../../../../../assests/img/icons/icon-appraisal-document.svg'
import { DownloadRounded, HighlightOffRounded } from '@mui/icons-material'
import ButtonHollow from '../../../../../ui/Buttons/ButtonHollow'
import { uploadFile } from '../../../../../utils/uploadFile'
import { patchRequest, privateRequest } from '../../../../../utils/request'
import { API_ENDPOINTS } from '../../../../../config/default'
import { toast } from 'react-toastify'

const RequestInfoForm = ({ deal }) => {
  const xsOnly = useMediaQuery('(max-width: 600px)')
  const selectedTab = useSelector((state) => state.insurance.selectedTab)
  const application = useSelector((state) => state.insurance.applications[selectedTab])
  const dispatch = useDispatch()

  const [note, setNote] = useState('')
  const [additionalDocuments, setAdditionalDocuments] = useState([])
  const [buttonDisabled, setButtonDisabled] = useState(false)

  const handleSetFiles = (files) => {
    setAdditionalDocuments([...additionalDocuments, ...Array.from(files)])
  }

  const handleDeleteFile = (index) => {
    setAdditionalDocuments(additionalDocuments.filter((item, i) => i !== index))
  }

  const handleSubmit = () => {
    setButtonDisabled(true)
    Promise.all(additionalDocuments.map((item) => uploadFile(item, 'default')))
      .then((result) => {
        const formData = {
          noteToTheInsuranceCompany: note,
          attachments: result.map((item) => ({
            attachment: item,
          })),
        }
        patchRequest
          .patch(API_ENDPOINTS.insurance.dealByApplication(application?.id, deal?.id), formData)
          .then(() => {
            privateRequest
              .post(
                API_ENDPOINTS.insurance.dealWorkflows.approvalRequested(application.id, deal?.id),
                {},
              )
              .then(() => {
                dispatch(
                  updateDeal({
                    id: deal?.id,
                    data: {
                      ...deal,
                      currentWorkflowState: 'insurance_company_sent',
                    },
                  }),
                )
                toast.success('Your response has been sent to the insurance company')
              })
          })
          .finally(() => setButtonDisabled(false))
      })
      .catch(() => setButtonDisabled(false))
  }

  useEffect(() => {
    setNote('')
    setAdditionalDocuments([])
  }, [selectedTab])

  return (
    <Box
      sx={{
        mt: '25px',
      }}
    >
      <TextBoldNormal>Additional documents requested:</TextBoldNormal>
      <Box
        className={xsOnly ? 'xs-only' : ''}
        sx={{
          mt: '17px',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
          '&.xs-only': {
            display: 'block',
          },
        }}
      >
        <Box
          className={xsOnly ? 'xs-only' : ''}
          sx={{
            width: '336px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
            background: colorsConfig.bgLightBlue,
            borderRadius: '6px',
            '&.xs-only': {
              width: '100%',
            },
          }}
        >
          <Box
            className={xsOnly ? 'xs-only' : ''}
            sx={{
              width: '190px',
              position: 'relative',
              border: 'none',
              backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='6' ry='6' stroke='%23007DFF' stroke-width='3' stroke-dasharray='10%2c 10' stroke-dashoffset='10' stroke-linecap='round'/%3e%3c/svg%3e")`,
              borderRadius: '6px',
              '&.xs-only': {
                width: '178px',
              },
            }}
          >
            <FileUploader
              name="file"
              types={['JPG', 'PNG', 'pdf', 'doc', 'jpeg']}
              hoverTitle="Drop here"
              multiple
              label="Drag & Drop files here"
              classes="upload-picture"
              maxSize={10}
              handleChange={handleSetFiles}
              onDrop={handleSetFiles}
              children={
                <Box
                  className={xsOnly ? 'xs-only' : ''}
                  sx={{
                    borderRadius: '6px',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%',
                    width: '100%',
                    background: 'none',
                    cursor: 'pointer',
                    px: '20px',
                    py: '24px',
                    '&.xs-only': {
                      px: '14px',
                    },
                  }}
                >
                  <img src={IconFolder} alt="" />
                  <TextNormal
                    sx={{
                      ml: '6px',
                      lineHeight: 'normal',
                    }}
                  >
                    Drag & Drop <br />
                    files here
                  </TextNormal>
                </Box>
              }
              sx={{
                width: '100%',
                position: 'relative',
                'label.is-disabled': {
                  border: 'none',
                  backgroundColor: '#F7FAFB',
                  backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='6' ry='6' stroke='%23007DFF' stroke-width='3' stroke-dasharray='10%2c 10' stroke-dashoffset='10' stroke-linecap='round'/%3e%3c/svg%3e")`,
                  borderRadius: '6px',
                },
                '& .MuiTypography-root': {
                  width: '100px',
                  marginTop: '-3px',
                },
              }}
            />
          </Box>
          <Box
            className={xsOnly ? 'xs-only' : ''}
            sx={{
              display: 'flex',
              alignItems: 'center',
              pl: '33px',
              '&.xs-only': {
                pl: 0,
                width: 'calc(100% - 178px)',
                maxWidth: '150px',
                justifyContent: 'center',
              },
            }}
          >
            <Button
              className={xsOnly ? 'xs-only' : ''}
              component={'label'}
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                textTransform: 'none',
                py: '8px',
                width: '100%',
                '&:hover': {
                  '& p': {
                    color: colorsConfig.buttonHover,
                    transition: '0.25s color ease-in-out',
                  },
                },
                '&.xs-only': {
                  flexDirection: 'column',
                  gap: '4px',
                },
              }}
            >
              <img src={IconUploadFile} alt="" />
              <TextBoldNormal
                className={xsOnly ? 'xs-only' : ''}
                sx={{
                  fonSize: '18px',
                  color: colorsConfig.mainBlue,
                  ml: '5px',
                  '&.xs-only': {
                    ml: 0,
                  },
                }}
              >
                Upload
              </TextBoldNormal>
              <input
                hidden
                type="file"
                multiple
                onChange={(e) => {
                  handleSetFiles(e.target.files)
                }}
              />
            </Button>
          </Box>
        </Box>
        <Box
          className={xsOnly ? 'xs-only' : ''}
          sx={{
            flex: 1,
            ml: '24px',
            '&.xs-only': {
              ml: 0,
              mt: '20px',
            },
          }}
        >
          <TextFieldSmallEdible
            fullWidth
            label={'Note to the bank'}
            multiline
            minRows={3}
            sx={{
              '& .MuiInputBase-root': {
                height: '88px',
              },
            }}
            value={note}
            onChange={(e) => {
              setNote(e.target.value)
            }}
          />
        </Box>
      </Box>
      {additionalDocuments.filter((item) => !item.deleted).length > 0 ? (
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            gap: '5px',
            mt: '10px',
          }}
        >
          {additionalDocuments.map((item, index) => {
            return (
              <Box
                key={index}
                sx={{
                  position: 'relative',
                }}
              >
                <IconButton
                  sx={{
                    width: '70px',
                    borderRadius: '6px',
                    '& img': {
                      width: '90%',
                    },
                    '&:hover': {
                      '& + .overlay-download': {
                        opacity: 1,
                        transition: '0.25s all ease-in-out',
                      },
                    },
                  }}
                  key={index}
                >
                  <img src={IconDocument} alt="" />
                </IconButton>
                <Box
                  className={'overlay-download'}
                  sx={{
                    width: '100%',
                    height: '100%',
                    opacity: 0,
                    top: 0,
                    left: 0,
                    position: 'absolute',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    background: 'rgba(0,0,0,.1)',
                    borderRadius: '6px',
                    pointerEvents: 'none',
                  }}
                >
                  <DownloadRounded
                    sx={{
                      fill: 'white',
                      opacity: '1',
                    }}
                  />
                </Box>
                <IconButton
                  sx={{
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    borderRadius: '6px',
                    width: '23px',
                    height: '23px',
                  }}
                  onClick={() => {
                    handleDeleteFile(index)
                  }}
                >
                  <HighlightOffRounded
                    sx={{
                      fill: colorsConfig.darkRed,
                      fontSize: '18px',
                    }}
                  />
                </IconButton>
              </Box>
            )
          })}
        </Box>
      ) : null}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          mt: '10px',
        }}
      >
        <ButtonHollow
          fullWidth={xsOnly}
          disabled={additionalDocuments.length === 0 || buttonDisabled}
          onClick={() => {
            handleSubmit()
          }}
        >
          Resend
        </ButtonHollow>
      </Box>
    </Box>
  )
}

export default RequestInfoForm
