import { INITIATOR } from '../../utils/constants/global.constants'
import {
  policyWorkflowStateNameKeys,
  policyWorkflowStates,
} from '../../utils/constants/workflows.constants'
import { getPolicyWorkflowStateCount } from './policies.helpers'

export const closingReasonEnumNames = {
  END_OF_CONTRACT: 'END_OF_CONTRACT',
  TOTAL_DAMAGE: 'TOTAL_DAMAGE',
  CUSTOMER_BUYOUT: 'CUSTOMER_BUYOUT',
  VEHICLE_SOLD: 'VEHICLE_SOLD',
  EARLY_CONTRACT_TERMINATION: 'EARLY_CONTRACT_TERMINATION',
  OTHER_REASON: 'OTHER_REASON',
}

export const closingReasonEnumsArray = [
  {
    value: 0,
    name: 'Unknown',
  },
  {
    value: 1,
    name: 'Restitution',
  },
  {
    value: 2,
    name: 'End of the contract',
  },
  {
    value: 3,
    name: 'Total damage',
  },
  {
    value: 4,
    name: 'Customer buyout',
  },
  {
    value: 5,
    name: 'Vehicle sold',
  },
  {
    value: 6,
    name: 'Early contract termination',
  },
  {
    value: 7,
    name: 'Other',
  },
]

export const closingReasonOptions = [
  {
    label: 'policy_closing_reason_end_of_contract',
    value: closingReasonEnumNames.END_OF_CONTRACT,
  },
  {
    label: 'policy_closing_reason_total_damage',
    value: closingReasonEnumNames.TOTAL_DAMAGE,
  },
  {
    label: 'policy_closing_reason_customer_buyout',
    value: closingReasonEnumNames.CUSTOMER_BUYOUT,
  },
  {
    label: 'policy_closing_reason_vehicle_sold',
    value: closingReasonEnumNames.VEHICLE_SOLD,
  },
  {
    label: 'policy_closing_reason_early_contract_termination',
    value: closingReasonEnumNames.EARLY_CONTRACT_TERMINATION,
  },
  {
    label: 'policy_closing_reason_other',
    value: closingReasonEnumNames.OTHER_REASON,
  },
]

export const policyWorkflowTransition = {
  confirm_to_linked_to_dealer: 'confirm_to_linked_to_dealer',
  confirm_to_restitution_started: 'confirm_to_restitution_started',
  confirm_to_restitution_in_progress: 'confirm_to_restitution_in_progress',
  close_due_to_restitution: 'close_due_to_restitution',
  close_due_to_early_contract_termination: 'close_due_to_early_contract_termination',
  close_due_to_vehicle_sold: 'close_due_to_vehicle_sold',
  close_due_to_customer_buyout: 'close_due_to_customer_buyout',
  close_due_to_total_damage: 'close_due_to_total_damage',
  close_due_to_end_of_contract: 'close_due_to_end_of_contract',
  close_due_to_other_reason: 'close_due_to_other_reason',
  cancel: 'cancel',
}

export const policyWorkflowTransitionMessage = {
  [policyWorkflowTransition.confirm_to_linked_to_dealer]:
    'workflow_transition_policy_confirm_to_linked_to_dealer_message',
  [policyWorkflowTransition.confirm_to_restitution_started]:
    'workflow_transition_policy_confirm_to_restitution_started_message',
  [policyWorkflowTransition.confirm_to_restitution_in_progress]:
    'workflow_transition_policy_confirm_to_restitution_in_progress_message',
  [policyWorkflowTransition.close_due_to_restitution]:
    'workflow_transition_policy_close_due_to_restitution_message',
  [policyWorkflowTransition.close_due_to_early_contract_termination]:
    'workflow_transition_policy_close_due_to_early_contract_termination_message',
  [policyWorkflowTransition.close_due_to_customer_buyout]:
    'workflow_transition_policy_close_due_to_customer_buyout_message',
  [policyWorkflowTransition.close_due_to_end_of_contract]:
    'workflow_transition_policy_close_due_to_end_of_contract_message',
  [policyWorkflowTransition.close_due_to_other_reason]:
    'workflow_transition_policy_close_due_to_other_reason_message',
  [policyWorkflowTransition.close_due_to_vehicle_sold]:
    'workflow_transition_policy_close_due_to_vehicle_sold_message',
  [policyWorkflowTransition.close_due_to_total_damage]:
    'workflow_transition_policy_close_due_to_total_damage_message',
  [policyWorkflowTransition.cancel]: 'workflow_transition_policy_cancel_message',
}

export const policyWorkflowTransitionInitiator = {
  [policyWorkflowTransition.confirm_to_linked_to_dealer]: INITIATOR.SYSTEM,
  [policyWorkflowTransition.confirm_to_restitution_started]: INITIATOR.DEALER,
  [policyWorkflowTransition.confirm_to_restitution_in_progress]: INITIATOR.SYSTEM,
  [policyWorkflowTransition.close_due_to_restitution]: INITIATOR.SYSTEM,
  [policyWorkflowTransition.close_due_to_early_contract_termination]: INITIATOR.SYSTEM,
  [policyWorkflowTransition.close_due_to_vehicle_sold]: INITIATOR.SYSTEM,
  [policyWorkflowTransition.close_due_to_total_damage]: INITIATOR.SYSTEM,
  [policyWorkflowTransition.cancel]: INITIATOR.SYSTEM,
  [policyWorkflowTransition.close_due_to_other_reason]: INITIATOR.SYSTEM,
  [policyWorkflowTransition.close_due_to_end_of_contract]: INITIATOR.SYSTEM,
  [policyWorkflowTransition.close_due_to_customer_buyout]: INITIATOR.SYSTEM,
}

export const activePoliciesDealerFilterStatusOptions = (statusCounts) => [
  {
    value: '',
    label: 'common_all',
    count: statusCounts?.active,
  },
  {
    value: policyWorkflowStates.linked_to_dealer,
    label: policyWorkflowStateNameKeys[policyWorkflowStates.linked_to_dealer],
    count: getPolicyWorkflowStateCount(policyWorkflowStates.linked_to_dealer, statusCounts.data),
  },
  {
    value: policyWorkflowStates.restitution_started,
    label: policyWorkflowStateNameKeys[policyWorkflowStates.restitution_started],
    count: getPolicyWorkflowStateCount(policyWorkflowStates.restitution_started, statusCounts.data),
  },
  {
    value: policyWorkflowStates.restitution_in_progress,
    label: policyWorkflowStateNameKeys[policyWorkflowStates.restitution_in_progress],
    count: getPolicyWorkflowStateCount(
      policyWorkflowStates.restitution_in_progress,
      statusCounts.data,
    ),
  },
]
