import React from 'react'
import { Box, Grid } from '@mui/material'
import TextNormal from '../../../ui/Text/TextNormal'
import TextFieldSmallEdible from '../../../ui/TextFieldSmallEdible'
import { sumDelimiter } from '../../../utils/sumDelimiter'
import UIInputAdornmentText from '../../../ui/UIFormComponents/UIInputAdornmentText'
import CheckmarkBlack from '../../../ui/CheckmarkBlack'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { useTranslation } from 'react-i18next'
import { formErrors } from '../../../components/Forms/FormTemplates/formErrors'

const CarLastServiceFields = ({ formData, setFormData, showErrors }) => {
  const { t } = useTranslation()

  return (
    <Grid container spacing={3} sx={{ mt: '7px' }}>
      <Grid item xs={12} md={4}>
        <LocalizationProvider sx={{ width: '100%' }} fullWidth dateAdapter={AdapterDayjs}>
          <DesktopDatePicker
            label={t('form_input_label_last_service_date')}
            inputFormat={'DD/MM/YYYY'}
            mask={'__/__/____'}
            disableMaskedInput={false}
            renderInput={(params) => (
              <TextFieldSmallEdible
                {...params}
                required
                disabled={formData.noCarService}
                fullWidth
                value={formData?.lastServiceDate ?? ''}
                error={showErrors && !formData.noCarService && !formData.lastServiceDate}
                helperText={
                  showErrors && !formData.noCarService && !formData.lastServiceDate
                    ? formErrors.notEmpty
                    : ''
                }
              />
            )}
            onChange={(newVal) => {
              setFormData({
                ...formData,
                lastServiceDate: newVal,
              })
            }}
            value={formData?.lastServiceDate}
            views={['year', 'month', 'day']}
            disableFuture
          />
        </LocalizationProvider>
      </Grid>
      <Grid item xs={12} md={4}>
        <TextFieldSmallEdible
          required
          disabled={formData.noCarService}
          fullWidth
          label="form_input_label_last_service_mileage"
          value={sumDelimiter(formData.lastServiceMileage)}
          inputProps={{
            inputMode: 'numeric',
          }}
          onChange={(e) => {
            setFormData({
              ...formData,
              lastServiceMileage: e.target.value.replace(/[^0-9]/g, ''),
            })
          }}
          InputProps={{
            endAdornment: <UIInputAdornmentText text="km" />,
          }}
          error={showErrors && !formData.noCarService && !formData.lastServiceMileage}
          helperText={
            showErrors && !formData.noCarService && !formData.lastServiceMileage
              ? formErrors.notEmpty
              : ''
          }
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            height: '37px',
          }}
        >
          <CheckmarkBlack
            value={formData.noCarService}
            setValue={(newVal) => {
              setFormData({
                ...formData,
                noCarService: newVal,
              })
            }}
          />
          <TextNormal
            sx={{
              ml: '6px',
            }}
          >
            {t('form_input_label_no_car_service')}
          </TextNormal>
        </Box>
      </Grid>
    </Grid>
  )
}

export default CarLastServiceFields
