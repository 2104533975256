export const getYearsFromApproximateDate = (date) => {
  const currentDate = new Date()
  const timeDifference = currentDate - date
  const ageInYears = Math.floor(timeDifference / (365.25 * 24 * 60 * 60 * 1000))
  let yearsString = ''
  switch (ageInYears) {
    case 1:
      yearsString = '1 years'
      break
    case 2:
      yearsString = '2 years'
      break
    case 3:
      yearsString = '3 years'
      break
    case 4:
      yearsString = '4 years'
      break
    case 5:
      yearsString = '5 years'
      break
    case 6:
      yearsString = '> 5 years'
      break
    case 0:
      yearsString = '< 1 year'
      break
  }
  return yearsString
}

export const getYearsDifferenceFromNow = (date) => {
  const currentDate = new Date()
  const timeDifference = currentDate - date
  const ageInYears = Math.floor(timeDifference / (365.25 * 24 * 60 * 60 * 1000))
  return ageInYears
}

export const formatDate = (inputDate) => {
  const date = new Date(inputDate)
  const options = { day: '2-digit', month: '2-digit', year: 'numeric' }
  const formattedDate = date.toLocaleDateString('en-GB', options).replaceAll('/', '.')
  return formattedDate
}

export const getFormattedDate = (inputDate, format = 'dd.MM.yyyy') => {
  if (!inputDate) return null

  let date
  if (typeof inputDate === 'string' || typeof inputDate === 'number') {
    date = new Date(inputDate)
  } else if (inputDate instanceof Date) {
    date = inputDate
  } else {
    return null
  }
  if (isNaN(date.getTime())) return null
  const pad = (num) => num.toString().padStart(2, '0')

  const day = pad(date.getDate())
  const month = pad(date.getMonth() + 1)
  const year = date.getFullYear()

  const hours = pad(date.getHours())
  const minutes = pad(date.getMinutes())
  const seconds = pad(date.getSeconds())

  switch (format) {
    case 'hh:mm:ss':
      return `${hours}:${minutes}:${seconds}`
    case 'dd.MM.yyyy hh:mm:ss':
      return `${day}.${month}.${year} ${hours}:${minutes}:${seconds}`
    default:
      return `${day}.${month}.${year}`
  }
}

export const getRequestMarkerClass = (targetDate) => {
  const today = new Date()
  const endDate = new Date(targetDate)
  today.setHours(0, 0, 0, 0)
  endDate.setHours(0, 0, 0, 0)
  if (endDate < today) {
    return 'danger'
  }
  let workingDays = 0
  let currentDate = new Date(today)
  while (currentDate <= endDate) {
    const dayOfWeek = currentDate.getDay()
    if (dayOfWeek !== 0 && dayOfWeek !== 6) {
      workingDays++
    }
    currentDate.setDate(currentDate.getDate() + 1)
  }
  if (workingDays <= 14) {
    return 'danger'
  } else if (workingDays <= 21) {
    return 'attention'
  } else {
    return 'success'
  }
}
