import React from 'react'
import UIModal from '../../../ui/UIModal/UIModal'
import { Stack } from '@mui/material'
import AddCompanyAndManagerForm from '../../Forms/FormTemplates/AddCompanyAndManagerForm/AddCompanyAndManagerForm'
import UITextHeadlineLarge from '../../../ui/UIText/UITextHeadlineLarge'

const AddCompanyAndManagerModal = ({ open, setOpen, onSubmit }) => {
  return (
    <UIModal width="800px" open={open} setOpen={setOpen}>
      <Stack gap="30px">
        <UITextHeadlineLarge text="Add a new dealer" />
        <AddCompanyAndManagerForm onClose={() => setOpen(false)} onSubmit={onSubmit} />
      </Stack>
    </UIModal>
  )
}

export default AddCompanyAndManagerModal
