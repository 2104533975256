import React, { useEffect, useState } from 'react'
import { Stack, useMediaQuery } from '@mui/material'
import BoxShadowed from '../../../ui/Layout/BoxShadowed'
import SearchInput from '../../../components/Search/SearchInput'
import UIPreloader from '../../../ui/UIPreloader/UIPreloader'
import { getRviTableData } from '../../../utils/helpers/tables/table.helpers'
import { setCurrentPageTitle } from '../../../store/features/authSlice'
import { useDispatch, useSelector } from 'react-redux'
import { policiesTableHead } from '../../../utils/constants/tables/table.constants'
import UITable from '../../../ui/UITable/UITable'
import RVIPoliciesActionItems from './RVIPoliciesActionItems'
import UITabs from '../../../ui/UITabs/UITabs'
import UITab from '../../../ui/UITabs/UITab'
import {
  getActivePolicies,
  getClosedPolicies,
  getPoliciesStatusCount,
} from '../../../store/features/rviDealer/rviDealer.actions'
import {
  initialActivePoliciesParams,
  initialClosedPoliciesParams,
  setActivePoliciesParams,
  setClosedPoliciesParams,
} from '../../../store/features/rviDealer/rviDealer.reducer'
import UITableWrapper from '../../../ui/UITable/UITableWrapper'
import UITablePagination from '../../../ui/UITable/UITablePagination'
import { useTranslation } from 'react-i18next'
import UITableStatusFilter from '../../../ui/UITable/UITableStatusFilter'
import { activePoliciesDealerFilterStatusOptions } from '../policies.constants'

const RVIPolicies = () => {
  const { t } = useTranslation()

  const isLoadingActivePolicies = useSelector((state) => state.rviDealer.activePolicies.isLoading)
  const activePolicies = useSelector((state) => state.rviDealer.activePolicies.data)
  const totalActivePolicies = useSelector((state) => state.rviDealer.activePolicies.total)
  const activePoliciesParams = useSelector((state) => state.rviDealer.activePolicies.params)

  const isLoadingClosedPolicies = useSelector((state) => state.rviDealer.closedPolicies.isLoading)
  const closedPolices = useSelector((state) => state.rviDealer.closedPolicies.data)
  const totalClosedPolicies = useSelector((state) => state.rviDealer.closedPolicies.total)
  const closedPoliciesParams = useSelector((state) => state.rviDealer.closedPolicies.params)

  const statusCount = useSelector((state) => state.rviDealer.statusCount)

  const dispatch = useDispatch()
  const xsOnly = useMediaQuery('(max-width: 600px)')

  const [tab, setTab] = useState(0)

  const handleSetTab = (newVal) => {
    setTab(newVal)
    dispatch(setActivePoliciesParams(initialActivePoliciesParams))
    dispatch(setClosedPoliciesParams(initialClosedPoliciesParams))
  }

  useEffect(() => {
    if (tab === 0) {
      dispatch(getActivePolicies())
    } else if (tab === 1) {
      dispatch(getClosedPolicies())
    }
  }, [tab, closedPoliciesParams, activePoliciesParams])

  useEffect(() => {
    dispatch(getPoliciesStatusCount())
    dispatch(setCurrentPageTitle('page_title_rvi'))
  }, [])

  return (
    <Stack gap="30px">
      <BoxShadowed
        className={xsOnly ? 'xs-only' : ''}
        sx={{
          '&.xs-only': {
            p: 0,
            boxShadow: 'none',
          },
        }}
      >
        <Stack gap="30px">
          <Stack
            className={xsOnly ? 'xs-only' : ''}
            direction={{ xs: 'column', md: 'row' }}
            justifyContent="space-between"
            alignItems="center"
            sx={{
              '&.xs-only': {
                px: '16px',
              },
            }}
            gap={{
              xs: '20px',
              md: 0,
            }}
          >
            <UITabs value={tab} onChange={(e, newVal) => handleSetTab(newVal)}>
              <UITab label={`${t('tabs_active_policies')} (${statusCount.active})`} />
              <UITab label={`${t('tabs_closed')} (${statusCount.closed})`} />
            </UITabs>
            {tab === 0 ? (
              <Stack
                sx={{ width: { xs: '100%', md: 'auto' } }}
                gap={{ xs: '8px', md: '20px' }}
                direction={{ xs: 'column-reverse', md: 'row' }}
                alignItems={{ xs: 'flex-end', md: 'center' }}
              >
                <SearchInput
                  fullWidth={xsOnly}
                  value={activePoliciesParams.policyExtendedSearch}
                  onChange={(e) =>
                    dispatch(
                      setActivePoliciesParams({
                        ...activePoliciesParams,
                        policyExtendedSearch: e.target.value,
                        page: 1,
                      }),
                    )
                  }
                />
                <UITableStatusFilter
                  options={activePoliciesDealerFilterStatusOptions(statusCount)}
                  value={activePoliciesParams.currentWorkflowState}
                  onChange={(val) =>
                    dispatch(
                      setActivePoliciesParams({
                        ...activePoliciesParams,
                        page: 1,
                        currentWorkflowState: val,
                      }),
                    )
                  }
                />
              </Stack>
            ) : tab === 1 ? (
              <SearchInput
                fullWidth={xsOnly}
                value={closedPoliciesParams.policyExtendedSearch}
                onChange={(e) =>
                  dispatch(
                    setClosedPoliciesParams({
                      ...closedPoliciesParams,
                      policyExtendedSearch: e.target.value,
                      page: 1,
                    }),
                  )
                }
              />
            ) : null}
          </Stack>

          {tab === 0 ? (
            <>
              <UITableWrapper>
                {isLoadingActivePolicies ? <UIPreloader /> : null}
                <UITable
                  head={policiesTableHead}
                  content={getRviTableData(activePolicies, t)}
                  emptyText="placeholder_policies_empty"
                  renderActionItems={(data) => {
                    return <RVIPoliciesActionItems data={data} />
                  }}
                />
              </UITableWrapper>
              <UITablePagination
                count={totalActivePolicies}
                page={activePoliciesParams.page}
                rowsPerPage={activePoliciesParams.itemsPerPage}
                onPageChange={(e, newValue) => {
                  dispatch(
                    setActivePoliciesParams({
                      ...activePoliciesParams,
                      page: newValue,
                    }),
                  )
                }}
                onRowsPerPageChange={(e) => {
                  dispatch(
                    setActivePoliciesParams({
                      ...activePoliciesParams,
                      page: 1,
                      itemsPerPage: e.target.value,
                    }),
                  )
                }}
              />
            </>
          ) : tab === 1 ? (
            <>
              <UITableWrapper>
                {isLoadingClosedPolicies ? <UIPreloader /> : null}
                <UITable
                  head={policiesTableHead}
                  content={getRviTableData(closedPolices, t)}
                  emptyText="No policies found."
                  renderActionItems={(data) => {
                    return <RVIPoliciesActionItems data={data} />
                  }}
                />
              </UITableWrapper>
              <UITablePagination
                count={totalClosedPolicies}
                page={closedPoliciesParams.page}
                rowsPerPage={closedPoliciesParams.itemsPerPage}
                onPageChange={(e, newValue) => {
                  dispatch(
                    setClosedPoliciesParams({
                      ...closedPoliciesParams,
                      page: newValue,
                    }),
                  )
                }}
                onRowsPerPageChange={(e) => {
                  dispatch(
                    setClosedPoliciesParams({
                      ...closedPoliciesParams,
                      page: 1,
                      itemsPerPage: e.target.value,
                    }),
                  )
                }}
              />
            </>
          ) : null}
        </Stack>
      </BoxShadowed>
    </Stack>
  )
}

export default RVIPolicies
