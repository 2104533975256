import React from 'react'
import { IconButton } from '@mui/material'
import Tooltip from '@material-ui/core/Tooltip'
import IconWA from '../../../assests/img/icons/icon-wa.svg'
import { useTranslation } from 'react-i18next'

const ButtonLinkWA = ({
  title = 'button_share_via_whatsapp',
  message,
  sx,
  recipient,
  ...props
}) => {
  const { t } = useTranslation()
  return (
    <Tooltip title={t(title)}>
      <IconButton
        component="a"
        href={`https://wa.me/${recipient ?? ''}?text=${encodeURIComponent(message)}`}
        target="_blank"
        sx={{
          width: '50px',
          height: '50px',
          borderRadius: '6px',
          ...sx,
        }}
        {...props}
      >
        <img
          src={IconWA}
          alt="WhatsApp"
          style={{
            width: '80%',
          }}
        />
      </IconButton>
    </Tooltip>
  )
}

export default ButtonLinkWA
