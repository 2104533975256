import React, { useState } from 'react'
import { Box, Grid, IconButton, Modal, Typography, useMediaQuery } from '@mui/material'
import IconAppraisalIntro from '../../assests/img/icons/icon-appraisal-intro.svg'
import ImageAppraisalIntro1 from '../../assests/img/appraisal-intro-1.png'
import ImageAppraisalIntro2 from '../../assests/img/appraisal-intro-2.png'
import ImageAppraisalIntro3 from '../../assests/img/appraisal-intro-3.png'
import { colorsConfig } from '../../config/themeConfig'
import ButtonAttention from '../../ui/Buttons/ButtonAttention'
import { Close } from '@mui/icons-material'
import { useSelector } from 'react-redux'
import EditVehicle from '../EditVehicle'
import TextH1 from '../../ui/Text/TextH1'
import TextNormal from '../../ui/Text/TextNormal'
import SendToCompoundModal from '../SendToCompound/SendToCompoundModal'
import { useTranslation } from 'react-i18next'

const AppraisalIntro = () => {
  const { t } = useTranslation()
  const xsOnly = useMediaQuery('(max-width: 600px)')
  const tabletOnly = useMediaQuery('(min-width: 600px) and (max-width: 1200px)')

  const carData = useSelector((state) => state.car.carData)

  const [sendToCompoundModal, setSendToCompoundModal] = useState(false)

  return (
    <Box
      className={xsOnly ? 'xs-only' : ''}
      sx={{
        mt: '60px',
        '&.xs-only': {
          mt: '40px',
        },
      }}
    >
      {carData.combinedStatus && carData.combinedStatus === 'car_workflow.moderation_failed' ? (
        <EditVehicle mode={'fromAppraisal'} />
      ) : (
        <Box>
          <Box
            className={xsOnly ? 'xs-only' : ''}
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              mb: '15px',
              '&.xs-only': {
                justifyContent: 'flex-start',
              },
            }}
          >
            <Box
              className={xsOnly ? 'xs-only' : ''}
              sx={{
                '&.xs-only': {
                  display: 'none',
                },
              }}
            >
              <img src={IconAppraisalIntro} alt="" />
            </Box>
            <TextH1
              className={xsOnly ? 'xs-only' : ''}
              sx={{
                ml: '25px',
                '&.xs-only': {
                  ml: 0,
                  fontSize: '32px',
                },
              }}
            >
              {t('title_car_appraisal_introduction')}
            </TextH1>
          </Box>
          <TextNormal
            className={xsOnly ? 'xs-only' : ''}
            sx={{
              textAlign: 'center',
              mb: '60px',
              lineHeight: '18.4px',
              '&.xs-only': {
                textAlign: 'left',
                fontSize: '14px',
                lineHeight: 'normal',
                mb: '10px',
              },
            }}
          >
            {t('p_appraisal_intro_1')}
          </TextNormal>
          <Grid container spacing={xsOnly ? 0 : 2}>
            <Grid item md={6} xs={12}>
              <Box
                className={xsOnly ? 'xs-only' : ''}
                sx={{
                  mb: '24px',
                  '&.xs-only': {
                    mb: '19px',
                  },
                }}
              >
                <img src={ImageAppraisalIntro1} style={{ width: '100%' }} alt="" />
              </Box>
              {xsOnly ? (
                <TextNormal
                  className={xsOnly ? 'xs-only' : ''}
                  sx={{
                    mb: '25px',
                    lineHeight: '18.4px',
                    '&.xs-only': {
                      fontSize: '14px',
                      lineHeight: 'normal',
                      mb: '10px',
                    },
                  }}
                >
                  {t('p_appraisal_intro_3')}
                </TextNormal>
              ) : null}
              {!xsOnly ? (
                <>
                  <TextNormal
                    className={xsOnly ? 'xs-only' : ''}
                    sx={{
                      mb: '24px',
                      lineHeight: '18.4px',
                      '&.xs-only': {
                        fontSize: '14px',
                        lineHeight: 'normal',
                        mb: '10px',
                      },
                    }}
                  >
                    {t('p_appraisal_intro_2')}
                  </TextNormal>
                  <ButtonAttention
                    className={[xsOnly ? 'xs-only' : '', tabletOnly ? 'tablet-only' : ''].join(' ')}
                    onClick={() => {
                      setSendToCompoundModal(true)
                    }}
                    sx={{
                      mb: '25px',
                      '&.xs-only': {
                        width: '100%',
                      },
                      '&.tablet-only': {
                        px: '40px',
                      },
                    }}
                  >
                    {t('button_send_to_our_compound')}
                  </ButtonAttention>
                </>
              ) : null}
              <Box
                className={xsOnly ? 'xs-only' : ''}
                sx={{
                  '&.xs-only': {
                    mb: '20px',
                  },
                }}
              >
                <img src={ImageAppraisalIntro2} style={{ width: '100%' }} alt="" />
              </Box>
            </Grid>
            <Grid item md={6} xs={12}>
              {!xsOnly ? (
                <TextNormal
                  className={xsOnly ? 'xs-only' : ''}
                  sx={{
                    mb: '25px',
                    lineHeight: '18.4px',
                    '&.xs-only': {
                      fontSize: '14px',
                      lineHeight: 'normal',
                    },
                  }}
                >
                  {t('p_appraisal_intro_3')}
                </TextNormal>
              ) : null}
              {xsOnly ? (
                <>
                  <TextNormal
                    className={xsOnly ? 'xs-only' : ''}
                    sx={{
                      mb: '24px',
                      lineHeight: '18.4px',
                      '&.xs-only': {
                        fontSize: '14px',
                        lineHeight: 'normal',
                        mb: '10px',
                      },
                    }}
                  >
                    {t('p_appraisal_intro_2')}
                  </TextNormal>
                  <ButtonAttention
                    className={xsOnly ? 'xs-only' : ''}
                    onClick={() => {
                      setSendToCompoundModal(true)
                    }}
                    sx={{
                      mb: '25px',
                      '&.xs-only': {
                        width: '100%',
                      },
                    }}
                  >
                    {t('button_send_to_our_compound')}
                  </ButtonAttention>
                </>
              ) : null}
              <Box
                className={xsOnly ? 'xs-only' : ''}
                sx={{
                  mb: '24px',
                  '&.xs-only': {
                    mb: '10px',
                  },
                }}
              >
                <img src={ImageAppraisalIntro3} style={{ width: '100%' }} alt="" />
              </Box>
              <TextNormal
                className={xsOnly ? 'xs-only' : ''}
                sx={{
                  lineHeight: '18.4px',
                  '&.xs-only': {
                    lineHeight: 'normal',
                    fontSize: '14px',
                  },
                }}
              >
                {t('p_appraisal_intro_4')}
                <br />
                <br />
                {t('p_appraisal_intro_5')}
              </TextNormal>
            </Grid>
          </Grid>
          <Modal
            open={sendToCompoundModal}
            onClose={() => {
              setSendToCompoundModal(false)
            }}
          >
            <Box
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 430,
                background: 'white',
                borderRadius: '10px',
                padding: '55px',
                maxHeight: '80vh',
                overflow: 'auto',
              }}
              className={'disable-scrollbar'}
            >
              <IconButton
                sx={{
                  position: 'absolute',
                  top: '5px',
                  right: '5px',
                }}
                onClick={() => {
                  setSendToCompoundModal(false)
                }}
              >
                <Close
                  style={{
                    fill: colorsConfig.mainRed,
                  }}
                />
              </IconButton>
              <Typography
                variant={'body1'}
                sx={{
                  fontSize: '40px',
                  fontWeight: 700,
                  mb: '30px',
                  lineHeight: '46px',
                }}
              >
                Send your car <br /> to us
              </Typography>
              <SendToCompoundModal
                closeModal={() => {
                  setSendToCompoundModal(false)
                }}
              />
            </Box>
          </Modal>
        </Box>
      )}
    </Box>
  )
}

export default AppraisalIntro
