import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setWarrantyDetailsOpen } from '../../../../store/features/warrantyApplicationSlice/warrantyApplication.reducer'
import CollapseBlock from '../../../../ui/Accordions/CollapseBlock'
import WarrantyDetailsForm from '../../../../components/Forms/FormTemplates/CarServices/WarrantyDetails/WarrantyDetailsForm'
import { Stack } from '@mui/material'
import CarWarrantyDocuments from '../components/CarWarrantyDocuments'

const WarrantyDetails = () => {
  const warrantyApplication = useSelector((state) => state.warrantyApplication.warrantyApplication)
  const { open, saved } = useSelector((state) => state.warrantyApplication.warrantyDetails)
  const dispatch = useDispatch()

  return (
    <CollapseBlock
      collapsed={!open}
      onCollapseChange={(value) => dispatch(setWarrantyDetailsOpen(!value))}
      title="Warranty details"
      description="Enter warranty details"
      saved={saved}
    >
      <WarrantyDetailsForm />
      {warrantyApplication?.currentWorkflowState &&
      warrantyApplication?.currentWorkflowState === 'issued' ? (
        <Stack direction="row" justifyContent="flex-end" sx={{ mt: '30px' }}>
          <CarWarrantyDocuments />
        </Stack>
      ) : null}
    </CollapseBlock>
  )
}

export default WarrantyDetails
