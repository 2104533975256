import React from 'react'
import { Box, Button, Typography, useMediaQuery } from '@mui/material'
import { colorsConfig } from '../../config/themeConfig'
import IconEdit from '../../assests/img/icons/icon-edit.svg'
import IconArrowBlue from '../../assests/img/icons/icon-arrow-blue.svg'
import IconArrowWhite from '../../assests/img/icons/icon-arrow-white.svg'
import { setAppraisalTab } from '../../store/features/carSlice/carSlice'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const CarPageEditButton = () => {
  const navigate = useNavigate()
  const params = useParams()
  const dispatch = useDispatch()
  const xsOnly = useMediaQuery('(max-width: 600px)')
  const { t } = useTranslation()

  const combinedStatus = useSelector((state) => state.car.carData.combinedStatus)
  const isCarArchived = useSelector((state) => state.car.carData.isArchived)
  const role = useSelector((state) => state.auth.role)

  const displaySingleButton =
    combinedStatus === 'car_workflow.new' && role === 'COMPANY' && !isCarArchived
  const displayDropdownButton =
    role === 'COMPANY' &&
    !xsOnly &&
    !isCarArchived &&
    (combinedStatus === 'car_workflow.appraisal_on_site' ||
      combinedStatus === 'car_workflow.moderation_failed')

  const displayEditSpecsMobile =
    role === 'COMPANY' &&
    xsOnly &&
    !isCarArchived &&
    (combinedStatus === 'car_workflow.new' ||
      combinedStatus === 'car_workflow.appraisal_on_site' ||
      combinedStatus === 'car_workflow.moderation_failed')

  const displayEditAppraisalMobile =
    role === 'COMPANY' &&
    xsOnly &&
    !isCarArchived &&
    (combinedStatus === 'car_workflow.appraisal_on_site' ||
      combinedStatus === 'car_workflow.moderation_failed')

  return (
    <Box
      className={xsOnly ? 'xs-only' : ''}
      sx={{
        '&.xs-only': {
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end',
          alignItems: 'center',
        },
      }}
    >
      {displaySingleButton || displayEditSpecsMobile ? (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            alignItems: 'center',
            mb: '5px',
          }}
        >
          <Button
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              color: colorsConfig.mainBlue,
              textTransform: 'none',
            }}
            onClick={() => {
              navigate(`/cars/${params.id}/edit`)
            }}
          >
            <img
              src={IconEdit}
              alt=""
              style={{
                marginRight: '5px',
                position: 'relative',
                top: '-2px',
              }}
            />
            <span
              style={{
                color: colorsConfig.mainBlue,
                fontSize: '14px',
                fontWeight: 400,
              }}
            >
              {t('button_edit_information')}
            </span>
          </Button>
        </Box>
      ) : null}
      {displayEditAppraisalMobile ? (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            alignItems: 'center',
            mb: '5px',
            ml: '5px',
          }}
        >
          <Button
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              color: colorsConfig.mainBlue,
              textTransform: 'none',
            }}
            onClick={() => {
              navigate(`/cars/${params.id}/appraisal`)
            }}
          >
            <img
              src={IconEdit}
              alt=""
              style={{
                marginRight: '5px',
                position: 'relative',
                top: '-2px',
              }}
            />
            <span
              style={{
                color: colorsConfig.mainBlue,
                fontSize: '14px',
                fontWeight: 400,
              }}
            >
              {t('button_edit_appraisal')}
            </span>
          </Button>
        </Box>
      ) : null}
      {displayDropdownButton ? (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            alignItems: 'center',
            position: 'relative',
            zIndex: 10,
            mb: '5px',
            '&:hover': {
              '& div': {
                pointerEvents: 'auto',
              },
              '& .dropdown-header': {
                background: colorsConfig.mainBlue,
                transition: '0.25s all ease-in-out',
              },
              '& p': {
                color: 'white',
                transition: '0.25s all ease-in-out',
              },
              '& .arrow-blue': {
                opacity: 0,
                transform: 'rotate(0deg)',
                transition: '0.25s all ease-in-out',
              },
              '& .arrow-white': {
                opacity: 1,
                transform: 'rotate(0deg)',
                transition: '0.25s all ease-in-out',
              },
              '& .dropdown-menu': {
                opacity: 1,
                pointerEvents: 'auto',
                transition: '0.25s all ease-in-out',
              },
            },
          }}
        >
          <Box
            className={'dropdown-header'}
            sx={{
              position: 'relative',
              width: '80px',
              height: '24px',
              border: '1px solid',
              borderColor: colorsConfig.mainBlue,
              borderRadius: '6px',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              cursor: 'pointer',
              transition: '0.25s all ease-in-out',
            }}
          >
            <Typography
              sx={{
                fontFamily: '"Helvetica", sans-serif',
                fontSize: '14px',
                fontWeight: 400,
                color: colorsConfig.mainBlue,
                transition: '0.25s all ease-in-out',
              }}
            >
              {t('button_edit')}
            </Typography>
            <Box
              sx={{
                width: '13px',
                height: '9px',
                ml: '6px',
                position: 'relative',
              }}
            >
              <Box
                className="arrow-blue img-arrow"
                sx={{
                  width: '13px',
                  height: '9px',
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  backgroundImage: `url(${IconArrowBlue})`,
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center',
                  transform: 'rotate(180deg)',
                  transition: '0.25s all ease-in-out',
                }}
              ></Box>
              <Box
                className="arrow-white img-arrow"
                sx={{
                  width: '13px',
                  height: '9px',
                  display: 'flex',
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  backgroundImage: `url(${IconArrowWhite})`,
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center',
                  opacity: 0,
                  transformOrigin: '50% 50%',
                  transform: 'rotate(180deg)',
                  transition: '0.25s all ease-in-out',
                }}
              ></Box>
            </Box>
            <Box
              sx={{
                pt: '4px',
                position: 'absolute',
                top: '100%',
                left: 0,
                width: '100%',
                pointerEvents: 'none',
              }}
            >
              <Box
                className={'dropdown-menu'}
                sx={{
                  borderRadius: '6px',
                  background: 'white',
                  boxShadow: '0px 4px 10px 0px rgba(0, 0, 0, 0.10)',
                  opacity: 0,
                  pointerEvents: 'none',
                  transition: '0.25s all ease-in-out',
                }}
              >
                <Button
                  fullWidth
                  sx={{
                    display: 'block',
                    textTransform: 'none',
                    color: colorsConfig.mainBlue,
                    fontSize: '14px',
                    whiteSpace: 'nowrap',
                  }}
                  onClick={() => {
                    navigate(`/cars/${params.id}/appraisal`)
                  }}
                >
                  {t('button_appraisal')}
                </Button>
                <Button
                  fullWidth
                  sx={{
                    display: 'block',
                    textTransform: 'none',
                    color: colorsConfig.mainBlue,
                    fontSize: '14px',
                    whiteSpace: 'nowrap',
                  }}
                  onClick={() => {
                    if (combinedStatus === 'car_workflow.moderation_failed') {
                      dispatch(setAppraisalTab(0))
                      navigate(`/cars/${params.id}/appraisal`)
                    } else {
                      navigate(`/cars/${params.id}/edit`)
                    }
                  }}
                >
                  {t('button_car_specs')}
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      ) : null}
    </Box>
  )
}

export default CarPageEditButton
