import React, { useEffect, useState } from 'react'
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  Grid,
  Link,
  Modal,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material'
import { Close } from '@mui/icons-material'
import { toast } from 'react-toastify'
import { API_ENDPOINTS } from '../../config/default'
import { publicRequest } from '../../utils/request'
import { colorsConfig } from '../../config/themeConfig'
import LogoBlue from '../../assests/img/logo-blue.svg'
import TextFieldSmallEdible from '../../ui/TextFieldSmallEdible'
import { countries } from '../../default-data/coutries'
import { Trans, useTranslation } from 'react-i18next'
import 'react-phone-input-2/lib/style.css'
import FormPhoneNumberBase from '../../components/Forms/FormComponents/FormPhoneNumber/FormPhoneNumberBase'
import FormUIDMaskedInputBase from '../../components/Forms/FormComponents/FormUIDMaskedInput/FormUIDMaskedInputBase'
import PrivacyPolicyLink from '../../components/LegalDocuments/PrivacyPolicyLink'
import UITextRegular from '../../ui/UIText/UITextRegular'
import TermsAndConditionsModal from '../../components/Modals/TermsAndConditionsModal/TermsAndConditionsModal'
import zipCodeApiService from '../../api/zipcodeApiService/zipcodeApiService'
import { getApiError } from '../../api/api'
import { signUpFormInitialState, signUpFormValidationSchema } from './signUpForm.helpers'
import UIButton from '../../ui/UIButtons/UIButton'

const SignUpCH = ({ setSignInModal, handleSignIn }) => {
  const { t, i18n } = useTranslation()

  const tabletOnly = useMediaQuery('(min-width: 600px) and (max-width: 1200px)')

  const currentDate = new Date()
  currentDate.setDate(currentDate.getDate() + 1)
  const [uploadedFile, setUploadedFile] = useState(null)
  const [uploadFile, setUploadFile] = useState('')
  const [disableOnSubmit, setDisableOnSubmit] = useState(false)
  const [notificationModal, setNotificationModal] = useState(false)
  const [submitted, setSubmitted] = useState(false)
  const [termsAndConditionsModal, setTermsAndConditionsModal] = useState(false)

  const [formData, setFormData] = useState(signUpFormInitialState)
  const [errors, setErrors] = useState({})
  const [showErrors, setShowErrors] = useState(false)
  const [isValid, setIsValid] = useState(false)

  const handleSubmit = (e) => {
    e.preventDefault()
  }

  const handleUploadLicense = (value) => {
    const fileName = value.replace('C:\\fakepath\\', '')
    if (fileName) {
      setUploadedFile(fileName)
    }
  }

  const handleValidate = async () => {
    try {
      await signUpFormValidationSchema.validate(formData, { abortEarly: false })
      setErrors({})
      setIsValid(true)
      return true
    } catch (err) {
      const newErrors = {}
      err.inner.forEach((error) => {
        newErrors[error.path] = error.message
      })
      setErrors(newErrors)
      setIsValid(false)
      return false
    }
  }

  const handleFormSubmit = async () => {
    const isFormValid = await handleValidate()
    if (!isFormValid) {
      setShowErrors(true)
      return
    }
    setDisableOnSubmit(true)
    const data = new FormData()
    const {
      login,
      firstName,
      surname,
      email,
      phoneNumber,
      companyName,
      country,
      landlineNumber,
      addressLine1,
      addressLine2,
      city,
      taxRegistrationNumber,
      tradeLicense,
      tradeLicenseNumber,
      postcode,
    } = formData
    data.append('login', login)
    data.append('email', email)
    data.append('phoneNumber', phoneNumber)
    data.append('companyName', companyName)
    data.append('taxRegistrationNumber', taxRegistrationNumber)
    data.append('country', country?.label)
    data.append('landlineNumber', landlineNumber)
    data.append('firstName', firstName)
    data.append('surname', surname)
    data.append('postcode', postcode)
    data.append('city', city)
    data.append('addressLine1', addressLine1)
    data.append('tradeLicenseNumber', tradeLicenseNumber)
    data.append('tradeLicense', tradeLicense)
    data.append('locale', i18n.language)
    if (addressLine2) {
      data.append('addressLine2', addressLine2)
    }
    publicRequest
      .post(API_ENDPOINTS.register, data, {
        headers: {
          accept: 'application/ld+json',
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(() => {
        window.scrollTo(0, 0)
        setSubmitted(true)
        setNotificationModal(true)
        setFormData(signUpFormInitialState)
      })
      .catch((e) => {
        toast.error(getApiError(e))
      })
      .finally(() => {
        setDisableOnSubmit(false)
      })
  }

  const getCityByZipCode = async () => {
    try {
      const response = await zipCodeApiService.getGeoCodingByZipCode(
        formData.country?.code,
        formData.postcode,
      )
      const places = response.data?.places
      if (places?.[0]?.['place name']) {
        setFormData({
          ...formData,
          city: places?.[0]?.['place name'],
        })
      }
    } catch (e) {
      console.error(e)
    }
  }

  useEffect(() => {
    if (formData.postcode && formData.postcode?.length > 0 && formData.country) {
      getCityByZipCode()
    }
  }, [formData.postcode, formData.country])

  useEffect(() => {
    if (showErrors) {
      handleValidate()
    }
  }, [formData, showErrors])

  return (
    <React.Fragment>
      {disableOnSubmit ? (
        <div className="preloader">
          <div className="lds-ripple">
            <div></div>
            <div></div>
          </div>
        </div>
      ) : null}
      <Box
        className={tabletOnly ? 'tablet-only' : ''}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          '&.tablet-only': {
            display: 'block',
          },
        }}
      >
        <Box sx={{ mt: 3 }}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <Stack
                spacing={2}
                sx={{
                  mb: {
                    xs: '25px',
                    md: 0,
                  },
                  width: '100%',
                }}
              >
                <TextFieldSmallEdible
                  required
                  fullWidth
                  id="login"
                  label={t('form_input_label_username')}
                  name="login"
                  value={formData.login}
                  onChange={(e) => {
                    setFormData({ ...formData, login: e.target.value })
                  }}
                  helperText={errors?.login}
                  error={errors?.login}
                />
                <TextFieldSmallEdible
                  required
                  fullWidth
                  id="firstName"
                  label={t('form_input_label_first_name')}
                  name="firstName"
                  autoComplete="given-name"
                  value={formData.firstName}
                  onChange={(e) => {
                    setFormData({ ...formData, firstName: e.target.value })
                  }}
                  helperText={errors?.firstName}
                  error={errors?.firstName}
                />
                <TextFieldSmallEdible
                  required
                  fullWidth
                  id="surname"
                  label={t('form_input_label_last_name')}
                  name="surname"
                  autoComplete="family-name"
                  value={formData.surname}
                  onChange={(e) => {
                    setFormData({ ...formData, surname: e.target.value })
                  }}
                  helperText={errors?.surname}
                  error={errors?.surname}
                />
                <TextFieldSmallEdible
                  required
                  fullWidth
                  id="email"
                  label={t('form_input_label_email')}
                  name="email"
                  autoComplete="email"
                  value={formData.email}
                  onChange={(e) => {
                    setFormData({ ...formData, email: e.target.value })
                  }}
                  helperText={errors?.email}
                  error={errors?.email}
                />
                <FormPhoneNumberBase
                  required
                  country="ch"
                  label="form_input_label_mobile_number"
                  value={formData.phoneNumber}
                  onChange={(val) => {
                    setFormData({ ...formData, phoneNumber: val })
                  }}
                  helperText={errors?.phoneNumber}
                  error={errors?.phoneNumber}
                />
              </Stack>
            </Grid>
            <Grid item sm={6} xs={12}>
              <Stack spacing={2}>
                <TextFieldSmallEdible
                  required
                  fullWidth
                  id="companyName"
                  label="form_input_label_company_name"
                  name="companyName"
                  value={formData.companyName}
                  onChange={(e) => {
                    setFormData({ ...formData, companyName: e.target.value })
                  }}
                  helperText={errors?.companyName}
                  error={errors?.companyName}
                />
                <Autocomplete
                  id="address-country"
                  options={[
                    {
                      label: null,
                    },
                    ...countries,
                  ]}
                  getOptionLabel={(option) => option.label || ''}
                  fullWidth
                  autoHighlight
                  value={formData.country}
                  onChange={(e, newVal) => {
                    setFormData({ ...formData, country: newVal })
                  }}
                  renderOption={(props, option) => {
                    if (!option.label) return null
                    return (
                      <Box
                        component="li"
                        sx={{
                          '& > img': {
                            mr: 2,
                            flexShrink: 0,
                          },
                        }}
                        {...props}
                      >
                        <img
                          loading="lazy"
                          width="20"
                          srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                          src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                          alt=""
                        />
                        {option.label}
                      </Box>
                    )
                  }}
                  renderInput={(params) => (
                    <TextFieldSmallEdible
                      {...params}
                      required
                      isAutocomplete
                      label="form_input_label_country"
                      helperText={errors?.country}
                      error={errors?.country}
                    />
                  )}
                />

                <TextFieldSmallEdible
                  required
                  fullWidth
                  label="form_input_label_address_line1"
                  value={formData.addressLine1}
                  onChange={(e) => {
                    setFormData({ ...formData, addressLine1: e.target.value })
                  }}
                  helperText={errors?.addressLine1}
                  error={errors?.addressLine1}
                />
                <TextFieldSmallEdible
                  fullWidth
                  label="form_input_label_address_line2"
                  value={formData.addressLine2}
                  onChange={(e) => {
                    setFormData({ ...formData, addressLine2: e.target.value })
                  }}
                />
                <TextFieldSmallEdible
                  required
                  fullWidth
                  label="form_input_label_postcode"
                  value={formData.postcode}
                  onChange={(e) => {
                    setFormData({ ...formData, postcode: e.target.value })
                  }}
                  helperText={errors?.postcode}
                  error={errors?.postcode}
                />
                <TextFieldSmallEdible
                  required
                  fullWidth
                  label="form_input_label_city"
                  value={formData.city}
                  onChange={(e) => {
                    setFormData({ ...formData, city: e.target.value })
                  }}
                  helperText={errors?.city}
                  error={errors?.city}
                />
                <FormPhoneNumberBase
                  required
                  country="ch"
                  label="form_input_label_landline_number"
                  value={formData.landlineNumber}
                  onChange={(val) => {
                    setFormData({ ...formData, landlineNumber: val })
                  }}
                  helperText={errors?.landlineNumber}
                  error={errors?.landlineNumber}
                />
                <FormUIDMaskedInputBase
                  required
                  value={formData.tradeLicenseNumber}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      tradeLicenseNumber: e.target.value,
                      taxRegistrationNumber: `${e.target.value} MWST`,
                    })
                  }}
                  helperText={errors?.tradeLicenseNumber}
                  error={errors?.tradeLicenseNumber}
                />
                {/*<FormUIDMaskedInputBase*/}
                {/*  value={licenseNumber.value}*/}
                {/*  onChange={(e) => {*/}
                {/*    licenseNumber.onChangeValue(e.target.value)*/}
                {/*    taxRegistrationNumber.onChangeValue(`${e.target.value} MWST`)*/}
                {/*  }}*/}
                {/*/>*/}
                <TextFieldSmallEdible
                  fullWidth
                  required
                  label="form_input_label_vat_nr"
                  inputProps={{
                    inputMode: 'numeric',
                    readOnly: true,
                  }}
                  value={formData.taxRegistrationNumber}
                  helperText={errors?.taxRegistrationNumber}
                  error={errors?.taxRegistrationNumber}
                />
                {uploadedFile ? (
                  <Box>
                    <Button
                      variant="outlined"
                      component="div"
                      sx={{
                        cursor: 'auto',
                        textAlign: 'left',
                        justifyContent: 'space-between',
                      }}
                      disableRipple
                      fullWidth
                      endIcon={
                        <Close
                          sx={{
                            cursor: 'pointer',
                          }}
                          onClick={() => {
                            setUploadedFile(null)
                            setFormData({
                              ...formData,
                              tradeLicense: null,
                            })
                          }}
                        />
                      }
                    >
                      {uploadedFile}
                    </Button>
                  </Box>
                ) : null}
                <Stack
                  gap="4px"
                  sx={{
                    '& .MuiButton-root': {
                      backgroundColor: 'transparent',
                      justifyContent: 'start',
                    },
                  }}
                >
                  <Button
                    fullWidth
                    component="label"
                    variant="contained"
                    sx={{
                      maxWidth: '300px',
                      height: '38px',
                      border: `1px solid ${colorsConfig.mainBlue}`,
                      color: '#757575',
                      textTransform: 'none',
                      transition: '0.25s all ease-in-out',
                      '&:hover': {
                        color: 'white',
                        background: colorsConfig.mainBlue,
                        transition: '0.25s all ease-in-out',
                        '& > svg > path': {
                          fill: 'white',
                          transition: '0.25s all ease-in-out',
                        },
                      },
                    }}
                  >
                    <svg
                      width="14"
                      height="19"
                      viewBox="0 0 14 19"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      style={{
                        marginRight: '10px',
                      }}
                    >
                      <path
                        d="M1.58203 18.5H12.1289C13.0013 18.5 13.7109 17.7903 13.7109 16.918V5.77344H10.0195C9.14716 5.77344 8.4375 5.06377 8.4375 4.19141V0.5H1.58203C0.709664 0.5 0 1.20966 0 2.08203V16.918C0 17.7903 0.709664 18.5 1.58203 18.5ZM3.69141 7.91797H10.0195C10.311 7.91797 10.5469 8.15383 10.5469 8.44531C10.5469 8.73679 10.311 8.97266 10.0195 8.97266H3.69141C3.39993 8.97266 3.16406 8.73679 3.16406 8.44531C3.16406 8.15383 3.39993 7.91797 3.69141 7.91797ZM3.69141 10.0273H10.0195C10.311 10.0273 10.5469 10.2632 10.5469 10.5547C10.5469 10.8462 10.311 11.082 10.0195 11.082H3.69141C3.39993 11.082 3.16406 10.8462 3.16406 10.5547C3.16406 10.2632 3.39993 10.0273 3.69141 10.0273ZM3.69141 12.1367H10.0195C10.311 12.1367 10.5469 12.3726 10.5469 12.6641C10.5469 12.9555 10.311 13.1914 10.0195 13.1914H3.69141C3.39993 13.1914 3.16406 12.9555 3.16406 12.6641C3.16406 12.3726 3.39993 12.1367 3.69141 12.1367ZM3.69141 14.2461H7.91016C8.20164 14.2461 8.4375 14.482 8.4375 14.7734C8.4375 15.0649 8.20164 15.3008 7.91016 15.3008H3.69141C3.39993 15.3008 3.16406 15.0649 3.16406 14.7734C3.16406 14.482 3.39993 14.2461 3.69141 14.2461Z"
                        fill="#757575"
                      />
                      <path
                        d="M10.0195 4.71836H13.4019L9.49219 0.808594V4.19101C9.49219 4.48196 9.72858 4.71836 10.0195 4.71836Z"
                        fill="#757575"
                      />
                    </svg>
                    {t('form_input_label_upload_registry_copy')}
                    <input
                      type="file"
                      hidden
                      name="upload-license"
                      onChange={(e) => {
                        handleUploadLicense(e.target.value)
                        setUploadFile(e.target.files[0])
                        setFormData({
                          ...formData,
                          tradeLicense: e.target.files[0],
                        })
                      }}
                    />
                  </Button>
                  {errors?.tradeLicense && (
                    <FormHelperText sx={{ color: colorsConfig.mainRed }}>
                      {t(errors?.tradeLicense)}
                    </FormHelperText>
                  )}
                </Stack>

                <Stack>
                  <FormControlLabel
                    sx={{
                      alignItems: 'center',
                    }}
                    control={
                      <Checkbox
                        color="primary"
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            consent: e.target.checked,
                          })
                        }}
                        checked={formData.consent}
                      />
                    }
                    label={
                      <UITextRegular>
                        <Trans
                          components={{
                            l: <PrivacyPolicyLink />,
                          }}
                        >
                          form_input_label_agree_with_privacy_policy
                        </Trans>
                      </UITextRegular>
                    }
                  >
                    <Trans
                      components={{
                        l: <PrivacyPolicyLink />,
                      }}
                    >
                      form_input_label_agree_with_privacy_policy
                    </Trans>
                  </FormControlLabel>
                  {errors?.consent && (
                    <FormHelperText sx={{ color: colorsConfig.mainRed }}>
                      {t(errors?.consent)}
                    </FormHelperText>
                  )}
                </Stack>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-end',
                    alignItems: 'flex-start',
                  }}
                >
                  <UIButton
                    width="187px"
                    sx={{ mb: '8px' }}
                    disabled={showErrors && !isValid}
                    onClick={() => {
                      handleFormSubmit()
                    }}
                    text="button_submit"
                  />
                </Box>
              </Stack>
              <Grid
                container
                justifyContent="flex-end"
                sx={{
                  paddingRight: '10px',
                }}
              >
                <Grid item>
                  <Link
                    variant="body2"
                    onClick={() => {
                      if (setSignInModal) {
                        setSignInModal(true)
                        return
                      }
                      if (handleSignIn) handleSignIn()
                    }}
                    sx={{
                      cursor: 'pointer',
                      fontSize: '12px',
                      color: colorsConfig.mainBlue,
                      textDecoration: 'none',
                      fontStyle: 'normal',
                      lineHeight: 'normal',
                    }}
                  >
                    {t('p_already_have_account')}
                  </Link>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
        <Modal
          open={notificationModal}
          onClose={() => {
            setNotificationModal(false)
          }}
          disableScrollLock
        >
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: '608px',
              height: '280px',
              background: 'white',
              borderRadius: '10px',
              padding: '44px 56px',
              overflow: 'auto',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <img style={{ width: '132px' }} src={LogoBlue} alt="logo-pic" />
            <Typography
              sx={{
                textAlign: 'center',
                lineHeight: 'normal',
              }}
            >
              {t('p_sign_up_success_message')}
            </Typography>
            <Button
              variant="contained"
              sx={{
                width: '464px',
                height: '48px',
                padding: '15px 60px',
                backgroundColor: colorsConfig.mainBlue,
                display: 'inline-flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontFamily: 'Helvetica',
                fontSize: '16px',
                fontWeight: '700',
                lineHeight: 'normal',
                textTransform: 'none',
                color: 'white',
              }}
              onClick={() => {
                setNotificationModal(false)
              }}
            >
              {t('button_got_it')}
            </Button>
          </Box>
        </Modal>
        {termsAndConditionsModal && (
          <TermsAndConditionsModal
            open={termsAndConditionsModal}
            setOpen={setTermsAndConditionsModal}
          />
        )}
      </Box>
    </React.Fragment>
  )
}

export default SignUpCH
