import * as Yup from 'yup'
import { countries, defaultCountryObj } from '../../../../default-data/coutries'
import { formErrors } from '../formErrors'

export const getManageLocationFormInitialState = (data, initialAddressType) => {
  const { type, city, state, country, street, postcode, addressLine1, addressLine2, comment } =
    data?.address ?? {}
  return {
    type: type ?? initialAddressType ?? 'all',
    city: city ?? '',
    state: state ?? '',
    country: country ? countries?.find((e) => e?.label === country) : defaultCountryObj,
    street: street ?? '',
    postcode: postcode ?? '',
    addressLine1: addressLine1 ?? '',
    addressLine2: addressLine2 ?? '',
    comment: comment ?? '',
  }
}

export const manageLocationFormValidationSchema = Yup.object().shape({
  city: Yup.string().required(formErrors.required),
  state: Yup.string().required(formErrors.required),
  country: Yup.mixed().required(formErrors.required),
  postcode: Yup.string().required(formErrors.required),
  addressLine1: Yup.string().required(formErrors.required),
})
