import { privateRequest } from '../../utils/request'

class PolicyWorkflowLogApiService {
  #api

  #endPoints

  #abortControllers

  constructor({ api }) {
    this.#api = api
    this.#endPoints = {
      collection: '/rvi/policy-workflow-logs',
      policy: {
        collection: (policyId) => `/rvi/policies/${policyId}/workflow-logs`,
      },
    }
    this.#abortControllers = {}
  }

  getPoliciesWorkflowLogs = async (params, prefix = 'getPoliciesWorkflowLogs') => {
    this.#abortControllers[prefix]?.abort()
    this.#abortControllers[prefix] = new AbortController()
    const response = await this.#api.get(this.#endPoints.collection, {
      params,
    })
    return response
  }

  getPolicyWorkflowLogs = async (policyId) => {
    const response = await this.#api.get(this.#endPoints.policy.collection(policyId), {
      params: { pagination: false },
    })
    return response
  }
}

const policyWorkflowLogApiService = new PolicyWorkflowLogApiService({ api: privateRequest })

export default policyWorkflowLogApiService
