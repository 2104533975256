import React, { useEffect, useState } from 'react'
import UIModal from '../../../ui/UIModal/UIModal'
import { Box, Button, InputAdornment, Stack } from '@mui/material'
import UITextHeadlineSmall from '../../../ui/UIText/UITextHeadlineSmall'
import TextFieldSmallEdible from '../../../ui/TextFieldSmallEdible'
import ButtonHollow from '../../../ui/Buttons/ButtonHollow'
import ButtonLG from '../../../ui/Buttons/ButtonLG'
import { isValidEmail } from '../../../utils/helpers/validation.helpers'
import { useDispatch, useSelector } from 'react-redux'
import {
  createCustomerAppraisal,
  sendAppraisalLinkByEmail,
} from '../../../store/features/customerAppraisal/customerAppraisal.actions'
import { colorsConfig } from '../../../config/themeConfig'
import { ContentCopy } from '@mui/icons-material'
import TextNormal from '../../../ui/Text/TextNormal'
import { APP_BASE_URL } from '../../../config/default'
import UIPreloader from '../../../ui/UIPreloader/UIPreloader'
import { copyToClipboard } from '../../../utils/helpers/common.helpers'
import { toast } from 'react-toastify'
import UITextRegular from '../../../ui/UIText/UITextRegular'
import ButtonLinkWA from '../../Buttons/Social/ButtonLinkWA'
import ButtonLinkTG from '../../Buttons/Social/ButtonLinkTG'
import ButtonLinkMail from '../../Buttons/Social/ButtonLinkMail'
import { resetAddedCustomerAppraisal } from '../../../store/features/customerAppraisal/customerAppraisal.reducer'
import { useTranslation } from 'react-i18next'
import { formErrors } from '../../Forms/FormTemplates/formErrors'

const AddCustomerAppraisalModal = ({ open, setOpen, onSubmit }) => {
  const { t } = useTranslation()
  const isGeneratingLink = useSelector((state) => state.customerAppraisal.create.isLoading)
  const customerAppraisal = useSelector((state) => state.customerAppraisal.create.data)
  const isSendingEmail = useSelector((state) => state.customerAppraisal.sendEmail.isLoading)
  const dispatch = useDispatch()

  const customerAppraisalTextWA = (link) =>
    t('message_template_customer_appraisal_invitation') + link

  const customerAppraisalTextTG = t('message_template_customer_appraisal_invitation')

  const appraisalLink = `${window.location.protocol}//${window.location.host}/customer/car-appraisal/${customerAppraisal?.appraisalToken?.token}`

  const [email, setEmail] = useState('')
  const [showErrors, setShowErrors] = useState(false)
  const [isValid, setIsValid] = useState(true)
  const [linkCopySuccess, setLinkCopySuccess] = useState(false)

  const handleValidate = () => {
    const isFormValid = isValidEmail(email)
    setIsValid(isFormValid)
    return isFormValid
  }

  const handleSubmitEmail = async () => {
    if (!handleValidate()) {
      setShowErrors(true)
      return
    }
    const data = {
      customer: {
        person: {
          email,
        },
      },
    }
    const res = await dispatch(
      createCustomerAppraisal({
        data,
      }),
    )
    if (createCustomerAppraisal.fulfilled.match(res)) {
      onSubmit()
    }
  }

  const handleCopyLink = async () => {
    await copyToClipboard(appraisalLink)
    setLinkCopySuccess(true)
    setTimeout(() => {
      setLinkCopySuccess(false)
    }, 3000)
  }

  const handleSendEmail = async () => {
    const res = await dispatch(sendAppraisalLinkByEmail({ idUrl: customerAppraisal?.id }))
    if (sendAppraisalLinkByEmail.fulfilled.match(res)) {
      toast.success(t('notification_customer_appraisal_send_email_success'))
      setOpen(false)
    }
  }

  useEffect(() => {
    if (showErrors) {
      handleValidate()
    }
  }, [showErrors, email])

  useEffect(() => {
    return () => {
      dispatch(resetAddedCustomerAppraisal())
      setEmail('')
      setShowErrors(false)
      setIsValid(false)
      setShowErrors(false)
    }
  }, [])

  return (
    <UIModal width="600px" open={open} setOpen={setOpen}>
      {isGeneratingLink && <UIPreloader text="preloader_generating_link" />}
      {isSendingEmail && <UIPreloader text="preloader_sending_the_email" />}
      <Stack
        gap={{ xs: '16px', md: '30px' }}
        sx={{
          pt: {
            xs: '16px',
          },
        }}
      >
        <UITextHeadlineSmall text="title_generate_appraisal_link" />
        <TextFieldSmallEdible
          required
          label="form_input_label_customer_email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          error={showErrors && !isValidEmail(email)}
          helperText={showErrors && !isValidEmail(email) ? formErrors.invalidEmail : ''}
        />
        {!customerAppraisal && (
          <Stack direction={{ xs: 'column', md: 'row' }} gap="10px">
            <ButtonHollow sx={{ flex: 1 }} onClick={() => setOpen(false)}>
              {t('button_cancel')}
            </ButtonHollow>
            <ButtonLG
              disabled={(showErrors && !isValid) || isGeneratingLink}
              sx={{ flex: 1 }}
              onClick={() => handleSubmitEmail()}
            >
              {t('button_generate_link')}
            </ButtonLG>
          </Stack>
        )}

        {customerAppraisal && (
          <Stack gap="35px">
            <Box
              sx={{
                position: 'relative',
              }}
            >
              <TextFieldSmallEdible
                label="form_input_label_appraisal_link"
                fullWidth
                value={appraisalLink}
                readOnly
                sx={{
                  '& .MuiInputBase-root': {
                    pr: 0,
                  },
                }}
                onFocus={(event) => {
                  event.target.select()
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position={'end'}>
                      <Button
                        sx={{
                          textTransform: 'none',
                          background: colorsConfig.mainBlue,
                          color: 'white',
                          borderRadius: '0 6px 6px 0',
                          transition: '0.25s all ease-in-out',
                          '&:hover': {
                            background: colorsConfig.buttonHover,
                            transition: '0.25s all ease-in-out',
                          },
                        }}
                        onClick={() => handleCopyLink()}
                      >
                        <ContentCopy
                          sx={{
                            transition: '0.25s all ease-in-out',
                            fill: 'white',
                          }}
                        />
                        <TextNormal
                          sx={{
                            fontSize: '14px',
                            color: 'white',
                            ml: '5px',
                            transition: '0.25s all ease-in-out',
                          }}
                        >
                          {t('button_copy')}
                        </TextNormal>
                      </Button>
                    </InputAdornment>
                  ),
                }}
              />
              <TextNormal
                className={linkCopySuccess ? 'success' : ''}
                sx={{
                  color: colorsConfig.mainGreen,
                  my: '5px',
                  fontSize: '12px',
                  position: 'absolute',
                  left: 0,
                  top: '100%',
                  opacity: 0,
                  transition: '0.25s all ease-in-out',
                  '&.success': {
                    opacity: 1,
                    transition: '0.25s all ease-in-out',
                  },
                }}
              >
                {t('p_link_copy_success')}
              </TextNormal>
            </Box>
            <Stack gap="10px">
              <UITextRegular text="p_or_share_via" />
              <Stack direction="row" gap="8px">
                <ButtonLinkWA message={customerAppraisalTextWA(appraisalLink)} />
                <ButtonLinkTG message={customerAppraisalTextTG} link={appraisalLink} />
                <ButtonLinkMail handleSendEmail={handleSendEmail} />
              </Stack>
            </Stack>
          </Stack>
        )}
      </Stack>
    </UIModal>
  )
}

export default AddCustomerAppraisalModal
