import React, { useEffect } from 'react'
import { Box, Button, CircularProgress, Typography } from '@mui/material'
import UITextHeadlineLarge from '../../../../ui/UIText/UITextHeadlineLarge'
import { useDispatch, useSelector } from 'react-redux'
import { colorsConfig } from '../../../../config/themeConfig'
import { getPolicyLoggingNodesData } from '../../../../components/Modals/LoggingNodesModal/LoggingNodesTemplates/loggingNodes.helpers'
import { useTranslation } from 'react-i18next'
import { getDashboardPoliciesWorkflowLogs } from '../../../../store/features/dashboard/dashboard.actions'
import { getFormattedDate } from '../../../../utils/date.helpers'
import { useNavigate } from 'react-router-dom'

const RviActivities = () => {
  const { t, i18n } = useTranslation()
  const isLoading = useSelector((state) => state.dashboard.policiesWorkflowLogs.isLoading)
  const policiesWorkflowLogs = useSelector((state) => state.dashboard.policiesWorkflowLogs.data)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    dispatch(getDashboardPoliciesWorkflowLogs())
  }, [])

  return (
    <Box sx={{ height: '265px', padding: '25px 25px 25px 45px', position: 'relative' }}>
      <UITextHeadlineLarge text="title_rvi_activities" sx={{ mb: '12px' }} />
      {isLoading ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'flex-end',
          }}
        >
          <CircularProgress
            size={60}
            sx={{
              '.css-176wh8e-MuiCircularProgress-circle': {
                color: colorsConfig.mainBlue,
              },
              margin: '0 8px',
            }}
          />
        </Box>
      ) : (
        <Box
          sx={{
            height: '135px',
            display: 'flex',
            flexDirection: 'column',
            gap: '7px',
            overflowY: 'auto',
            scrollBehavior: 'smooth',
            scrollbarGutter: 'stable',
            scrollbarWidth: 'thin',
            '&::-webkit-scrollbar': {
              width: '5px',
              height: '120px',
              background: colorsConfig.buttonDisabled,
              borderRadius: '5px',
            },
            '&::-webkit-scrollbar-thumb': {
              height: '70px',
              background: colorsConfig.mainBlue,
              borderRadius: '5px',
            },
          }}
        >
          {getPolicyLoggingNodesData(policiesWorkflowLogs, i18n.language).map((node) => {
            return (
              <Box
                key={node?.id}
                sx={{
                  display: 'flex',
                  gap: '20px',
                }}
              >
                <Typography sx={{ color: '#757575' }}>
                  {getFormattedDate(node.initialData?.createdAt)}
                </Typography>
                <Box>
                  <Typography
                    sx={{
                      fontWeight: 700,
                      marginBottom: '-4px',
                      maxWidth: '100%',
                      overflow: 'hidden',
                      pr: '20px',
                    }}
                  >
                    {t(node?.message)}
                  </Typography>
                  <Button
                    sx={{
                      fontSize: '14px',
                      color: '#757575',
                      textDecoration: 'underline',
                      margin: 0,
                      padding: 0,
                      '&:hover': {
                        background: '#fff',
                      },
                    }}
                    onClick={() => navigate(`/rvi/policies/${node?.initialData?.policy?.id}`)}
                  >
                    {node?.initialData?.policy?.details?.vin}
                  </Button>
                </Box>
              </Box>
            )
          })}
        </Box>
      )}
    </Box>
  )
}

export default RviActivities
