export const isCarDataValid = (coreData, mainData) => {
  const { vin, make, model } = coreData
  if (!vin || !make || !model || vin.length !== 17) return false
  const {
    firstRegistrationDate,
    mileage,
    engineType,
    origin,
    driveType,
    gearbox,
    lastServiceMileage,
    lastServiceDate,
    hasBeenServiced,
  } = mainData
  if (hasBeenServiced && (!lastServiceMileage || !lastServiceDate)) return false
  if (!firstRegistrationDate || !mileage || !engineType || !origin || !driveType || !gearbox) {
    return false
  }
  return true
}
