import React, { useState } from 'react'
import { Box, IconButton, Link, Typography } from '@mui/material'
import { colorsConfig } from '../../../config/themeConfig'
import IconAttention from '../../../assests/img/icons/icon-attention.svg'
import TextNormal from '../../../ui/Text/TextNormal'
import { useDispatch, useSelector } from 'react-redux'
import { setAppraisalTab } from '../../../store/features/carSlice/carSlice'
import { useNavigate } from 'react-router-dom'
import { ExpandMore } from '@mui/icons-material'

const QualityCheckHints = (props) => {
  const role = useSelector((state) => state.auth.role)

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { hints } = props

  const [showHints, setShowHints] = useState(role === 'COMPANY')

  const handleGoToPage = (link, tab) => {
    if (tab === null) {
      navigate(link)
      return
    }
    dispatch(setAppraisalTab(tab))
    navigate(link)
  }

  return (
    <Box
      sx={{
        mt: '20px',
        mb: '10px',
        pt: '38px',
        pb: '44px',
        position: 'relative',
        '&::after': {
          content: '" "',
          display: 'block',
          position: 'absolute',
          width: 'calc(100% + 9000px)',
          height: '100%',
          left: '-23px',
          top: 0,
          zIndex: -1,
          pointerEvents: 'none',
          background: colorsConfig.lightYellow,
        },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          alignItems: 'center',
          mb: showHints ? '20px' : '0',
          position: 'relative',
          cursor: 'pointer',
        }}
        onClick={() => {
          setShowHints(!showHints)
        }}
      >
        <img
          style={{
            marginRight: '10px',
          }}
          src={IconAttention}
          alt=""
        />
        <Typography
          sx={{
            fontFamily: '"Helvetica", sans-serif',
            fontSize: '18px',
            fontWeight: 700,
            color: colorsConfig.mainBlack,
          }}
        >
          {role === 'OPERATOR' ? 'Previous comments' : ''}
          {role === 'COMPANY' ? 'Updates required' : ''}
        </Typography>
        <IconButton
          className={showHints ? '' : 'closed'}
          sx={{
            position: 'absolute',
            width: '35px',
            height: '35px',
            top: '50%',
            mt: '-17.5px',
            right: 0,
            transformOrigin: '50% 50%',
            transform: 'rotate(0deg)',
            transition: '0.25s all ease-in-out',
            '&.closed': {
              transform: 'rotate(180deg)',
              transition: '0.25s all ease-in-out',
            },
          }}
        >
          <ExpandMore
            sx={{
              fontSize: '40px',
              fill: colorsConfig.mainBlack,
            }}
          />
        </IconButton>
      </Box>
      {showHints ? (
        <Box>
          {hints.map((item, index) => {
            return (
              <Box
                key={index}
                sx={{
                  mb: '20px',
                  '&:last-child': {
                    mb: 0,
                  },
                }}
              >
                <TextNormal>
                  #{index + 1} {item.hint}
                  <br />
                  {role === 'COMPANY' ? (
                    <Link
                      sx={{
                        color: colorsConfig.mainBlue,
                        cursor: 'pointer',
                        transition: '0.25s all ease-in-out',
                        '&:hover': {
                          transition: '0.25s all ease-in-out',
                          color: colorsConfig.buttonHover,
                        },
                      }}
                      onClick={() => {
                        handleGoToPage(item.navigate, item.tab)
                      }}
                    >
                      {item.linkText}
                    </Link>
                  ) : null}
                </TextNormal>
              </Box>
            )
          })}
        </Box>
      ) : null}
    </Box>
  )
}

export default QualityCheckHints
