import React, { useEffect, useState } from 'react'
import { Box, useMediaQuery } from '@mui/material'
import { colorsConfig } from '../../config/themeConfig'
import TextH1 from '../../ui/Text/TextH1'
import TextNormal from '../../ui/Text/TextNormal'
import TextBoldNormal from '../../ui/Text/TextBoldNormal'
import IconDoubleArrows from '../../assests/img/icons/icon-double-arrows.svg'
import ButtonAttention from '../../ui/Buttons/ButtonAttention'
import Preloader from '../Preloader'
import { privateRequest } from '../../utils/request'
import { API_ENDPOINTS } from '../../config/default'
import { downloadFileFromPrivateBucket } from '../../utils/downloadFile'
import { toast } from 'react-toastify'
import { useSelector } from 'react-redux'
import ModalWrap from './ModalWrap'
import TextFieldSmallEdible from '../../ui/TextFieldSmallEdible'
import { useTranslation, Trans } from 'react-i18next'
import { CURRENCY, REGION_LOCALE } from '../../utils/constants/global.constants'
import { apiConstants } from '../../api/api'

const RequestCoinsModal = ({ open, setOpen, onFinish, title, description }) => {
  const { t } = useTranslation()
  const currency = CURRENCY[REGION_LOCALE]
  const xsOnly = useMediaQuery('(max-width: 600px)')

  const company = useSelector((state) => state.auth.company)

  const [coinsAmount, setCoinsAmount] = useState(50)
  const [localCurrencyAmount, setLocalCurrencyAmount] = useState(200)
  const [generatingInvoice, setGeneratingInvoice] = useState(false)

  const handleSetLocalCurrencyAmount = () => {
    if (coinsAmount * 4 !== localCurrencyAmount) {
      setLocalCurrencyAmount(coinsAmount * 4)
    }
  }

  const handleSetCoinsAmount = () => {
    if (coinsAmount * 4 !== localCurrencyAmount) {
      setCoinsAmount(localCurrencyAmount / 4)
    }
  }

  const handleSubmit = () => {
    setGeneratingInvoice(true)
    privateRequest
      .post(API_ENDPOINTS.coins.depositDemand, { amount: coinsAmount })
      .then((response) => {
        privateRequest
          .get(response.data.invoice)
          .then((responseInvoice) => {
            privateRequest
              .get(
                API_ENDPOINTS.privateFiles(responseInvoice.data.file['@id'].replace('/files/', '')),
              )
              .then(async (fileResponse) => {
                await downloadFileFromPrivateBucket(
                  fileResponse.data.signedUrl,
                  company.name
                    ? `Invoice for ${company.name} (${coinsAmount * 4} ${currency})`
                    : `Invoice (${coinsAmount * 4} ${currency})`,
                )
                setGeneratingInvoice(false)
                if (onFinish) {
                  onFinish()
                }
                setOpen(false)
              })
              .catch((e) => {
                toast.error(
                  e?.response?.data?.[apiConstants.hydraDescription] ?? e ?? 'Unknown error',
                )
                setGeneratingInvoice(false)
              })
          })
          .catch((e) => {
            toast.error(e?.response?.data?.[apiConstants.hydraDescription] ?? e ?? 'Unknown error')
            setGeneratingInvoice(false)
          })
      })
      .catch((e) => {
        toast.error(e?.response?.data?.[apiConstants.hydraDescription] ?? e ?? 'Unknown error')
        setGeneratingInvoice(false)
      })
  }

  useEffect(() => {
    handleSetLocalCurrencyAmount()
  }, [coinsAmount])

  useEffect(() => {
    handleSetCoinsAmount()
  }, [localCurrencyAmount])

  return (
    <ModalWrap
      open={open}
      setOpen={setOpen}
      wrapperStyles={{
        width: 680,
      }}
    >
      <TextH1
        className={xsOnly ? 'xs-only' : ''}
        sx={{
          mb: '16px',
          '&.xs-only': {
            fontSize: '24px',
          },
        }}
      >
        {title ? t(title) : t('modal_title_request_fleatify_coins')}
      </TextH1>
      <TextNormal
        className={xsOnly ? 'xs-only' : ''}
        sx={{
          mb: '30px',
          '&.xs-only': {
            fontSize: '14px',
          },
        }}
      >
        {description ? t(description) : t('modal_p_request_coins1')}
        {/*<br />*/}
        {/*<Trans*/}
        {/*  components={{*/}
        {/*    a: <span style={{ color: colorsConfig.mainBlue }} />,*/}
        {/*  }}*/}
        {/*>*/}
        {/*  modal_p_request_coins2*/}
        {/*</Trans>*/}
      </TextNormal>
      <TextBoldNormal
        sx={{
          mb: '16px',
        }}
      >
        {t('p_enter_amount')} 1 FC = 4 {currency}
      </TextBoldNormal>
      <Box
        className={xsOnly ? 'xs-only' : ''}
        sx={{
          display: 'flex',
          '&.xs-only': {
            display: 'block',
          },
        }}
      >
        <Box
          className={xsOnly ? 'xs-only' : ''}
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
            mb: '31px',
            position: 'relative',
            '&.xs-only': {
              display: 'block',
            },
          }}
        >
          <Box
            className={xsOnly ? 'xs-only' : ''}
            sx={{
              position: 'absolute',
              width: '30px',
              height: '21px',
              top: '50%',
              mt: '-10.5px',
              left: '50%',
              ml: '-15px',
              '&.xs-only': {
                transform: 'rotate(90deg)',
              },
            }}
          >
            <img src={IconDoubleArrows} alt="" />
          </Box>
          <Box
            className={xsOnly ? 'xs-only' : ''}
            sx={{
              mr: '70px',
              position: 'relative',
              '&.xs-only': {
                mr: 0,
                mb: '50px',
              },
            }}
          >
            <TextFieldSmallEdible
              type="text"
              className={xsOnly ? 'xs-only' : ''}
              value={coinsAmount}
              inputProps={{
                inputMode: 'numeric',
              }}
              onChange={(e) => {
                setCoinsAmount(parseFloat(e.target.value.replace(/[^0-9.]/g, '')))
              }}
              sx={{
                width: '168px',
                '&.xs-only': {
                  width: '100%',
                },
              }}
            />
            <TextNormal
              sx={{
                color: colorsConfig.iconGray,
                fontSize: '14px',
                position: 'absolute',
                top: '9px',
                right: '11px',
                '&::after': {
                  content: '" "',
                  display: 'block',
                  position: 'absolute',
                  height: '16px',
                  width: '1px',
                  background: colorsConfig.lightGray,
                  left: '-10px',
                  top: '50%',
                  mt: '-8px',
                },
              }}
            >
              FC
            </TextNormal>
          </Box>
          <Box
            sx={{
              position: 'relative',
            }}
          >
            <TextFieldSmallEdible
              value={localCurrencyAmount}
              inputProps={{
                inputMode: 'numeric',
              }}
              onChange={(e) => {
                setLocalCurrencyAmount(parseFloat(e.target.value.replace(/[^0-9.]/g, '')))
              }}
              className={xsOnly ? 'xs-only' : ''}
              type="text"
              sx={{
                width: '168px',
                '&.xs-only': {
                  width: '100%',
                },
              }}
            />
            <TextNormal
              sx={{
                color: colorsConfig.iconGray,
                fontSize: '14px',
                position: 'absolute',
                top: '9px',
                right: '11px',
                '&::after': {
                  content: '" "',
                  display: 'block',
                  position: 'absolute',
                  height: '16px',
                  width: '1px',
                  background: colorsConfig.lightGray,
                  left: '-10px',
                  top: '50%',
                  mt: '-8px',
                },
              }}
            >
              {currency}
            </TextNormal>
          </Box>
        </Box>
      </Box>
      <TextNormal
        sx={{
          fontSize: '14px',
          color: colorsConfig.lightGray,
          mb: '18px',
        }}
      >
        {t('modal_p_request_coins3')}
      </TextNormal>
      <ButtonAttention
        fullWidth
        disabled={!coinsAmount || !localCurrencyAmount || generatingInvoice}
        onClick={() => {
          handleSubmit(coinsAmount)
        }}
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {t('button_get_invoice')}
        {generatingInvoice ? <Preloader type={'gooey'} /> : null}
      </ButtonAttention>
    </ModalWrap>
  )
}

export default RequestCoinsModal
