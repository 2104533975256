import React, { useState } from 'react'
import {
  Box,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import useInput from '../../hooks/useInput'
import { useNavigate } from 'react-router'
import { publicRequest } from '../../utils/request'
import { API_ENDPOINTS } from '../../config/default'
import { toast } from 'react-toastify'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import { useDispatch } from 'react-redux'
import { signIn } from '../../store/features/authSlice'

const SignInPromo = (props) => {
  const { setGetStartedSection } = props

  const themeColors = {
    mainBlack: '#3D3D3D',
    mainBlue: '#007DFF',
    gray: '#757575',
  }

  const inputStyles = {
    '& .MuiInputBase-input': {
      border: '1px solid transparent',
      boxShadow: '0 0 10px 0 rgba(0,0,0,.1) !important',
      borderRadius: '4px',
      fontSize: '14px',
      fontFamily: '"Helvetica", sans-serif',
    },
  }

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const userName = useInput('', { isEmpty: true })
  const password = useInput('', { isEmpty: true, minLength: 4, maxLength: 25 })
  const [passwordVisible, setPasswordVisible] = useState(false)

  const handleSubmit = () => {
    const formData = {
      login: userName.value,
      password: password.value,
    }
    publicRequest
      .post(API_ENDPOINTS.login, formData)
      .then(async (response) => {
        const userData = {
          accessToken: response.data.token,
        }
        await window.localStorage.setItem('userData', JSON.stringify(userData))
        dispatch(signIn(response.data.token))
      })
      .catch(() => {
        toast.error('Invalid username or password, please try again')
      })
  }

  return (
    <Box>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography
            variant="h3"
            sx={{
              color: themeColors.mainBlack,
              fontFamily: '"Helvetica", sans-serif',
              fontSize: {
                xs: '24px',
                sm: '40px',
              },
              fontWeight: 700,
              mb: '10px',
              textAlign: {
                xs: 'center',
                sm: 'left',
              },
            }}
          >
            Sign In
          </Typography>
          <Typography
            variant="body2"
            sx={{
              color: themeColors.mainBlack,
              fontFamily: '"Helvetica", sans-serif',
              fontSize: {
                xs: '14px',
                sm: '16px',
              },
              fontWeight: 400,
              mb: {
                sm: '24px',
                xs: '6px',
              },
              textAlign: {
                xs: 'center',
                sm: 'left',
              },
            }}
          >
            Enter to your account
          </Typography>
        </Grid>
        <Grid item lg={6} xs={12}>
          <Stack spacing={2}>
            <TextField
              required
              fullWidth
              id="username"
              label="Username"
              name="username"
              autoComplete="username"
              type="text"
              onChange={(e) => {
                userName.onChange(e)
              }}
              onBlur={(e) => {
                userName.onBlur(e)
              }}
              value={userName.value}
              helperText={userName.firstBlur && userName.hasErrors ? userName.errorText : ''}
              error={userName.firstBlur && userName.hasErrors}
              sx={inputStyles}
              size="small"
            />
            <TextField
              required
              fullWidth
              name="password"
              label="Password"
              type={passwordVisible ? 'text' : 'password'}
              id="password"
              autoComplete="new-password"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => {
                        setPasswordVisible(!passwordVisible)
                      }}
                    >
                      {passwordVisible ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              onChange={(e) => {
                password.onChange(e)
              }}
              onBlur={(e) => {
                password.onBlur(e)
              }}
              value={password.value}
              helperText={password.firstBlur && password.hasErrors ? password.errorText : ''}
              error={password.firstBlur && password.hasErrors}
              sx={inputStyles}
              size="small"
            />
            <Button
              variant="contained"
              sx={{
                fontFamily: "'Helvetica', sans-serif",
                fontWeight: 700,
                py: '12px',
                px: '27px',
                width: '175px',
                height: '48px',
                borderRadius: '4px',
                background: themeColors.mainBlue,
                textTransform: 'none',
              }}
              onClick={() => {
                handleSubmit()
              }}
              disabled={userName.hasErrors || password.hasErrors}
            >
              Sign In
            </Button>
            <Link
              variant="body2"
              onClick={() => {
                setGetStartedSection(3)
              }}
              sx={{
                cursor: 'pointer',
              }}
            >
              Don't have an account yet? Sign up
            </Link>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  )
}

export default SignInPromo
