import {
  policyWorkflowStateNameKeys,
  policyWorkflowStates,
} from '../../../../../utils/constants/workflows.constants'
import { colorsConfig } from '../../../../../config/themeConfig'

export const policiesStatusCakeColors = [
  colorsConfig.mainBlue,
  colorsConfig.buttonYellow,
  colorsConfig.mainGreen,
  colorsConfig.fadedBlue,
  colorsConfig.darkRed,
]

export const getStatusCountByWorkflowState = (statusCounts, workflowState) => {
  return statusCounts?.find((e) => e?.workflowState === workflowState)?.count ?? 0
}

export const getPoliciesStatusCakePieChartData = (statusCounts, t) => [
  {
    label: t(policyWorkflowStateNameKeys[policyWorkflowStates.linked_to_dealer]),
    value: getStatusCountByWorkflowState(statusCounts, policyWorkflowStates.linked_to_dealer),
  },
  {
    label: t(policyWorkflowStateNameKeys[policyWorkflowStates.restitution_started]),
    value: getStatusCountByWorkflowState(statusCounts, policyWorkflowStates.restitution_started),
  },
  {
    label: t(policyWorkflowStateNameKeys[policyWorkflowStates.restitution_in_progress]),
    value: getStatusCountByWorkflowState(
      statusCounts,
      policyWorkflowStates.restitution_in_progress,
    ),
  },
  {
    label: t(policyWorkflowStateNameKeys[policyWorkflowStates.closed]),
    value: getStatusCountByWorkflowState(statusCounts, policyWorkflowStates.closed),
  },
  {
    label: t(policyWorkflowStateNameKeys[policyWorkflowStates.cancelled]),
    value: getStatusCountByWorkflowState(statusCounts, policyWorkflowStates.cancelled),
  },
]

export const getPoliciesStatusCakeStatusListData = (statusCounts, t) => [
  {
    name: t(policyWorkflowStateNameKeys[policyWorkflowStates.linked_to_dealer]),
    count: getStatusCountByWorkflowState(statusCounts, policyWorkflowStates.linked_to_dealer),
    workflowState: policyWorkflowStates.linked_to_dealer,
  },
  {
    name: t(policyWorkflowStateNameKeys[policyWorkflowStates.restitution_started]),
    count: getStatusCountByWorkflowState(statusCounts, policyWorkflowStates.restitution_started),
    workflowState: policyWorkflowStates.restitution_started,
  },
  {
    name: t(policyWorkflowStateNameKeys[policyWorkflowStates.restitution_in_progress]),
    count: getStatusCountByWorkflowState(
      statusCounts,
      policyWorkflowStates.restitution_in_progress,
    ),
    workflowState: policyWorkflowStates.restitution_in_progress,
  },
  {
    name: t(policyWorkflowStateNameKeys[policyWorkflowStates.closed]),
    count: getStatusCountByWorkflowState(statusCounts, policyWorkflowStates.closed),
    workflowState: policyWorkflowStates.closed,
  },
  {
    name: t(policyWorkflowStateNameKeys[policyWorkflowStates.cancelled]),
    count: getStatusCountByWorkflowState(statusCounts, policyWorkflowStates.cancelled),
    workflowState: policyWorkflowStates.cancelled,
  },
]
