import React from 'react'
import BoxShadowed from '../../../ui/Layout/BoxShadowed'
import { useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import ControlPointCheckbox from '../AppraisalBlock/ControlPointCheckbox'

const AppraisalInteriorConditions = ({ readonly, hideGaps }) => {
  const interiorConditions = useSelector((state) => state.car.appraisal.interiorConditions)

  return (
    <BoxShadowed
      className={hideGaps ? 'hide-gaps' : ''}
      sx={{
        mt: '30px',
        '&.hide-gaps': {
          mt: 0,
          boxShadow: 'none',
        },
      }}
    >
      <Grid container spacing="20px">
        {interiorConditions.map((item) => {
          return (
            <Grid key={item?.id} item md={6} xs={12}>
              <ControlPointCheckbox data={item} readonly={!!readonly} />
            </Grid>
          )
        })}
      </Grid>
    </BoxShadowed>
  )
}

export default AppraisalInteriorConditions
